import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';

const useListadoPagos = () => {

    //Todas las constantes
    const [Monto, setMonto] = useState('');
    const [esValidoMonto, setEsValidoMonto] = useState(true); // Estado para verificar si es válido
    const [numeroTarjeta, setNumeroTarjeta] = useState('');
    const [esValidoTarjeta, setEsValidoTarjeta] = useState(true); // Estado para verificar si es válido
    const [codigo, setCodigo] = useState('');
    const [esValidoCodigo, setEsValidoCodigo] = useState(true); // Estado para verificar si es válido
    const [fechaVencimiento, setFechaVencimiento] = useState('');
    const [esValidoFecha, setEsValidoFecha] = useState(true); // Estado para verificar si es válido
    const [nombreTitular, setNombreTitular] = useState('');
    const [esValidoTitular, setEsValidoTitular] = useState(true); // Estado para verificar si es válido
    const [showModal, setShowModal] = useState(false);
    const [cuentasSeleccionadas, setCuentasSeleccionadas] = useState([]);
    const navegar = useNavigate();
    const { putFetch } = useFetch();
    const { mensajeError, mensajeExitoso, mensajeAdvertencia } = useAlert();

    const estiloInputTarjeta = {
        borderColor: esValidoTarjeta ? '' : 'red'
    }

    const estiloInputFecha = {
        borderColor: esValidoFecha ? '' : 'red'
    }

    const estiloInputMonto = {
        borderColor: esValidoMonto ? '' : 'red'
    }

    const estiloInputCodigo = {
        borderColor: esValidoCodigo ? '' : 'red'
    }

    const estiloInputTitular = {
        borderColor: esValidoTitular ? '' : 'red'
    }
    const styleText = {
        margin: 0,
        textAlign: 'left'
    };

    const promps = {
        Monto: Monto,
        numeroTarjeta: numeroTarjeta,
        codigo: codigo,
        fechaVencimiento: fechaVencimiento,
        nombreTitular: nombreTitular,
        estiloInputTarjeta: estiloInputTarjeta,
        estiloInputFecha: estiloInputFecha,
        estiloInputMonto: estiloInputMonto,
        estiloInputCodigo: estiloInputCodigo,
        estiloInputTitular: estiloInputTitular,
        esValidoTarjeta: esValidoTarjeta,
        esValidoFecha: esValidoFecha,
        esValidoMonto: esValidoMonto,
        setNumeroTarjeta: setNumeroTarjeta,
        setFechaVencimiento: setFechaVencimiento,
        setMonto: setMonto,
        setCodigo: setCodigo,
        setNombreTitular: setNombreTitular,
        styleText: styleText
    };

    //Todas las funciones
    const ValidarEntradaTarjeta = (cadena) => {
        const regex = /^[0-9]+(-|\s)?([0-9]+(-|\s)?){3}[0-9]+$/;
        return regex.test(cadena);
    }

    const ValidarEntradaMonto = (cadena) => {
        const regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(cadena);
    }

    const ValidarMonto = (valor) => {
        return ValidarEntradaMonto(valor);
    }

    const validarTarjeta = (valor) => {
        // Verificar si la cadena contiene solo números, espacios y guiones
        if (!ValidarEntradaTarjeta(valor)) {
            return false;
        }

        let formattedNumber = valor.replace(/[^\d -]/g, '');
        // Eliminar espacios en blanco extra y asegurar un máximo de 4 separadores permitidos
        formattedNumber = formattedNumber.replace(/\s+/g, ' ').trim();
        // Validar que haya exactamente 16 dígitos numéricos en total
        const regex = /^(\d{4}[ -]?){3}\d{4}$/;
        // Comprobar si la cadena formateada coincide con la expresión regular y tiene la longitud adecuada
        return regex.test(formattedNumber) && formattedNumber.length === 19;
    }

    const validarFecha = (mes) => {
        if (!mes) {
            return false;
        }

        const fechaActual = new Date();
        const fechaVencimientoDate = new Date(mes);

        // Comparar solo por año y mes, ignorando el día
        // Esto es porque el <input type="month"> devuelve siempre el primer día del mes seleccionado
        const añoActual = fechaActual.getFullYear();
        const mesActual = fechaActual.getMonth();

        const añoSeleccionado = fechaVencimientoDate.getFullYear();
        const mesSeleccionado = fechaVencimientoDate.getMonth();

        if (añoSeleccionado > añoActual) {
            return true; // El año seleccionado es mayor
        } else if (añoSeleccionado === añoActual && mesSeleccionado > mesActual) {
            return true; // El mes seleccionado es mayor en el mismo año
        } else if (añoSeleccionado === añoActual && mesSeleccionado === mesActual) {
            return true; // Es el mismo mes y año actual
        } else {
            return false; // El mes seleccionado no es mayor
        }
    }

    const validarTitular = (valor) => {
        if (!valor || valor.trim() === '') {
            return false;
        }
        return true;
    }

    const validarCodigo = (valor) => {
        if (!valor || valor.trim() === '') {
            return false;
        }
        return true;
    }

    const verificarCamposVacios = () => {
        if (Monto !== "") {
            const campos = [
                { valor: numeroTarjeta, nombre: 'Número de Tarjeta' },
                { valor: codigo, nombre: 'Código' },
                { valor: fechaVencimiento, nombre: 'Fecha de Vencimiento' },
                { valor: nombreTitular, nombre: 'Nombre del Titular' },
            ];

            // Filtra los campos que están vacíos o no son cadenas de texto válidas
            const camposVacios = campos.filter(campo => {
                // Verificar si campo.valor es una cadena de texto y no está vacío
                if (typeof campo.valor !== 'string' || campo.valor.trim() === '') {
                    return true; // El campo está vacío o no es válido
                }
                return false; // El campo tiene un valor válido
            }).map(campo => campo.nombre); // Mapea para obtener solo los nombres de los campos vacíos

            return camposVacios;
        } else {
            return []; // Retorna un array vacío si Monto está vacío
        }
    };

    const Limpiarhandle = () => {
        setMonto('');
        setNumeroTarjeta('');
        setCodigo('');
        setFechaVencimiento('');
        setNombreTitular('');

        setEsValidoMonto(true);
        setEsValidoTarjeta(true);
        setEsValidoFecha(true);
        setEsValidoCodigo(true);
        setEsValidoTitular(true);

        setCuentasSeleccionadas([]);
    }

    const OpenModal = (valor, cuentasPagar) => {
        let montoFormateado = parseFloat(valor).toFixed(2);  // Esto formatea el número a dos decimales
        if (cuentasPagar.length > 0) {
            setMonto(montoFormateado);
            setCuentasSeleccionadas(cuentasPagar);
            setShowModal(true);
        } else {
            mensajeAdvertencia('Seleccione al menos un pago!');
        };
    };

    //Todos los puts
    const putOnClick = async (id, datos) => {
        const urlParcial = `detalles_cuentas_por_cobrar/${id}`;

        try {
            const respuesta = await putFetch(urlParcial, datos);
            Limpiarhandle();
            setShowModal(false);
            return respuesta;
        } catch (error) {
            throw error;
        }
    };

    const handleClickValidar = () => {
        // Realiza las validaciones al hacer clic en el botón
        const esMontoValido = ValidarMonto(Monto);
        const esTarjetaValida = validarTarjeta(numeroTarjeta);
        const esFechaValida = validarFecha(fechaVencimiento);
        const esCodigoValido = validarCodigo(codigo);
        const esTitularValido = validarTitular(nombreTitular);
        console.log(fechaVencimiento);

        // Actualizar estados
        setEsValidoMonto(esMontoValido);
        setEsValidoTarjeta(esTarjetaValida);
        setEsValidoFecha(esFechaValida);
        setEsValidoCodigo(esCodigoValido);
        setEsValidoTitular(esTitularValido);

        const camposVacios = verificarCamposVacios();

        if (camposVacios.length > 0) {
            mensajeAdvertencia("!Por favor complete todos los campos!");
            return;
        }

        if (esMontoValido && esTarjetaValida && esFechaValida) {
            if (cuentasSeleccionadas.length > 0) {
                const promesas = [];
                cuentasSeleccionadas.forEach(cuenta => {

                    const promesa = putOnClick(cuenta.id, cuenta.datos)
                        .then(respuesta => {
                            return true; // Indicar éxito si es necesario
                        })
                        .catch(error => {
                            return false; // Indicar error si es necesario
                        });

                    promesas.push(promesa);
                });
                Promise.all(promesas)
                    .then(resultados => {

                        const exitoso = resultados.every(resultado => resultado === true);
                        if (exitoso) {
                            mensajeExitoso('Pago realizado exitosamente!');
                            navegar('/miscursos');
                        } else {
                            mensajeError('Hubo un problema al realizar algunos pagos');
                        }
                    })
                    .catch(error => {
                        mensajeError('Error al esperar promesas:', error);
                    });
                return;
            }
        }
    };

    const handleClose = () => {
        setShowModal(false);
        Limpiarhandle();
    };

    const handleContinue = () => {
        handleClickValidar();
    };

    const handleCancel = () => {
        setShowModal(false);
        Limpiarhandle();
    };

    return {
        handleCancel,
        handleClose,
        handleContinue,
        OpenModal,
        showModal,
        promps,
        handleClickValidar
    }
}

export { useListadoPagos }