import React, { useEffect } from 'react';
import { LoginButton } from '../buttons/LoginButton';
import { gapi } from 'gapi-script';
import { useUsuarios } from './usuarios/useUsuarios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../auth/authConfig';

const Login = () => {
  const { getUsuario } = useUsuarios();

  const onSuccess = (res) => {
    getUsuario(res, true);
  };

  const onFailure = (res) => {
    console.log('Login Failed: res: ', res);
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID_GOOGLE,
        scope: '',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  const { instance } = useMsal()

  const handleLogin = async () => {
    try {
      // Inicia el login popup
      const loginResponse = await instance.loginPopup(loginRequest);

      // Obtiene un token de acceso silenciosamente
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: loginResponse.account,
      });

      const accessToken = tokenResponse.accessToken;

      // Llama a la API de Microsoft Graph
      const userInfo = await fetch("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((res) => res.json());

      // Aquí se tiene el nombre y correo del usuario
      getUsuario(userInfo, false, accessToken);

    } catch (error) {
      console.error("Error durante el login o fetch:", error);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="shadow p-3 mb-5 bg-color0 rounded text-center">
            <div>
              <h1 className='text-center text-color0'>Inicio de sesión</h1>
            </div>
            <div>
              <img
                src="https://www.crwflags.com/FOTW/images/s/sv_uso.gif"
                alt="Logo universidad de Sonsonate"
                style={{
                  borderRadius: "10px", /* Esquinas redondeadas */
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", /* Sombra */
                  width: "auto", /* Ajusta según sea necesario */
                  height: "auto" /* Mantiene la proporción */
                }}
              />
              <div className="mb-1 col-md-12">
                <div className='mt-2 col-md-12'>
                  <LoginButton onFailure={onFailure} onSuccess={onSuccess} />
                  <button className='mt-2 col-md-8'
                    onClick={handleLogin}
                    style={{
                      padding: '8px 10px',
                      fontSize: '14px',
                      color: '#757575',
                      backgroundColor: 'white',
                      border: '2px solid rgba(0, 0, 0, 0.2)',
                      borderRadius: '5px',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '18px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <img
                      src="https://www.microsoft.com/favicon.ico"
                      alt="Microsoft"
                      width="20"
                      height="20"
                    />
                    Iniciar sesión con Microsoft
                  </button>
                </div>
              </div>
            </div>
            <div className="text-color0">
              Universidad de Sonsonate
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Login };
