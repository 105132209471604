import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import authService from '../../services/authService';
import { FiMail, FiLock } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await authService.login(email, password);
      if (response) {
        // const token = response.data.token;
        login(response); // Autenticar al usuario
        navigate('/principal'); // Redirigir al usuario al dashboard o página principal 
        window.location.reload(); // Forzar una recarga
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.redirect) {
        setErrorMessage('Cuenta no activada. Redirigiendo para reenviar correo de activación...');
        setTimeout(() => {
          navigate(error.response.data.redirect); // Redirigir a la página de reenvío de activación
        }, 3000);  // Esperar 3 segundos antes de redirigir
      } else {
        setErrorMessage('Error al iniciar sesión. Por favor, verifica tu correo y contraseña.');
      }
    }
  };

  return (
    <div className="max-w-md px-6 py-2 mx-auto bg-white">
      <form onSubmit={handleLogin}>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="email">
            Correo electrónico
          </label>
          <div className="relative">
            <FiMail className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 top-1/2 left-3" />
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Correo electrónico"
              className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="password">
            Contraseña
          </label>
          <div className="relative">
            <FiLock className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 top-1/2 left-3" />
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
            />
          </div>
      {errorMessage && <p className="p-2 mt-4 text-center text-red-600 bg-red-100 rounded-md">{errorMessage}</p>}
        </div>
        <button type="submit" className="w-full px-4 py-3 text-lg font-semibold text-white transition duration-300 transform rounded-lg shadow-md bg-bluelight hover:bg-lightgreentxt hover:scale-105 focus:outline-none">
          Iniciar sesión
        </button>
      </form>
      <div className="mt-6 text-center">
        <Link to="/resend-activation" className="block text-sm font-semibold text-darkbluetxt hover:text-lightgreentxt">
          ¿No recibiste el correo de activación?
        </Link>
        {/*
        link oculto
        <Link to="/request-password-reset" className="block mt-2 text-sm font-semibold text-darkbluetxt hover:text-lightgreentxt">
          ¿Olvidaste tu contraseña?
        </Link>
         */}
      </div>
    </div>
  );
};

export default LoginForm;
