
function useFetch() {
  const urlBase = "https://edc-api.usonsonate.edu.sv:4443/cursos/v1/";
  //const urlBase = 'http://localhost:3020/cursos/v1/'

  const getFetch = (urlParcial) => {
    const url = urlBase.concat(urlParcial);
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        //*credentials: 'include' // Asegura que las cookies se envíen con la solicitud
      })
        .then((response) => {
          // Verificar si la respuesta es JSON
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            return response.json(); // Si es JSON, procesar como JSON
          } else {
            return response.text(); // Si no es JSON, procesar como texto
          }
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  };


  const postFetch = (urlParcial, datos) => {
    const url = urlBase.concat(urlParcial);

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // Aquí podrías añadir un token de autenticación si es necesario
          // 'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(datos),
        //credentials: 'include'
      };

      fetch(url, payload)
        .then((response) => {
          // Verificar si la respuesta es JSON
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            return response.json(); // Si es JSON, procesar como JSON
          } else {
            return response.text(); // Si no es JSON, procesar como texto
          }
        })
        .then((data) => resolve(data))
        .catch((error) => {
          console.log('error->', error);
          reject(error);
        });
    });
  };


  const putFetch = (urlParcial, datos) => {
    const url = urlBase.concat(urlParcial);

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'// Añadir el token al encabezado
        },
        body: JSON.stringify(datos),
        //credentials: 'include'
      };

      fetch(url, payload)
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log('error->', error);
          reject(error);
        });
    });
  };

  const deleteFetch = (urlParcial) => {
    const url = urlBase.concat(urlParcial);

    return new Promise((resolve, reject) => {
      const payload = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json' // Añadir el token al encabezado
        }
      };

      fetch(url, payload)
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log('error->', error);
          reject(error);
        });
    });
  };

  return { getFetch, postFetch, putFetch, deleteFetch };
}

export { useFetch };
