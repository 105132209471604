import React from 'react'

function GruposModal({ grupoSeleccionado, handleChange, instructores }) {

    return (
        <form className="row g-3 needs-validation" noValidate>
            <div className="col-md-2 d-none">
                <label htmlFor="txtIdCurso" className="form-label">IDCURSO</label>
                <input
                    id='txtIdCurso'
                    onChange={handleChange}
                    value={(grupoSeleccionado) ? grupoSeleccionado.idCurso ? grupoSeleccionado.idCurso : '' : ''}
                    type="number"
                    className="form-control"
                    name="idCurso"
                    disabled
                />
            </div>
            <div className="col-md-2">
                <label htmlFor="txtIdGrupo" className="form-label">ID</label>
                <input
                    id='txtIdGrupo'
                    onChange={handleChange}
                    value={(grupoSeleccionado) ? grupoSeleccionado.idGrupo ? grupoSeleccionado.idGrupo : '' : ''}
                    type="number"
                    className="form-control"
                    name="idGrupo"
                    disabled
                />
            </div>
            <div className="col-md-2">
                <label htmlFor="txtGrupo" className="form-label">Grupo</label>
                <input
                    id='txtGrupo'
                    onChange={handleChange}
                    value={(grupoSeleccionado) ? grupoSeleccionado.grupo ? grupoSeleccionado.grupo : '' : ''}
                    type="text"
                    className="form-control"
                    name="grupo"
                    required
                />
            </div>
            <div className="col-md-8">
                <label htmlFor="txtModalidad" className="form-label">Modalidad</label>
                <input
                    id='txtModalidad'
                    onChange={handleChange}
                    value={(grupoSeleccionado) ? grupoSeleccionado.modalidad ? grupoSeleccionado.modalidad : '' : ''}
                    type="text"
                    className="form-control"
                    name="modalidad"
                    required
                />
            </div>
            <div className="col-md-12">
                <label htmlFor="txtLugar" className="form-label">Lugar</label>
                <input
                    id='txtLugar'
                    onChange={handleChange}
                    value={(grupoSeleccionado) ? grupoSeleccionado.lugar ? grupoSeleccionado.lugar : '' : ''}
                    type="text"
                    className="form-control"
                    name="lugar"
                    required
                />
            </div>
            <div className="col-md-12">
                <label htmlFor="txtIdInstructor" className="form-label">Instructor</label>
                <select
                    id='txtIdInstructor'
                    onChange={handleChange}
                    value={(grupoSeleccionado) ? grupoSeleccionado.idUsuario || '' : ''}
                    className="form-control"
                    name="idUsuario"
                    required
                >
                    <option value="">Seleccione un Instructor</option>
                    {instructores.length > 0 ? instructores.map(ins => (
                        <option key={ins.idInstructor} value={ins.idInstructor}>
                            {ins.instructor}
                        </option>
                    )) : (
                        <option value="" disabled>No hay instructores disponibles</option>
                    )}

                </select>
            </div>
        </form>
    )
}

export { GruposModal }