import React, { useState} from 'react';
import axios from 'axios';
import NavbarPrincipal from './NavbarPrincipal';

const Reports = () => {
  
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear >= 2024 ? currentYear : 2024);
  const [reportType, setReportType] = useState('');
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Lista de tipos de reportes
  const reportTypes = [
    'Municipios',
    'Departamentos',
    'Distritos',
    'Edades',
    'Género',
    'Casco (rural o urbano)',
    'Inscritos por carrera en cada curso',
    'Inscritos por curso',
    'Inscritos en horarios por carrera',
    'Institución de procedencia',
    'Año de graduación',
    'Estudiantes que desertaron del curso preu',
    'Estudiantes que realizaron cambio de horario o de curso',
    'Estudiantes que reprobaron el curso',
    'Método por el cual se dio a conocer la carrera',
  ];

  // Generar una lista de años desde 2024 hasta el año actual
  const years = Array.from(
    { length: currentYear - 2024 + 1 },
    (_, i) => 2024 + i
  );

  const handleGenerateReport = async () => {
    if (!reportType) {
      alert('Por favor, selecciona un tipo de reporte.');
      return;
    }

    setLoading(true);
/*
    try {
      // Aquí hacemos una petición al backend con el año y el tipo de reporte
      const response = await axios.get(`${API_URL}/reports`, {
        params: {
          year,
          reportType,
        },
      });

      setReportData(response.data);
    } catch (error) {
      console.error('Error al generar el reporte:', error);
      setReportData([]);
    } finally {
      setLoading(false);
    }*/
  };

  return (
    <div className="flex flex-col min-h-screen">
      <NavbarPrincipal />
      <div className="flex flex-grow bg-gray-50">
        <div className="flex flex-col items-center justify-center flex-1 p-6">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">Generador de Reportes</h1>
          <div className="w-full max-w-xl p-6 bg-white border border-gray-300 rounded-lg shadow-md">
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700">Selecciona el Año:</label>
              <select
                value={year}
                onChange={(e) => setYear(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded"
              >
                {years.map((yearOption) => (
                  <option key={yearOption} value={yearOption}>
                    {yearOption}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700">Selecciona el Tipo de Reporte:</label>
              <select
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded"
              >
                <option value="">-- Selecciona una opción --</option>
                {reportTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={handleGenerateReport}
              className="w-full px-4 py-2 font-semibold text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Generar Reporte
            </button>
          </div>
          {loading ? (
            <p className="mt-6 text-center text-blue-500">Generando reporte...</p>
          ) : reportData.length > 0 ? (
            <div className="w-full max-w-4xl mt-6 overflow-x-auto">
              <h2 className="mb-4 text-2xl font-bold text-center text-gray-700">
                Reporte de {reportType} - {year}
              </h2>
              <table className="w-full bg-white border border-gray-300 rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200">
                    {Object.keys(reportData[0]).map((key) => (
                      <th key={key} className="p-3 text-left">
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      {Object.values(item).map((value, idx) => (
                        <td key={idx} className="p-3 border-t border-gray-300">
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            !loading &&
            reportType && (
              <p className="mt-6 text-center text-gray-600">
                No se encontraron datos para el reporte seleccionado.
              </p>
            )
          )}
        </div>
      </div>
    </div>
  );
  
};

export default Reports;