import { Grupos } from "./Grupos";

function Cursos(idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, precio, duracion, idTipoCurso, idDiplomado, idCiclo) {
    const grupos = []; // Array de Grupos

    function agregarGrupo(idGrupo, grupo, modalidad, lugar, idCurso, idUsuario) {
        // Verifica si el grupo ya existe
        let newGrupo = grupos.find(g => g.idGrupo === idGrupo);
        if (!newGrupo) {
            newGrupo = Grupos(idGrupo, grupo, modalidad, lugar, idCurso, idUsuario);
            grupos.push(newGrupo);
        }
        return newGrupo; // Retorna el grupo (para agregar horarios después)
    }

    return {
        idCurso,
        curso,
        imagen,
        fechaInicio,
        fechaFinalizacion,
        estado,
        precio,
        duracion,
        idTipoCurso,
        idDiplomado,
        idCiclo,
        grupos,
        agregarGrupo
    };
}
export { Cursos }