import { useNavigate } from 'react-router-dom';

const WaitingRoom = ({ estado, tipoInscripcion, exonerado }) => {
    const navigate = useNavigate();

    const renderContent = () => {
        if (estado === 'form_completo') {
            return (
                <div>
                    <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Inscripción Completa</h2>
                    <p className="mb-4 text-center text-gray-600">
                        ¡Felicidades! Has completado exitosamente el proceso de inscripción. Ahora puedes proceder a consultar los horarios y grupos asignados para tus clases.
                    </p>
                    <div className="flex justify-center"> {/* Contenedor para centrar el botón */}
                        <button
                            onClick={() => navigate('/schedules-and-groups')}
                            className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                        >
                            Ver Horarios y Grupos
                        </button>
                    </div>
                </div>

            );
        } else if (estado === 'form_exonerado_completo') {
            return (
                <div>
                    {tipoInscripcion === '2' && (
                        <div>
                            <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Proceso de Exoneración en Curso</h2>
                            <p className="mb-4 text-center text-gray-600">
                                Si completaste algún proceso de ingreso en otra universidad, por favor acércate a la Unidad de Atención al Estudiante en nuestras instalaciones. Recuerda presentar un comprobante que respalde dicho proceso de ingreso en la institución anterior para validar tu solicitud de exoneración.
                            </p>
                            <div className="flex justify-center"> {/* Contenedor para centrar el botón */}
                                <button
                                    onClick={() => navigate('/payment')}
                                    disabled={!exonerado}
                                    className={`px-6 py-2 text-lg font-semibold text-white rounded-lg ${exonerado ? 'bg-lightgreentxt hover:bg-darkgreentxt' : 'bg-gray-400 cursor-not-allowed'
                                        }`}
                                >
                                    Ir a Pago
                                </button>
                            </div>
                        </div>

                    )}
                    {tipoInscripcion === '3' && (
                        <div>
                            <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Proceso de Equivalencias y Exoneración</h2>
                            <p className="mb-4 text-gray-600">
                                Como estudiante que ha iniciado una carrera en otra universidad, puedes elegir entre iniciar el proceso de equivalencias o seguir con la exoneración para empezar desde el inicio de la carrera. Selecciona el proceso que deseas continuar y comunícate con el decano correspondiente.
                            </p>

                            {/* Ingeniería */}
                            <div className="mb-8">
                                <h3 className="text-lg font-semibold text-gray-800">Ingeniería</h3>
                                <p className="mt-2 text-gray-600">¿Qué proceso deseas seguir?</p>

                                <h4 className="mt-4 font-semibold text-gray-700">Equivalencias</h4>
                                <p className="mb-2 text-gray-600">
                                    Para comenzar el proceso de equivalencias en la Facultad de Ingeniería, comunícate con:
                                </p>
                                <p className="text-gray-600">
                                    👉🏼 <strong>Lic. Carlos Alberto Martínez Zaragoza</strong> <br />
                                    📞 WhatsApp: <a href="https://wa.me/50376030352" target="_blank" className="text-sky-600 hover:underline">7603-0352</a>
                                </p>
                                <p className="mt-2 text-gray-600">
                                    Al comunicarte, te sugerimos presentarte con tu nombre completo, especificar el nombre de la ingeniería de tu interés, mencionar el nivel de estudios que posees y que ya has completado el formulario de ingreso.
                                </p>

                                <h4 className="mt-4 font-semibold text-gray-700">Exoneración</h4>
                                <p className="text-gray-600">
                                    Si decides iniciar la carrera desde el principio mediante la exoneración, por favor preséntate en la Unidad de Atención al Estudiante. Lleva un documento que valide tus estudios previos o el inicio de estudios en otra universidad.
                                </p>
                            </div>

                            {/* Economía */}
                            <div className="mb-8">
                                <h3 className="text-lg font-semibold text-gray-800">Economía</h3>
                                <p className="mt-2 text-gray-600">¿Qué proceso deseas seguir?</p>

                                <h4 className="mt-4 font-semibold text-gray-700">Equivalencias</h4>
                                <p className="mb-2 text-gray-600">
                                    Para comenzar el proceso de equivalencias en la Facultad de Economía y Ciencias Sociales, comunícate con:
                                </p>
                                <p className="text-gray-600">
                                    👉🏼 <strong>Lic. Fernando Juan José Rodríguez Salazar</strong> <br />
                                    📞 WhatsApp: <a href="https://wa.me/50360715063" target="_blank" className="text-sky-600 hover:underline">6071-5063</a>
                                </p>
                                <p className="mt-2 text-gray-600">
                                    Al comunicarte, preséntate con tu nombre completo, especifica el nombre de la ingeniería de tu interés, y menciona que ya has completado el formulario de ingreso con los datos necesarios.
                                </p>

                                <h4 className="mt-4 font-semibold text-gray-700">Exoneración</h4>
                                <p className="text-gray-600">
                                    Si decides iniciar la carrera desde el principio mediante la exoneración, por favor preséntate en la Unidad de Atención al Estudiante con un documento que valide tus estudios previos o el inicio de estudios en otra universidad.
                                </p>
                            </div>

                            {/* Jurídica */}
                            <div className="mb-8">
                                <h3 className="text-lg font-semibold text-gray-800">Jurídica</h3>
                                <p className="mt-2 text-gray-600">¿Qué proceso deseas seguir?</p>

                                <h4 className="mt-4 font-semibold text-gray-700">Equivalencias</h4>
                                <p className="mb-2 text-gray-600">
                                    Para comenzar el proceso de equivalencias en la Facultad de Ciencias Jurídicas, comunícate con:
                                </p>
                                <p className="text-gray-600">
                                    👉🏼 <strong>Licda. Silvia Yolany López de Cruz</strong> <br />
                                    📞 WhatsApp: <a href="https://wa.me/50378619502" target="_blank" className="text-sky-600 hover:underline">7861-9502</a>
                                </p>
                                <p className="mt-2 text-gray-600">
                                    Al comunicarte, te sugerimos presentarte con tu nombre completo, especificar la ingeniería de tu interés, y mencionar que ya has completado el formulario de ingreso.
                                </p>

                                <h4 className="mt-4 font-semibold text-gray-700">Exoneración</h4>
                                <p className="text-gray-600">
                                    Si decides iniciar la carrera desde el principio mediante la exoneración, por favor preséntate en la Unidad de Atención al Estudiante con un documento que valide tus estudios previos o el inicio de estudios en otra universidad.
                                </p>
                            </div>

                            {/* Botón de Pago */}
                            <div className="flex justify-center mt-6">
                                <button
                                    onClick={() => navigate('/payment')}
                                    disabled={!exonerado}
                                    className={`px-6 py-2 text-lg font-semibold text-white rounded-lg ${exonerado ? 'bg-lightgreentxt hover:bg-darkgreentxt' : 'bg-gray-400 cursor-not-allowed'}`}
                                >
                                    Ir a Pago
                                </button>
                            </div>
                        </div>
                    )}

                    {tipoInscripcion === '4' && (
                        <div>
                            <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Proceso para Equivalencias y Exoneración de Carrera Terminada</h2>
                            <p className="mb-4 text-gray-600">
                                Como estudiante con una carrera finalizada, puedes optar por iniciar el proceso de equivalencias para convalidar materias o seguir con la exoneración para iniciar desde el comienzo. Selecciona la opción que deseas y comunícate con el decano correspondiente.
                            </p>

                            {/* Ingeniería */}
                            <div className="mb-8">
                                <h3 className="text-lg font-semibold text-gray-800">Ingeniería</h3>
                                <p className="mt-2 text-gray-600">¿Qué proceso deseas seguir?</p>

                                <h4 className="mt-4 font-semibold text-gray-700">Equivalencias</h4>
                                <p className="mb-2 text-gray-600">
                                    Para comenzar el proceso de equivalencias en la Facultad de Ingeniería, comunícate con:
                                </p>
                                <p className="text-gray-600">
                                    👉🏼 <strong>Lic. Carlos Alberto Martínez Zaragoza</strong> <br />
                                    📞 WhatsApp: <a href="https://wa.me/50376030352" target="_blank" className="text-sky-600 hover:underline">7603-0352</a>
                                </p>
                                <p className="mt-2 text-gray-600">
                                    Al comunicarte, te sugerimos presentarte con tu nombre completo, especificar la ingeniería de tu interés, y mencionar que has completado el formulario de ingreso y que posees una carrera finalizada.
                                </p>

                                <h4 className="mt-4 font-semibold text-gray-700">Exoneración</h4>
                                <p className="text-gray-600">
                                    Si decides iniciar la carrera desde el principio mediante la exoneración, acude a la Unidad de Atención al Estudiante con documentos que validen tus estudios previos en la carrera completada.
                                </p>
                            </div>

                            {/* Economía */}
                            <div className="mb-8">
                                <h3 className="text-lg font-semibold text-gray-800">Economía</h3>
                                <p className="mt-2 text-gray-600">¿Qué proceso deseas seguir?</p>

                                <h4 className="mt-4 font-semibold text-gray-700">Equivalencias</h4>
                                <p className="mb-2 text-gray-600">
                                    Para comenzar el proceso de equivalencias en la Facultad de Economía y Ciencias Sociales, comunícate con:
                                </p>
                                <p className="text-gray-600">
                                    👉🏼 <strong>Lic. Fernando Juan José Rodríguez Salazar</strong> <br />
                                    📞 WhatsApp: <a href="https://wa.me/50360715063" target="_blank" className="text-sky-600 hover:underline">6071-5063</a>
                                </p>
                                <p className="mt-2 text-gray-600">
                                    Al comunicarte, preséntate con tu nombre completo, especifica la carrera de tu interés, y menciona que tienes una carrera finalizada y has completado el formulario de ingreso.
                                </p>

                                <h4 className="mt-4 font-semibold text-gray-700">Exoneración</h4>
                                <p className="text-gray-600">
                                    Si decides comenzar desde el inicio mediante la exoneración, por favor preséntate en la Unidad de Atención al Estudiante y lleva un documento que certifique tus estudios completados.
                                </p>
                            </div>

                            {/* Jurídica */}
                            <div className="mb-8">
                                <h3 className="text-lg font-semibold text-gray-800">Jurídica</h3>
                                <p className="mt-2 text-gray-600">¿Qué proceso deseas seguir?</p>

                                <h4 className="mt-4 font-semibold text-gray-700">Equivalencias</h4>
                                <p className="mb-2 text-gray-600">
                                    Para comenzar el proceso de equivalencias en la Facultad de Ciencias Jurídicas, comunícate con:
                                </p>
                                <p className="text-gray-600">
                                    👉🏼 <strong>Licda. Silvia Yolany López de Cruz</strong> <br />
                                    📞 WhatsApp: <a href="https://wa.me/50378619502" target="_blank" className="text-sky-600 hover:underline">7861-9502</a>
                                </p>
                                <p className="mt-2 text-gray-600">
                                    Al comunicarte, preséntate con tu nombre completo, especifica la carrera de tu interés, y menciona que ya completaste el formulario y que tienes una carrera finalizada.
                                </p>

                                <h4 className="mt-4 font-semibold text-gray-700">Exoneración</h4>
                                <p className="text-gray-600">
                                    Si decides iniciar desde el principio mediante la exoneración, por favor preséntate en la Unidad de Atención al Estudiante con documentos que validen tu carrera terminada.
                                </p>
                            </div>

                            {/* Botón de Pago */}
                            <div className="flex justify-center mt-6">
                                <button
                                    onClick={() => navigate('/payment')}
                                    disabled={!exonerado}
                                    className={`px-6 py-2 text-lg font-semibold text-white rounded-lg ${exonerado ? 'bg-lightgreentxt hover:bg-darkgreentxt' : 'bg-gray-400 cursor-not-allowed'}`}
                                >
                                    Ir a Pago
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else if (estado === 'equivalencias_externas' && tipoInscripcion === '5') {
            return (
                <div className="p-6 ">
                    <h2 className="mb-8 text-2xl font-bold text-center text-sky-700">Proceso de Equivalencias Externas</h2>

                    <div className="mb-6">
                        <p className="leading-relaxed text-gray-600">
                            Las equivalencias se conceden por materias cursadas y aprobadas en instituciones de educación superior, tanto nacionales como extranjeras. A continuación, se detallan los requisitos y pasos para solicitar la equivalencia de estudios:
                        </p>
                    </div>

                    {/* Requisitos de Equivalencias Externas */}
                    <div className="mb-6">
                        <h3 className="mb-4 text-lg font-semibold text-gray-800">Requisitos para Solicitud de Equivalencias</h3>
                        <ol className="space-y-4 leading-relaxed text-gray-600 list-decimal list-inside">
                            <li>
                                <strong>Llenar Solicitud:</strong> Completar la solicitud de equivalencias en el formulario respectivo, proporcionado en la Secretaría de la Universidad.
                            </li>
                            <li>
                                <strong>Anexar Certificación de Notas:</strong> Adjuntar la certificación de notas de las materias cursadas y aprobadas, cuya equivalencia se solicita. La certificación debe incluir:
                                <ul className="ml-5 list-disc list-inside">
                                    <li>Nota obtenida</li>
                                    <li>Fecha de aprobación</li>
                                    <li>Unidades valorativas de cada materia</li>
                                </ul>
                                <p className="mt-2 text-gray-700">
                                    La certificación debe estar firmada y sellada por la autoridad competente de la institución de procedencia. Si es extranjera, debe estar autenticada, traducida al español en caso de ser necesario, y legalizada en forma oficial.
                                </p>
                            </li>
                            <li>
                                <strong>Programas de Materias:</strong> Adjuntar los programas de cada materia cuya equivalencia se solicita, acreditando su vigencia en la época cursada y aprobada. Los programas deben estar autenticados por las autoridades académicas de la institución de procedencia.
                            </li>
                            <li>
                                <strong>Pagar Aranceles:</strong> Realizar el pago de los aranceles correspondientes al proceso de equivalencias.
                            </li>
                            <li>
                                <strong>Certificación de Prácticas Reglamentarias:</strong> Si la carrera lo requiere, anexar la certificación de prácticas reglamentarias para aquellas materias específicas.
                            </li>
                        </ol>
                    </div>

                    {/* Instrucciones adicionales */}
                    <div className="mt-6">
                        <p className="leading-relaxed text-gray-600">
                            Es importante asegurarse de cumplir con todos los requisitos mencionados y entregar los documentos en buen estado. Para más detalles sobre el proceso o asesoría, puedes comunicarte con la Secretaría de la Universidad.
                        </p>
                    </div>
                </div>
            );
        } else if (estado === 'maestria') {
            return (
                <div className="p-6">
                    <h2 className="mb-8 text-2xl font-bold text-center text-sky-700">Inscripción en Maestrías</h2>

                    {/* Maestría en Neuropsicología del Aprendizaje */}
                    <div className="mb-10">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800">Maestría en Neuropsicología del Aprendizaje</h3>
                        <p className="mb-2 leading-relaxed text-gray-600">
                            Para iniciar el proceso de inscripción, comunícate con la directora de la Escuela de Educación:
                        </p>
                        <p className="mb-2 font-medium text-gray-800">
                            👉🏼 Licda. Ana María Zelidon de Lemus
                        </p>
                        <p className="mb-4 text-gray-800">
                            📞 WhatsApp: <a href="https://wa.me/50378619131" target="_blank" className="text-sky-600 hover:underline">7861-9131</a>
                        </p>
                        <p className="leading-relaxed text-gray-600">
                            Al comunicarte, preséntate con tu nombre completo, especifica el nombre de la maestría y menciona que has completado el formulario de ingreso.
                        </p>
                    </div>

                    {/* Maestrías en Economía */}
                    <div className="mb-6">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800">Maestría en Administración Financiera y Gerencia de Proyectos</h3>
                        <p className="mb-2 leading-relaxed text-gray-600">
                            Para iniciar el proceso de inscripción en estas maestrías, comunícate con el Decano de la Facultad de Economía y Ciencias Sociales:
                        </p>
                        <p className="mb-2 font-medium text-gray-800">
                            👉🏼 Lic. Fernando Juan José Rodríguez Salazar
                        </p>
                        <p className="mb-4 text-gray-800">
                            📞 WhatsApp: <a href="https://wa.me/50360715063" target="_blank" className="text-sky-600 hover:underline">6071-5063</a>
                        </p>
                        <p className="leading-relaxed text-gray-600">
                            Al comunicarte, preséntate con tu nombre completo, especifica el nombre de la maestría de tu interés y menciona que has completado el formulario de ingreso.
                        </p>
                    </div>
                </div>
            );
        } else if (estado === 'educacion') {
            return (
                <div className="p-6 ">
                    <h2 className="mb-8 text-2xl font-bold text-center text-sky-700">Proceso de Inscripción en Carreras de Educación</h2>

                    <div className="mb-10">
                        <p className="mb-2 leading-relaxed text-gray-600">
                            Para comenzar el proceso de inscripción en alguna de las carreras de educación, contacta a la directora de la Escuela de Educación:
                        </p>
                        <p className="mb-2 font-medium text-gray-800">
                            👉🏼 Licda. Ana María Zelidon de Lemus
                        </p>
                        <p className="mb-4 text-gray-800">
                            📞 WhatsApp: <a href="https://wa.me/50378619131" target="_blank" className="text-sky-600 hover:underline">7861-9131</a>
                        </p>
                        <p className="leading-relaxed text-gray-600">
                            Al comunicarte, preséntate con tu nombre completo, especifica el nombre de la carrera de tu interés y menciona que has completado el formulario de ingreso con los datos requeridos.
                        </p>
                    </div>

                    {/* Requisitos de ingreso */}
                    <div className="mb-6">
                        <h3 className="mb-4 text-lg font-semibold text-gray-800">Requisitos para Carreras en Educación</h3>
                        <ol className="space-y-2 leading-relaxed text-gray-600 list-decimal list-inside">
                            <li>Presentar el comprobante en original de la Prueba PAES o AVANZO con promedio INTERMEDIO o SUPERIOR.</li>
                            <li>Presentar la Certificación de Notas en Original, con un promedio en las 4 asignaturas básicas (Lenguaje, Matemática, Ciencias y Sociales) del 1° y 2° año de bachillerato, con nota igual o superior a 7.0.
                                <p className="mt-2 font-medium text-gray-700">
                                    IMPORTANTE: Si la PAES tiene un promedio de 8.0 o superior y la AVANZO está en nivel SUPERIOR, este requisito no es necesario.
                                </p>
                            </li>
                            <li>Entrevista vocacional.</li>
                            <li>Examen Psicológico.</li>
                            <li>Curso Propedéutico.</li>
                            <li>Los requisitos 3, 4 y 5 se aplicarán en la USO.</li>
                        </ol>
                    </div>
                </div>

            );

        } else if (estado === 'Inscrito' || estado === 'Completo') {
            return (
                <div>
                    <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Registro Completo</h2>
                    <p className="mb-4 text-center text-gray-600">
                        ¡Felicidades! Tu registro se ha completado exitosamente.
                    </p>
                    <div className="flex justify-center">
                        <button
                            onClick={() => navigate('/schedules-and-groups')}
                            className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>


            );
        } else if (estado === 'ciencia_datos') {
            return (
                <div>
                    <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Registro Completo</h2>
                    <p className="mb-4 text-center text-gray-600">
                        ¡Felicidades! Tu registro se ha completado exitosamente.
                    </p>
                    <p className="mb-4 text-center text-gray-600">
                        Para el Técnico en Ciencia de Datos, es requisito completar el Curso de Inducción, el cual incluye dos reuniones virtuales obligatorias.
                    </p>
                    <p className="mb-4 text-center text-gray-600">
                        Te recomendamos estar atento tanto a tu correo personal como a tu correo institucional, donde recibirás la información sobre las fechas y el acceso a estas reuniones.
                    </p>
                    <div className="flex justify-center">
                        <button
                            onClick={() => navigate('/payment')}
                            className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                        >
                            Siguiente
                        </button>
                    </div>
                </div>


            );
        }

        return (
            <div>
                <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Proceso de Inscripción en Curso</h2>
                <p className="mb-4 text-center text-gray-600">Continúa con el proceso en tu perfil.</p>
                <button
                    onClick={() => navigate('/profile')}
                    className="px-6 py-2 text-lg font-semibold text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                >
                    Ir al Perfil
                </button>
            </div>
        );
    };

    return (
        <div className="flex flex-col items-center justify-center h-full px-4 py-8 bg-gray-100">
            <div className="flex flex-col items-center w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
                {renderContent()}
            </div>
        </div>
    );
};

export default WaitingRoom;
