import { useState } from "react";
import { useFetch } from "../../tools/useFetch";
import { useAlert } from "../../hooks/useAlert";
import { DetallesPlantillas } from "../../models/DetallesPlantillas";
import { Plantillas } from "../../models/Plantillas";

const useDetallesPlantillas = () => {

  //ORDENAR CONSTANTES
  const { getFetch, postFetch, deleteFetch, putFetch } = useFetch();
  const [detallesPlantillas, setDetallesPlantillas] = useState([]);
  const { mensajeError } = useAlert();

  //ORDENAR GETS
  const getPlantillasByCicloAndCurso = (idCu, idCi) => {
    const urlParcial = `detalles_plantillas/${idCu}/${idCi}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos, estado, mensaje } = data;
        if (estado) {
          const datosEstandarizados = datos.map(item => {
            const detalles = DetallesPlantillas(
              item.idDetallePlantilla || 0,
              item.vencimiento || '',
              item.orden || '',
              item.generarRecargo || false,
              item.valorRecargo || 0,
              item.exigible || false,
              item.idPlantilla || 0,
              item.idCodigoServicio || 0,
              item.idCiclo || 0,
              item.idCurso || 0
            );
            const plantillas = Plantillas(
              item.idPlantilla || 0,
              item.concepto || '',
              item.monto || 0,
              item.tipo || '',
              item.plantilla || '',
              item.idCodigoServicio || 0
            );

            return {
              ...detalles,
              ...plantillas
            }
          }
          );

          setDetallesPlantillas(datosEstandarizados);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        console.log("Error->", error);
      });
  };

  //ORDENAR POST
  const postOnClick = (datos) => {
    const urlParcial = 'detalles_plantillas/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (!estado) {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        console.log('Error->', error);
      });
  };

  //ORDENAR PUT
  const putOnClick = (id, datos) => {
    const urlParcial = `detalles_plantillas/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (!estado) {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  //ORDENAR DELETE
  const deleteOnClick = (id) => {
    const urlParcial = `detalles_plantillas/${id}`;
    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (!estado) {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        console.log('Error->', error);
      });
  };

  return {
    detallesPlantillas,
    getPlantillasByCicloAndCurso,
    deleteDetallePlantilla: deleteOnClick,
    postDetallePlantilla: postOnClick,
    putDetallePlantilla: putOnClick
  }
}

export { useDetallesPlantillas }