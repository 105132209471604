import React from 'react'

const BtnEditar = ({add = '', databstogle, databstarget, titulo, onclick, type=''}) => {
    
    return (
        <>
            <button type={type} data-bs-toggle={databstogle} data-bs-target={databstarget} onClick={onclick} className={'btn editar-btn ' + add}>{titulo}</button>
        </>
    )
}

export { BtnEditar }