import React from 'react'

function DiplomadosModal({ diplomadoSeleccionado, handleChange }) {
  return (
    <form className="row g-3 needs-validation" noValidate>
      <div className="col-md-2">
        <label htmlFor="txtIdDiplomado" className="form-label">ID</label>
        <input
          id='txtIdDiplomado'
          onChange={handleChange}
          value={(diplomadoSeleccionado) ? (diplomadoSeleccionado.idDiplomado) ? diplomadoSeleccionado.idDiplomado : '' : ''}
          type="number"
          className="form-control"
          name="idDiplomado"
          disabled
        />
      </div>

      <div className="col-md-8">
        <label htmlFor="txtCurso" className="form-label">Diplomado</label>
        <input
          id='txtCurso'
          onChange={handleChange}
          value={(diplomadoSeleccionado) ? (diplomadoSeleccionado.nombreDiplomado) ? diplomadoSeleccionado.nombreDiplomado : '' : ''}
          type="text"
          className="form-control"
          name="nombreDiplomado"
          required
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="txtFechaInicio" className="form-label">Fecha Inicio</label>
        <input
          id='txtFechaInicio'
          onChange={handleChange}
          value={(diplomadoSeleccionado) ? (diplomadoSeleccionado.fechaInicio) ? diplomadoSeleccionado.fechaInicio.split('T')[0] : '' : ''}
          type="date"
          className="form-control"
          name="fechaInicio"
          disabled
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="txtFechaFin" className="form-label">Fecha Finalizacion</label>
        <input
          id='txtFechaFin'
          onChange={handleChange}
          value={(diplomadoSeleccionado) ? (diplomadoSeleccionado.fechaFin) ? diplomadoSeleccionado.fechaFin.split('T')[0] : '' : ''}
          type="date"
          className="form-control"
          name="fechaFinalizacion"
          disabled
        />
      </div>
      <div className="col-md-2">
        <label htmlFor="txtDuracion" className="form-label">Duracion</label>
        <input
          id='txtDuracion'
          onChange={handleChange}
          value={(diplomadoSeleccionado) ? (diplomadoSeleccionado.duracion) ? diplomadoSeleccionado.duracion : '' : ''}
          type="number"
          className="form-control"
          name="duracion"
          disabled
        />
      </div>
      <div className="col-md-2">
        <input
          className="form-check-input"
          type="checkbox"
          name="completo"
          id="chkCompleto"
          value={(diplomadoSeleccionado) ? diplomadoSeleccionado.completo : ''}
          checked={(diplomadoSeleccionado) ? diplomadoSeleccionado.completo : false}
          onChange={handleChange}
        />
        <label
          className="form-check-label"
          htmlFor="chkCompleto"
        >
          Completo
        </label>
      </div>
    </form>
  )
}

export { DiplomadosModal }