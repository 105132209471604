import React from 'react';
import { BtnEliminar } from '../buttons/BtnEliminar';

const AbrirModal = ({ ocultar, show, handleClose, handleContinue, handleCancel, titulo, children }) => {
    return (
        <div className={`modal ${show ? 'd-block' : 'd-none'}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                <div className="modal-header">
                            <h1 className="modal-title fs-5 text-color3" >{titulo}</h1>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={handleClose}
                                aria-label="Close"
                            ></button>
                        </div>
                    
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <BtnEliminar onclick={handleCancel} titulo={'Cancelar'}/>
                        <button type="button" className={ocultar ? "btn btn-secondary d-none" : "btn btn-secondary"} onClick={handleContinue}>Continuar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {AbrirModal};
