import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useLocation } from 'react-router-dom';
import { useAlert } from '../../hooks/useAlert';

const useListaHorarios = () => {

    const crearDatos = (horario = null) => {
        return {
            idHorario: horario ? horario.idHorario : '',
            diaInicio: horario ? horario.diaInicio : '',
            diaFin: horario ? horario.diaFin : '',
            horaInicio: horario ? horario.horaInicio : '12:00',
            horaFin: horario ? horario.horaFin : '12:00',
            dias: horario ? horario.dias : '',
            idGrupo: horario ? horario.idGrupo : ''
        };
    };

    //Todas las constantes
    const location = useLocation();
    const { idGrupo, grupo } = location.state || {};
    const [horarios, setHorarios] = useState([]);
    const [horarioSeleccionado, setHorarioSeleccionado] = useState(crearDatos());
    const [operacion, setOperacion] = useState(1);
    const [titulo, setTitulo] = useState('');
    const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
    const { mensajeError, mensajeExitoso, mensajeAdvertencia, preguntarConfirmacion } = useAlert();
    const [selectedDays, setSelectedDays] = useState([]);
    const daysOfWeek = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

    //Los useEffect
    useEffect(() => {
        getHorarios(idGrupo);
    }, [idGrupo]);

    //Funciones
    const openModal = (op, horarioSeleccionado = crearDatos()) => {
        setHorarioSeleccionado(horarioSeleccionado);
        setOperacion(op);
        setTitulo(op === 1 ? 'Registrar Horario' : 'Editar Horario');
    };

    const handleChangeChk = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            // Si el checkbox está seleccionado, añadimos el día al array
            setSelectedDays([...selectedDays, value]);
        } else {
            // Si se deselecciona, removemos el día del array
            setSelectedDays(selectedDays.filter(day => day !== value));
        }
    };

    const validarCampos = () => {
        if (selectedDays.length == 0) {
            mensajeAdvertencia('Seleccione al menos un dia!');
            return;
        }

        if (horarioSeleccionado.diaInicio === "") {
            mensajeAdvertencia('Defina dia de inicio!');
            return;
        }

        if (horarioSeleccionado.diaFin === "") {
            mensajeAdvertencia('Defina dia de fin!');
            return;
        }

        var dias = selectedDays.join(", ");

        const datos = {
            diaInicio: horarioSeleccionado.diaInicio,
            diaFin: horarioSeleccionado.diaFin,
            horaInicio: horarioSeleccionado.horaInicio,
            horaFin: horarioSeleccionado.horaFin,
            dias: dias,
            idGrupo: idGrupo
        };

        var dias = selectedDays.join(", ");
        if (operacion === 1) {
            postOnClick(datos);
        } else {
            const id = horarioSeleccionado.idHorario;
            //putOnClick(id, datos);
        }
        document.getElementById('btnCerrarModalHorarios').click();
    };

    //Todos los gets
    const getHorarios = (idGrupo) => {
        const urlParcial = `horarios/${idGrupo}`;

        getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;
                setHorarios(datos);
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    //Todos los post
    const postOnClick = (datos) => {
        const urlParcial = 'horarios/';
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso(mensaje);
                    if (idGrupo) {
                        getHorarios(idGrupo);
                    }
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    //Todos los put
    const putOnClick = (id, datos) => {
        const urlParcial = `horarios/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso(mensaje);
                    if (idGrupo) {
                        getHorarios(idGrupo);
                    }
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    //Todos los delete
    const deleteOnClick = async (id) => {
        const urlParcial = `horarios/${id}`;
        const confirmed = await preguntarConfirmacion(
            "¿Estás seguro que desea eliminar?",
            "Esta acción no se puede deshacer."
        );
      
        if (!confirmed) {
            return;
        } 
        deleteFetch(urlParcial)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso(mensaje);
                    if (idGrupo) {
                        getHorarios(idGrupo);
                    }
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });


    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Convertir el valor a número
        const numericValue = Number(value);

        if (name === "diaInicio" || name === "diaFin") {
            // Validar que el valor esté dentro del rango permitido
            if (numericValue >= 0 && numericValue <= 31) {
                // Actualizar el estado solo si el valor es válido
                setHorarioSeleccionado({
                    ...horarioSeleccionado,
                    [name]: numericValue
                });
            }
        } else {
            setHorarioSeleccionado({
                ...horarioSeleccionado,
                [name]: value
            });
        }
    };

    return {
        horarios,
        titulo,
        handleChange,
        openModal,
        deleteOnClick,
        grupo,
        handleChangeChk,
        daysOfWeek,
        horarioSeleccionado,
        validarCampos
    };
};

export { useListaHorarios };
