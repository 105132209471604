import { Cursos } from "./Cursos";

function Ciclos(idCiclo = 0, ciclo = '', anio = 0, activo = false) {
    const cursos = []; // Array de Cursos

    function agregarCurso(idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, precio, duracion, idTipoCurso, idDiplomado, idCiclo) {
        // Verifica si el curso ya existe
        let newCurso = cursos.find(c => c.idCurso === idCurso);
        if (!newCurso) {
            newCurso = Cursos(idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, precio, duracion, idTipoCurso, idDiplomado, idCiclo);
            cursos.push(newCurso);
        }
        return newCurso; // Retorna el curso
    }

    return {
        idCiclo,
        ciclo,
        anio,
        activo,
        cursos,
        agregarCurso
    };
}

export { Ciclos }
