import { useDetallesCuentasPorCobrar } from './useDetallesCuentasPorCobrar';
import { AbrirModal } from '../../components/AbrirModal';
import { ListadoPagos } from '../pagos/listadoPagos';
import { useListadoPagos } from '../pagos/useListadoPagos';
import { BtnAgregar } from '../../buttons/BtnAgregar';

const DetallesCuentasPorCobrar = () => {
    const {
        promps,
        OpenModal,
        handleCancel,
        handleContinue,
        handleClose,
        showModal
    } = useListadoPagos();

    const {
        cuentasSeleccionadas,
        error,
        cursos,
        cuentasPendientes,
        cursoSeleccionado,
        handleCursoChange,
        sumaMontosSeleccionados,
        formatFecha,
        //handleFinalizarSeleccion,
        handleCheckboxChange
    } = useDetallesCuentasPorCobrar();

    return (
        <div className="p-2 mt-custom">
            <div className="col-md-12">
                <h2>Seleccione el curso</h2>
                <div className='d-flex '>
                    <div className='flex-grow-1'>
                        <div className='col-md-6 m-2'>
                            <select
                                id='txtIdCurso'
                                onChange={handleCursoChange}
                                value={cursoSeleccionado.idCurso}
                                className="form-select form-select-md mb-3"
                                name="idCurso"
                                required
                            >
                                {cursos.length > 0 ? (
                                    cursos.map(ci => (
                                        <option key={ci.idCurso} value={ci.idCurso}>
                                            {ci.curso}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No hay cursos disponibles</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className='m-2'>
                        <BtnAgregar titulo={(cuentasSeleccionadas.length > 0) ? 'Pagar $' + sumaMontosSeleccionados.toFixed(2) : 'Pagar $0.00'} onclick={() => OpenModal(sumaMontosSeleccionados, cuentasSeleccionadas)} />
                    </div>
                </div>
            </div>
            {error ? (
                <div className="alert alert-danger">{error}</div>
            ) : (
                <>
                    {cuentasPendientes && cuentasPendientes.length > 0 ? (
                        cuentasPendientes.map((cuenta) => (
                            <div key={cuenta.idDetalleCuentaPorCobrar} className="card border-color4 m-3">
                                <div className="card-header d-flex bg-color2">
                                    <div className="flex-grow-1">
                                        <h4 className='text-color4'>{cuenta.concepto}</h4>
                                    </div>
                                    <div className="">
                                        <input className={(cuenta.estado === 'pendiente') ? 'form-check-input border-color4' : 'form-check-input border-color4 d-none'}
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(cuenta.idDetalleCuentaPorCobrar, cuenta.monto)}
                                            checked={cuentasSeleccionadas.some(cuentaSel => cuentaSel.id === cuenta.idDetalleCuentaPorCobrar)}
                                        />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title text-color5">VENCIMIENTO: {cuenta.fechaVencimiento ? formatFecha(cuenta.fechaVencimiento) : ''}</h5>
                                    <h5 className="card-text text-color5">
                                        Monto: ${cuenta.monto}
                                    </h5>
                                    <h5 className="card-text text-color5">
                                        Estado: {cuenta.estado}
                                    </h5>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No hay cuentas disponibles</div>
                    )}

                    <AbrirModal
                        show={showModal}
                        handleContinue={handleContinue}
                        handleCancel={handleCancel}
                        handleClose={handleClose}
                        titulo={"Formulario de pago"}
                        ocultar={false}
                    >
                        {/* Pasamos los props necesarios a ListadoPagos */}
                        <ListadoPagos
                            {...promps}
                        />
                    </AbrirModal>
                </>
            )}
        </div>
    );
}

export { DetallesCuentasPorCobrar };
