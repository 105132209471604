import { useEffect, useState } from 'react'
import { useFetch } from '../../tools/useFetch';
import { useListadoKits } from '../kits/useListadoKits'
import { useAlert } from "../../hooks/useAlert"

const useListadoEntregaKit = () => {

  const crearDatos = (entregaKit = null) => {
    return {
      idEntregaKit: entregaKit ? entregaKit.idEntregaKit : '',
      idKit: entregaKit ? entregaKit.idKit : '',
      idInscripcion: entregaKit ? entregaKit.idInscripcion : '',
      talla: entregaKit ? entregaKit.talla : '',
      entregado: entregaKit ? entregaKit.entregado : 0,
      fechaEntrega: entregaKit ? formatearFecha(entregaKit.fechaEntrega) : '' // Formatear la fecha aquí
    };
  };

  const {
    mensajeAdvertencia,
    mensajeError,
    mensajeExitoso,
    preguntarConfirmacion
  } = useAlert();
  const {
    kits
  } = useListadoKits();

  //ORDENAR CONSTANTES
  const [entregaKits, setEntregaKits] = useState([]);
  const [inscripcion, setInscripcion] = useState([]);
  const [entregaKitSeleccionado, setEntregaKitSeleccionado] = useState(crearDatos());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();

  //ORDENAR USEEFFECT
  useEffect(() => {
    getEntregaKit();
  }, []);

  useEffect(() => {
    getInscripcion();
  }, []);

  //ORDENAR FUNCIONES
  const openModal = (op, entregaKitSeleccionado = crearDatos()) => {
    setEntregaKitSeleccionado(entregaKitSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Entrega de Kit' : 'Editar Entrega de Kit');
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEntregaKitSeleccionado(entregaKitPrevio => ({
      ...entregaKitPrevio,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
    }));
  }

  const deleteData = (id) => {
    deleteOnClick(id);
  };

  const validarTalla = (valor) => {
    const regex = /^[0-9A-Za-z]$/;
    return typeof valor === 'string' && valor.length === 1 && regex.test(valor);
  };

  const validarCampos = () => {
    if (
      entregaKitSeleccionado.idKit === '' ||
      entregaKitSeleccionado.idInscripcion === '' ||
      entregaKitSeleccionado.talla === '' ||
      entregaKitSeleccionado.fechaEntrega === ''
    ) {
      mensajeAdvertencia("Por favor complete todos los campos");
      return;
    }
    if (!validarTalla(entregaKitSeleccionado.talla)) {
      mensajeAdvertencia("Talla inválida. Ingrese un caracter valido");
      return;
    }

    const datos = {
      idKit: entregaKitSeleccionado.idKit,
      idInscripcion: entregaKitSeleccionado.idInscripcion,
      talla: entregaKitSeleccionado.talla,
      entregado: entregaKitSeleccionado.entregado,
      fechaEntrega: entregaKitSeleccionado.fechaEntrega
    };

    const operacion = entregaKitSeleccionado.idEntregaKit ? 2 : 1;
    if (operacion === 1) {
      postOnClick(datos);
    } else {
      const id = entregaKitSeleccionado.idEntregaKit;
      putOnClick(id, datos);
    }
    document.getElementById('btnCerrarModal').click();
  };

  const formatearFecha = (fecha) => {
    const fechaObj = new Date(fecha);
    const year = fechaObj.getFullYear();
    let month = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    let day = fechaObj.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  //ORDENAR GETS
  const getEntregaKit = () => {
    const urlParcial = "entrega_kit/"
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        setEntregaKits(datos);
      })
      .catch((error) => {
        // console.log("Error->", error);
      })
  }

  const getInscripcion = () => {
    const urlParcial = "entrega_kit/inscripcion/"
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        setInscripcion(datos);
      })
      .catch((error) => {
        // console.log("Error->", error);
      })
  }

  //ORDENAR POST
  const postOnClick = (datos) => {
    const urlParcial = 'entrega_kit/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos insertados");
          getEntregaKit();
        } else {
          mensajeError("Error al insertar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  }

  //ORDENAR PUT
  const putOnClick = (id, datos) => {
    const urlParcial = `entrega_kit/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos actualizados");
          getEntregaKit();
        } else {
          mensajeError("Error al actuaizar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  }

  //ORDENAR DELETE
  const deleteOnClick = async (id) => {
    const urlParcial = `entrega_kit/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
  );

  if (!confirmed) {
      return;
  } 

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Registro eliminado");
          getEntregaKit();
        } else {
          mensajeError("Error al eliminar registro: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  }

  return {
    getEntregaKit,
    entregaKits,
    handleChange,
    crearDatos,
    titulo,
    openModal,
    entregaKitSeleccionado,
    deleteData,
    validarCampos,
    formatearFecha,
    kits,
    inscripcion
  }
}

export { useListadoEntregaKit }