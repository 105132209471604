import React from 'react';
import { FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="py-4 text-white bg-blue-900">
            <div className="container flex flex-col items-center justify-between mx-auto space-y-6 md:flex-row md:space-y-0">
                <div className="text-center md:text-left">
                    <p className="text-lg font-semibold">© 2024 Universidad de Sonsonate</p>
                    <p className="text-sm text-gray-300">Todos los derechos reservados.</p>
                </div>
                <div className="flex space-x-6">
                    <a href="https://twitter.com/somos_uso" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 transform hover:scale-110 hover:text-lightgreentxt">
                        <FaTwitter size={28} />
                    </a>
                    <a href="https://www.instagram.com/somos_uso" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 transform hover:scale-110 hover:text-lightgreentxt">
                        <FaInstagram size={28} />
                    </a>
                    <a href="https://www.facebook.com/usonsonate.edu.sv" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 transform hover:scale-110 hover:text-lightgreentxt">
                        <FaFacebook size={28} />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
