// src/components/NotFound.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-red-600">404</h1>
      <h2 className="text-2xl font-semibold text-gray-700">Página no encontrada</h2>
      <p className="mt-4 text-lg text-gray-600">
        La página que estás buscando no existe.
      </p>
      <Link to="/" className="px-4 py-2 mt-6 text-white bg-blue-500 rounded hover:bg-blue-600">
        Volver al inicio
      </Link>
    </div>
  );
};

export default NotFound;
