import React from 'react'
import { CursoPrincipal } from './CursoPrincipal'

function DiplomadoPrincipal({ diplomado, inscribirse, user }) {
    return (
        <div key={diplomado.idDiplomado} className="diplomado">
            <h3>Diplomado - {diplomado.nombreDiplomado}</h3>

            {/* Contenedor de los cursos en formato de cuadrícula */}
            <div className="courses">
                <div className="row">
                    {diplomado.cursos.map((curso) => (
                        <div className="col-md-6 d-flex" key={curso.idCurso}>
                            <CursoPrincipal
                                curso={curso}
                                inscribirse={inscribirse}
                                user={user}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export { DiplomadoPrincipal }
