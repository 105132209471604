
function Usuarios(idUsuario = 0, correo = '', nombres = '', apellidos = '', edad = 0, sexo = '', telefono = '', dui = '', nit = '', idRol = 0) {
    return {
        idUsuario, 
        correo, 
        nombres, 
        apellidos, 
        edad, 
        sexo, 
        telefono, 
        dui, 
        nit, 
        idRol
    }
}

export { Usuarios }