import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { useAuth } from '../../contexts/DataContext';

function useUsuarios() {

    //Todas las constantes
    const { getUser } = useAuth();
    const location = useLocation();
    const { getFetch, postFetch } = useFetch();
    const navigate = useNavigate();
    const fromAdmin = location.state?.from?.pathname || '/cursos'; // Ruta de redirección después de iniciar sesión
    const fromProfesor = location.state?.from?.pathname || '/asignarnotas';
    const fromParticipante = location.state?.from?.pathname || '/';
    const { mensajeError, mensajeExitoso, } = useAlert();
    const [cargoUnaVez, setCargoUnaVez] = useState(false);//Variable para controlar que no se ejecute mucho el obtener usuario

    //ORDENAR USEEFFECT

    //Todos los gets
    const getUsuario = async (oAutenticado, conGoogle, token = '') => {
        if (cargoUnaVez) {
            return;
        }
                   
        const urlParcial = `usuarios/${(conGoogle) ? oAutenticado.profileObj.email : oAutenticado.mail || oAutenticado.userPrincipalName}`;
        try {
            const data = await getFetch(urlParcial);
            const { datos, mensaje, estado } = data;
            if (estado) {
                if (datos.length === 0) {
                    //No se encontro el usuario entonces irlo a agregar a la base de datos
                    const datosUsuario = {
                        nombres: (conGoogle) ? oAutenticado.profileObj.givenName : oAutenticado.givenName,
                        apellidos: (conGoogle) ? oAutenticado.profileObj.familyName : oAutenticado.surname,
                        correo: (conGoogle) ? oAutenticado.profileObj.email : oAutenticado.mail || oAutenticado.userPrincipalName
                    }
                    postOnClickUsuario(datosUsuario, oAutenticado, conGoogle);
                } else if (datos.length > 0) {
                    const { rol } = datos[0];
                    getUser();
                    if (rol === 'admin') {
                        navigate(fromAdmin, { replace: true });
                    } else if (rol === 'instructor') {
                        navigate(fromProfesor, { replace: true });
                    } else {
                        navigate(fromParticipante, { replace: true }); // Redirigir a la ruta original
                    }
                }
            } else {
                mensajeError(mensaje);
            }

        } catch (error) {
            mensajeError("Error inesperado, contacte al programador. " + error);
        }
    };

    //Todos los post
    const postOnClickUsuario = (datos, oAutenticado, conGoogle) => {
        const urlParcial = 'usuarios/';
        
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                //Despues de agregar al usuario agrego su responsable
                const { mensaje, estado } = respuesta;
                if (estado) {
                    const { insertId } = respuesta.datos;
                    const datosResponsable = {
                        nombreCompleto: ''
                    }
                    postOnClickResponsable(insertId, datosResponsable, oAutenticado, conGoogle);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    const postOnClickResponsable = (idUsuario, datos, oAutenticado, conGoogle) => {
        const urlParcial = 'usuarios/responsable';

        postFetch(urlParcial, datos)
            .then((respuesta) => {
                //Tenemos el responsable, ahora vamos por el participante
                const { mensaje, estado } = respuesta;
                if (estado) {
                    const { insertId } = respuesta.datos;

                    const datosParticipante = {
                        idResponsable: insertId,
                        idUsuario: idUsuario
                    }
                    postOnClickParticipante(datosParticipante, oAutenticado, conGoogle);
                } else {
                    mensajeError(mensaje);
                }

            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    const postOnClickParticipante = (datos, oAutenticado, conGoogle) => {
        const urlParcial = 'usuarios/participante';

        postFetch(urlParcial, datos)
            .then((respuesta) => {
                //Al insertar el participante obtengo al usuario
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Bienvenido - " + (conGoogle ? oAutenticado.profileObj.givenName : oAutenticado.givenName));
                    setCargoUnaVez(true);
                    getUsuario(oAutenticado, conGoogle);
                } else {
                    setCargoUnaVez(true);
                    mensajeError(mensaje);
                }

            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    return {
        getUsuario
    }
}

export { useUsuarios }