import axios from 'axios';
import {API_URL} from '../config';

const MATERIA_URL = `${API_URL}/materias`;

const createMateria = (materiaData) => {
  return axios.post(`${MATERIA_URL}/create`, materiaData);
};

const getMaterias = () => {
  return axios.get(`${MATERIA_URL}/all`);
};

const updateMateria = (id, materiaData) => {
  return axios.put(`${MATERIA_URL}/update/${id}`, materiaData);
};

const deleteMateria = (id) => {
  return axios.delete(`${MATERIA_URL}/delete/${id}`);
};

export default { createMateria, getMaterias, updateMateria, deleteMateria };