import React from 'react'
import { NavBar} from './NavBar'
import ContactarWhatsapp from './ContactarWhatsapp'

const Layout = ({ children }) => {
    return (
        <>
            <NavBar />
            {children}
            <ContactarWhatsapp/>
        </>
    )
}

export { Layout }