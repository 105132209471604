export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID_MICROSOFT,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_INQUILINO}/oauth2/v2.0/authorize`,
        redirectUri: "http://localhost:3000/login",
    },
}

export const loginRequest = {
    scopes: ["User.Read"],
}