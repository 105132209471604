import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessGranted, setAccessGranted] = useState(false);
  const [loading, setLoading] = useState(true);
  //const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null); 
  const sessionDuration = 10 * 60 * 1000; // 10 minutos
  

  // Función para verificar la expiración del token
  const checkTokenExpiration = () => {
    const token = localStorage.getItem('token');
    const expirationTime = localStorage.getItem('expirationTime');
    const storedRole = localStorage.getItem('userRole');
    if (token && expirationTime) {
      const now = new Date().getTime();
      if (now < expirationTime) {
        setUserRole(storedRole);
        setIsAuthenticated(true);
        return true;
      }
    }
    return false;
  };

  
  useEffect(() => {
    const storedRole = localStorage.getItem('userRole');
    if (checkTokenExpiration() && storedRole) {
      setUserRole(storedRole);
      setIsAuthenticated(true);
    } else {
      logout();
    }
    setLoading(false);
  }, []);

  const login = (token) => {
    const expirationTime = new Date().getTime() + sessionDuration;
    localStorage.setItem('token', token);

    const payload = JSON.parse(atob(token.split('.')[1]));
    const role = payload.rol; // Obtén el rol del token

    localStorage.setItem('userRole', role);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('userRole', role);
    setUserRole(role);
    setIsAuthenticated(true);
    setAccessGranted(false); 
    setLoading(false);
  };

  const loginWithOAuth2 = (token) => {
    login(token); // Reutiliza la lógica del método `login` para guardar el token y rol
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('userRole');
    setIsAuthenticated(false);
    setAccessGranted(false);
    setUserRole(null);
  };


  //acceso temporal a rutas por btn o link
  const grantAccess = () => setAccessGranted(true);

  const revokeAccess = () => setAccessGranted(false);

  // Manejar la actividad del usuario
  useEffect(() => {
    const handleActivity = () => {
      if (localStorage.getItem('token')) {
        const newExpirationTime = new Date().getTime() + sessionDuration;
        localStorage.setItem('expirationTime', newExpirationTime);
      }
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  });

  // Memoizar el valor del contexto para evitar recalculaciones innecesarias
  const authContextValue = useMemo(() => ({
    isAuthenticated,
    login,
    loginWithOAuth2, // Nuevo método
    logout,
    grantAccess,
    revokeAccess,
    accessGranted,
    userRole,
  }), [isAuthenticated, accessGranted, userRole]);
  

  if (loading) {
    return null; // No muestra mensaje de carga
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);