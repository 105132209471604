import { Horarios } from "./Horarios";

function Grupos(idGrupo, grupo, modalidad, lugar, idCurso, idUsuario) {
    const horarios = []; // Array de Horarios

    function agregarHorario(idHorario, diaInicio, diaFin, horaInicio, horaFin, dias, idGrupo) {
        // Verifica si el horario ya existe
        const horarioExistente = horarios.find(h => h.idHorario === idHorario);
        if (!horarioExistente) {
            horarios.push(Horarios(idHorario, diaInicio, diaFin, horaInicio, horaFin, dias, idGrupo));
        }
    }

    return {
        idGrupo,
        grupo,
        modalidad,
        lugar,
        idCurso,
        idUsuario,
        horarios,
        agregarHorario
    };
}
export { Grupos }