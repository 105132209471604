import React from 'react';
import { useListadoMunicipios } from '../municipios/useListadoMunicipios';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

const ListaMunicipios = ({ idDepartamento }) => {

  const {
    municipios,
    handleChange,
    titulo,
    municipioSeleccionado,
    openModal,
    deleteOnClick,
    validarCampos
  } = useListadoMunicipios(idDepartamento);

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1"><h1>Municipios</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="modal" databstarget="#modalMunicipios" onclick={() => openModal(1)} titulo={"Agregar Municipio"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Municipio</th>
              <th scope="col">Código</th>
              <th scope="col">Departamento</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {municipios ? municipios.map((municipio) => (
              <tr key={municipio.idMunicipio}>
                <th scope="row">{municipio.idMunicipio}</th>
                <td>{municipio.municipio}</td>
                <td>{municipio.codigo}</td>
                <td>{municipio.nombreDepartamento}</td>
                <td>
                  <BtnEditar databstogle="modal" databstarget="#modalMunicipios" onclick={() => openModal(2, municipio)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteOnClick(municipio.idMunicipio)} titulo={"Eliminar"} />
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>

      <div className="modal fade" id="modalMunicipios" tabIndex="-1" aria-labelledby="modalMunicipiosLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalMunicipiosLabel">{titulo}</h1>
              <button
                type="button"
                id='btnCerrarModal'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                  <label htmlFor="txtIdMunicipio" className="form-label">ID</label>
                  <input
                    id='txtIdMunicipio'
                    onChange={handleChange}
                    value={municipioSeleccionado.idMunicipio}
                    type="number"
                    className="form-control"
                    name="idMunicipio"
                    disabled
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="txtMunicipio" className="form-label">Municipio</label>
                  <input
                    id='txtMunicipio'
                    onChange={handleChange}
                    value={municipioSeleccionado.municipio}
                    type="text"
                    className="form-control"
                    name="municipio"
                    required
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="txtCodigo" className="form-label">Código</label>
                  <input
                    id='txtCodigo'
                    onChange={handleChange}
                    value={municipioSeleccionado.codigo}
                    type="text"
                    className="form-control"
                    name="codigo"
                    required
                  />
                </div>
                <div className="col-md-12 text-md-end">
                  <hr />
                  <BtnEliminar add={'me-2'} type='button' titulo={'Cancelar'} databsdismiss={'modal'} />
                  <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ListaMunicipios };

