import React from 'react'
import { BtnAgregar } from '../../buttons/BtnAgregar'
import { BtnEliminar } from '../../buttons/BtnEliminar'
import { BtnEditar } from '../../buttons/BtnEditar'
function ListaActividades({ actividades, titulo, handleChange, actividadSeleccionada, openModal, deleteOnClick, validarCampos }) {

    return (
        <>
            <div className="mt-custom d-flex">
                <div className='p-2 flex-grow-1'><h1>Actividades del curso :</h1></div>
                <div className="p-2">
                    <BtnAgregar databstogle="modal" databstarget="#modalActividades" onclick={() => openModal(1)} titulo={"Agregar Actividad"} />
                </div>
            </div>
            <div className="table-responsive text-center">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Actividad</th>
                            <th scope="col">Porcentaje</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {actividades ? actividades.map((actividad) => (
                            <tr key={actividad.idActividad}>
                                <th scope="row">{actividad.idActividad}</th>
                                <td>{actividad.actividad}</td>
                                <td>{actividad.porcentaje}</td>
                                <td>
                                    <BtnEditar databstarget={"#modalActividades"} databstogle={"modal"} onclick={() => openModal(2, actividad)} titulo={'Editar'} />
                                    <BtnEliminar onclick={() => deleteOnClick(actividad.idActividad)} titulo={"Eliminar"} />
                                </td>
                            </tr>
                        )) : null}
                    </tbody>
                </table>
            </div>
            <div className="modal fade" id="modalActividades" tabIndex="-1" aria-labelledby="modalActividadesLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modalActividadesLabel">{titulo}</h1>
                            <button
                                type="button"
                                id='btnCerrarModalActividades'
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form className="row g-3 needs-validation" noValidate>
                                <div className="col-md-2">
                                    <label htmlFor="txtIdActividad" className="form-label">ID</label>
                                    <input
                                        id='txtIdActividad'
                                        onChange={handleChange}
                                        value={actividadSeleccionada.idActividad}
                                        type="number"
                                        className="form-control"
                                        name="idActividad"
                                        disabled
                                    />
                                </div>
                                <div className="col-md-10">
                                    <label htmlFor="txtActividad" className="form-label">Actividad</label>
                                    <input
                                        id='txtActividad'
                                        onChange={handleChange}
                                        value={actividadSeleccionada.actividad}
                                        type="text"
                                        className="form-control"
                                        name="actividad"
                                        required
                                    />
                                </div>
                                <div className="col-md-10">
                                    <label htmlFor="txtPorcentaje" className="form-label">Porcentaje</label>
                                    <input
                                        id='txtPorcentaje'
                                        onChange={handleChange}
                                        value={actividadSeleccionada.porcentaje}
                                        type="number"
                                        className="form-control"
                                        name="porcentaje"
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <BtnEditar type='button' onclick={validarCampos} titulo={"Guardar cambios"}/>
                                    <hr />
                                    <BtnEliminar type='button' databsdismiss={"modal"} titulo={"Cancelar"}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { ListaActividades }