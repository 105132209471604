import { Cursos } from "./Cursos";

function Diplomados(idDiplomado, nombreDiplomado, fechaInicio, fechaFin, duracion, completo) {
    const cursos = []; // Array de Cursos

    function agregarCurso(idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, precio, duracion, idTipoCurso, idDiplomado, idCiclo) {
        // Verifica si el curso ya existe
        let newCurso = cursos.find(c => c.idCurso === idCurso);
        if (!newCurso) {
            newCurso = Cursos(idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, precio, duracion, idTipoCurso, idDiplomado, idCiclo);
            cursos.push(newCurso);
        }
        return newCurso; // Retorna el curso (para agregar grupos después)
    }

    return {
        idDiplomado,
        nombreDiplomado,
        fechaInicio,
        fechaFin,
        duracion,
        completo,
        cursos,
        agregarCurso
    };
}
export { Diplomados }