import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { useDetallesPlantillas } from '../detalles_plantillas/useDetallesPlantillas';
import { Plantillas } from '../../models/Plantillas';

const useListadoPlantillas = ({ obtenerCursos }) => {

  //Todas las constantes
  const [plantillas, setPlantillas] = useState([]);
  const [plantillaSeleccionada, setPlantillaSeleccionada] = useState(Plantillas());
  const [plantillasSeleccionadas, setPlantillasSeleccionadas] = useState([]);
  const [operacion, setOperacion] = useState(1);
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, mensajeInformacion, preguntarConfirmacion } = useAlert();
  const [showModalPlantillas, setShowModalPlantillas] = useState(false);
  const { detallesPlantillas, getPlantillasByCicloAndCurso, deleteDetallePlantilla, postDetallePlantilla, putDetallePlantilla } = useDetallesPlantillas();
  const [idCurso, setIdCurso] = useState(0);
  const [idCiclo, setIdCiclo] = useState(0);

  //Todos los useEffect
  useEffect(() => {
    getPlantillas();
  }, []);

  useEffect(() => {
    if (detallesPlantillas.length > 0) {
      setPlantillasSeleccionadas(detallesPlantillas);
    } else {
      setPlantillasSeleccionadas([]);
    }
  }, [detallesPlantillas]);


  const openModal = (op, plantillaSeleccion = Plantillas()) => {
    setOperacion(op);

    if (op === 2) {
      // Modo edición, pasamos los datos de la plantilla seleccionada
      setPlantillaSeleccionada(plantillaSeleccion);
    } else {
      // Modo creación, limpiamos los valores
      setPlantillaSeleccionada(Plantillas());
    }

    setShowModal(true);
  };


  const openModalPlantillas = (idCurso, idCiclo) => {
    getPlantillasByCicloAndCurso(idCiclo, idCurso);
    setIdCurso(idCurso);
    setIdCiclo(idCiclo);
    setShowModalPlantillas(true);
  }

  const validarCampos = () => {
    if (plantillaSeleccionada.concepto.trim() === '') {
      mensajeAdvertencia('Debe agregar un concepto');
      return;
    }

    if (plantillaSeleccionada.monto.trim() === '') {
      mensajeAdvertencia('Debe llenar monto');
      return;
    }

    if (plantillaSeleccionada.tipo.trim() === '') {
      mensajeAdvertencia('Debe llenar tipo');
      return;
    }

    if (plantillaSeleccionada.plantilla.trim() === '') {
      mensajeAdvertencia('Debe llenar plantilla');
      return;
    }

    if (plantillaSeleccionada.idCodigoServicio === '') {
      mensajeAdvertencia('Debe seleccionar un codigo');
      return;
    }

    const datos = {
      concepto: plantillaSeleccionada.concepto,
      monto: plantillaSeleccionada.monto,
      tipo: plantillaSeleccionada.tipo,
      plantilla: plantillaSeleccionada.plantilla,
      idCodigoServicio: plantillaSeleccionada.idCodigoServicio
    };

    if (operacion === 1) {
      postOnClick(datos);
    } else {
      const id = plantillaSeleccionada.idPlantilla;
      putOnClick(id, datos);
    }
    setShowModal(false);
  };

  //Todos los gets
  const getPlantillas = () => {
    const urlParcial = 'plantillas/';
    getFetch(urlParcial)
      .then((data) => {
        const { datos, mensaje, estado } = data;
        if (estado) {
          setPlantillas(datos);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  //Todos los post
  const postOnClick = (datos) => {
    const urlParcial = 'plantillas/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getPlantillas();
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  //Todos los puts
  const putOnClick = (id, datos) => {
    const urlParcial = `plantillas/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getPlantillas();
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const actualizarCostoCurso = async (id, datos) => {
    const urlParcial = `cursos/actualizarcosto/${id}`;
    try {
      const respuesta = await putFetch(urlParcial, datos);
      const { estado, mensaje } = respuesta;
      if (!estado) {
        mensajeError(mensaje);
      }
    } catch (error) {
      mensajeError("Error inesperado en cursos, contacte al programador. " + error);
    }
  };

  //Todos los delete
  const deleteOnClick = async (id) => {
    const urlParcial = `plantillas/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );

    if (!confirmed) {
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getPlantillas();
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlantillaSeleccionada(plantillaPrevio => ({ ...plantillaPrevio, [name]: value }));
  };

  const handleContinuePlantillas = () => {
    var continuar = true;
    // Crear un conjunto de IDs de plantillas previamente seleccionadas para una búsqueda rápida
    const idsPlantillas = new Set(detallesPlantillas.map(dp => dp.idDetallePlantilla));

    // Crear un conjunto de IDs de las plantillas seleccionadas actualmente
    const idsPlantillasSeleccionadas = new Set(plantillasSeleccionadas.map(p => p.idDetallePlantilla));

    // Filtrar las nuevas plantillas seleccionadas que no están en detallesPlantillas
    const nuevasPlantillasSeleccionadas = plantillasSeleccionadas.filter(
      id => !idsPlantillas.has(id.idDetallePlantilla)
    );

    // Filtrar las plantillas que fueron deseleccionadas
    const plantillasDeseleccionadas = detallesPlantillas.filter(
      dp => !idsPlantillasSeleccionadas.has(dp.idDetallePlantilla)
    );

    //Verificar que todas las plantillas cumplan con los campos requeridos
    plantillasSeleccionadas.forEach(ps => {
      if ((!ps.vencimiento && ps.generarRecargo) || (!ps.valorRecargo && ps.generarRecargo)) {
        mensajeAdvertencia("Debe seleccionar fecha vencimiento y asignar monto para los items con recargo.");
        continuar = false;
      }
    });

    if (!continuar) {//No continuar si todo mal
      return;
    }

    // Acciones para nuevas plantillas seleccionadas
    nuevasPlantillasSeleccionadas.forEach(id => {
      const datos = {
        vencimiento: id.vencimiento ? id.vencimiento.split('T')[0] : null,
        orden: `${idCurso}${idCiclo}${id.idPlantilla}`,
        generarRecargo: id.generarRecargo ? id.generarRecargo : false,
        valorRecargo: id.valorRecargo ? id.valorRecargo : 0.00,
        exigible: id.exigible ? id.exigible : false,
        idPlantilla: id.idPlantilla,
        //idCodigoServicio: id.idCodigoServicio, esto se definira segun el id de la plantilla
        idCiclo: idCiclo,
        idCurso: idCurso
      };

      postDetallePlantilla(datos);

    });

    let totalCurso = 0;
    plantillasSeleccionadas.forEach(dp => {
      totalCurso += Number(dp.monto);
      if (dp.idDetallePlantilla) {
        if (!dp.generarRecargo) {
          dp.vencimiento = null;
          dp.valorRecargo = 0;
        } else {
          dp.vencimiento = dp.vencimiento ? dp.vencimiento.split('T')[0] : null
        }
        putDetallePlantilla(dp.idDetallePlantilla, dp);
      }
    });

    // Acciones para plantillas deseleccionadas
    plantillasDeseleccionadas.forEach(dp => {
      deleteDetallePlantilla(dp.idDetallePlantilla);
    });

    //Actualizar costo del curso
    const nuevoCosto = {
      monto: totalCurso
    }
    actualizarCostoCurso(idCurso, nuevoCosto)
      .then(() => {
        obtenerCursos();
        mensajeExitoso("Cambios realizados!");
        setShowModalPlantillas(false);
      })
      .catch((error) => {
        console.error("Error en el flujo de actualización: ", error);
        // Ya estás manejando los errores en actualizarCostoCurso, pero puedes manejar otros aquí si es necesario
      });
  }

  const handleChangePlantillas = (plantilla, e) => {
    const { name, type, value, checked } = e.target;

    // Verificar si la plantilla ya está seleccionada
    const plantillaSeleccionada = plantillasSeleccionadas.find(
      (p) => p.idPlantilla === plantilla.idPlantilla
    );

    if (name === "plantilla") {
      if (plantillaSeleccionada) {
        // Si ya está seleccionada, la quitamos de las seleccionadas
        setPlantillasSeleccionadas(
          plantillasSeleccionadas.filter((p) => p.idPlantilla !== plantilla.idPlantilla)
        );
      } else {
        // Si no está seleccionada, la añadimos
        setPlantillasSeleccionadas([...plantillasSeleccionadas, { ...plantilla }]);
      }
    } else if (plantillaSeleccionada) {
      // Si es otro cambio (detalles como monto, recargo o vencimiento)
      const updatedPlantilla = { ...plantillaSeleccionada };

      if (name === "generar") {
        updatedPlantilla.generarRecargo = checked;
      } else if (name === "valorRecargo") {
        updatedPlantilla.valorRecargo = value;
      } else if (name === "vencimiento") {
        updatedPlantilla.vencimiento = value;
      } else if (name === "exigible") {
        updatedPlantilla.exigible = checked;
      }

      // Actualizamos la plantilla dentro del estado de plantillas seleccionadas
      setPlantillasSeleccionadas(
        plantillasSeleccionadas.map((p) =>
          p.idPlantilla === plantilla.idPlantilla ? updatedPlantilla : p
        )
      );
    }
  };
  const [showModal, setShowModal] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const handleContinue = () => {
    validarCampos();
  };

  return {
    setShowModalPlantillas,
    openModalPlantillas,
    handleChangePlantillas,
    handleContinuePlantillas,
    plantillasSeleccionadas,
    showModalPlantillas,
    plantillas,
    getPlantillas,
    handleChange,
    openModal,
    plantillaSeleccionada,
    deleteOnClick,
    showModal,
    handleClose,
    handleCancel,
    handleContinue,
  }
};

export { useListadoPlantillas };
