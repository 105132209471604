function Horarios(idHorario, diaInicio, diaFin, horaInicio, horaFin, dias, idGrupo) {
    return {
        idHorario,
        diaInicio,
        diaFin,
        horaInicio,
        horaFin,
        dias,
        idGrupo
    };
}
export { Horarios }