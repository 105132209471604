import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Para redirigir al usuario
import NavbarPrincipal from '../NavPrincipal/NavbarPrincipal';
import VerticalMenu from '../NavVerticalHelper/VerticalMenu';
import Modal from '../Modal/Modal'; // Componente Modal que ya hemos creado
import axios from 'axios'; // Importar axios para hacer solicitudes HTTP
import { API_URL } from '../../config';

import Footer from '../Footer';


const InscriptionProcess = () => {
    const [modalContent, setModalContent] = useState(null);
    const [anioIngreso, setAnioIngreso] = useState(null);
    const [enabledRoutes, setEnabledRoutes] = useState({
        inscriptionProcess: true,
        profile: false
    });
    
    const [studentExists, setStudentExists] = useState(null); // Estado para verificar existencia del estudiante
    
    const navigate = useNavigate();
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [selectedCaseValue, setSelectedCaseValue] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    
    const [isPaid, setIsPaid] = useState(false); // Estado para el pago

    
    // Verificar el estado de pago al cargar el componente
    useEffect(() => {
        const checkEstadoPago = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${API_URL}/student/estado-pago`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
    
                if (response.data.estado_pago === 1) {
                    setIsPaid(true); // El pago está completo
                    setEnabledRoutes({
                        inscriptionProcess: true,
                        profile: true,
                        schedulesAndGroups: true,
                        payment: true,
                        grades: true, // Habilitar todas las rutas
                    });
                }
            } catch (error) {
                console.error('Error al obtener el estado de pago:', error);
            }
        };
    
        checkEstadoPago();
    }, []);
    
    //modal de confirmación
    const openConfirmationModal = (route, caseValue) => {
        setShowConfirmationModal(true)
        setSelectedRoute(route);
        setSelectedCaseValue(caseValue);
    };

    const confirmRedirection = () => {
        setShowConfirmationModal(false);
        handleRedirection(selectedRoute, selectedCaseValue);
    };


    // Verificar existencia del estudiante al cargar el componente
    useEffect(() => {
        const fetchStudentId = async () => {
            try {
                const token = localStorage.getItem('token');
                

                const response = await axios.get(`${API_URL}/student/find-id`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.status === 200) {
                    
                    setStudentExists(true); // Estudiante encontrado
                }
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    
                    setStudentExists(false); // Estudiante no encontrado
                } else {
                    console.error('Error al verificar el estudiante:', err);
                }
            }
        };

        fetchStudentId();
    }, []);


    // Obtener anio_ingreso al cargar el componente
    useEffect(() => {
        const fetchAnioIngreso = async () => {
            try {
                const response = await axios.get(`${API_URL}/config/anio`);
                console.log('Respuesta del backend:', response.data);
                // Verifica que response.data es un arreglo y tiene al menos un elemento
                setAnioIngreso(response.data.anio_ingreso);
            } catch (error) {
                console.error('Error al obtener el año de ingreso:', error);
            }
        };
        fetchAnioIngreso();
    }, []);

    const openModal = (content, buttonLabel = "Continuar") => {
        setModalContent({ ...content, buttonLabel });
    };

    const closeModal = () => {
        setModalContent(null);
    };

    // Redirige al usuario a la siguiente ruta y habilita esa ruta
    const handleRedirection = async (route, caseValue = null) => {

        const token = localStorage.getItem('token');

        console.log('handleRedirection llamada con:', { route, caseValue });
        console.log('Valor de anioIngreso en handleRedirection:', anioIngreso);

        if (!anioIngreso) {
            console.error('anioIngreso no está disponible aún');
            return;
        }

        try {
            console.log('Datos enviados al backend:', {
                tipo_inscripcion: caseValue,
                anio_ingreso: anioIngreso,
            });

            await axios.post(`${API_URL}/student/create`, {
                tipo_inscripcion: caseValue,
                anio_ingreso: anioIngreso,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Actualizar rutas habilitadas y navegar
            setEnabledRoutes((prevState) => ({
                ...prevState,
                [route]: true,
            }));
            navigate(`/${route}`, { state: { caseValue } });
            closeModal(); // Cerrar el modal después de redirigir
        } catch (error) {
            console.error('Error creando el registro del estudiante:', error);
            // Opcionalmente, mostrar un mensaje de error al usuario
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <NavbarPrincipal />
            <div className="flex flex-grow">
                <VerticalMenu enabledRoutes={enabledRoutes} />
                <div
                    className={`flex flex-col ${studentExists === true ? '' : ''
                        } flex-1 px-4 py-8 pl-20 bg-gray-100 md:px-12 md:pl-20 lg:px-24`}
                >
                    <h1 className={`mb-6 text-4xl font-bold text-center text-sky-700 ${studentExists === true ? 'hidden' : ''
                        }`}>Proceso de Inscripción</h1>
                    {studentExists === false && (
                        <p className="max-w-5xl mx-auto mb-12 text-lg text-center text-gray-600">
                            Te recomendamos leer la siguiente información según tu situación académica para completar el
                            proceso de manera adecuada. Esto te ayudará a evitar contratiempos y a seguir el procedimiento correcto.
                        </p>
                    )}
                    <div
                        className={` ${studentExists === true ? ' flex items-center justify-center flex-1  ' : 'grid max-w-5xl grid-cols-1 gap-6 mx-auto md:grid-cols-2 lg:grid-cols-3'
                            } `}
                    >


                        {/* Renderizado condicional basado en la existencia del estudiante */}
                        {studentExists === false ? (
                            <>
                                {/* Caso 1 - Ingreso Normal */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso Normal</h2>
                                    <p className="mb-4 text-gray-600">Estudiantes de bachillerato de último año.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso Normal",
                                                content: (
                                                    <div>
                                                        Si posees solo estudio de bachillerato, entonces debes de llenar el presente formulario para inscribirte al curso preuniversitario, así como cancelar el arancel de <strong className=" text-sky-600">$30</strong> con tarjeta de crédito o débito, o sino en efectivo en las instalaciones de la universidad.
                                                    </div>

                                                ),
                                                action: () => openConfirmationModal('profile', '1')
                                            }, "Continuar con el Formulario")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>

                                {/* Caso 2 - Ingreso con Curso Preu de otra Universidad 
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso con Curso Preu de otra Universidad</h2>
                                    <p className="mb-4 text-gray-600">Estudiantes que realizaron el curso preuniversitario o examen de ingreso en otra universidad y quieren entrar a la USO.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso con Curso Preu de otra Universidad",
                                                content: (
                                                    <div>
                                                        Si has realizado un curso preuniversitario en otra universidad, completa los formularios que se te proporcionan y sigue cada paso hasta completar el proceso de inscripción. Esto asegurará que tu perfil esté actualizado y listo para continuar.
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '2')
                                            }, "Continuar con los siguientes pasos")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>
                                */}

                                {/* Caso 3 - Ingreso con equivalencias 
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso de estudiantes que comenzaron una carrera en otra universidad</h2>
                                    <p className="mb-4 text-gray-600">Estudiantes que comenzaron una carrera en otra universidad, pero no la culminaron.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso de estudiantes que comenzaron una carrera en otra universidad",
                                                content: (
                                                    <div>
                                                        Si comenzaste una carrera en otra institución, completa el formulario de inscripción y:
                                                        <ul className="ml-5 list-disc">
                                                            <li> Solicita el análisis de equivalencias para convalidar tus estudios.</li>
                                                            <li> Empieza el proceso de Exoneración del curso</li>
                                                        </ul>
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '3')
                                            }, "Continuar con exoneración del curso")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>
                                */}

                                {/* Caso 4 - Ingreso de estudiantes con carreras ahora exoneración general */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso con Exoneración</h2>
                                    <p className="mb-4 text-gray-600">Aplica para estudiantes que poseen una carrera universitaria, han iniciado una carrera en otra institución o han comenzado un proceso de ingreso en otra universidad.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso de estudiantes con carreras",
                                                content: (
                                                    <div>
                                                        <p>
                                                            La exoneración se concede a los siguientes casos:
                                                        </p>
                                                        <ul className="ml-5 space-y-2 list-disc marker:text-gray-600">
                                                            <li>Estudiantes que poseen alguna carrera universitaria (Técnico, Licenciatura o Ingeniería) y quieren comenzar la carrera desde cero.</li>
                                                            <li>Estudiantes que han comenzado una carrera en otra institución y quieren volver a comenzar la carrera.</li>
                                                            <li>Personas que han comenzado un proceso de ingreso en otra universidad.</li>
                                                        </ul>
                                                        <p className="mt-4">
                                                            El proceso implica cancelar el curso preuniversitario (<strong className="text-sky-600">$30</strong>) y completar con tus datos el presente formulario, pero no realizar el curso Preuniversitario.
                                                        </p>
                                                        <p className="mt-4">
                                                            Debe de presentar a la Unidad de Atención al Estudiante un documento que verifique alguno de los 3 casos anteriores.
                                                        </p>
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '4')
                                            }, "Continuar con el Formulario")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>

                                {/* Caso 5 - Equivalencias Externas ahora equivalencias general */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso con Equivalencias</h2>
                                    <p className="mb-4 text-gray-600">
                                        Se conceden por materias cursadas y aprobadas en instituciones de educación superior, nacionales o extranjeras, y para quienes posean una carrera de educación superior.
                                    </p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() =>
                                                openModal({
                                                    title: "Proceso de Equivalencias",
                                                    content: (
                                                        <div
                                                            className="p-4 overflow-y-auto bg-white rounded-lg shadow-inner"
                                                            style={{ maxHeight: '40vh' }}
                                                        >
                                                            <p className="mb-4 text-gray-700">
                                                                Las equivalencias se conceden por materias cursadas y aprobadas en instituciones de educación superior, tanto nacionales como extranjeras, también a personas que posean una carrera en educación superior. A continuación, se detallan los requisitos y pasos para solicitar la equivalencia de estudios:
                                                            </p>
                                                            <h3 className="mb-4 text-lg font-semibold text-gray-600">
                                                                Requisitos para Solicitud de Equivalencias:
                                                            </h3>
                                                            <ul className="ml-5 space-y-4 text-gray-700 list-disc marker:text-sky-600">
                                                                <li>
                                                                    <strong className="text-sky-600">Llenar Solicitud:</strong> Completar la solicitud de equivalencias en el formulario respectivo.
                                                                </li>
                                                                <li>
                                                                    <strong className="text-sky-600">Anexar Certificación de Notas:</strong> Adjuntar la certificación de notas de las materias cursadas y aprobadas, cuya equivalencia se solicita. La certificación debe incluir:
                                                                    <ul className="mt-2 ml-5 list-none">
                                                                        <li className="flex items-center">
                                                                            <span className="mr-2 text-sky-600">⭐</span> Nota obtenida
                                                                        </li>
                                                                        <li className="flex items-center">
                                                                            <span className="mr-2 text-sky-600">📅</span> Fecha de aprobación
                                                                        </li>
                                                                        <li className="flex items-center">
                                                                            <span className="mr-2 text-sky-600">📊</span> Unidades valorativas de cada materia
                                                                        </li>
                                                                    </ul>
                                                                    La certificación debe estar firmada y sellada por la autoridad competente de la institución de procedencia. Si es extranjera, debe estar autenticada, traducida al español en caso de ser necesario, y legalizada en forma oficial.
                                                                </li>
                                                                <li>
                                                                    <strong className="text-sky-600">Programas de Materias:</strong> Adjuntar los programas de cada materia cuya equivalencia se solicita, acreditando su vigencia en la época cursada y aprobada. Los programas deben estar autenticados por las autoridades académicas de la institución de procedencia.
                                                                </li>
                                                                <li>
                                                                    <strong className="text-sky-600">Pagar Aranceles:</strong> Realizar el pago de los aranceles correspondientes al proceso de equivalencias (<strong className="text-sky-600">$50</strong>).
                                                                </li>
                                                                <li>
                                                                    <strong className="text-sky-600">Certificación de Prácticas Reglamentarias:</strong> Si la carrera lo requiere, anexar la certificación de prácticas reglamentarias para aquellas materias específicas.
                                                                </li>
                                                            </ul>
                                                            <p className="mt-6 text-gray-600">
                                                                Es importante asegurarse de cumplir con todos los requisitos mencionados y entregar los documentos en buen estado. Para más detalles sobre el proceso o asesoría, puedes comunicarte con el Decano de la Facultad a la que ingresarás.
                                                            </p>
                                                        </div>
                                                    ),
                                                    action: () => openConfirmationModal('profile', '5')
                                                }, "Continuar con el Formulario")
                                            }
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>

                            </>
                        ) : studentExists === true ? (
                            <div className="flex flex-col items-center w-full max-w-md p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Proceso de inscripción seleccionado</h2>
                                <p className="mb-4 text-center text-gray-600">Continúa con el proceso en tu perfil.</p>
                                <div className="flex justify-center">
                                    <button
                                        onClick={() => navigate('/profile')}
                                        className="px-6 py-2 text-lg font-semibold text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                    >
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">Cargando información...</p>
                        )}
                    </div>

                    {/* Componente Modal */}
                    {modalContent && (
                        <Modal title={modalContent.title} onClose={closeModal}>
                            <p className="leading-relaxed">{modalContent.content}</p>
                            {modalContent.action && (
                                <div className="mt-6 text-right">
                                    <button
                                        onClick={modalContent.action}
                                        className="px-6 py-2 font-semibold text-white transition-colors duration-300 ease-in-out rounded-lg shadow-md bg-lightgreentxt hover:bg-darkgreentxt focus:outline-none"
                                    >
                                        {modalContent.buttonLabel || "Continuar"}
                                    </button>
                                </div>
                            )}
                        </Modal>
                    )}
                    {/* Modal de confirmación */}
                    {showConfirmationModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
                            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
                                <h2 className="mb-4 text-2xl font-bold text-center text-sky-700">Confirmar Selección</h2>
                                <p className="mb-6 text-center text-gray-600">
                                    ¿Estás seguro de seleccionar esta opción? Una vez confirmada, no se podrá cambiar.
                                </p>
                                <div className="flex justify-between">
                                    <button
                                        onClick={() => setShowConfirmationModal(false)}
                                        className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        onClick={confirmRedirection}
                                        className="px-4 py-2 font-semibold text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div >
    );

};

export default InscriptionProcess;