import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from "../../hooks/useAlert"
import { Fuentes } from '../../models/Fuentes';

const useListadoFuentes = () => {

  const {
    mensajeAdvertencia,
    mensajeError,
    mensajeExitoso,
    preguntarConfirmacion
  } = useAlert();

  //ORDENAR CONSTANTES
  const [fuentes, setFuentes] = useState([]);
  const [fuenteSeleccionada, setFuenteSeleccionada] = useState(Fuentes());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();

  //ORDENAR USEEFFECT
  useEffect(() => {
    getFuentes();
  }, []);

  //ORDENAR FUNCIONES
  const openModal = (op, fuenteSeleccionada = Fuentes()) => {
    setFuenteSeleccionada(fuenteSeleccionada);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Fuente' : 'Editar Fuente');
  };

  const deleteData = (id) => {
    deleteOnClick(id);
  };

  const validarCampos = () => {
    if (fuenteSeleccionada.fuente.trim() === '') {
      mensajeAdvertencia("El campo no puede quedar vacio");
    } else {
      if (operacion === 1) {
        postOnClick(fuenteSeleccionada);
      } else {
        const id = fuenteSeleccionada.idFuente;
        putOnClick(id, fuenteSeleccionada);
      }
      document.getElementById('btnCerrarModal').click();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFuenteSeleccionada((fuentePrevio) => ({ ...fuentePrevio, [name]: value }));
  };

  //ORDENAR GETS
  const getFuentes = () => {
    const urlParcial = "fuentes/";
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        const datosEstandarizados = datos.map(item =>
          Fuentes(
            item.idFuente || 0,
            item.fuente || '',
          )
        );
        setFuentes(datosEstandarizados);
      })
      .catch((error) => {
        console.log("Error->", error);
      });
  };

  //ORDENAR POST
  const postOnClick = (datos) => {
    const urlParcial = 'fuentes/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos insertados");
          getFuentes();
        } else {
          mensajeError("Error al insertar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  //ORDENARA PUT
  const putOnClick = (id, datos) => {
    const urlParcial = `fuentes/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos actualizados");
          getFuentes();
        } else {
          mensajeError("Error al actuaizar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  //ORDENAR DELETE
  const deleteOnClick = async (id) => {
    const urlParcial = `fuentes/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
  );

  if (!confirmed) {
      return;
  } 
    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Registro eliminado");
          getFuentes();
        } else {
          mensajeError("Error al eliminar registro: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  return {
    getFuentes,
    fuentes,
    handleChange,
    titulo,
    fuenteSeleccionada,
    openModal,
    deleteData,
    validarCampos
  };
};

export { useListadoFuentes };
