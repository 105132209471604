import { GoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/DataContext';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../auth/authConfig';

function LogoutButton() {
    const { deleteUser, user } = useAuth();
    const navegar = useNavigate();
    const { instance } = useMsal();

    // Verificar si el correo es de Google
    const isGoogleAccount = user && user.length > 0 && user[0].correo
        ? user[0].correo.includes('@gmail.com')
        : false;

    const onSuccess = () => {
        if (user && user.length > 0 && user[0].correo) {
            const correo = user[0].correo;

            if (!correo.includes('@gmail.com')) {
                // Si no es de Google, redirigir a Microsoft Logout
                instance.logoutPopup(loginRequest).catch((e) => {
                    console.error(e);
                });
            }
        }
        deleteUser();
        navegar('/login');
    };

    return (
        <>
            {isGoogleAccount ? (
                // Mostrar el botón de logout de Google si el correo es de Google
                <GoogleLogout
                    clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}
                    buttonText={'Logout from Google'}
                    onLogoutSuccess={onSuccess}
                />
            ) : (
                // Mostrar el botón personalizado con el ícono de Microsoft
                <button
                    type="button"
                    onClick={onSuccess}
                    style={{
                        width: '100%',
                        padding: '8px 10px',
                        fontSize: '14px',
                        color: '#757575',
                        backgroundColor: 'white',
                        border: '2px solid rgba(0, 0, 0, 0.2)',
                        borderRadius: '5px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '18px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <img
                        src="https://www.microsoft.com/favicon.ico"
                        alt="Microsoft"
                        width="20"
                        height="20"
                    />
                    Logout from Microsoft
                </button>
            )}
        </>
    );
}

export { LogoutButton };