import React from 'react'

function HorarioPrincipal({ horario }) {
    return (
        <p key={horario.idHorario}>
            <span> <strong>Del:</strong> {new Date(horario.diaInicio).toLocaleDateString('es-ES', { day: '2-digit', month: 'short' })} </span>
            <span> <strong>Al:</strong> {new Date(horario.diaFin).toLocaleDateString('es-ES', { day: '2-digit', month: 'short' })} </span>
            <span><strong>Días:</strong> {horario.dias} </span>
            <span><strong>De:</strong> {horario.horaInicio} <strong>A:</strong> {horario.horaFin}</span>
        </p>

    )
}

export { HorarioPrincipal }