import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Para redirigir al usuario
import NavbarPrincipal from '../NavPrincipal/NavbarPrincipal';
import VerticalMenu from '../NavVerticalHelper/VerticalMenu';
import Modal from '../Modal/Modal'; // Componente Modal que ya hemos creado
import axios from 'axios'; // Importar axios para hacer solicitudes HTTP
import { API_URL } from '../../config';

const InscriptionProcess = () => {
    const [modalContent, setModalContent] = useState(null);
    const [anioIngreso, setAnioIngreso] = useState(null);
    const [enabledRoutes, setEnabledRoutes] = useState({
        inscriptionProcess: true,
        profile: false,
        schedulesAndGroups: false,
        payment: false,
        grades: false
    });

    const [studentExists, setStudentExists] = useState(null); // Estado para verificar existencia del estudiante

    const navigate = useNavigate();
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [selectedCaseValue, setSelectedCaseValue] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    //modal de confirmación
    const openConfirmationModal = (route, caseValue) => {
        setShowConfirmationModal(true)
        setSelectedRoute(route);
        setSelectedCaseValue(caseValue);
    };

    const confirmRedirection = () => {
        setShowConfirmationModal(false);
        handleRedirection(selectedRoute, selectedCaseValue);
    };


    // Verificar existencia del estudiante al cargar el componente
    useEffect(() => {
        const fetchStudentId = async () => {
            try {
                const token = localStorage.getItem('token');
                console.log('Token cargado desde localStorage:', token);

                const response = await axios.get(`${API_URL}/student/find-id`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.status === 200) {
                    console.log('Estudiante encontrado, respuesta del servidor:', response.data);
                    setStudentExists(true); // Estudiante encontrado
                }
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    console.log('Estudiante no encontrado, status 404');
                    setStudentExists(false); // Estudiante no encontrado
                } else {
                    console.error('Error al verificar el estudiante:', err);
                }
            }
        };

        fetchStudentId();
    }, []);


    // Obtener anio_ingreso al cargar el componente
    useEffect(() => {
        const fetchAnioIngreso = async () => {
            try {
                const response = await axios.get(`${API_URL}/config/anio`);
                console.log('Respuesta del backend:', response.data);
                // Verifica que response.data es un arreglo y tiene al menos un elemento
                setAnioIngreso(response.data.anio_ingreso);
            } catch (error) {
                console.error('Error al obtener el año de ingreso:', error);
            }
        };
        fetchAnioIngreso();
    }, []);

    const openModal = (content, buttonLabel = "Continuar") => {
        setModalContent({ ...content, buttonLabel });
    };

    const closeModal = () => {
        setModalContent(null);
    };

    // Redirige al usuario a la siguiente ruta y habilita esa ruta
    const handleRedirection = async (route, caseValue = null) => {

        const token = localStorage.getItem('token');
        console.log('Token being sent:', token);

        console.log('handleRedirection llamada con:', { route, caseValue });
        console.log('Valor de anioIngreso en handleRedirection:', anioIngreso);

        if (!anioIngreso) {
            console.error('anioIngreso no está disponible aún');
            return;
        }

        try {
            console.log('Datos enviados al backend:', {
                tipo_inscripcion: caseValue,
                anio_ingreso: anioIngreso,
            });

            await axios.post(`${API_URL}/student/create`, {
                tipo_inscripcion: caseValue,
                anio_ingreso: anioIngreso,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Actualizar rutas habilitadas y navegar
            setEnabledRoutes((prevState) => ({
                ...prevState,
                [route]: true,
            }));
            navigate(`/${route}`, { state: { caseValue } });
            closeModal(); // Cerrar el modal después de redirigir
        } catch (error) {
            console.error('Error creando el registro del estudiante:', error);
            // Opcionalmente, mostrar un mensaje de error al usuario
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <NavbarPrincipal />
            <div className="flex flex-grow">
                <VerticalMenu enabledRoutes={enabledRoutes} />

                <div className="flex flex-col flex-1 px-4 py-8 pl-20 bg-gray-100 md:px-12 md:pl-20 lg:px-24">
                    <h1 className="mb-6 text-4xl font-bold text-center text-sky-700">Proceso de Inscripción</h1>
                    {studentExists === false && (
                        <p className="max-w-5xl mx-auto mb-12 text-lg text-center text-gray-600">
                            Te recomendamos leer la siguiente información según tu situación académica para completar el
                            proceso de manera adecuada. Esto te ayudará a evitar contratiempos y a seguir el procedimiento correcto.
                        </p>
                    )}

                    <div className="grid max-w-5xl grid-cols-1 gap-6 mx-auto md:grid-cols-2 lg:grid-cols-3">
                        {/* Renderizado condicional basado en la existencia del estudiante */}
                        {studentExists === false ? (
                            <>
                                {/* Caso 1 - Ingreso Normal */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso Normal</h2>
                                    <p className="mb-4 text-gray-600">Estudiantes de bachillerato de último año.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso Normal",
                                                content: (
                                                    <div>
                                                        Si tu último nivel de estudio es el bachillerato, es importante que continúes con el proceso actual.
                                                        <br />
                                                        Completa los datos solicitados y cancela el arancel de <strong>$30</strong> para que se creen tus credenciales y puedas ingresar al curso Preuniversitario.
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '1')
                                            }, "Continuar con el Proceso")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>

                                {/* Caso 2 - Ingreso con Curso Preu de otra Universidad */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso con Curso Preu de otra Universidad</h2>
                                    <p className="mb-4 text-gray-600">Estudiantes que realizaron el curso preuniversitario o examen de ingreso en otra universidad y quieren entrar a la USO.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso con Curso Preu de otra Universidad",
                                                content: (
                                                    <div>
                                                        Si has realizado un curso preuniversitario en otra universidad, completa los formularios que se te proporcionan y sigue cada paso hasta completar el proceso de inscripción. Esto asegurará que tu perfil esté actualizado y listo para continuar.
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '2')
                                            }, "Continuar con los siguientes pasos")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>

                                {/* Caso 3 - Ingreso con equivalencias */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso de estudiantes que comenzaron una carrera en otra universidad</h2>
                                    <p className="mb-4 text-gray-600">Estudiantes que comenzaron una carrera en otra universidad, pero no la culminaron.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso de estudiantes que comenzaron una carrera en otra universidad",
                                                content: (
                                                    <div>
                                                        Si comenzaste una carrera en otra institución, completa el formulario de inscripción y:
                                                        <ul className="ml-5 list-disc">
                                                            <li> Solicita el análisis de equivalencias para convalidar tus estudios.</li>
                                                            <li> Empieza el proceso de Exoneración del curso</li>
                                                        </ul>
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '3')
                                            }, "Continuar con exoneración del curso")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>

                                {/* Caso 4 - Ingreso de estudiantes con carreras */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Ingreso de estudiantes con carreras</h2>
                                    <p className="mb-4 text-gray-600">Estudiantes con técnicos o carrera de estudio superior (Licenciatura, Ingeniería o Técnico) culminadas que desean comenzar una nueva carrera.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Ingreso de estudiantes con carreras",
                                                content: (
                                                    <div>
                                                        Si ya has completado una carrera técnica o de nivel superior, puedes:
                                                        <ul className="ml-5 list-disc">
                                                            <li><strong>Ingreso por equivalencias:</strong> Solicita el análisis de equivalencias para reconocer tus estudios previos.</li>
                                                            <li><strong>Exoneración del curso:</strong> Elige esta opción para comenzar una nueva carrera sin convalidar estudios anteriores.</li>
                                                        </ul>
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '4')
                                            }, "Continuar con exoneración del curso")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>

                                {/* Caso 5 - Equivalencias Externas */}
                                <div className="flex flex-col p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-sky-700">Equivalencias Externas</h2>
                                    <p className="mb-4 text-gray-600">Materias cursadas y aprobadas en instituciones de educación superior nacionales o extranjeras.</p>
                                    <div className="flex justify-center mt-auto">
                                        <button
                                            onClick={() => openModal({
                                                title: "Equivalencias Externas",
                                                content: (
                                                    <div>
                                                        Para solicitar equivalencias por estudios realizados en otras instituciones, sigue estos pasos y prepárate para presentar la documentación en la universidad:
                                                        <ol className="ml-5 list-decimal">
                                                            <li>Completa la solicitud de equivalencias en la Secretaría de la Universidad.</li>
                                                            <li>Prepara la certificación de notas autenticada con firma y sello de la autoridad correspondiente.</li>
                                                            <li>Incluye programas de materias autenticados (cuando corresponda).</li>
                                                            <li>Realiza el pago de los aranceles correspondientes.</li>
                                                            <li>Anexa certificación de prácticas reglamentarias, si es requerido.</li>
                                                        </ol>
                                                        Asegúrate de tener toda la papelería lista para agilizar el proceso al momento de asistir.
                                                    </div>
                                                ),
                                                action: () => openConfirmationModal('profile', '5')
                                            }, "Continuar con inscripción")}
                                            className="px-4 py-2 text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : studentExists === true ? (
                            <div className="flex flex-col items-center justify-center flex-1 h-full px-4 py-8 bg-gray-100">
                                <div className="flex flex-col items-center w-full max-w-md p-6 transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-lg hover:shadow-xl">
                                    <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Proceso de inscripción seleccionado</h2>
                                    <p className="mb-4 text-center text-gray-600">Continúa con el proceso en tu perfil.</p>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={() => navigate('/profile')}
                                            className="px-6 py-2 text-lg font-semibold text-white transition-colors duration-300 ease-in-out rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                        >
                                            Siguiente Paso
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">Cargando información...</p>
                        )}
                    </div>

                    {/* Componente Modal */}
                    {modalContent && (
                        <Modal title={modalContent.title} onClose={closeModal}>
                            <p className="leading-relaxed">{modalContent.content}</p>
                            {modalContent.action && (
                                <div className="mt-6 text-right">
                                    <button
                                        onClick={modalContent.action}
                                        className="px-6 py-2 font-semibold text-white transition-colors duration-300 ease-in-out rounded-lg shadow-md bg-lightgreentxt hover:bg-darkgreentxt focus:outline-none"
                                    >
                                        {modalContent.buttonLabel || "Continuar"}
                                    </button>
                                </div>
                            )}
                        </Modal>
                    )}
                    {/* Modal de confirmación */}
                    {showConfirmationModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
                            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
                                <h2 className="mb-4 text-2xl font-bold text-center text-sky-700">Confirmar Selección</h2>
                                <p className="mb-6 text-center text-gray-600">
                                    ¿Estás seguro de seleccionar esta opción? Una vez confirmada, no se podrá cambiar.
                                </p>
                                <div className="flex justify-between">
                                    <button
                                        onClick={() => setShowConfirmationModal(false)}
                                        className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        onClick={confirmRedirection}
                                        className="px-4 py-2 font-semibold text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

};

export default InscriptionProcess;