import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { Ciclos } from '../../models/Ciclos';

const useListadoCiclos = () => {

  // Todas las constantes
  const [ciclos, setCiclos] = useState([]);
  const [cicloSeleccionado, setCicloSeleccionado] = useState(Ciclos());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, preguntarConfirmacion } = useAlert();

  // Todos los useEffect
  useEffect(() => {
    getCiclos();
  }, []);

  // Todas las funciones
  const openModal = (op, cicloSeleccionado = Ciclos()) => {
    setCicloSeleccionado(cicloSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Ciclo' : 'Editar Ciclo');
  };


  const validarCampos = () => {
    if (cicloSeleccionado.ciclo.trim() === '') {
      mensajeAdvertencia('¡Ciclo vacío!');
      return false;
    }

    const anio = cicloSeleccionado.anio;
    const anioRegex = /^\d{4}$/; // Expresión regular para un año de 4 dígitos

    if (!anioRegex.test(anio) || Number(anio) < 1900 || Number(anio) > 2099) {
      mensajeAdvertencia('¡Año vacío o no es un año válido (debe ser un año completo de 4 dígitos)!');
      return false;
    }

    if (![0, 1].includes(Number(cicloSeleccionado.activo))) {
      mensajeAdvertencia('¡El campo activo debe ser 0 o 1!');
      return false;
    }

    ciclos.forEach(ciclo => {
      if (ciclo.activo === 1) {
        if (cicloSeleccionado.idCiclo) {
          // Desactivar ciclo anterior
          const datos = Ciclos(0, ciclo.ciclo, ciclo.anio, 0);
          putOnClick(ciclo.idCiclo, datos);
        }
        return;
      }
    });

    if (operacion === 1) {
      // Insertar (capturar y enviar parámetros)
      postOnClick(cicloSeleccionado);
    } else {
      // Actualizar
      const id = cicloSeleccionado.idCiclo;
      putOnClick(id, cicloSeleccionado);
    }
    document.getElementById('btnCerrarModal').click();
  };

  // Todos los gets
  const getCiclos = () => {
    const urlParcial = 'ciclos/';
    getFetch(urlParcial)
      .then((data) => {
        const { datos, mensaje, estado } = data;
        if (estado) {
          const datosEstandarizados = datos.map(item =>
            Ciclos(
              item.idCiclo || 0,
              item.ciclo || '',
              item.anio || 0,
              item.activo || false
            )
          );
          setCiclos(datosEstandarizados);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  const obtenerCiclosDiplomado = async (idDiplomado) => {
    const urlParcial = `ciclos/ciclosdiplomado/${idDiplomado}`;
    return getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        return datos;  // Retorna los datos para el siguiente `then`
      })
      .catch((error) => {
        console.log("Error->", error);
        throw error;  // Lanza el error para manejarlo donde se llame
      });
  };

  const obtenerCiclosPosteriores = async (anio, idCiclo, limite) => {
    const urlParcial = `ciclos/ciclosposteriores/${anio}/${idCiclo}/${limite}`;
    return getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        return datos;  // Retorna los datos para el siguiente `then`
      })
      .catch((error) => {
        console.log("Error->", error);
        throw error;  // Lanza el error para manejarlo donde se llame
      });
  };

  // Todos los post
  const postOnClick = (datos) => {
    const urlParcial = 'ciclos/';

    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getCiclos();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  // Todos los post
  const insertarCicloFaltante = async () => {
    const urlParcial = 'ciclos/insertarfaltante';

    return postFetch(urlParcial, [])
      .then((respuesta) => {
        const { mensaje, estado, datos } = respuesta;
        if (estado) {
          return datos;
          //mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  // Todos los puts
  const putOnClick = (id, datos) => {
    const urlParcial = `ciclos/${id}`;

    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getCiclos();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  // Todos los deletes
  const deleteOnClick = async (id) => {
    const urlParcial = `ciclos/${id}`;
    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );
    
    if (!confirmed){
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getCiclos();
          mensajeExitoso(mensaje);
        }
        getCiclos(cicloSeleccionado);
      })
      .catch((error) => {
        console.log('Error->', error);
      });
  };

  // Todos los handleChange
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Determina el nuevo valor basado en el tipo de input
    const newValue = type === 'checkbox' ? checked : value;

    setCicloSeleccionado(cicloPrevio => ({
      ...cicloPrevio,
      [name]: newValue
    }));
  };

  return {
    getCiclos,
    ciclos,
    handleChange,
    handleChangeCiclo: handleChange,
    titulo,
    openModal,
    cicloSeleccionado,
    setCicloSeleccionado,
    deleteOnClick,
    validarCampos,
    obtenerCiclosDiplomado,
    obtenerCiclosPosteriores,
    insertarCicloFaltante
  };
};

export { useListadoCiclos };
