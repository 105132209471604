import React, { useEffect } from 'react'
import { useListadoCiclos } from '../ciclos/useListadoCiclos'
import { BtnEditar } from '../../buttons/BtnEditar';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';

const ListaCiclos = () => {

  const {
    getCiclos,
    ciclos,
    handleChange,
    titulo,
    cicloSeleccionado,
    openModal,
    deleteOnClick,
    validarCampos
  } = useListadoCiclos();

  useEffect(() => {
    getCiclos();
  }, [])

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1"><h1>Ciclos</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="modal" databstarget="#modalCiclos" onclick={() => openModal(1)} titulo={"Agregar Ciclo"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Ciclo</th>
              <th scope="col">Año</th>
              <th scope="col">Activo</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {ciclos ? ciclos.map((ciclo) => (
              <tr key={ciclo.idCiclo}>
                <th scope="row">{ciclo.idCiclo}</th>
                <td>{ciclo.ciclo}</td>
                <td>{ciclo.anio}</td>
                <td>{(ciclo.activo === 1) ? "Si" : 'No'}</td>
                <td>
                  <BtnEditar databstogle="modal" databstarget="#modalCiclos" onclick={() => openModal(2, ciclo)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteOnClick(ciclo.idCiclo)} titulo={"Eliminar"} />
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>

      <div className="modal fade" id="modalCiclos" tabIndex="-1" aria-labelledby="modalCiclosLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalCiclosLabel">{titulo}</h1>
              <button
                type="button"
                id='btnCerrarModal'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                  <label htmlFor="txtIdCiclo" className="form-label">ID</label>
                  <input
                    id='txtIdCiclo'
                    onChange={handleChange}
                    value={cicloSeleccionado.idCiclo}
                    type="number"
                    className="form-control"
                    name="idCiclo"
                    disabled
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="txtCiclo" className="form-label">Ciclo</label>
                  <input
                    id='txtCiclo'
                    onChange={handleChange}
                    value={cicloSeleccionado.ciclo}
                    type="text"
                    className="form-control"
                    name="ciclo"
                    required
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="txtAnio" className="form-label">Año</label>
                  <input
                    id='txtAnio'
                    onChange={handleChange}
                    value={cicloSeleccionado.anio}
                    type="text"
                    className="form-control"
                    name="anio"
                    required
                  />
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="activo"
                    id="chkActivo"
                    value={cicloSeleccionado.activo}
                    checked={cicloSeleccionado.activo}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="chkActivo"
                  >
                    Activo
                  </label>
                </div>
                <div className="col-md-12 text-md-end">
                  <hr />
                  <BtnEliminar add={'me-2'} type='button' titulo={'Cancelar'} databsdismiss={'modal'} />
                  <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ListaCiclos }
