import React, { useEffect } from 'react';
import { useListadoNotas } from '../Nota/useListadoNotas'
import { AbrirModal } from '../../components/AbrirModal';
import { AsignarNotas } from './AsignarNotas';
import { BtnEditar } from '../../buttons/BtnEditar';
import { BtnEliminar } from '../../buttons/BtnEliminar';

const ListadoNotas = () => {
    const { searchTable, cursos, notas, actividades, grupos, selectOptionCurso, selectOptionAcividad, selectOptionGrupo,
        seleccionar, selectedName, selectedNota, setSelectedNota,
        Agregar, InsertarLista, tableRef, limpiarInputs, getCursosPorId, user,
        fontSizeCard, cardBosyStyle, cardHeaderStyle, styleNotas, styleNumber,
        handleCancel, handleClose, handleContinue, showModal
    } = useListadoNotas();

    useEffect(() => {
        if (user) {
            getCursosPorId(user[0].idUsuario);
        }
    }, [user]);

    return (
        <>
            <div className="container-fluid mt-custom">
                <div className="card">
                    <div className="card-header bg-color3 text-color1" >
                        <h2 className="text-left mb-0 small">Cursos y actividades</h2>
                    </div>
                    <div className="card-body">
                        <div className="row col-md-12 justify-content-center"> {/*justify-content-center para centrar*/}

                            <div className="col-md-7 mt-2 col-lg-3" >
                                <div className="card border-0">
                                    <div className="card-header bg-color3 text-color1">
                                        <h2 className="h6 mb-0" style={fontSizeCard}>Cursos</h2>
                                    </div>
                                    <div className="card-body p-0">
                                        <select className="form-control border-0 custom-select" onChange={selectOptionCurso} style={cardBosyStyle}>
                                            <option value="">Seleccione una opción</option>
                                            {cursos ? cursos.map((curso) => (
                                                <option key={curso.idCurso} value={curso.idCurso}>{curso.curso}</option>
                                            )) : null}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-7 mt-2 col-lg-1" >
                                <div className="card border-0">
                                    <div className="card-header bg-color3 text-color1">
                                        <h2 className="h6 mb-0" style={fontSizeCard}>Grupo</h2>
                                    </div>
                                    <div className="card-body p-0">
                                        <select className="form-control border-0 custom-select" onChange={selectOptionGrupo} style={cardBosyStyle}>
                                            <option value="">Seleccione una opción</option>
                                            {grupos ? grupos.map((grupo) => (
                                                <option key={grupo.idGrupo} value={grupo.idGrupo}>{grupo.grupo}</option>
                                            )) : null}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-7 mt-2 col-lg-3" >
                                <div className="card border-0">
                                    <div className="card-header bg-color3 text-color1">
                                        <h2 className="h6 mb-0" style={fontSizeCard}>Actividades activas</h2>
                                    </div>
                                    <div className="card-body p-0">
                                        <select className="form-control border-0 custom-select" onChange={selectOptionAcividad} style={cardBosyStyle}>
                                            <option value="">Seleccione una opción</option>
                                            {actividades ? actividades.map((actividad) => (
                                                <option key={actividad.idActividad} value={actividad.idActividad}>{actividad.actividad}</option>
                                            )) : null}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid" style={{ paddingTop: '30px' }}>
                <div className="card">
                    <div className="card-header bg-color3 text-color1">
                        <h2 className="text-left mb-0 small">Lista de alumnos</h2>
                    </div>
                    <div className="card-body">
                        <div className="row col-lg-12 mb-3"> {/*justify-content-center para centrar*/}
                            <div className="col-md-auto ">
                                <span className="search-text" >Expediente a buscar:</span>
                            </div>
                            <div className="col-md-2">
                                <input type="text" className="search-input" id="searchInput" onInput={searchTable} placeholder="Buscar" />
                            </div>
                        </div>

                        <table id="dataTable" ref={tableRef} className="table table-striped table-bordered table-fixed" style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '0%' }}>#</th>
                                    <th style={{ width: '5%' }}>Expediente</th>
                                    <th style={{ width: '20%' }}>Nombres</th>
                                    <th style={{ width: '5%' }}>Sexo</th>
                                    <th style={{ width: '10%' }}>Nota</th>
                                    <th style={{ width: '8%' }}>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notas ? notas.map((nota, index) => (
                                    <tr key={nota.idNota} style={{ height: '5px' }}>
                                        <td>{index + 1}</td>
                                        <td>{nota.expediente}</td>
                                        <td>{nota.nombres && nota.apellidos ? `${nota.nombres} ${nota.apellidos}` : nota.nombres || ''}</td>
                                        <td>{nota.sexo}</td>
                                        <td style={{ width: '10%', textAlign: 'center', verticalAlign: 'middle', padding: '0' }}>
                                            <input
                                                type="text"
                                                value={nota.nota !== null ? nota.nota : ''}
                                                readOnly
                                                style={styleNotas}
                                            />
                                            <input
                                                type="number"
                                                min="0"
                                                max="10"
                                                onInput={(e) => {
                                                    const value = Math.min(Math.max(e.target.value, 0), 10);
                                                    Agregar(nota.idNota, e.target.value)
                                                }}
                                                style={styleNumber}
                                            />
                                        </td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '0' }}>
                                            <BtnEditar onclick={() => seleccionar(nota.idNota, nota.nombres, nota.apellidos, nota.nota)} titulo={'Seleccionar'} />
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                        <div className="search-container" style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            <BtnEliminar add={'me-2'} onclick={limpiarInputs} titulo={'Limpiar campos'} />
                            <BtnEditar onclick={InsertarLista} titulo={'Guardar cambios'} />
                        </div>
                    </div>
                </div>
                <AbrirModal show={showModal}
                    handleContinue={handleContinue}
                    handleCancel={handleCancel}
                    handleClose={handleClose}
                    titulo={"Asignar nota"}
                    ocultar={false}
                >
                    <AsignarNotas selectedName={selectedName} selectedNota={selectedNota} setSelectedNota={setSelectedNota} />
                </AbrirModal>
            </div>
        </>
    )
}
export { ListadoNotas };
