import { useAlert } from "../../hooks/useAlert";
import { useFetch } from "../../tools/useFetch";
import { useEffect, useState } from "react";
import { useListaListaTiposDeCurso } from "../tipocurso/useListaTiposDeCurso";
import { Cursos } from "../../models/Cursos";
import { TipoCurso } from "../../models/TipoCurso";
import { Kits } from "../../models/Kits";
import { Inscripciones } from "../../models/Inscripciones";
import { Participantes } from "../../models/Participantes";
import { Usuarios } from "../../models/Usuarios";
import { Ciclos } from "../../models/Ciclos";
import { EntregaKit } from "../../models/EntregaKit";

function useGestionInscritos() {
    const { getFetch, postFetch, putFetch } = useFetch();
    const [inscritos, setInscritos] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [buscarPorExpediente, setBuscarPorExpediente] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [cursoSeleccionado, setCursoSeleccionado] = useState("0");
    const [cicloSeleccionado, setCicloSeleccionado] = useState("0");
    const [tipoSeleccionado, setTipoSeleccionado] = useState("0");
    const [showModal, setShowModal] = useState(false);
    const [inscritoSelecionado, setinscritoSelecionado] = useState([]);
    const [kits, setKits] = useState([]);
    const [data, setData] = useState([]);
    const {
        mensajeExitoso, mensajeAdvertencia, mensajeError
    } = useAlert();

    const {
        getTiposCurso,
        tiposCurso
    } = useListaListaTiposDeCurso();

    const handleCancel = () => {
        setShowModal(false);
    };

    const openModal = (inscrito) => {
        getKits();
        setinscritoSelecionado(inscrito);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleContinue = () => {
        // Verificar si un valor está vacío
        const isEmpty = (value) => value === "" || value === null || value === undefined;

        const { idInscripcion, idKit, talla, entregado, fechaEntrega, idEntregaKit } = data;

        // Verificamos si cualquiera de los campos (excepto idEntregaKit) está vacío
        if (isEmpty(idInscripcion) || isEmpty(idKit) || isEmpty(talla) || isEmpty(entregado) || isEmpty(fechaEntrega)) {
            mensajeAdvertencia("¡Hay campos vacíos! Por favor, completa todos los campos.");
        } else {
            // Si todos los campos están completos, verificamos el campo idEntregaKit
            if (isEmpty(idEntregaKit)) {
                postOnClick(data);
            } else {
                putOnClick(idEntregaKit, data);
            }
            setShowModal(false);
            getInscritos(cursoSeleccionado, cicloSeleccionado); // Recargar los datos
        }
    };


    // Función para manejar el cambio en los selects
    const handleSelectChange = (e) => {
        const { name, value } = e.target;  // Obtenemos el nombre del select y su valor

        // Actualizamos el estado de acuerdo al select que fue modificado
        if (name === "curso") {
            setCursoSeleccionado(value);  // Actualizamos el valor del curso
        } else if (name === "ciclo") {
            setCicloSeleccionado(value);  // Actualizamos el valor del ciclo
        } else if (name === "tipo") {
            setTipoSeleccionado(value);
            setCursoSeleccionado(0);
        }
    };

    useEffect(() => {
        getTiposCurso(); // Traemos los tipos de curso una vez
        getCursos(cicloSeleccionado, tipoSeleccionado); // Traemos los cursos al cambiar ciclo o tipo
        getInscritos(cursoSeleccionado, cicloSeleccionado); // Traemos los inscritos al cambiar curso o ciclo
    }, [tipoSeleccionado, cicloSeleccionado, cursoSeleccionado]);

    const handleCheckboxChange = () => {
        setBuscarPorExpediente(!buscarPorExpediente);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Función que filtra los inscritos según los criterios de búsqueda
    const filtrarInscritos = () => {
        // Filtrar por el valor del checkbox
        let resultadoFiltrado = inscritos;

        if (buscarPorExpediente) {
            // Filtrar por expediente
            resultadoFiltrado = resultadoFiltrado.filter(inscrito =>
                inscrito.expediente.toLowerCase().includes(searchQuery.toLowerCase())
            );
        } else {
            // Filtrar por nombre
            resultadoFiltrado = resultadoFiltrado.filter(inscrito =>
                inscrito.nombres.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Filtrar por curso
        if (cursoSeleccionado !== "0") {
            resultadoFiltrado = resultadoFiltrado.filter(inscrito =>
                inscrito.idCurso === parseInt(cursoSeleccionado)
            );
        }

        // Filtrar por ciclo
        if (cicloSeleccionado !== "0") {
            resultadoFiltrado = resultadoFiltrado.filter(inscrito =>
                inscrito.idCiclo === parseInt(cicloSeleccionado)
            );
        }

        return resultadoFiltrado;
    };

    const getInscritos = (idCurso, idCiclo) => {
        let urlParcial = `inscritos/${idCurso}/${idCiclo}`;

        // Realizar el GET con el cuerpo de la solicitud
        getFetch(urlParcial)
            .then((data) => {
                const { mensaje, estado, datos } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item => {
                        const ciclo = Ciclos(item.idCiclo, item.ciclo);
                        const inscripcion = Inscripciones(item.idInscripcion, item.activa, item.fechaInscripcion, item.idParticipante);
                        const participantes = Participantes(item.idParticipante, item.expediente, item.codigo, '', item.nie);
                        const usuarios = Usuarios(item.idUsuario, item.correo, item.nombres);
                        const curso = Cursos(item.idCurso, item.curso, '', '', '', '', '', '', '', '', item.idCiclo);
                        const entregakit = EntregaKit(item.idEntregaKit, '', item.idInscripcion, item.talla, item.entregado, item.fechaEntrega);
                        const kit = Kits(item.idKit, item.kit);
                        return {
                            ...inscripcion,
                            ...participantes,
                            ...usuarios,
                            ...ciclo,
                            ...curso,
                            ...entregakit,
                            ...kit
                        };
                    });

                    setInscritos(datosEstandarizados);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });

    };

    const getCursos = (idCiclo, idTipoCurso) => {
        let urlParcial = `cursos/${idCiclo}/${idTipoCurso}`;

        getFetch(urlParcial)
            .then((data) => {
                const { mensaje, estado, datos } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item => {
                        const cursos = Cursos(item.idCurso, item.curso, '', '', '', item.estado);
                        const tipo = TipoCurso(item.idTipoCurso, item.tipo);
                        return {
                            ...cursos,
                            ...tipo
                        };
                    });

                    setCursos(datosEstandarizados);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });

    };

    //ORDENAR GETS
    const getKits = () => {
        const urlParcial = "kits/";
        getFetch(urlParcial)
            .then((data) => {
                const { mensaje, estado, datos } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item =>
                        Kits(
                            item.idKit || 0,
                            item.kit || '',

                        ));
                    setKits(datosEstandarizados);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                console.log("Error->", error);
            });
    };

    const postOnClick = (datos) => {
        const urlParcial = 'entrega_kit/';
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Datos insertados");
                } else {
                    mensajeError("Error al insertar datos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    }

    //ORDENAR PUT
    const putOnClick = (id, datos) => {
        const urlParcial = `entrega_kit/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Datos actualizados");
                } else {
                    mensajeError("Error al actuaizar datos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    }


    return {
        filtrarInscritos,
        handleSelectChange,
        cicloSeleccionado,
        cursoSeleccionado,
        handleCheckboxChange,
        handleSearchChange,
        searchQuery,
        buscarPorExpediente,
        cursos,
        showModal,
        handleContinue,
        handleCancel,
        handleClose,
        openModal,
        inscritoSelecionado,
        kits,
        setData,
        tiposCurso,
        tipoSeleccionado
    };
}

export { useGestionInscritos };
