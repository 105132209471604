import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { Roles } from '../../models/Roles';

const useListadoRoles = () => {
  //Todas las constantes
  const [roles, setRoles] = useState([]);
  const [rolSeleccionado, setRolSeleccionado] = useState(Roles());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, mensajeInformacion, preguntarConfirmacion } = useAlert();

  //Todos los useEffect
  useEffect(() => {
    getRoles();
  }, []);

  //Todas las funciones
  const openModal = (op, rolSeleccionado = Roles()) => {
    setRolSeleccionado(rolSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Rol' : 'Editar Rol');
  };

  const validarCampos = () => {
    if (rolSeleccionado.rol.trim() === '') {
      mensajeAdvertencia("Debe escribir el rol!");
    } else {
      const datos = {
        rol: rolSeleccionado.rol
      };

      if (operacion === 1) {
        postOnClick(datos);
      } else {
        const id = rolSeleccionado.idRol;
        putOnClick(id, datos);
      }
      document.getElementById('btnCerrarModal').click();
    }
  };

  //Todos los gets
  const getRoles = () => {
    const urlParcial = "roles/";
    getFetch(urlParcial)
      .then((data) => {
        const { mensaje, estado, datos } = data;
        if (estado) {
          const datosEstandarizados = datos.map(item =>
            Roles(
              item.idRol || 0,
              item.rol || ''
            ));
          setRoles(datosEstandarizados);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  //Todos los post
  const postOnClick = (datos) => {
    const urlParcial = 'roles/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getRoles();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  //Todos los puts
  const putOnClick = (id, datos) => {
    const urlParcial = `roles/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getRoles();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  //Todos los delete
  const deleteOnClick = async (id) => {
    const urlParcial = `roles/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );

    if (!confirmed) {
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getRoles();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  //Todos los handle
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRolSeleccionado((rolPrevio) => ({ ...rolPrevio, [name]: value }));
  };

  return {
    getRoles,
    roles,
    handleChange,
    titulo,
    openModal,
    rolSeleccionado,
    deleteOnClick,
    validarCampos
  };
};

export { useListadoRoles };
