import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarPrincipal from '../NavPrincipal/NavbarPrincipal';
import {API_URL} from '../../config'

const StudentStatus = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${API_URL}/students/status`);
        setStudents(response.data);
        setLoading(false);
      } catch (error) {
        // Si ocurre un error, mantenemos la tabla vacía y quitamos el loading
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <NavbarPrincipal />
      <div className="flex flex-grow bg-gray-50">
        <div className="flex flex-col items-center justify-center flex-1 p-6">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">Estado General de Estudiantes</h1>
          {loading ? (
            <p className="text-center text-blue-500">Cargando...</p>
          ) : (
            <div className="w-full max-w-6xl overflow-x-auto">
              <table className="w-full bg-white border border-gray-300 rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-3 text-left">Nombre</th>
                    <th className="p-3 text-left">Correo Electrónico</th>
                    <th className="p-3 text-left">Carrera</th>
                    <th className="p-3 text-left">Estado</th>
                    <th className="p-3 text-left">Año de Graduación</th>
                    <th className="p-3 text-left">Teléfono Móvil</th>
                    <th className="p-3 text-left">Dirección</th>
                  </tr>
                </thead>
                <tbody>
                  {students.length === 0 ? (
                    <tr>
                      <td className="p-3 text-center text-gray-600 border-t border-gray-300" colSpan="7">
                        No hay estudiantes registrados.
                      </td>
                    </tr>
                  ) : (
                    students.map((student) => (
                      <tr key={student.idEstudiantes} className="hover:bg-gray-100">
                        <td className="p-3 border-t border-gray-300">{student.nombre} {student.apellido}</td>
                        <td className="p-3 border-t border-gray-300">{student.email}</td>
                        <td className="p-3 border-t border-gray-300">{student.carrera_estudio}</td>
                        <td className={`p-3 border-t border-gray-300 ${student.estado === 'Activo' ? 'text-green-600' : 'text-red-600'}`}>
                          {student.estado}
                        </td>
                        <td className="p-3 border-t border-gray-300">{student.anio_graduacion}</td>
                        <td className="p-3 border-t border-gray-300">{student.telefono_movil}</td>
                        <td className="p-3 border-t border-gray-300">{student.direccion}, {student.municipio}, {student.departamento}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentStatus;
