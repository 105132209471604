
function Direcciones(idDireccion = 0, idMunicipio = 0, complemento = '', idDepartamento = 0) {
    return {
        idDireccion, 
        idMunicipio, 
        complemento,
        idDepartamento
    }
}

export { Direcciones }