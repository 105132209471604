import React, { useRef, useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { TipoCurso } from '../../models/TipoCurso';

const useListaListaTiposDeCurso = () => {

  const [tiposCurso, setTiposCurso] = useState([]);
  const [tiposCursoSeleccionado, setTiposCursoSeleccionado] = useState(TipoCurso());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, preguntarConfirmacion } = useAlert();
  const [imagen, setImagen] = useState(null);
  const fileInputRef = useRef(null);

  const openModal = (op, tiposCursoSeleccionado = TipoCurso()) => {
    setTiposCursoSeleccionado(tiposCursoSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Tipo de curso' : 'Editar Tipo de curso');
    if (op === 1) {
      // Reset imagen when adding a new type of course
      setImagen(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const resetForm = () => {
    setTiposCursoSeleccionado(TipoCurso());
    setImagen(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validarCampos = () => {
    const tipo = tiposCursoSeleccionado.tipo || '';
    const descripcion = tiposCursoSeleccionado.descripcion || '';

    if (tipo.trim() === '' || descripcion.trim() === '') {
      mensajeAdvertencia("Debe completar todos los campos");
    } else {

      if (operacion === 1) {
        postOnClick(tiposCursoSeleccionado);
      } else {
        const id = tiposCursoSeleccionado.idTipoCurso;
        putOnClick(id, tiposCursoSeleccionado);
      }
      // Close modal should reset form
      resetForm();
      document.getElementById('btnCerrarModal').click();
    }
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImagen(reader.result);
      reader.readAsDataURL(file);
    } else {
      setImagen(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const getTiposCurso = () => {
    const urlParcial = "tiposcurso/";
    getFetch(urlParcial)
      .then((data) => {
        const { mensaje, estado, datos } = data;
        if (estado) {
          const datosEstandarizados = datos.map(item =>
            TipoCurso(
              item.idTipoCurso || 0,
              item.tipo || '',
              item.imagen || '',
              item.descripcion || ''
            ));
          setTiposCurso(datosEstandarizados);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const postOnClick = (datos) => {
    const urlParcial = 'tiposcurso/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getTiposCurso();
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const putOnClick = (id, datos) => {
    const urlParcial = `tiposcurso/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getTiposCurso();
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const deleteOnClick = async (id) => {
    const urlParcial = `tiposcurso/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );

    if (!confirmed) {
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getTiposCurso();
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTiposCursoSeleccionado(prevState => ({ ...prevState, [name]: value }));
  };

  return {
    getTiposCurso,
    tiposCursoSeleccionado,
    tiposCurso,
    handleChange,
    titulo,
    openModal,
    deleteOnClick,
    validarCampos,
    handleChangeFile,
    imagen,
    setImagen,
    resetForm,
    fileInputRef
  };
};

export { useListaListaTiposDeCurso };
