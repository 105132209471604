import React from 'react';
import { FiX } from 'react-icons/fi'; 

const Modal = ({ title, children, onClose, onRedirect, hasRedirect }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center transition-opacity duration-300 ease-out bg-black bg-opacity-50">
            <div className="relative w-full max-w-lg p-8 transition-all duration-300 ease-out transform bg-white shadow-2xl rounded-2xl">
                {/* Botón de cierre */}
                <button 
                    className="absolute text-gray-500 transition duration-200 top-4 right-4 hover:text-red-500 focus:outline-none"
                    onClick={onClose}
                >
                    <FiX size={24} /> {/* Ícono X estilizado */}
                </button>

                {/* Título del modal */}
                <h2 className="pb-4 mb-6 text-3xl font-bold border-b text-sky-700">{title}</h2>

                {/* Contenido del modal */}
                <div className="leading-relaxed text-gray-700">
                    {children}
                </div>

                {/* Botones de acción */}
                <div className="mt-6 space-x-4 text-right">
                    {/* Botón de redirección solo si hay redirección */}
                    {hasRedirect && (
                        <button
                            onClick={onRedirect}
                            className="px-6 py-2 font-semibold text-white transition-colors duration-300 ease-in-out bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Continuar
                        </button>
                    )}

                    {/* Botón de cerrar */}
                    <button
                        onClick={onClose}
                        className="px-6 py-3 font-semibold text-white transition duration-300 bg-gray-600 rounded-full shadow-md hover:bg-gray-700 focus:outline-none hover:shadow-lg"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
