import { useState } from "react";
import { useFetch } from "../../tools/useFetch";
import { useAlert } from "../../hooks/useAlert";
import { useListadoCiclos } from "../ciclos/useListadoCiclos";
import { useCursos } from "../cursos/useCursos";

function useSeleccionDiplomado({ obtenerCursos, setShowModalDiplomadosSeleccion }) {

    const { getFetch, postFetch } = useFetch();
    const [diplomados, setDiplomados] = useState([]);
    const { mensajeError, mensajeExitoso } = useAlert();
    const { obtenerCiclosDiplomado, obtenerCiclosPosteriores, insertarCicloFaltante } = useListadoCiclos();
    const { obtenerCursosDeDiplomadoPorCiclo } = useCursos();

    const obtenerDiplomadosFinalizados = (tipoFiltro) => {
        if (tipoFiltro !== '1') {
            return;
        }
        const urlParcial = `diplomados/finalizados`
        getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;
                setDiplomados(datos);
            })
            .catch((error) => {
                console.log("Error->", error);
            })
    }

    const obtenerCursosDuplicar = async (idDiplomado, idCiclo) => {
        const urlParcial = `cursos/duplicar/${idDiplomado}/${idCiclo}`;
        return getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;
                return datos;  // Retorna los datos para el siguiente `then`
            })
            .catch((error) => {
                console.log("Error->", error);
                throw error;  // Lanza el error para manejarlo donde se llame
            });
    };

    const insertarReplicaCurso = (datosRep) => {
        const urlParcial = `cursos/duplicar`;
        postFetch(urlParcial, datosRep)
            .then((respuesta) => {
                const { estado, mensaje, datos } = respuesta;
                if (estado) {
                    const datosAct = {
                        idInsertado: datos.insertId,
                        idCurso: datosRep.idCurso
                    }
                    insertarReplicaActividad(datosAct);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };

    const insertarReplicaDiplomado = async (datosRep) => {
        const urlParcial = `diplomados/duplicar`;
        return postFetch(urlParcial, datosRep)
            .then((respuesta) => {
                const { estado, mensaje, datos } = respuesta;
                if (estado) {
                    return datos;
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };

    const insertarReplicaActividad = (datosRep) => {
        const urlParcial = `actividades/duplicar`;
        postFetch(urlParcial, datosRep)
            .then((respuesta) => {
                const { estado, mensaje } = respuesta;
                if (estado) {

                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };

    const handleOnClickDiplomado = async (anio, idCiclo, idDiplomado, getCiclos) => {
        let ocurrioError = false;
        try {
            // Inicializar variables
            let cantCiclosDeDiplomado = 0;
            let cantidadPosterior = 0;
            let cantidadCiclosAgregar = 0;
            let ciclosPosteriores = [];

            // Obtener los ciclos del diplomado
            const ciclos = await obtenerCiclosDiplomado(idDiplomado);
            cantCiclosDeDiplomado = ciclos.length;

            // Obtener ciclos posteriores
            const ciclos2 = await obtenerCiclosPosteriores(anio, idCiclo, cantCiclosDeDiplomado);

            ciclosPosteriores = ciclos2;
            cantidadPosterior = ciclos2.length;

            // Comparar si la cantidad de ciclos posteriores alcanza para el módulo
            if (cantidadPosterior < cantCiclosDeDiplomado) {
                cantidadCiclosAgregar = cantCiclosDeDiplomado - cantidadPosterior;
            }

            // Agregar los ciclos faltantes
            for (let index = 0; index < cantidadCiclosAgregar; index++) {
                try {
                    const datos = await insertarCicloFaltante();
                    ciclosPosteriores.push({ idCiclo: datos.insertId });
                } catch (error) {
                    ocurrioError = true;
                    console.log("Error al insertar ciclo faltante:", error);
                }
                
            }

            //INSERTAR DIPLOMADO
            const datos = {
                idDiplomado: idDiplomado
            }
            const diplomado = await insertarReplicaDiplomado(datos);

            let n = 0;
            // Obtener los cursos de cada ciclo
            for (const ciclo of ciclos) {
                try {
                    const cursos = await obtenerCursosDeDiplomadoPorCiclo(ciclo.idCiclo, idDiplomado);
                    cursos.forEach(curso => {
                        // Aqui se manejan los ciclos que tiene el diplomado
                        const datosInsert = {
                            idCiclo: ciclosPosteriores[n].idCiclo,//Ciclo en el que se replicara
                            idDiplomado: diplomado.insertId,
                            idCurso: curso.idCurso
                        }
                        insertarReplicaCurso(datosInsert);
                    });
                    n++;
                } catch (error) {
                    ocurrioError = true;
                    console.log("Error al obtener cursos del diplomado por ciclo:", error);
                }
            }
        } catch (error) {
            ocurrioError = true;
            console.log("Error general:", error);
        } finally {
            if(!ocurrioError){
                mensajeExitoso("Diplomado duplicado exitosamente.");
                getCiclos();
            }
            setShowModalDiplomadosSeleccion(false);
            obtenerCursos();
        }
    };

    return {
        obtenerDiplomadosFinalizados,
        handleOnClickDiplomado,
        diplomados
    }
}

export { useSeleccionDiplomado }