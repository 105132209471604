
function DetallesCuentasPorCobrar(idDetalleCuentaPorCobrar = 0, estado = 'pendiente', concepto = 0, monto = 0.0, fechaVencimiento = '', fechaPago = '', recargo = false, valorRecargo = 0.0, idCodigoServicio = 0, idCuentaPorCobrar = 0, idCurso = 0, orden = '', idParticipante = 0) {
    return {
        idDetalleCuentaPorCobrar,
        estado,
        concepto,
        monto,
        fechaVencimiento,
        fechaPago,
        recargo,
        valorRecargo,
        idCodigoServicio,
        idCuentaPorCobrar,
        idCurso,
        orden,
        idParticipante
    }
}

export { DetallesCuentasPorCobrar }