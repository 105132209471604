import React, { useEffect } from 'react'
import { useListadoDepartamentos } from '../departamentos/useListadoDepartamentos'
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

const ListaDepartamentos = () => {

  const {
    getDepartamentos,
    handleForSearchChange,
    departamentos,
    openModal,
    deleteOnClick
  } = useListadoDepartamentos();

  useEffect(() => {
    getDepartamentos();
  }, [])

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1"><h1>Departamentos</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="" databstarget="" onclick={() => openModal(1)} titulo={"Agregar Departamento"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <div className="container-fluid col-md-6">
          <input className="form-control m-2" onChange={handleForSearchChange} type="search" placeholder="Escriba el nombre del departamento para filtrar" aria-label="Search" />
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Departamento</th>
              <th scope="col">N° de Municipios</th>
              <th scope="col">Código</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {departamentos ? departamentos.map((departamento) => (
              <tr key={departamento.idDepartamento}>
                <th scope="row">{departamento.departamento}</th>
                <td>{departamento.municipios}</td>
                <td>{departamento.codigo}</td>
                <td>
                  <BtnEditar databstogle="" databstarget="" onclick={() => openModal(2, departamento)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteOnClick(departamento.idDepartamento)} titulo={"Eliminar"} />
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>
    </>
  )
}

export { ListaDepartamentos }
