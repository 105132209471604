import { useEffect, useState } from "react";
import { useFetch } from "../../tools/useFetch";
import { useAlert } from "../../hooks/useAlert";
import { Cursos } from "../../models/Cursos";
import { TipoCurso } from "../../models/TipoCurso";

function useGestionCursos({ obtenerCursos }) {

    const [tipos_cursos, setTipos_cursos] = useState([]);
    const [cursos, setCursos] = useState([]);
    const { getFetch, postFetch, putFetch } = useFetch();
    const { mensajeError, mensajeExitoso, mensajeInformacion } = useAlert();
    const [expandedCursoId, setExpandedCursoId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalCursos, setShowModalCursos] = useState(false);
    const [showModalCursosSeleccion, setShowModalCursosSeleccion] = useState(false);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [imagen, setImagen] = useState();

    useEffect(() => {
        getTiposCursos();
    }, []);

    const getTiposCursos = () => {
        const urlParcial = `tiposcurso/`
        getFetch(urlParcial)
            .then((data) => {
                const { datos, estado, mensaje } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item =>
                        TipoCurso(
                            item.idTipoCurso || 0,
                            item.tipo || '',
                            item.imagen || '',
                            item.descripcion || ''
                        )
                    );
                    setTipos_cursos(datosEstandarizados);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                console.log("Error->", error);
            })
    }

    const toggleCurso = (idCurso) => {
        setExpandedCursoId(expandedCursoId === idCurso ? null : idCurso);
    };

    //
    const openModal = () => {
        handleClose();
        setShowModal(true);
    };

    const openModalCursos = (curso = null, soloSeleccionar) => {
        setCursoSeleccionado(curso);
        if (!soloSeleccionar) {
            handleClose();
            setShowModalCursos(true);
        }
    };

    const openModalCursosSeleccion = () => {
        setShowModalCursosSeleccion(true);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        let newValue;
        if (type === 'checkbox') {
            newValue = checked;
        } else if (type === 'radio') {
            newValue = value; // Asume que el valor del radio será relevante
        } else {
            newValue = value; // Para otros tipos de inputs
        }

        setCursoSeleccionado((cursoPrevio) => ({
            ...cursoPrevio,
            [name]: newValue
        }));
    };


    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImagen(reader.result);
            });
            reader.readAsDataURL(file);
        } else {
            setImagen(''); // Puedes reiniciar el estado de la imagen si lo deseas
        }
    };

    const validarCamposCurso = (idTipo, cicloSelect) => {

        const curso = Cursos(
            cursoSeleccionado.idCurso,
            cursoSeleccionado.curso,
            (imagen) ? imagen : cursoSeleccionado.imagen,
            cursoSeleccionado.fechaInicio.split('T')[0],
            cursoSeleccionado.fechaFinalizacion.split('T')[0],
            (cursoSeleccionado.estado) ? cursoSeleccionado.estado : 'EDICION',
            (cursoSeleccionado.precio) ? cursoSeleccionado.precio : 0,
            cursoSeleccionado.duracion,
            idTipo,
            (idTipo === '1') ? cursoSeleccionado.idDiplomado : null,
            cicloSelect
        );
        if (!curso.curso || !curso.fechaInicio || !curso.fechaFinalizacion) {
            mensajeInformacion("Por favor llenar los campos necesarios");
            return false;
        }

        // Convertir las fechas a objetos Date para comparar
        const fechaInicio = new Date(curso.fechaInicio);
        const fechaFinalizacion = new Date(curso.fechaFinalizacion);

        if (fechaInicio > fechaFinalizacion) {
            mensajeInformacion("La fecha de inicio no puede ser mayor a la fecha de finalización");
            return false;
        }

        if (curso.idCurso) {
            //Actualizar curso
            putOnClick(curso.idCurso, curso);
        } else {
            //Insertar curso
            postOnClick(curso);
        }
        setShowModalCursos(false);
        return;
    }

    const postOnClick = (datos) => {
        const urlParcial = 'cursos/';
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { estado, mensaje } = respuesta;
                if (estado) {
                    mensajeExitoso(mensaje);
                    obtenerCursos();
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };


    const putOnClick = (id, datos) => {
        const urlParcial = `cursos/actualizar/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { estado, mensaje } = respuesta;
                if (estado) {
                    mensajeExitoso(mensaje);
                    obtenerCursos();
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };

    const handleClose = () => {
        setShowModal(false);
        setShowModalCursos(false);
        //setShowModalDiplomados(false);
    };

    return {
        setShowModalCursos,
        setExpandedCursoId,
        setCursos,
        obtenerCursos,
        imagen,
        validarCamposCurso,
        handleChange,
        handleChangeFile,
        cursoSeleccionado,
        showModal,
        showModalCursos,
        setShowModalCursosSeleccion,
        showModalCursosSeleccion,
        handleClose,
        openModal,
        openModalCursos,
        openModalCursosSeleccion,
        tipos_cursos,
        toggleCurso,
        expandedCursoId,
        cursos
    };
}

export { useGestionCursos };
