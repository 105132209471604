
function Municipios(idMunicipio = 0, municipio = '', codigo = '', idDepartamento = 0) {
    return {
        idMunicipio, 
        municipio, 
        codigo, 
        idDepartamento
    }
}

export { Municipios }