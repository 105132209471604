import React, { useState } from 'react';
import authService from '../../services/authService';
import Navbar from '../NavIndex/Navbar';
import Footer from '../Footer';

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await authService.requestPasswordReset(email);
      setMessage('Se ha enviado un correo para restablecer la contraseña.');
    } catch (error) {
      console.error(error); // Log más detallado
      setMessage('Error al enviar el correo de restablecimiento de contraseña.');
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex items-center justify-center flex-grow bg-gray-100">
        <div className="w-full max-w-3xl p-8 mx-auto bg-white rounded-lg shadow-md lg:max-w-2xl">
          <h1 className="mb-8 text-4xl font-bold text-center text-darkbluetxt">
            Solicitar Restablecimiento de Contraseña
          </h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="email">
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Introduce tu correo"
                className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
              />
            </div>
            <button
              type="submit"
              className="w-full px-4 py-3 text-lg font-semibold text-white transition duration-300 transform rounded-lg shadow-md bg-bluelight hover:bg-lightgreentxt hover:scale-105 focus:outline-none"
            >
              Solicitar Restablecimiento
            </button>
          </form>
          {message && <p className="p-4 mt-4 text-center text-red-600 bg-red-100 rounded-md">{message}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RequestPasswordReset;
