import React from 'react'
import { BtnAgregar } from '../../buttons/BtnAgregar'
import { GestionHorarios } from '../horarios/GestionHorarios'
import { BtnEditar } from '../../buttons/BtnEditar'
import { Horarios } from '../../models/Horarios'

function GestionGrupos({ curso, grupo, expandedGrupoId, toggleGrupo, openModalGrupos, openModalHorarios, deleteOnClick, calcularHoras}) {
    return (
        <div key={grupo.idGrupo} className=''>
            {/* Renderizado del grupo */}
            <div className="d-flex ms-5">
                <div className="p-2 flex-grow-1">
                    <BtnEditar
                        titulo={expandedGrupoId === grupo.idGrupo ? '-' : '+'}
                        databstogle={'collapse'}
                        databstarget={`#collapseGrupo${grupo.idGrupo}`}
                        onclick={() => toggleGrupo(grupo.idGrupo)}  // Manteniendo onclick
                    />
                    Grupo: {grupo.grupo} | Horas acumuladas: {calcularHoras(grupo.horarios)} | Total horas: {curso.duracion}
                </div>
                <div className="p-2">
                    <BtnEditar titulo="Editar Grupo" onclick={() => openModalGrupos(grupo)} />
                    <BtnAgregar add='ms-2' titulo={'Agregar Horario'} onclick={() => openModalHorarios(new Horarios(null, null, null, null, null, null, grupo.idGrupo))} />
                </div>
            </div>

            {expandedGrupoId === grupo.idGrupo && (
                <div className="card card-body ms-5" id={`collapseHorario${grupo.idGrupo}`}>
                    <h3>Horarios:</h3>
                    {(grupo.horarios.length) ? grupo.horarios.map((horario) => (
                        <GestionHorarios 
                        key={horario.idHorario} 
                        horario={horario}
                        openModalHorarios={openModalHorarios} 
                        deleteOnClick={deleteOnClick}/>
                    )) : null}
                </div>
            )}
        </div>
    )
}

export { GestionGrupos }