import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/DataContext';
import { useListaListaTiposDeCurso } from '../pages/tipocurso/useListaTiposDeCurso';
import { useHome } from './useHome';
import { CursoPrincipal } from './CursoPrincipal';
import { DiplomadoPrincipal } from './DiplomadoPrincipal';

const Home = () => {
  const { user, getUser } = useAuth();
  const navegar = useNavigate();
  const { obtenerCursos, handleChange, diplomados, tipoFiltro, cursosFiltrados } = useHome();
  const { tiposCurso, getTiposCurso } = useListaListaTiposDeCurso();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    obtenerCursos(user ? user[0].idUsuario : 0);
    getTiposCurso();
  }, [user]);

  const inscribirse = (id) => {
    navegar(`/cursos/${id}`);
  };

  return (
    <>
      <div className="container mt-custom">
        <div class="container filter">
          <div class="filter-box">
            <h2 for="filter" class="filter-label"> Cursos disponibles</h2>
            <form className="d-flex">
              <select id="filter" class="filter-select" onChange={handleChange}>
                <option value="0">Todos</option>
                {tiposCurso.map((tipo) => (
                  <option key={tipo.idTipoCurso} value={tipo.idTipoCurso}>
                    {tipo.tipo}
                  </option>
                ))}
              </select>
            </form>
          </div>
        </div>

        {/* Mostrar Diplomados si el filtro es '0' (Todos) o '1' (Diplomados) */}
        {(tipoFiltro === '0' || tipoFiltro === '1') && Object.keys(diplomados).length > 0 && (
          <section className='diplomados'>
            <h2>Diplomados</h2>
            {Object.keys(diplomados).map((idDiplomado) => (
              <DiplomadoPrincipal
                key={idDiplomado}
                diplomado={diplomados[idDiplomado]}
                inscribirse={inscribirse}
                user={user}
              />
            ))}
          </section>
        )}

        {/* Mostrar Cursos filtrados por '2' (Cortos) o '3' (Financiados) */}
        {cursosFiltrados.length > 0 && (
          <section className='cursos-independientes'>
            <h2>Cursos independientes</h2>
            {cursosFiltrados.map((curso) => (
              <CursoPrincipal
                key={curso.idCurso}
                curso={curso}
                inscribirse={inscribirse}
                user={user}
              />
            ))}
          </section>
        )}

        {/* Mensajes de error si no hay resultados */}
        {tipoFiltro !== '1' && cursosFiltrados.length === 0 && (
          <p>No hay cursos disponibles para el filtro seleccionado.</p>
        )}

        {tipoFiltro === '1' && Object.keys(diplomados).length === 0 && (
          <p>No hay diplomados disponibles.</p>
        )}

      </div>
      <footer className="footer">
        <div className="footer-container">
          {tiposCurso && tiposCurso.length > 0 ? (
            tiposCurso
              .filter((tipo) => tipo.imagen || tipo.descripcion)
              .map((tipo, index) => (
                <div key={index} className="footer-item">
                  <img
                    src={tipo.imagen || 'default-image-url'}
                    alt={tipo.descripcion || 'Descripción no disponible'}
                  />
                  <div>
                    <h6>
                      {tipo.descripcion || 'Descripción no disponible'}
                    </h6>
                  </div>
                </div>
              ))
          ) : (
            <p className="footer-empty">No hay información disponible.</p>
          )}
        </div>
      </footer>
    </>
  );
};

export { Home };
