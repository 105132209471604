import React, { useState } from 'react';

const AcademicInfoForm = ({ studentData, setStudentData, handleNextStep, handlePreviousStep }) => {
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!studentData.titulo_bachillerato.trim()) {
            newErrors.titulo_bachillerato = 'El título de bachillerato es requerido';
        }
        if (!studentData.institucion_bachillerato.trim()) {
            newErrors.institucion_bachillerato = 'La institución de bachillerato es requerida';
        }
        if (!/^\d{4}$/.test(studentData.anio_graduacion_bachillerato)) {
            newErrors.anio_graduacion_bachillerato = 'Debe ingresar un año válido de 4 dígitos';
        }
        if (studentData.titulo_superior && !/^\d{4}$/.test(studentData.anio_graduacion_superior)) {
            newErrors.anio_graduacion_superior = 'Debe ingresar un año válido de 4 dígitos';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Limitar el año a 4 dígitos y solo números
        if ((name === 'anio_graduacion_bachillerato' || name === 'anio_graduacion_superior') && !/^\d{0,4}$/.test(value)) {
            return;
        }
        setStudentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            handleNextStep();
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full max-w-2xl p-8 space-y-6 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-center text-sky-700">Información Académica</h1>

            <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">Descripción de título de bachillerato<span className="text-red-500">*</span></label>
                <input
                    type="text"
                    name="titulo_bachillerato"
                    value={studentData.titulo_bachillerato}
                    onChange={handleChange}
                    placeholder="Bachillerato técnico vocacional en desarrollo de software"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
                />
                {errors.titulo_bachillerato && <p className="text-red-600">{errors.titulo_bachillerato}</p>}
            </div>

            <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">Institución de procedencia<span className="text-red-500">*</span></label>
                <input
                    type="text"
                    name="institucion_bachillerato"
                    value={studentData.institucion_bachillerato}
                    onChange={handleChange}
                    placeholder="Instituto Nacional de Sonzacate"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
                />
                {errors.institucion_bachillerato && <p className="text-red-600">{errors.institucion_bachillerato}</p>}
            </div>

            <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">Año de graduación de bachillerato<span className="text-red-500">*</span></label>
                <input
                    type="text"
                    name="anio_graduacion_bachillerato"
                    value={studentData.anio_graduacion_bachillerato}
                    onChange={handleChange}
                    placeholder="2017"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
                />
                {errors.anio_graduacion_bachillerato && <p className="text-red-600">{errors.anio_graduacion_bachillerato}</p>}
            </div>

            <h1 className="text-3xl font-bold text-center text-sky-700">Educación Superior</h1>

            <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">Descripción del título</label>
                <input
                    type="text"
                    name="titulo_superior"
                    value={studentData.titulo_superior}
                    onChange={handleChange}
                    placeholder="Ingeniería en Sistemas Computacionales"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
                />
            </div>

            <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">Institución de procedencia</label>
                <input
                    type="text"
                    name="institucion_superior"
                    value={studentData.institucion_superior}
                    onChange={handleChange}
                    placeholder="Universidad de Ejemplo"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
                />
            </div>

            <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">Año de graduación</label>
                <input
                    type="text"
                    name="anio_graduacion_superior"
                    value={studentData.anio_graduacion_superior}
                    onChange={handleChange}
                    placeholder="2021"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
                />
                {errors.anio_graduacion_superior && <p className="text-red-600">{errors.anio_graduacion_superior}</p>}
            </div>

            <div className="flex justify-between">
                <button
                    type="button"
                    onClick={handlePreviousStep}
                    className="px-4 py-2 text-lg font-semibold text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none"
                >
                    Atrás
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 text-lg font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
                >
                    Siguiente
                </button>
            </div>
        </form>
    );
};

export default AcademicInfoForm;
