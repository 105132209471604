import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/DataContext';

function PublicRoutes() {

    const { user } = useAuth();

    if (user) return <Navigate to='/cursos' />

    return (
        <Outlet />
    )
}

export { PublicRoutes }