
function EntregaKit(idEntregaKit = 0, idKit = 0, idInscripcion = 0, talla = '', entregado = false, fechaEntrega = '') {
    return {
        idEntregaKit, 
        idKit, 
        idInscripcion, 
        talla, 
        entregado, 
        fechaEntrega
    }
}

export { EntregaKit }