import React from 'react';
import { useListadoCodigoServicio } from '../codigo_servicio/useListadoCodigoDeServicio';

const PlantillasModal = ({ plantillaSeleccionada, handleChange }) => {
    const {
        codigosServicio
    } = useListadoCodigoServicio();

    return (
        <div className="modal-body">
            <form className="row g-3 needs-validation" noValidate>
                {/* Fila 1: ID y Concepto */}
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="txtIdPlantilla" className="form-label">ID</label>
                        <input
                            id='txtIdPlantilla'
                            value={plantillaSeleccionada.idPlantilla || ''}
                            type="number"
                            className="form-control"
                            name="idPlantilla"
                            disabled
                        />
                    </div>

                    <div className="col-md-8">
                        <label htmlFor="txtConcepto" className="form-label">Concepto</label>
                        <input
                            id='txtConcepto'
                            onChange={handleChange}
                            value={plantillaSeleccionada.concepto || ''}
                            type="text"
                            className="form-control"
                            name="concepto"
                            required
                        />
                    </div>
                </div>

                {/* Fila 2: Monto y Tipo */}
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="txtMonto" className="form-label">Monto</label>
                        <input
                            id='txtMonto'
                            onChange={handleChange}
                            value={plantillaSeleccionada.monto || ''}
                            type="number"
                            className="form-control"
                            name="monto"
                            required
                        />
                    </div>

                    <div className="col-md-8">
                        <label htmlFor="txtTipo" className="form-label">Tipo</label>
                        <input
                            id='txtTipo'
                            onChange={handleChange}
                            value={plantillaSeleccionada.tipo || ''}
                            type="text"
                            className="form-control"
                            name="tipo"
                            required
                        />
                    </div>
                </div>

                {/* Fila 3: Plantilla */}
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="txtPlantilla" className="form-label">Plantilla</label>
                        <input
                            id='txtPlantilla'
                            onChange={handleChange}
                            value={plantillaSeleccionada.plantilla || ''}
                            type="text"
                            className="form-control"
                            name="plantilla"
                            required
                        />
                    </div>
                    <div className="col-md-8">
                        <label htmlFor="txtIdCodigo" className="form-label">Codigo servicio</label>
                        <select
                            id="CodigoSelect"
                            onChange={handleChange}
                            value={plantillaSeleccionada.idCodigoServicio || ''}
                            className="form-select"
                            name="idCodigoServicio"
                            required
                        >
                            <option value="">Selecciona un codigo</option>
                            {codigosServicio.map(codigosServicio => (
                                <option key={codigosServicio.idCodigoServicio}
                                    value={codigosServicio.idCodigoServicio}> {`${codigosServicio.codigo} - ${codigosServicio.concepto}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </form>
        </div>
    );
};

export { PlantillasModal };
