import Swal from 'sweetalert2';

function useAlert() {
  const showAlert = (type, title, text) => {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
    });
  };

  const mensajeExitoso = (title) => {
    showAlert('success', title, "¡Exito!");
  };

  const mensajeError = (title) => {
    showAlert('error', title, "¡Error!");
  };

  const mensajeAdvertencia = (title) => {
    showAlert('warning', title, "Advertencia");
  };

  const mensajeInformacion = (title) => {
    showAlert('info', title, "¡Informacion!");
  };

  // Nueva función para mostrar una alerta con confirmación
  const preguntarConfirmacion = async (title, text) => {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,  
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    });

    // Retorna un booleano indicando si el usuario confirmó
    return result.isConfirmed;
  };

  return {
    mensajeExitoso,
    mensajeError,
    mensajeAdvertencia,
    mensajeInformacion,
    preguntarConfirmacion,
  };
}

export { useAlert };
