import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/NavIndex/Home";
import HomeDocente from "./components/NavIndex/HomeDocente";
import Information from "./components/NavIndex/Information";

import AccountActivated from "./components/Login/AccountActivated";
import NotFound from "./components/NotFound";

import AssignCourses from "./components/NavPrincipal/AssignCourses";
import AssignGroup from "./components/NavPrincipal/AssignGroup";
import AssignGrades from "./components/NavPrincipal/AssignGrades";
import Reports from "./components/NavPrincipal/Reports";
import Principal from "./components/NavPrincipal/Principal";
import StudentStatus from "./components/NavPrincipal/StudentStatus";
import InscriptionProcess from "./components/NavPrincipal/InscriptionProcess";

import Payment from "./components/NavVerticalHelper/Payment";
import SchedulesAndGroups from "./components/NavVerticalHelper/SchedulesAndGroups";
import Grades from "./components/NavVerticalHelper/Grades";
import Profile from "./components/NavVerticalHelper/StudentProfile/Profile";

import ProtectedRouteButton from "./context/ProtectedRouteButton";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";

import LoginForm from "./components/Login/LoginForm";
import RegisterForm from "./components/Login/RegisterForm";
import ActivateAccount from "./components/Login/ActivateAccount";
import RequestPasswordReset from "./components/Login/RequestPasswordReset";
import ResendActivation from "./components/Login/ResendActivation";
import ResetPassword from "./components/Login/ResetPassword";

import DocenteList from "./components/Docentes/ManageTeachers";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Rutas públicas */}
          <Route path="/" element={<Home />} />
          <Route path="/information" element={<Information />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route
            path="/activate-account/:token"
            element={<ActivateAccount />}
          />
          <Route
            path="/request-password-reset"
            element={<RequestPasswordReset />}
          />
          <Route path="/resend-activation" element={<ResendActivation />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/account-activated" element={<AccountActivated />} />
          <Route path="/admin" element={<HomeDocente />} />

          {/* Rutas protegidas */}
          <Route
            path="/principal"
            element={
              <ProtectedRoute>
                <Principal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assign-courses"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <AssignCourses />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/assign-groups-and-schedules"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <AssignGroup />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/assign-grades"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <AssignGrades />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <Reports />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/student-status"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <StudentStatus />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/inscription-process"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <InscriptionProcess />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <Payment />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/schedules-and-groups"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <SchedulesAndGroups />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/grades"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <Grades />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <Profile />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          <Route
            path="/docentes"
            element={
              <ProtectedRoute>
                <ProtectedRouteButton>
                  <DocenteList />
                </ProtectedRouteButton>
              </ProtectedRoute>
            }
          />
          {/* Ruta para manejar páginas no encontradas */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
