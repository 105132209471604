import { useState } from 'react'
import { useAlert } from '../../hooks/useAlert';
import { useFetch } from '../../tools/useFetch';
import { Diplomados } from "../../models/Diplomados";

function useGestionDiplomados({ obtenerCursos }) {

  const { mensajeError, mensajeExitoso, mensajeInformacion } = useAlert();
  const { postFetch, putFetch } = useFetch();
  const [expandedDiplomadoId, setExpandedDiplomadoId] = useState(null);
  const [showModalDiplomados, setShowModalDiplomados] = useState(false);
  const [showModalDiplomadosSeleccion, setShowModalDiplomadosSeleccion] = useState(false);
  const [diplomadoSeleccionado, setDiplomadoSeleccionado] = useState(null);

  const toggleDiplomado = (idDiplomado) => {
    setExpandedDiplomadoId(expandedDiplomadoId === idDiplomado ? null : idDiplomado);
  };

  const openModalDiplomados = (diplomado = null) => {
    setDiplomadoSeleccionado(diplomado);
    setShowModalDiplomados(true);
  };

  const openModalDiplomadosSeleccion = (diplomado = null) => {
    setShowModalDiplomadosSeleccion(true);
  };

  const handleContinueDiplomado = async () => {
    validarCamposDiplomado();
  };

  const validarCamposDiplomado = () => {
    const diplomado = new Diplomados(
      diplomadoSeleccionado ? diplomadoSeleccionado.idDiplomado : null,
      diplomadoSeleccionado ? diplomadoSeleccionado.nombreDiplomado : '',
      diplomadoSeleccionado ? (diplomadoSeleccionado.fechaInicio) ? diplomadoSeleccionado.fechaInicio.split('T')[0] : null : null,
      diplomadoSeleccionado ? (diplomadoSeleccionado.fechaFin) ? diplomadoSeleccionado.fechaFin.split('T')[0] : null : null,
      diplomadoSeleccionado ? diplomadoSeleccionado.duracion : '',
      diplomadoSeleccionado ? diplomadoSeleccionado.completo : false
    );

    if (!diplomado.nombreDiplomado) {
      mensajeInformacion("Por favor llenar el campo de diplomado");
      return;
    }
    
    if (diplomado.idDiplomado) {
      putOnClick(diplomado.idDiplomado, diplomado);
    } else {
      postOnClick(diplomado);
    }
    setShowModalDiplomados(false);
  };

  const handleChangeDiplomado = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === 'checkbox' ? checked : value;

    setDiplomadoSeleccionado(diplomadoPrevio => ({
      ...diplomadoPrevio,
      [name]: newValue
    }));
  };

  //ORDENAR POST
  const postOnClick = (datos) => {
    const urlParcial = 'diplomados/insertar/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos insertados");
          obtenerCursos();
        } else {
          mensajeError("Error al insertar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  //ORDENARA PUT
  const putOnClick = (id, datos) => {
    const urlParcial = `diplomados/actualizar/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos actualizados");
          obtenerCursos();
        } else {
          mensajeError("Error al actuaizar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };


  return {
    toggleDiplomado,
    showModalDiplomados,
    showModalDiplomadosSeleccion,
    openModalDiplomadosSeleccion,
    openModalDiplomados,
    expandedDiplomadoId,
    setExpandedDiplomadoId,
    setShowModalDiplomados,
    setShowModalDiplomadosSeleccion,
    handleContinueDiplomado,
    diplomadoSeleccionado,
    handleChangeDiplomado
  }
}

export { useGestionDiplomados }