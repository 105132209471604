import { useListadoRoles } from "../roles/useListadoRoles"
const CambiarRolM = ({ usuario, onUpdateRol }) => {
    const {
        roles
    } = useListadoRoles();
    return (
        <>
            {usuario && (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12" >
                            <label htmlFor="txtId" className="form-label">ID</label>
                            <input
                                id='txtId'
                                value={usuario.idUsuario || ''}
                                type="text"
                                className="form-control"
                                name="txtId"
                                required
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" >
                            <label htmlFor="txtNombre" className="form-label">Nombre</label>
                            <input
                                id='txtNombre'
                                value={usuario.nombres && usuario.apellidos ? `${usuario.nombres} ${usuario.apellidos}` : usuario.nombres || ''}
                                type="text"
                                className="form-control"
                                name="txtNombre"
                                required
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="idrol" className="form-label">Rol:</label>
                        <select
                            id="idrol"
                            value={usuario.idRol || ''}
                            onChange={(e) => onUpdateRol(e.target.value)}
                            className="form-select"
                            name="idrol"
                            required
                        >
                            <option value="">Selecciona un rol</option>
                            {roles.map(rol => (
                                <option key={rol.idRol} value={rol.idRol}>{rol.rol}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </>
    );
};

export { CambiarRolM };
