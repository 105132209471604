import NavbarPrincipal from '../NavPrincipal/NavbarPrincipal';
import { AuthContext } from '../../context/AuthContext';
import React, { useState, useEffect, useContext } from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    FaUserPlus, FaEnvelopeOpenText, FaClipboardList, FaClock, FaCreditCard, FaBuilding, FaFutbol, FaFlask, FaUsers,
    FaChartLine, FaArrowUp
} from 'react-icons/fa';
import {
    SiMicrosoftoutlook, SiMicrosoftonedrive, SiMicrosoftword, SiMicrosoftexcel, SiMicrosoftpowerpoint,
    SiMicrosoftteams
} from 'react-icons/si';

import { useNavigate } from "react-router-dom";

import Footer from '../Footer';

//images

import Infraestructura from '../../assets/images/Instalaciones/EdificioA.JPG';
import Deporte from '../../assets/images/Instalaciones/Partido.JPG';
import Investigacion from '../../assets/images/Instalaciones/Investigacion.JPG';
import OpCrecimiento from '../../assets/images/Instalaciones/LabClase.JPG';
import ComunidadV from '../../assets/images/Preu/visita9.JPEG';
import Instalaciones1 from '../../assets/images/Instalaciones/biblioteca.JPG';
import Instalaciones2 from '../../assets/images/Instalaciones/Cancha.JPG';
import Instalaciones3 from '../../assets/images/Instalaciones/Centro.JPG';
import Instalaciones4 from '../../assets/images/Instalaciones/CentroComputo.JPG';
import Instalaciones5 from '../../assets/images/Instalaciones/Chess.JPG';
import Instalaciones6 from '../../assets/images/Instalaciones/Cocina.JPG';
import Instalaciones7 from '../../assets/images/Instalaciones/EdificioB.JPG';
import Instalaciones8 from '../../assets/images/Instalaciones/EdificioC.JPG';
import Instalaciones9 from '../../assets/images/Instalaciones/Gym1.JPG';
import Instalaciones10 from '../../assets/images/Instalaciones/jardin.JPG';
import Instalaciones11 from '../../assets/images/Instalaciones/Mecanica.JPG';
import Instalaciones12 from '../../assets/images/Instalaciones/sintetica.JPG';
import Instalaciones13 from '../../assets/images/Instalaciones/UsoPrincipal.JPG';
import Instalaciones14 from '../../assets/images/Instalaciones/salaActividades.JPG';
import Instalaciones15 from '../../assets/images/Instalaciones/NoSeLic.JPG';

const preloadImages = (imageUrls) => {
    const loadImage = (url) => {
        const img = new Image();
        img.src = url;
    };

    // Pre-load just the first few images to avoid overloading the page
    loadImage(imageUrls[0]);
    loadImage(imageUrls[1]);
    loadImage(imageUrls[2]);
    loadImage(imageUrls[3]);

    // Preload the rest after a slight delay
    setTimeout(() => {
        imageUrls.slice(2).forEach(loadImage);
    }, 2000);
};

const Principal = () => {

    const { grantAccess } = useContext(AuthContext); 

    const navigate = useNavigate();

    const handleRedirect = () => {
        grantAccess();
        navigate("/inscription-process"); 
    };

    const [hoveredIndex, setHoveredIndex] = useState(null);

    //carrusel beneficios

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        lazyLoad: 'ondemand',
        prevArrow:
            <CustomPrevArrow />,
        nextArrow:
            <CustomNextArrow />,
        appendDots: dots => (
            <div>
                <ul className="flex justify-center mt-4 space-x-2"> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div className="w-3 h-3 rounded-full bg-bluelight hover:bg-lightgreentxt "></div>
        ),
    };

    const benefits = [
        {
            image: Infraestructura,
            icon: FaBuilding,
            title: "Infraestructura",
            description: "Tenemos instalaciones modernas y bien equipadas para facilitar el aprendizaje y la investigación."
        },
        {
            image: Deporte,
            icon: FaFutbol,
            title: "Fomentamos el deporte",
            description: "Contamos con un complejo deportivo en el cual puedes practicar o recrearte."
        },
        {
            image: Investigacion,
            icon: FaFlask,
            title: "Oportunidades de investigación",
            description: "Fomentamos la investigación y la innovación."
        },
        {
            image: ComunidadV,
            icon: FaUsers,
            title: "Comunidad vibrante",
            description: "La vida en el campus es dinámica y diversa, con clubes y organizaciones estudiantiles."
        },
        {
            image: OpCrecimiento,
            icon: FaChartLine,
            title: "Oportunidad de crecimiento",
            description: "Ofrecemos oportunidades para el crecimiento personal y profesional."
        }
    ];

    //carrusel instalaciones

    const settingsCinta = {
        infinite: true,
        speed: 1500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    //Flecha Arriba

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        preloadImages([
            Infraestructura,
            Deporte,
            Investigacion,
            Instalaciones1,
            Instalaciones2,
            Instalaciones3,
            Instalaciones4,
            Instalaciones5,
            Instalaciones6,
            Instalaciones7,
            Instalaciones8,
            Instalaciones9,
            Instalaciones10,
            Instalaciones11,
            Instalaciones12,
            Instalaciones13,
            Instalaciones14,
            Instalaciones15,
        ]);
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        <div className="">
            <NavbarPrincipal />
            {/* Proceso */}


            <div className="flex flex-col items-center justify-center my-16 bg-white md:py-16">
                <div className="mb-12 text-center md:mb-16">
                    <h1 className="mb-4 text-3xl font-bold md:text-4xl text-darkbluetxt">Proceso de Inscripción</h1>
                    <h2 className="text-xl font-medium text-gray-600 md:text-2xl">Admisión Virtual</h2>
                    <p className="max-w-2xl mx-auto mt-4 text-lg font-light text-gray-700 md:text-xl">
                        Queremos facilitar los trámites para el proceso de inscripción, te presentamos paso a paso el camino a
                        seguir.
                    </p>
                </div>
                <div className="w-full max-w-6xl">
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-5">
                        {[
                            {
                                icon: FaUserPlus,
                                title: "Paso 1",
                                description: "Completa el formulario de registro personal y académico.",
                                bgColor: "bg-darkbluetxt"
                            },
                            {
                                icon: FaEnvelopeOpenText,
                                title: "Paso 2",
                                description: "Selecciona el curso de tu preferencia.",
                                bgColor: "bg-lightgreentxt"
                            },
                            {
                                icon: FaClipboardList,
                                title: "Paso 3",
                                description: "Elige el grupo que se adapte a tu horario.",
                                bgColor: "bg-[#00AEEF]"
                            },
                            {
                                icon: FaCreditCard,
                                title: "Paso 4",
                                description: "Realiza el pago de aranceles correspondientes.",
                                bgColor: "bg-darkbluetxt"
                            },
                            {
                                icon: FaClock,
                                title: "Paso 5",
                                description: "Espera un correo con tus credenciales de estudiante.",
                                bgColor: "bg-lightgreentxt"
                            }
                        ].map((step, index) => (
                            <div key={index} className="flex flex-col items-center text-center group">
                                <div className={`p-6 mb-4 text-white transition-transform transform rounded-full shadow-xl
                        ${step.bgColor} group-hover:scale-110 group-hover:shadow-2xl`}>
                                    <step.icon className="text-3xl " />
                                </div>
                                <p className="text-lg font-medium text-gray-800 md:text-xl">{step.title}</p>
                                <p className="mt-2 text-base text-gray-600 md:text-lg">
                                    {step.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            {/* Beneficios Carousel */}


            <Slider {...settings} className="max-w-6xl mx-auto">
                {benefits.map((benefit, index) => (
                    <div key={index}
                        className="relative flex items-center justify-center transition-transform duration-500 transform">
                        <img src={benefit.image} alt={benefit.title} className="object-cover w-full rounded-lg h-72 md:h-96" />
                        <div
                            className="absolute inset-0 flex flex-col items-center justify-center p-6 bg-black rounded-lg bg-opacity-60">
                            <benefit.icon className="mb-4 text-6xl text-white transition-transform duration-300 hover:scale-110" />
                            <h3 className="mb-2 text-2xl font-bold text-white transition-transform duration-300 hover:scale-110">
                                {benefit.title}</h3>
                            <p
                                className="text-lg leading-relaxed text-center text-white transition-transform duration-300 hover:scale-110">
                                {benefit.description}
                            </p>
                        </div>
                    </div>
                ))}
            </Slider>


            {/* Alianza con Microsoft */}


            <div className="col-span-2 p-8 mt-16 text-center ">
                <h2 className="mb-4 text-2xl font-bold md:text-3xl text-darkbluetxt">Alianza con Microsoft</h2>
                <p className="max-w-xl mx-auto mb-8 text-lg font-light text-gray-700">
                    Obtén gratis la última versión de Office 365 para que puedas instalarla en 5 dispositivos.
                </p>
                <div className="grid max-w-2xl grid-cols-2 gap-8 mx-auto md:grid-cols-3">
                    {[
                        { icon: SiMicrosoftoutlook, label: "Outlook" },
                        { icon: SiMicrosoftonedrive, label: "OneDrive" },
                        { icon: SiMicrosoftword, label: "Word" },
                        { icon: SiMicrosoftexcel, label: "Excel" },
                        { icon: SiMicrosoftpowerpoint, label: "PowerPoint" },
                        { icon: SiMicrosoftteams, label: "Teams" }
                    ].map((app, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <app.icon
                                className="text-5xl transition-transform duration-300 transform text-darkbluetxt md:text-6xl hover:scale-110 hover:text-blue-900" />
                            <p className="mt-2 text-sm font-semibold text-gray-600 md:text-base">{app.label}</p>
                        </div>
                    ))}
                </div>
            </div>


            {/* Carrusel instalaciones */}


            <div className="z-10 max-w-full py-16 mx-auto overflow-hidden">
                <Slider {...settingsCinta}>
                    {[Instalaciones1, Instalaciones2, Instalaciones3, Instalaciones4, Instalaciones5, Instalaciones6, Instalaciones7, Instalaciones8, Instalaciones9, Instalaciones10, Instalaciones11, Instalaciones12, Instalaciones13, Instalaciones14, Instalaciones15].map((image, index) => (
                        <div
                            key={index}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            className={`relative transition-transform transform ${hoveredIndex === index ? "scale-110 z-50" : "scale-100 z-10"
                                }`}
                        >
                            <img
                                loading="lazy"
                                src={image}
                                alt={`Imagen ${index + 1}`}
                                className="object-cover w-full h-56 transition-transform transform hover:z-50"
                            />
                        </div>
                    ))}
                </Slider>
            </div>

            <Footer />

            {/* Btn Ariba */}


            <div className="fixed z-50 bottom-20 right-8">
                {isVisible && (
                    <button onClick={scrollToTop}
                        className="p-6 text-white transition duration-300 transform shadow-lg bg-lightgreentxt rounded-xl hover:bg-darkgreentxt focus:outline-none hover:scale-105">
                        <FaArrowUp size={20} />
                    </button>
                )}
            </div>
        </div>
    );
}



//carrusel beneficios flechas

const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="absolute z-10 flex items-center justify-center w-12 h-12 text-white -translate-y-1/2 rounded-full cursor-pointer left-4 bg-bluelight top-1/2 hover:bg-lightgreentxt"
            onClick={onClick}>
            &#10094;
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="absolute z-10 flex items-center justify-center w-12 h-12 text-white -translate-y-1/2 rounded-full cursor-pointer right-4 bg-bluelight top-1/2 hover:bg-lightgreentxt"
            onClick={onClick}>
            &#10095;
        </div>
    );
};

export default Principal;
