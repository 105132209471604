import React, { useEffect } from 'react';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { AbrirModal } from '../../components/AbrirModal';
import { DiplomadosModal } from '../diplomados/DiplomadosModal';
import { CursosModal } from '../cursos/CursosModal';
import { GestionCursos } from '../cursos/GestionCursos';
import { GestionDiplomados } from '../diplomados/GestionDiplomados';
import { useGestionDiplomados } from '../diplomados/useGestionDiplomados';
import { useGestionCursos } from '../cursos/useGestionCursos';
import { useGestionGrupos } from '../grupos/useGestionGrupos';
import { useGestionHorarios } from '../horarios/useGestionHorarios';
import { HorariosModal } from '../horarios/HorariosModal';
import { GruposModal } from '../grupos/GruposModal';
import { useAdministracionCursoDiplomado } from './useAdministracionCursoDiplomado';
import { BtnEditar } from '../../buttons/BtnEditar';
import { useListaActividades } from '../actividades/useListaActividades';
import { ListaActividades } from '../actividades/ListaActividades';
import { AsignarPlantillas } from '../plantillas/AsignarPlantillas';
import { useListadoPlantillas } from '../plantillas/useListadoPlantillas';
import { SeleccionDiplomado } from '../diplomados/SeleccionDiplomado';
import { SeleccionCurso } from '../cursos/SeleccionCurso';
import { useSeleccionCurso } from '../cursos/useSeleccionCurso';
import { useSeleccionDiplomado } from '../diplomados/useSeleccionDiplomado';
import { useAlert } from '../../hooks/useAlert';

function AdministracionCursoDiplomado() {

    const { mensajeInformacion } = useAlert();

    const { obtenerCursos, datos, handleChangeTipo, tipoFiltro,
        cursosFiltrados, setTipoFiltro, handleChangeCiclo, ciclos, cicloSelect, anioSelect, getCiclos
    } = useAdministracionCursoDiplomado();

    const { toggleDiplomado, expandedDiplomadoId, showModalDiplomados, showModalDiplomadosSeleccion,
        openModalDiplomados, openModalDiplomadosSeleccion, setShowModalDiplomados, setShowModalDiplomadosSeleccion, handleContinueDiplomado,
        diplomadoSeleccionado, handleChangeDiplomado
    } = useGestionDiplomados({ obtenerCursos });

    const {
        setShowModalCursos, tipos_cursos, toggleCurso, expandedCursoId,
        cursoSeleccionado, showModalCursos, showModalCursosSeleccion, setShowModalCursosSeleccion, openModalCursos, openModalCursosSeleccion, validarCamposCurso,
        handleChange, handleChangeFile, imagen
    } = useGestionCursos({ obtenerCursos });

    const {
        expandedGrupoId, toggleGrupo, grupoSeleccionado, handleChangeGrupo,
        openModalGrupos, showModalGrupos, instructores, setShowModalGrupos,
        handleContinueGrupo, calcularHoras
    } = useGestionGrupos({ obtenerCursos, cursoSeleccionado });

    const {
        openModalHorarios, handleChangeHorario, handleContinueHorario,
        horarioSeleccionado, showModalHorarios, setShowModalHorarios,
        diasSeleccionados, handleButtonClick, deleteOnClick
    } = useGestionHorarios({ obtenerCursos });

    const {
        actividades,
        titulo,
        handleChangeActividad,
        actividadSeleccionada,
        openModalActividad,
        openModalActividades,
        deleteOnClickActividad,
        showModalActividades,
        setShowModalActividad,
        validarCampos
    } = useListaActividades();

    const {
        plantillas,
        plantillasSeleccionadas,
        handleChangePlantillas,
        handleContinuePlantillas,
        showModalPlantillas,
        openModalPlantillas,
        setShowModalPlantillas
    } = useListadoPlantillas({ obtenerCursos });

    const { obtenerCursosFinalizados, cursos, handleOnClick, obtenerCursosConDetallesPlantillas, esParaDetalles, setEsParaDetalles, setIdCurso } = useSeleccionCurso({ obtenerCursos, setShowModalCursosSeleccion });

    const { obtenerDiplomadosFinalizados, diplomados, handleOnClickDiplomado } = useSeleccionDiplomado({ obtenerCursos, setShowModalDiplomadosSeleccion });

    const handleContinue = async () => {
        validarCamposCurso(tipoFiltro, cicloSelect);
        setTipoFiltro(tipoFiltro);
    };

    const abrirModal = () => {
        if (tipoFiltro === '1') {
            //Abrir modal diplomados
            openModalDiplomados();
        } else {
            openModalCursos();
        }
    }

    const abrirModalSeleccion = (paraDetalles, idCurso, precio) => {
        setEsParaDetalles(paraDetalles);
        setIdCurso(idCurso);
        if(paraDetalles){
            if(parseFloat(precio) > 0){
                mensajeInformacion("El curso tiene plantillas definidas!");
                return;
            }
            openModalCursosSeleccion();
            return;
        }
        if (tipoFiltro === '1') {
            //Abrir modal diplomados
            openModalDiplomadosSeleccion();
        } else {
            openModalCursosSeleccion();
        }
    }

    const handleClose = () => {
        setShowModalCursos(false);
        setShowModalCursosSeleccion(false);
        setShowModalDiplomados(false);
        setShowModalDiplomadosSeleccion(false);
        setShowModalGrupos(false);
        setShowModalHorarios(false);
        setShowModalActividad(false);
        setShowModalPlantillas(false);
    };  

    return (
        <>
            <div className='mt-custom'></div>
            <div className='d-flex '>
                <div className='flex-grow-1'>
                    <div className='col-md-8 m-2'>
                        <form className="d-flex">
                            <select className="form-select" onChange={handleChangeTipo}>
                                {tipos_cursos.map((tipo) => (
                                    <option key={tipo.idTipoCurso} value={tipo.idTipoCurso}>
                                        {tipo.tipo}
                                    </option>
                                ))}
                            </select>
                            <select className="form-select ms-2" onChange={handleChangeCiclo}>
                                {ciclos.map((ciclo) => (
                                    <option key={ciclo.idCiclo} value={ciclo.idCiclo}>
                                        {"Ciclo: " + ciclo.ciclo + "/" + ciclo.anio}
                                    </option>
                                ))}
                            </select>

                        </form>
                    </div>
                </div>
                <div className='m-2'>
                    <BtnEditar titulo="Duplicar" onclick={() => abrirModalSeleccion(false, 0, 0)} />
                    <BtnAgregar add='ms-2' titulo={(tipoFiltro === '1') ? 'Agregar Diplomado' : 'Agregar Curso'} onclick={() => abrirModal()} />
                </div>
            </div>

            {/* Mostrar Diplomados si el filtro es '0' (Todos) o '1' (Diplomados) */}
            {(tipoFiltro === '0' || tipoFiltro === '1') && Object.keys(datos).length > 0 && (
                <section>
                    <h2>Diplomados</h2>
                    {Object.keys(datos).map((idDiplomado) => {
                        const diplomado = datos[idDiplomado];
                        return (<GestionDiplomados
                            key={idDiplomado}
                            diplomado={diplomado}
                            expandedDiplomadoId={expandedDiplomadoId}
                            toggleDiplomado={toggleDiplomado}
                            openModalDiplomados={openModalDiplomados}
                            toggleCurso={toggleCurso}
                            expandedCursoId={expandedCursoId}
                            openModalCursos={openModalCursos}
                            openModalGrupos={openModalGrupos}
                            toggleGrupo={toggleGrupo}
                            expandedGrupoId={expandedGrupoId}
                            openModalHorarios={openModalHorarios}
                            deleteOnClick={deleteOnClick}
                            openModalActividades={openModalActividades}
                            openModalPlantillas={openModalPlantillas}
                            idCiclo={cicloSelect}
                            tipoCurso={tipoFiltro}
                            calcularHoras={calcularHoras}
                            abrirModalSeleccion={abrirModalSeleccion}
                        />
                        )
                    })}
                </section>
            )}

            {/* Mostrar Cursos filtrados por '2' (Cortos) o '3' (Financiados) */}
            {cursosFiltrados.length > 0 && (
                <section>
                    <h2>Cursos</h2>
                    {cursosFiltrados.map((curso) => (
                        <GestionCursos
                            key={curso.idCurso}
                            curso={curso}
                            expandedCursoId={expandedCursoId}
                            toggleCurso={toggleCurso}
                            openModalCursos={openModalCursos}
                            toggleGrupo={toggleGrupo}
                            expandedGrupoId={expandedGrupoId}
                            openModalGrupos={openModalGrupos}
                            openModalHorarios={openModalHorarios}
                            deleteOnClick={deleteOnClick}
                            openModalActividades={openModalActividades}
                            openModalPlantillas={openModalPlantillas}
                            idCiclo={cicloSelect}
                            tipoCurso={tipoFiltro}
                            calcularHoras={calcularHoras}
                            abrirModalSeleccion={abrirModalSeleccion}
                        />
                    ))}
                </section>
            )}

            {/* Mensajes de error si no hay resultados */}
            {tipoFiltro !== '1' && cursosFiltrados.length === 0 && (
                <p>No hay cursos disponibles para el filtro seleccionado.</p>
            )}

            {tipoFiltro === '1' && Object.keys(datos).length === 0 && (
                <p>No hay diplomados disponibles.</p>
            )}

            <AbrirModal show={showModalDiplomados}
                handleContinue={handleContinueDiplomado}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Agregar Diplomado"}
                ocultar={false}
            >
                <DiplomadosModal diplomadoSeleccionado={diplomadoSeleccionado} handleChange={handleChangeDiplomado} />
            </AbrirModal>

            <AbrirModal show={showModalCursos}
                handleContinue={handleContinue}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Agregar Curso"}
                ocultar={false}
            >
                <CursosModal cursoSeleccionado={cursoSeleccionado} handleChange={handleChange} handleChangeFile={handleChangeFile} imagen={imagen} />
            </AbrirModal>

            <AbrirModal show={showModalGrupos}
                handleContinue={handleContinueGrupo}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Agregar Grupo"}
                ocultar={false}
            >
                <GruposModal grupoSeleccionado={grupoSeleccionado} handleChange={handleChangeGrupo} instructores={instructores} />
            </AbrirModal>

            <AbrirModal show={showModalHorarios}
                handleContinue={handleContinueHorario}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Agregar Horario"}
                ocultar={false}
            >
                <HorariosModal cursoSeleccionado={cursoSeleccionado} horarioSeleccionado={horarioSeleccionado} handleChange={handleChangeHorario} diasSeleccionados={diasSeleccionados} handleButtonClick={handleButtonClick} />
            </AbrirModal>

            <AbrirModal show={showModalActividades}
                //handleContinue={guardarCambios}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Actividades"}
                ocultar={true}
            >
                <ListaActividades
                    actividades={actividades}
                    titulo={titulo}
                    handleChange={handleChangeActividad}
                    actividadSeleccionada={actividadSeleccionada}
                    openModal={openModalActividad}
                    deleteOnClick={deleteOnClickActividad}
                    validarCampos={validarCampos} />
            </AbrirModal>

            <AbrirModal show={showModalPlantillas}
                handleContinue={handleContinuePlantillas}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Plantillas"}
                ocultar={false}
            >
                <AsignarPlantillas
                    handleChangePlantillas={handleChangePlantillas}
                    plantillas={plantillas}
                    plantillasSeleccionadas={plantillasSeleccionadas} />
            </AbrirModal>

            <AbrirModal show={showModalDiplomadosSeleccion}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Diplomados finalizados."}
                ocultar={true}
            >
                <SeleccionDiplomado tipoFiltro={tipoFiltro} showModalDiplomadosSeleccion={showModalDiplomadosSeleccion} cicloSelect={cicloSelect} anioSelect={anioSelect}
                obtenerDiplomadosFinalizados={obtenerDiplomadosFinalizados} diplomados={diplomados} handleOnClick={handleOnClickDiplomado} getCiclos={getCiclos} />
            </AbrirModal>

            <AbrirModal show={showModalCursosSeleccion}
                handleCancel={handleClose}
                handleClose={handleClose}
                titulo={"Cursos finalizados."}
                ocultar={true}
            >
                <SeleccionCurso tipoFiltro={tipoFiltro} showModalCursosSeleccion={showModalCursosSeleccion} cicloSelect={cicloSelect} esParaDetalles={esParaDetalles} 
                obtenerCursosFinalizados={obtenerCursosFinalizados} obtenerCursosConDetallesPlantillas={obtenerCursosConDetallesPlantillas} cursos={cursos} handleOnClick={handleOnClick} />
            </AbrirModal>

        </>
    );
}

export { AdministracionCursoDiplomado };
