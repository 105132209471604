import React from 'react'

function SeleccionFecha({ establecerFecha }) {

  const handleChange = (e) => {
    const { value } = e.target;
    establecerFecha(value);
  }
  return (
    <div className="col-md-6">
      <label htmlFor="txtFechaPago" className="form-label">Fecha Pago</label>
      <input
        id='txtFechaPago'
        onChange={handleChange}
        //value={(cursoSeleccionado) ? cursoSeleccionado.fechaInicio.split('T')[0] : ''}
        type="date"
        className="form-control"
        name="fechaPago"
        required
      />
    </div>
  )
}

export default SeleccionFecha