import React from 'react'

const BtnEliminar = ({ add, disabled, titulo, onclick, databsdismiss, type=''}) => {
    return (
        <>
            <button type={type} disabled={disabled} onClick={onclick} data-bs-dismiss={databsdismiss} className={'btn cerrar-sesion-btn ' + add}>{titulo}</button>
        </>
    )
}

export { BtnEliminar }