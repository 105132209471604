
function Actividades(idActividad = 0, idCurso = 0, actividad = '', porcentaje = 0) {

    return {
        idActividad,
        idCurso,
        actividad,
        porcentaje,
    };
}

export { Actividades }