import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LogoutButton } from '../buttons/LogoutButton';
import { gapi } from 'gapi-script';
import { useAuth } from '../contexts/DataContext';

const NavBar = () => {

    const { user, getUser } = useAuth();

    const getMenuItems = (user) => {
        if (!user || !user[0] || !user[0].rol) {
            return [];
        }

        const { rol } = user[0];
        const menuItems = [];

        if (rol === 'participante') {
            menuItems.push(
                { text: "Mis Cursos", link: "/miscursos" },
                { text: "Pagos", link: "/realizarpago" }
            );
        }

        if (rol === 'admin') {
            menuItems.push(
                {
                    text: "Administrar cursos",
                    dropdown: true,
                    items: [
                        { text: "Ciclos", link: "/ciclos" },
                        { text: "Cursos", link: "/cursos" },
                        { text: "Tipos de curso", link: "/tiposcurso" }
                    ]
                },
                {
                    text: "Administrar pagos",
                    dropdown: true,
                    items: [
                        { text: "Participantes", link: "/participantes" },
                        { text: "Plantillas", link: "/plantillas" },
                        { text: "Codigo servicio", link: "/codigo_servicio" }
                    ]
                },
                {
                    text: "Administrar usuarios",
                    dropdown: true,
                    items: [
                        { text: "Rol", link: "/roles" },
                        { text: "Asingar rol", link: "/asignarRol" }, //Servira para cambiarle el rol a los participantes
                    ]
                },
                {
                    text: "Administrar inscripciones",
                    dropdown: true,
                    items: [
                        { text: "Kits", link: "/kits" },
                        { text: "EntregaDeKit", link: "/entrega_kit" },
                        { text: "Inscripciones", link: "/inscritos" }
                    ]
                },
                {
                    text: "Administrar recursos",
                    dropdown: true,
                    items: [
                        { text: "Departamentos", link: "/departamentos" },
                        { text: "Fuentes", link: "/fuentes" }
                    ]
                }
            );
        }

        if (rol === 'instructor') {
            menuItems.push({
                text: "Administrar notas",
                dropdown: true,
                items: [
                    { text: "Asignar Notas", link: "/asignarnotas" },
                    { text: "Asistencia", link: "/" }
                ]
            });
        }

        return menuItems;
    };

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_CLIENT_ID_GOOGLE,
                scope: '',
            });
        }
        gapi.load('client:auth2', start);
        getUser();
    }, []);

    // Uso del código
    const menuItems = getMenuItems(user);

    return (
        <nav className="navbar navbar-expand-lg custom-gradient-nav fixed-top px-2 py-3">
            <Link className="navbar-brand text-color1 ddHover p-1" to="/"> <img
                src="https://admision.usonsonate.edu.sv/static/media/usoLogo.68d12be3f2b4e942326e.png"
                alt="Logo de la empresa"
                style={{ width: '100px', height: 'auto' }}
            /></Link>
            <button className="navbar-toggler bg-color1" type="button" data-bs-toggle="collapse" data-bs-target="#responsive-navbar-nav" aria-controls="responsive-navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="responsive-navbar-nav">
                {user ? (
                    <ul className="navbar-nav">
                        {menuItems.map((item, index) => (
                            <React.Fragment key={index}>
                                {item.dropdown ? (
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle text-color1 ddHover" href="#" id={"navbarDropdown" + index} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className='txtHover'>{item.text}</span>
                                        </a>
                                        <ul className="dropdown-menu bg-color4" aria-labelledby={"navbarDropdown" + index}>
                                            {item.items.map((subItem, subIndex) => (
                                                <li key={subIndex}><a className="dropdown-item ddHover text-color1" href={subItem.link}>{subItem.text}</a></li>
                                            ))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <a className="nav-link text-color1 ddHover" href={item.link} >{item.text}</a>
                                    </li>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                ) : ''}
                <ul className="navbar-nav ms-auto">
                    {!user ? (
                        <li className="nav-item">
                            <a
                                className="nav-link text-color1 ddHover"
                                href="/login"
                                style={{
                                    color: 'white',
                                    textDecoration: 'none',
                                    padding: '10px 20px',
                                    border: '2px solid white',
                                    borderRadius: '5px'
                                }}
                            >
                                Iniciar Sesión
                            </a>
                        </li>
                    ) : (
                        <li className="nav-item">
                            <LogoutButton />
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    )
}

export { NavBar };

