import { useEffect, useState } from "react";
import { useListadoDepartamentos } from "../departamentos/useListadoDepartamentos";
import { useListadoMunicipios } from "../municipios/useListadoMunicipios";
import { useFetch } from "../../tools/useFetch";
import { useAlert } from "../../hooks/useAlert"
import { Direcciones } from "../../models/Direcciones";

function useGestionDirecciones(idDepartamentoInicial, idParticipante, idResponsable, getDatos) {

  const {
    mensajeAdvertencia
    } = useAlert();

  //ORDENAR CONSTANTES
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(Direcciones());
  const { departamentos, getDepartamentos } = useListadoDepartamentos();
  const { municipios, getMunicipios } = useListadoMunicipios(idDepartamentoInicial);
  const [persona, setPersona] = useState(1);
  const { postFetch, putFetch, deleteFetch } = useFetch();

  //ORDENAR USEEFFECT
  useEffect(() => {
    getDepartamentos();
    if (direccionSeleccionada.idDepartamento) {
      getMunicipios(direccionSeleccionada.idDepartamento);
    }
  }, [direccionSeleccionada]);

  //ORDENAR FUNCIONES
  const openModal = (per, direccion = Direcciones()) => {
    setDireccionSeleccionada(direccion);
    setPersona(per);
  };

  const validarCampos = () => {
    if (direccionSeleccionada.idDepartamento === '') {
      mensajeAdvertencia("Seleccione el departamento");
    } else if (direccionSeleccionada.idMunicipio === '') {
      mensajeAdvertencia("Seleccione el municipio");
    } else if (direccionSeleccionada.complemento === '') {
      mensajeAdvertencia("Escriba un complemento a la informacion");
    } else {
      const datos = {
        idMunicipio: direccionSeleccionada.idMunicipio,
        complemento: direccionSeleccionada.complemento
      };
      postOnClick(datos);
      document.getElementById('btnCerrarModal').click();
    }
  }

  const handleChangeDirecciones = (e) => {
    const { name, value } = e.target;
    setDireccionSeleccionada((direccionPrevia) => ({ ...direccionPrevia, [name]: value }));
  };

  //ORDENAR POST
  const postOnClick = (datos) => {
    const urlParcial = 'direcciones/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        if (persona === 1) {
          //Participante
          const { insertId } = respuesta.datos;
          const data = {
            idDireccion: insertId
          }
          putParticipanteOnClick(idParticipante, data);
        } else {
          //Encargado
          const { insertId } = respuesta.datos;
          const data = {
            idDireccion: insertId
          }
          putResponsableOnClick(idResponsable, data);
        }
      })
      .catch((error) => {
        console.log('Error->', error);
      });
  }

  //ORDENAR PUT
  const putParticipanteOnClick = (id, datos) => {
    const urlParcial = `participantes/actualizardireccion/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        deleteDireccion(direccionSeleccionada.idDireccion);
        getDatos(1);
      })
      .catch((error) => {
        console.log('Error->', error);
      })
  }

  const putResponsableOnClick = (id, datos) => {
    const urlParcial = `responsables/actualizardireccion/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        deleteDireccion(direccionSeleccionada.idDireccion);
        getDatos(2);
      })
      .catch((error) => {
        console.log('Error->', error);
      })
  }

  //ORDENAR DELETE
  const deleteDireccion =  (id) => {
    const urlParcial = `direcciones/${id}`;

    deleteFetch(urlParcial)
      .then((respuesta) => {

      })
      .catch((error) => {
        console.log('Error->', error);
      })
  }

  return {
    openModal,
    municipiosDir: municipios,
    departamentosDir: departamentos,
    handleChangeDirecciones,
    direccionSeleccionada,
    setDireccionSeleccionada,
    validarCampos
  };
}

export { useGestionDirecciones };
