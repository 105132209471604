export default function DarFormatoFecha(fecha) {
    const fechaObj = new Date(fecha.split('T')[0]); // Asegúrarse de tenerlo asi
    return fechaObj.toLocaleDateString('es-ES', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC'
    });
}
