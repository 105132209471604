import { useAuth } from '../../contexts/DataContext';
import { useListadoNotas } from "../Nota/useListadoNotas";
import { useEffect, useState } from 'react';
import { useListadoFuentes } from '../fuentes/useListadoFuentes';
import { useListadoDepartamentos } from '../departamentos/useListadoDepartamentos';
import { useListadoMunicipios } from '../municipios/useListadoMunicipios';
import { useGestionDirecciones } from '../direcciones/useGestionDirecciones';
import { useFuenteInformacion } from '../fuente_informacion/useFuenteInformacion';
import { useFetch } from '../../tools/useFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from "../../hooks/useAlert";

function useInscripcion() {

  const {
    mensajeAdvertencia
  } = useAlert();

  const crearDatos = (dato) => {
    return {
      idUsuario: dato ? dato.idUsuario : '',
      idParticipante: dato ? dato.idParticipante : '',
      idResponsable: dato ? dato.idResponsable : '',
      codigo: dato ? dato.codigo : '',
      expediente: dato ? dato.expediente : '',
      municipioNacimiento: dato ? dato.municipioNacimiento : '',
      departamentoNacimiento: dato ? dato.departamentoNacimiento : '',
      fechaNacimiento: dato ? dato.fechaNacimiento ? formatFecha(dato.fechaNacimiento) : '' : '',
      nombres: dato ? dato.nombres : '',
      apellidos: dato ? dato.apellidos : '',
      dui: dato ? dato.dui : '',
      nit: dato ? dato.nit : '',
      carnetMinoridad: dato ? dato.carnetMinoridad : '',
      nie: dato ? dato.nie : '',
      edad: dato ? dato.edad : '',
      idDireccion: dato ? dato.idDireccion ? dato.idDireccion : '0' : '0',
      dirParticipante: dato ? dato.dirParticipante : '',
      direccionParticipante: dato ? dato.direccionParticipante ? dato.direccionParticipante : '' : '',
      telefonoFijo: dato ? dato.telefonoFijo : '',
      telefono: dato ? dato.telefono : '',
      correo: dato ? dato.correo : '',
      alergico: dato ? dato.padecimiento ? 'si' : 'no' : 'no',
      padecimiento: dato ? dato.padecimiento : '',
      profesion: dato ? dato.ocupacion ? 'si' : 'no' : 'no',
      ocupacion: dato ? dato.ocupacion : '',
      dirContacto: dato ? dato.dirContacto : '',
      direccionEncargado: dato ? dato.direccionEncargado ? dato.direccionEncargado : '' : '',
      nombresContacto: dato ? dato.nombresContacto : '',
      parentesco: dato ? dato.parentesco : '',
      telefonoContacto: dato ? dato.telefonoContacto : '',
      telFijoContacto: dato ? dato.telFijoContacto : '',
      correoContacto: dato ? dato.correoContacto : '',
      depContacto: dato ? dato.depContacto : '',
      muContacto: dato ? dato.muContacto : '',
      comContacto: dato ? dato.comContacto : '',
      depParticipante: dato ? dato.depParticipante : '',
      muParticipante: dato ? dato.muParticipante : '',
      comParticipante: dato ? dato.comParticipante : '',
      genero: dato ? dato.genero : '',
      estadoFamiliar: dato ? dato.estadoFamiliar : ''
    };
  };

  //ORDENAR CONSTANTES
  const { user } = useAuth();
  const { postFetch, putFetch, getFetch } = useFetch();
  const { postOnClickNotas } = useListadoNotas();
  const [showModal, setShowModal] = useState(false);
  const [datos, setDatos] = useState([]);
  const navegar = useNavigate();
  const { idCurso } = useParams();
  const { fuentes, getFuentes } = useListadoFuentes();
  const { departamentos, getDepartamentos } = useListadoDepartamentos();
  const [datoSeleccionado, setDatoSeleccionado] = useState(crearDatos());
  const { fuentesParticipante, getFuentesByIdParticipante } = useFuenteInformacion();
  const { municipios, getMunicipios } = useListadoMunicipios(datoSeleccionado.departamentoNacimiento);
  const [errorTelefonoFijo, setErrorTelefonoFijo] = useState('');
  const [errorTelefonoMovil, setErrorTelefonoMovil] = useState('');
  const [fuentesSeleccionadas, setFuentesSeleccionadas] = useState([]);
  const handleClose = () => setShowModal(false);

  ///ORDENAR LOS USEEFECT
  useEffect(() => {
    getIdsCurso(idCurso);
    getDatos(0);
  }, []);

  useEffect(() => {
    getFuentes();
    getDepartamentos();
  }, []);

  useEffect(() => {
    if (datoSeleccionado.idParticipante) {
      getFuentesByIdParticipante(datoSeleccionado.idParticipante);
    }
  }, [datoSeleccionado.idParticipante]);

  useEffect(() => {// Obtener municipios cuando el departamento de nacimiento cambie
    if (datoSeleccionado.departamentoNacimiento) {
      getMunicipios(datoSeleccionado.departamentoNacimiento);
    }
  }, [datoSeleccionado.departamentoNacimiento]);

  useEffect(() => { // Seleccionar fuentes cuando cambien las fuentes del participante
    if (fuentesParticipante.length > 0) {
      const idsSeleccionados = fuentesParticipante.map(fp => fp.idFuente);
      setFuentesSeleccionadas(idsSeleccionados);
    }
  }, [fuentesParticipante]);

  useEffect(() => {// Si no hay departamento de nacimiento seleccionado, elige el primero de la lista
    if (departamentos.length > 0 && !datoSeleccionado.departamentoNacimiento) {
      setDatoSeleccionado(prev => ({
        ...prev,
        departamentoNacimiento: departamentos[0].idDepartamento
      }));
    }
  }, [departamentos]);

  useEffect(() => {// Actualiza dato seleccionado cuando se obtienen nuevos datos
    if (datos.length > 0) {
      const seleccionado = crearDatos(datos[0]);
      setDatoSeleccionado(seleccionado);
    }
  }, [datos]);

  ///OREDENAR FUNCIONES
  const direccionParticipante = (idD, id, id2, com) => {
    return {
      idDireccion: idD,
      idDepartamento: id,
      idMunicipio: id2,
      complemento: com
    }
  }

  const direccionEncargado = (idD, id, id2, com) => {
    return {
      idDireccion: idD,
      idDepartamento: id,
      idMunicipio: id2,
      complemento: com
    }
  }

  const formatFecha = (fecha) => {
    return fecha.split('T')[0];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setDatoSeleccionado((prev) => {
      // Si el campo modificado es 'fechaNacimiento', calculamos la edad
      let edad = prev.edad;
      if (name === 'fechaNacimiento') {
        const fechaNacimiento = new Date(value);
        const hoy = new Date();
        edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
        const mes = hoy.getMonth() - fechaNacimiento.getMonth();
        if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
          edad--; // Ajustamos si el cumpleaños no ha pasado en el año actual
        }
      }
  
      return {
        ...prev,
        [name]: value,
        ...(name === 'fechaNacimiento' && { edad }),
      };
    });
  };  

  const handleChangeFuentes = (event) => {
    const id = parseInt(event.target.id.split('-')[1], 10);
    if (event.target.checked) {
      setFuentesSeleccionadas(prev => [...prev, id]);
    } else {
      setFuentesSeleccionadas(prev => prev.filter(fuenteId => fuenteId !== id));
    }
  };

  const validarCamposInscripcion = () => {
    if (fuentesSeleccionadas.length < 1) {
      mensajeAdvertencia("Seleccione una fuente de información al menos");
      return;
    }

    if (!datoSeleccionado.codigo) { //validacion de cod de participante
      mensajeAdvertencia("Debe llenar el campo código");
      return;
    }

    if (!datoSeleccionado.expediente) { //validacion de expediente de participante
      mensajeAdvertencia("Debe llenar el campo expediente");
      return;
    }

    if (!datoSeleccionado.fechaNacimiento) {// Validación de fecha de nacimiento
      mensajeAdvertencia("Debe seleccionar su fecha de nacimiento");
      return;
    }

    const fechaNacimiento = new Date(datoSeleccionado.fechaNacimiento);
    const fechaActual = new Date();
    if (fechaNacimiento > fechaActual) {
      mensajeAdvertencia("La fecha de nacimiento no puede ser una fecha futura");
      return;
    }

    if (!datoSeleccionado.nombres) {//validacion de nombres del participante
      mensajeAdvertencia("Debe escribir sus Nombres");
      return;
    }

    if (!datoSeleccionado.apellidos) {//validacion de apellidos
      mensajeAdvertencia("Debe escribir sus apellidos");
      return;
    }

    const duiRegex = /^\d{9}$/;
    if (datoSeleccionado.dui && !duiRegex.test(datoSeleccionado.dui)) {// Validación de DUI
      mensajeAdvertencia("Debe completar el campo de DUI sin guion, 9 caracteres");
      return;
    }

    const nitRegex = /^\d{14}$/;
    if (datoSeleccionado.nit && !nitRegex.test(datoSeleccionado.nit)) {// Validación de NIT
      mensajeAdvertencia("Debe completar el campo de NIT sin guion, 14 caracteres");
      return;
    }

    if (datoSeleccionado.carnetMinoridad) {// Si se ha proporcionado Carnet de minoridad, validar su formato
      const carnetDeMinoridadRegex = /^\d{9}$/;
      if (!carnetDeMinoridadRegex.test(datoSeleccionado.carnetMinoridad)) {
        mensajeAdvertencia("Debe completar el campo de Carnet de minoridad, 9 caracteres");
        return;
      }
    }

    if (datoSeleccionado.nie) {// Si se ha proporcionado NIE, validar su formato
      const nieRegex = /^\d{11}$/;
      if (!nieRegex.test(datoSeleccionado.nie)) {
        mensajeAdvertencia("Debe completar el campo de NIE, 11 caracteres");
        return;
      }
    }

    if (!datoSeleccionado.edad) {//validacion de edad
      mensajeAdvertencia("Debe completar el campo de Edad");
      return;
    }

    const comboboxGenero = document.getElementById('genero');
    if (!comboboxGenero || comboboxGenero.value === '') {// Validación de Género
      mensajeAdvertencia("Debe seleccionar el Genero");
      return;
    }

    const comboboxEstadoCivil = document.getElementById('estadoFamiliar');
    if (!comboboxEstadoCivil || comboboxEstadoCivil.value === '') {// Validación de estado civil
      mensajeAdvertencia("Debe seleccionar  su estado civil");
      return;
    }

    const telefonoFijoRegex = /^\d{8}$/;
    if (!telefonoFijoRegex.test(datoSeleccionado.telefonoFijo)) {// Validación de Telefono fijo del participante
      mensajeAdvertencia("Debe completar el campo de tel fijo del participante, 8 caracteres");
      return;
    }

    const telefonoRegex = /^\d{8}$/;
    if (!telefonoRegex.test(datoSeleccionado.telefono)) {// Validación de Telefono movil del participante
      mensajeAdvertencia("inserte tel del participante, 8 caracteres");
      return;
    }

    if (!datoSeleccionado.correo) {// Validación de correo electrónico
      mensajeAdvertencia("Debe escribir su correo electrónico");
      return;
    }

    const correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!correoRegex.test(datoSeleccionado.correo)) {// Validar formato de correo electrónico (opcional)
      mensajeAdvertencia("Ingrese un correo electrónico válido");
      return;
    }

    if (!datoSeleccionado.direccionParticipante) {// Validar dirección si se carga a través de un botón
      mensajeAdvertencia("Debe agregar una dirección");
      return;
    }

    if (!datoSeleccionado.nombresContacto) {//validacion de nombres  del contacto
      mensajeAdvertencia("Debe escribir nombre completo del contacto");
      return;
    }

    if (!datoSeleccionado.parentesco) {//validacion de parentesco del contacto
      mensajeAdvertencia("Debe completar el campo de parentesco");
      return;
    }

    const telefonoFijoContactoRegex = /^\d{8}$/;
    if (!telefonoFijoContactoRegex.test(datoSeleccionado.telFijoContacto)) { // Validación de Telefono fijo del responsable
      mensajeAdvertencia("Completar el campo de tel fijo del contacto, 8 caracteres");
      return;
    }

    const telefonoContactoRegex = /^\d{8}$/;
    if (!telefonoContactoRegex.test(datoSeleccionado.telefonoContacto)) {// Validación de Telefono fijo del responsable
      mensajeAdvertencia("Completar el campo de tel del contacto, 8 caracteres");
      return;
    }

    if (!datoSeleccionado.correoContacto) {// Validación de correo electrónico contacto
      mensajeAdvertencia("Debe escribir correo electrónico del contacto");
      return;
    }

    const datosResponsable = {
      nombreCompleto: datoSeleccionado.nombresContacto,
      telefono: datoSeleccionado.telefonoContacto,
      telefonoFijo: datoSeleccionado.telFijoContacto,
      parentesco: datoSeleccionado.parentesco,
      correo: datoSeleccionado.correoContacto
    };

    putResponsable(datoSeleccionado.idResponsable, datosResponsable);

    const datosUsuario = {
      correo: datoSeleccionado.correo,
      nombres: datoSeleccionado.nombres,
      apellidos: datoSeleccionado.apellidos,
      edad: datoSeleccionado.edad,
      sexo: datoSeleccionado.genero,
      telefono: datoSeleccionado.telefono,
      dui: datoSeleccionado.dui,
      nit: datoSeleccionado.nit
    };

    putUsuario(datoSeleccionado.idUsuario, datosUsuario);
    const datosParticipante = {
      expediente: datoSeleccionado.expediente,
      carnetMinoridad: datoSeleccionado.carnetMinoridad,
      nie: datoSeleccionado.nie,
      fechaNacimiento: datoSeleccionado.fechaNacimiento,
      telefonoFijo: datoSeleccionado.telefonoFijo,
      ocupacion: (datoSeleccionado.profesion === 'si') ? datoSeleccionado.ocupacion : '',
      codigo: datoSeleccionado.codigo,
      padecimiento: (datoSeleccionado.alergico === 'si') ? datoSeleccionado.padecimiento : '',
      estadoFamiliar: datoSeleccionado.estadoFamiliar,
      idUsuario: datoSeleccionado.idUsuario,
      lugarNacimiento: datoSeleccionado.municipioNacimiento,
      idResponsable: datoSeleccionado.idResponsable//Luego de insertar el responsable se le asignara aqui
    };
    putParticipante(datoSeleccionado.idParticipante, datosParticipante);

    // Crear un conjunto de IDs de fuentes del participante para una búsqueda rápida
    const idsFuentesParticipante = new Set(fuentesParticipante.map(f => f.idFuente));
    // Filtrar las nuevas fuentes seleccionadas que no están en fuentesParticipante
    const nuevasFuentesSeleccionadas = fuentesSeleccionadas.filter(
      id => !idsFuentesParticipante.has(id)
    );

    nuevasFuentesSeleccionadas.forEach(id => {
      const datos = {
        idFuente: id,
        idParticipante: datoSeleccionado.idParticipante
      }
      postFuenteInformacion(datos);
    });

    setShowModal(true);
  }

  const handleContinue = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  ///ORDENAR GETS
  const getDatos = (persona) => {
    const urlParcial = `usuarios/participantes/${user[0].idUsuario}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        if (persona === 0) {
          setDatos(datos);
        } else if (persona === 1) {//Direccion Participante
          setDatoSeleccionado((datoPrevio) => ({ ...datoPrevio, ["direccionParticipante"]: datos[0].direccionParticipante }));
        } else {//Direccion Encargado
          setDatoSeleccionado((datoPrevio) => ({ ...datoPrevio, ["direccionEncargado"]: datos[0].direccionEncargado }));
        }

      })
      .catch((error) => {
        console.log("Error en usuarios->", error);
      });
  };

  const getIdsCurso = (id) => {
    const urlParcial = `cursos/comprobarinscripcion/${id}/${user[0].idUsuario}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        if (datos.length > 0) {
          if (datos[0].continuar.toString() === '0') {
            navegar('/miscursos');
          }
        }
      })
      .catch((error) => {
        console.log("Error un cursos->", error);
      });
  };

  //ORDENAR POST
  const postFuenteInformacion = (datos) => {
    const urlParcial = 'fuenteinformacion/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
      })
      .catch((error) => {
        console.log('Error en fuenteinformacion->', error);
      });
  }

  const postOnClick = async (datos, actividades) => {
    const urlParcial = 'inscripciones/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { insertId } = respuesta.datos;
        actividades.forEach(actividad => {
          const datosNota = {
            nota: 0,
            idActividad: actividad.idActividad,
            idInscripcion: insertId
          }
          postOnClickNotas(datosNota);
        });
      })
      .catch((error) => {
        console.log('Error en inscripciones->', error);
      });
  };

  //ORDENAR PUT
  const putParticipante = (id, datos) => {
    const urlParcial = `usuarios/participante/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
      })
      .catch((error) => {
        console.log('Error en usuarios->', error);
      });
  }

  const putResponsable = (id, datos) => {
    const urlParcial = `usuarios/responsable/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
      })
      .catch((error) => {
        console.log('Error en usuarios->', error);
      });
  }

  const putUsuario = (id, datos) => {
    const urlParcial = `usuarios/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
      })
      .catch((error) => {
        console.log('Error en usuarios->', error);
      });
  }

  ///OTRAS CONSTANTES
  const {
    openModal,
    direccionSeleccionada,
    departamentosDir,
    municipiosDir,
    handleChangeDirecciones,
    validarCampos
  } = useGestionDirecciones(
    datoSeleccionado.idDepartamento,
    datoSeleccionado.idParticipante,
    datoSeleccionado.idResponsable,
    getDatos,
    setDatoSeleccionado
  );

  return {
    setShowModal,
    showModal,
    handleClose,
    handleContinue,
    handleCancel,
    datos,
    getDatos,
    getIdsCurso,
    postOnClick,
    datoSeleccionado,
    handleChange,
    departamentos,
    municipios,
    openModal,
    direccionParticipante,
    errorTelefonoFijo,
    direccionEncargado,
    errorTelefonoMovil,
    fuentes,
    fuentesSeleccionadas,
    handleChangeFuentes,
    validarCamposInscripcion,
    handleChangeDirecciones,
    direccionSeleccionada,
    departamentosDir,
    municipiosDir,
    validarCampos,
    idCurso
  }
}
export default useInscripcion