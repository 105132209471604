import axios from 'axios';
import {API_URL} from '../config';

const AUTH_URL = `${API_URL}/auth`;

// Función para guardar el token en localStorage
const saveToken = (token) => {
  localStorage.setItem('token', token);
};

const register = async (name, lastname, email, password) => {
  try {
    const response = await axios.post(`${AUTH_URL}/register`, { name, lastname, email, password });
    return response.data; // Retornar los datos si es necesario
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error al registrar');
  }
};

const login = async (email, password) => {
  try {
    const response = await axios.post(`${AUTH_URL}/login`, { email, password });
    const { token } = response.data;
    saveToken(token); // Usar la función para guardar el token
    return token;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error al iniciar sesión');
  }
};

const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post(`${AUTH_URL}/request-password-reset`, { email });
    return response.data; // Retornar los datos si es necesario
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error al solicitar el restablecimiento de contraseña');
  }
};

const resetPassword = async (token, password) => {
  try {
    const response = await axios.post(`${AUTH_URL}/reset-password/${token}`, { password });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error al restablecer la contraseña');
  }
};

export default { register, login, requestPasswordReset, resetPassword };
