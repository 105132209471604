import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import imgLogo from '../../assets/images/usoLogo.png';
import { AuthContext } from '../../context/AuthContext';

const NavbarPrincipal = () => {
    const { logout, grantAccess, userRole } = useContext(AuthContext); // Obtener la función logout
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const handleLogout = () => {
        logout(); // Llamar a la función logout
        navigate('/'); // Redirigir al usuario a la página de inicio o login
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const isActive = (paths) => paths.includes(location.pathname);

    //console.log("Valor de userRole en Navbar:", userRole); 

    const roleBasedLinks = () => {
        switch (userRole) {
            case '1': // Admin
                return (
                    <>
                        <Link to="/assign-groups-and-schedules" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive('/assign-groups-and-schedules') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Establecer grupos
                        </Link>
                        <Link to="/assign-courses" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive('/assign-courses') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Establecer cursos
                        </Link>
                        <Link to="/docentes" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive('/docentes') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Ver docentes
                        </Link>
                        <Link to="/assign-grades" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive('/assign-grades') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Establecer notas
                        </Link>
                        <Link to="/reports" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive('/reports') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Ver Reportes
                        </Link>
                        <Link to="/student-status" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive('/student-status') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Ver Estado de Estudiante
                        </Link>
                    </>
                );
            case '2': // Docente
                return (
                    <>
                        <Link to="/assign-grades" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive('/assign-grades') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Establecer notas
                        </Link>
                    </>
                );
            case '3': // Estudiante
                return (
                    <>
                        <Link to="/inscription-process" onClick={() => grantAccess()}
                            className={`border-b-2 transition-colors duration-300 ${isActive(['/inscription-process', '/profile', '/schedules-and-groups', '/payment', '/grades']) ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                                }`}>
                            Inscripción
                        </Link>
                    </>
                );
            default:
                return null;
        }
    };

    const roleBasedLinksToggle = () => {
        switch (userRole) {
            case '1': // Admin
                return (
                    <>

                        <li>
                            <Link to="/assign-groups-and-schedules" onClick={() => grantAccess()} className={`block py-2 border-b border-gray-200
                    hover:text-green-500 ${isActive('/assign-groups-and-schedules') ? 'text-green-500' : ''}`}>
                                Establecer grupos
                            </Link>
                        </li>
                        <li>
                            <Link to="/assign-courses" onClick={() => grantAccess()} className={`block py-2 border-b border-gray-200 hover:text-green-500
                    ${isActive('/assign-courses') ? 'text-green-500' : ''}`}>
                                Establecer cursos
                            </Link>
                        </li>
                        <li>
                            <Link to="/assign-grades" onClick={() => grantAccess()} className={`block py-2 border-b border-gray-200 hover:text-green-500
                                ${isActive('/assign-grades') ? 'text-green-500' : ''}`}>
                                Establecer notas
                            </Link>
                        </li>
                        <li>
                            <Link to="/reports" onClick={() => grantAccess()} className={`block py-2 border-b border-gray-200 hover:text-green-500
                    ${isActive('/reports') ? 'text-green-500' : ''}`}>
                                Ver reportes
                            </Link>
                        </li>
                        <li>
                            <Link to="/student-status" onClick={() => grantAccess()} className={`block py-2 hover:text-green-500 ${isActive('/student-status')
                                ? 'text-green-500' : ''}`}>
                                Ver Estado de Estudiante
                            </Link>
                        </li>
                    </>
                );
            case '2': // Docente
                return (
                    <>
                        <li>
                            <Link to="/assign-grades" onClick={() => grantAccess()} className={`block py-2 border-b border-gray-200 hover:text-green-500
                                ${isActive('/assign-grades') ? 'text-green-500' : ''}`}>
                                Establecer notas
                            </Link>
                        </li>
                    </>
                );
            case '3': // Estudiante
                return (
                    <>
                        <li>
                            <Link to="/inscription-process" onClick={() => grantAccess()} className={`block py-2 border-b border-gray-200 hover:text-green-500
                                ${isActive(['']) ? 'text-green-500' : ''} `}>
                                Inscripción
                            </Link>
                        </li>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <nav className="relative z-50 w-full bg-gradient-to-r from-sky-500 via-sky-700 to-blue-900">
            <div className="justify-between hidden w-full py-4 pr-10 text-white lg:flex ps-8">
                <div className="flex items-center space-x-8">
                    <Link to="https://www.usonsonate.edu.sv" className="object-left" >
                        <img src={imgLogo} alt="Logo" className="h-14" />
                    </Link>
                    <Link to="/principal"
                        className={`border-b-2 transition-colors duration-300 ${isActive('/principal') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                            }`}>
                        Principal
                    </Link>
                    {roleBasedLinks()}
                </div>
                <div className="flex items-center">
                    <button
                        onClick={handleLogout}
                        className="px-4 py-3 font-semibold text-white transition duration-300 transform bg-red-500 rounded-lg shadow-md hover:bg-red-600 hover:scale-105 focus:outline-none"
                    >
                        Cerrar Sesión
                    </button>
                </div>
            </div>



            <div className="flex items-center justify-between px-4 py-5 text-white lg:hidden">
                <Link to="https://www.usonsonate.edu.sv/">
                    <img src={imgLogo} alt="Logo" className="h-14" />
                </Link>
                <button onClick={toggleMenu} className="focus:outline-none">
                    {isMenuOpen ?
                        <FiX size={24} /> :
                        <FiMenu size={24} />}
                </button>
            </div>

            {isMenuOpen && (
                <div className="absolute left-0 top-auto z-50 w-full text-gray-600 bg-white shadow-lg rounded-b-md lg:hidden">
                    <ul className="px-6 py-4 space-y-2">
                        <li>
                            <Link to="/principal" className={`block py-2 border-b border-gray-200 hover:text-green-500
                    ${isActive('/principal') ? 'text-green-500' : ''}`}>
                                Principal
                            </Link>
                        </li>
                        {roleBasedLinksToggle()}
                        <li>
                            <button onClick={handleLogout}
                                className="w-full px-4 py-3 font-semibold text-white transition duration-300 transform bg-red-500 rounded-lg shadow-md hover:bg-red-600 hover:scale-105 focus:outline-none">
                                Cerrar Sesión
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default NavbarPrincipal;
