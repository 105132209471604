function Plantillas(idPlantilla = 0, concepto = '', monto = '', tipo = '', plantilla = '', idCodigoServicio = 0) {
    return {
        idPlantilla,
        concepto,
        monto,
        tipo,
        plantilla,
        idCodigoServicio
    }
}

export { Plantillas }
