import React from 'react'

function ContactarWhatsapp() {
    return (
        <p className="responsive-email">
            <a href="https://wa.me/60586125?text=Hola,%20necesito%20informacion" target="_blank"
                className="floating-icon">
                <i className="bi bi-whatsapp"></i>
                <span className="floating-text">Contactar via WhatsApp</span>
            </a>
        </p>
    )
}

export default ContactarWhatsapp