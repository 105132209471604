import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu, FiX, FiUser, FiCalendar, FiCreditCard, FiBarChart2, FiArchive } from 'react-icons/fi';

const VerticalMenu = ({ enabledRoutes }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => location.pathname === path;

  // Función para aplicar clases y deshabilitar enlaces
  const getLinkClass = (isEnabled, path) => {
    return `flex items-center px-4 py-2 transition-colors duration-300 ${isEnabled ? 'hover:text-lightgreentxt' : 'text-gray-400 cursor-not-allowed'} ${isActive(path) ? 'bg-darkgreentxt text-white' : 'text-darkbluetxt'}`;
  };

  return (
    <div className={`fixed z-40 h-fit top-1/4 bg-white shadow-lg transition-all duration-300 ease-in-out ${isOpen ? 'w-56' : 'w-16'}`}>
      <div className="flex flex-col h-full text-gray-800 bg-white shadow-lg">
        <div className="flex items-center justify-between p-4 bg-white">
          {isOpen && <span className="text-lg font-semibold text-lightgreentxt">Opciones</span>}
          <button onClick={toggleMenu} className="focus:outline-none text-darkbluetxt hover:text-lightgreentxt">
            {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        <nav className="flex-grow bg-white">
          <ul className="space-y-4">
            <li>
              <Link
                to="/inscription-process"
                className={getLinkClass(enabledRoutes.inscriptionProcess, '/inscription-process')}
                onClick={(e) => !enabledRoutes.inscriptionProcess && e.preventDefault()}
              >
                <FiArchive size={20} className={`${isOpen ? 'mr-3' : 'mx-auto'}`} />
                {isOpen && <span className="font-medium">Proceso de Inscripción</span>}
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                className={getLinkClass(enabledRoutes.profile, '/profile')}
                onClick={(e) => !enabledRoutes.profile && e.preventDefault()}
              >
                <FiUser size={20} className={`${isOpen ? 'mr-3' : 'mx-auto'}`} />
                {isOpen && <span className="font-medium">Perfil</span>}
              </Link>
            </li>
            <li>
              <Link
                to="/schedules-and-groups"
                className={getLinkClass(enabledRoutes.schedulesAndGroups, '/schedules-and-groups')}
                onClick={(e) => !enabledRoutes.schedulesAndGroups && e.preventDefault()}
              >
                <FiCalendar size={20} className={`${isOpen ? 'mr-3' : 'mx-auto'}`} />
                {isOpen && <span className="font-medium">Horarios y Grupos</span>}
              </Link>
            </li>
            <li>
              <Link
                to="/payment"
                className={getLinkClass(enabledRoutes.payment, '/payment')}
                onClick={(e) => !enabledRoutes.payment && e.preventDefault()}
              >
                <FiCreditCard size={20} className={`${isOpen ? 'mr-3' : 'mx-auto'}`} />
                {isOpen && <span className="font-medium">Pagos</span>}
              </Link>
            </li>
            <li>
              <Link
                to="/grades"
                className={getLinkClass(enabledRoutes.grades, '/grades')}
                onClick={(e) => !enabledRoutes.grades && e.preventDefault()}
              >
                <FiBarChart2 size={20} className={`${isOpen ? 'mr-3' : 'mx-auto'}`} />
                {isOpen && <span className="font-medium">Notas</span>}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default VerticalMenu;
