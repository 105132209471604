import React from 'react';
import { useListadoPlantillas } from '../plantillas/useListadoPlantillas';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';
import { AbrirModal } from '../../components/AbrirModal';
import { PlantillasModal } from './PlantillasModal';

const ListaPlantillas = () => {

  const {
    plantillas,
    handleChange,
    plantillaSeleccionada,
    openModal,
    deleteOnClick,
    showModal,
    handleClose,
    handleCancel,
    handleContinue,

  } = useListadoPlantillas(() => { });

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1"><h1>Plantillas</h1></div>
        <div className="p-2">
          <BtnAgregar onclick={() => openModal(1)} titulo={"Agregar Plantilla"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Concepto</th>
              <th scope="col">Monto</th>
              <th scope="col">Tipo</th>
              <th scope="col">Plantilla</th>
              <th scope="col">Codigo</th>
              <th scope="col">ConceptoCodigo</th>
              <th scope="col">Exigible</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {plantillas ? plantillas.map((plantilla) => (
              <tr key={plantilla.idPlantilla}>
                <th scope="row">{plantilla.idPlantilla}</th>
                <td>{plantilla.concepto}</td>
                <td>{plantilla.monto}</td>
                <td>{plantilla.tipo}</td>
                <td>{plantilla.plantilla}</td>
                <td>{plantilla.codigo}</td>
                <td>{plantilla.conceptoCodigo}</td>
                <td>{plantilla.exigible}</td>
                <td>
                  <BtnEditar onclick={() => openModal(2, plantilla)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteOnClick(plantilla.idPlantilla)} titulo={"Eliminar"} />
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>
      <AbrirModal show={showModal}
        handleContinue={handleContinue}
        handleCancel={handleCancel}
        handleClose={handleClose}
        titulo={"Plantillas"}
        ocultar={false}>
        <PlantillasModal plantillaSeleccionada={plantillaSeleccionada} handleChange={handleChange} />
      </AbrirModal>
    </>
  );
};

export { ListaPlantillas };
