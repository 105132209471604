
import { useListadoEntregaKit } from './useListadoEntregaDeKit';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

const ListaEntregaKit = () => {
  const {
    entregaKits,
    handleChange,
    crearDatos,
    titulo,
    entregaKitSeleccionado,
    openModal,
    deleteData,
    validarCampos,
    formatearFecha,
    kits,
    inscripcion
  } = useListadoEntregaKit();

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1"><h1>Entrega de Kits</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="modal" databstarget="#modalEntregaKit" onclick={() => openModal(1)} titulo={"Agregar Entrega de Kit"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Kit</th>
              <th scope="col">Nombre</th>
              <th scope="col">Talla</th>
              <th scope="col">Entregado</th>
              <th scope="col">Fecha de Entrega</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {entregaKits ? entregaKits.map((entregaKit) => (
              <tr key={entregaKit.idEntregaKit}>
                <th scope="row">{entregaKit.idEntregaKit}</th>
                <td>{entregaKit.kit}</td>
                <td>{entregaKit.nombres}</td>
                <td>{entregaKit.talla}</td>
                <td>{entregaKit.entregado ? 'Sí' : 'No'}</td>
                <td>{formatearFecha(entregaKit.fechaEntrega)}</td>
                <td>

                  <BtnEditar databstogle="modal" databstarget="#modalEntregaKit" onclick={() => openModal(2, crearDatos(entregaKit))} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteData(entregaKit.idEntregaKit)} titulo={"Eliminar"} />

                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>

      <div className="modal fade" id="modalEntregaKit" tabIndex="-1" aria-labelledby="modalEntregaKitLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalEntregaKitLabel">{titulo}</h1>
              <button
                type="button"
                id='btnCerrarModal'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3 needs-validation" noValidate>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="txtIdEntregaKit" className="form-label">ID</label>
                    <input
                      id='txtidEntregaKit'
                      onChange={handleChange}
                      value={entregaKitSeleccionado.idEntregaKit}
                      type="text"
                      className="form-control"
                      name="idEntregaKit"
                      required
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="InscripcionSelect" className="form-label">Inscripcion:</label>
                    <select
                      id="InscripcionSelect"
                      value={entregaKitSeleccionado.idInscripcion}
                      onChange={handleChange}
                      className="form-select"
                      name="idInscripcion"
                      required
                    >
                      <option value="">Selecciona una opcion</option>
                      {inscripcion.map(inscripcion => (
                        <option key={inscripcion.idInscripcion} value={inscripcion.idInscripcion}>{inscripcion.nombres}</option>
                      ))}
                    </select>
                  </div>
                  {entregaKitSeleccionado.idInscripcion === 'op' && (
                    <div className="col-md-4">
                      <label htmlFor="txtidInscripcion" className="form-label">Nuevo ID:</label>
                      <input
                        id='txtidInscripcion'
                        onChange={handleChange}
                        value={entregaKitSeleccionado.nuevoIdInscripcion}
                        type="text"
                        className="form-control"
                        name="nuevoIdInscripcion"
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="kitsSelect" className="form-label">Kits:</label>
                    <select
                      id="kitsSelect"
                      value={entregaKitSeleccionado.idKit}
                      onChange={handleChange}
                      className="form-select"
                      name="idKit"
                      required
                    >
                      <option value="">Selecciona un kit</option>
                      {kits.map(kit => (
                        <option key={kit.idKit} value={kit.idKit}>{kit.kit}</option>
                      ))}
                    </select>
                  </div>
                  {entregaKitSeleccionado.idKit === 'otro' && (
                    <div className="col-md-4">
                      <label htmlFor="txtIdKit" className="form-label">Nuevo ID de Kit:</label>
                      <input
                        id='txtIdKit'
                        onChange={handleChange}
                        value={entregaKitSeleccionado.nuevoIdKit}
                        type="text"
                        className="form-control"
                        name="nuevoIdKit"
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <label htmlFor="txtTalla" className="form-label">Talla</label>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <input
                      id='txtTalla'
                      onChange={handleChange}
                      value={entregaKitSeleccionado.talla}
                      type="text"
                      className="form-control"
                      name="talla"
                      required
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <div className="form-check">
                      <input
                        id='chkEntregado'
                        onChange={handleChange}
                        checked={!!entregaKitSeleccionado.entregado}
                        type="checkbox"
                        className="form-check-input"
                        name="entregado"
                      />
                      <label htmlFor="chkEntregado" className="form-check-label ms-2">Entregado</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="txtFechaEntrega" className="form-label">Fecha de Entrega</label>
                    <input
                      id='txtFechaEntrega'
                      onChange={handleChange}
                      value={entregaKitSeleccionado.fechaEntrega} // Formatear la fecha al asignarla al input
                      type="date"
                      className="form-control"
                      name="fechaEntrega"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-md-end">
                    <hr />
                    <button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">
                      Cancelar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={validarCampos}>
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export { ListaEntregaKit };
