import { useEffect, useState } from "react";
import { useFetch } from "../../tools/useFetch";
import { Grupos } from "../../models/Grupos";
import { useAlert } from "../../hooks/useAlert";

function useGestionGrupos({ obtenerCursos, cursoSeleccionado }) {

    const [expandedGrupoId, setExpandedGrupoId] = useState(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState(null);
    const [showModalGrupos, setShowModalGrupos] = useState(false);
    const [instructores, setInstructores] = useState([]);
    const { getFetch, postFetch, putFetch } = useFetch();
    const { mensajeError, mensajeExitoso, mensajeInformacion } = useAlert();


    useEffect(() => {
        getInstructores();
    }, []);

    const toggleGrupo = (idGrupo) => {
        setExpandedGrupoId(expandedGrupoId === idGrupo ? null : idGrupo);
    };

    const openModalGrupos = (grupo = null) => {
        setGrupoSeleccionado(grupo);
        setShowModalGrupos(true);
    };

    const getInstructores = () => {
        const urlParcial = `usuarios/instructores/3`;
        getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;
                setInstructores(datos);
            })
            .catch((error) => {
                console.log("Error->", error);
            });
    };

    const handleChangeGrupo = (e) => {
        const { name, value, type, checked } = e.target;

        // Determina el nuevo valor basado en el tipo de input
        const newValue = type === 'checkbox' ? checked : value;

        setGrupoSeleccionado(grupoPrevio => ({
            ...grupoPrevio,
            [name]: newValue
        }));
    };

    const handleContinueGrupo = async () => {
        validarCamposGrupo();
    };

    const validarCamposGrupo = () => {
        const grupo = new Grupos(
            grupoSeleccionado ? grupoSeleccionado.idGrupo : null,
            grupoSeleccionado ? grupoSeleccionado.grupo : '',
            grupoSeleccionado ? grupoSeleccionado.modalidad : '',
            grupoSeleccionado ? grupoSeleccionado.lugar : '',
            grupoSeleccionado ? grupoSeleccionado.idCurso : '',
            grupoSeleccionado ? grupoSeleccionado.idUsuario : ''
        );

        if (!grupo.grupo || !grupo.modalidad || !grupo.lugar || !grupo.idUsuario) {
            mensajeInformacion("Por favor llenar los campos necesarios");
            return;
        }
        if (grupo.idGrupo) {
            putOnClick(grupo.idGrupo, grupo);
        } else {
            postOnClick(grupo);
        }
        setShowModalGrupos(false);
    };

    const calcularHoras = (horarios) => {
        if (!horarios || horarios.length === 0) return 0;

        let totalMinutos = 0;

        horarios.forEach(horario => {
            const fechaInicio = horario.diaInicio.split('T')[0];
            const fechaFin = horario.diaFin.split('T')[0];

            // Contar los días seleccionados dentro del rango de fechas
            const diasEnRango = contarDiasEnRango(fechaInicio, fechaFin, horario.dias);

            // Convertir las horas de inicio y fin a minutos
            const [horaInicio, minutoInicio] = horario.horaInicio.split(':').map(Number);
            const [horaFin, minutoFin] = horario.horaFin.split(':').map(Number);

            // Calcular la duración de una sesión en minutos
            const duracionSesion = (horaFin * 60 + minutoFin) - (horaInicio * 60 + minutoInicio);

            // Sumar al total el tiempo total para los días en el rango
            totalMinutos += diasEnRango * duracionSesion;
        });

        // Convertir minutos totales a horas
        const totalHoras = totalMinutos / 60;
        return totalHoras;
    };

    const contarDiasEnRango = (fechaInicio, fechaFin, diasDeseados) => {
        const diasMap = {
            Domingo: 0, Lunes: 1, Martes: 2, Miércoles: 3, Jueves: 4, Viernes: 5, Sábado: 6
        };

        // Convertir la cadena de días deseados a un array de índices
        const diasIndices = diasDeseados
            .split(',')
            .map(dia => diasMap[dia.trim()])
            .filter(dia => dia !== undefined); // Filtrar días inválidos

        let contadorDias = 0;

        diasIndices.forEach(dia => {
            contadorDias += countInRange(dia, fechaInicio, fechaFin);
        });
        
        return contadorDias;
    };

    function countInRange(dia, startDate, endDate) {
        // Convertimos las fechas a objetos Date asegurándonos de que estén bien formateadas
        const start = new Date(`${startDate}T00:00:00`);
        const end = new Date(`${endDate}T00:00:00`);

        // Validamos que las fechas sean válidas
        if (isNaN(start) || isNaN(end)) {
            throw new Error("Por favor, introduce fechas válidas.");
        }

        // Aseguramos que el rango esté en el orden correcto
        if (start > end) {
            throw new Error("La fecha de inicio no puede ser mayor que la fecha de fin.");
        }

        let count = 0;

        // Iteramos por cada día dentro del rango
        let currentDate = new Date(start);
        while (currentDate <= end) {          
            if (currentDate.getDay() === dia) { // getDay() devuelve 2 para martes
                count++;
            }
            // Avanzamos al día siguiente
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return count;
    }

    //ORDENAR POST
    const postOnClick = (datos) => {
        const urlParcial = 'grupos/';
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Datos insertados");
                    obtenerCursos();
                } else {
                    mensajeError("Error al insertar datos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    };

    //ORDENARA PUT
    const putOnClick = (id, datos) => {
        const urlParcial = `grupos/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Datos actualizados");
                    obtenerCursos();
                } else {
                    mensajeError("Error al actuaizar datos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    };

    return {
        handleChangeGrupo,
        toggleGrupo,
        expandedGrupoId,
        grupoSeleccionado,
        openModalGrupos,
        showModalGrupos,
        instructores,
        setShowModalGrupos,
        handleContinueGrupo,
        calcularHoras
    }
}

export { useGestionGrupos }