import React, { useState, useEffect } from "react";
import NavbarPrincipal from "./NavbarPrincipal";
import { API_URL } from "../../config";
import axios from "axios";
import Select from "react-select";
import html2pdf from "html2pdf.js";


const AssignNotes = () => {
  const [courses, setCourses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [noteData, setNoteData] = useState({ note: "" });
  const [editingStudent, setEditingStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [searched, setSearched] = useState(false); // Estado para controlar si se presionó el botón de búsqueda

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${API_URL}/courses/all`);
        const formattedCourses = response.data.map((course) => ({
          value: course.idCursos,
          label: `${course.nombre} (${course.anio_curso})`,
        }));
        setCourses(formattedCourses);
      } catch (error) {
        setError("Error al obtener los cursos");
        console.error(error);
      }
    };
    fetchCourses();
  }, []);

  const fetchGroups = async (courseId) => {
    try {
      const token = localStorage.getItem("token"); // Asegúrate de que el token se almacene en localStorage al iniciar sesión

      const response = await axios.get(
        `${API_URL}/groups/by-course/${courseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedGroups = response.data.map((group) => ({
        value: group.idGrupos,
        label: `${group.nombre} - ${group.hora_inicio} a ${group.hora_fin}`,
      }));
      setGroups(formattedGroups);
    } catch (error) {
      setError("Error al obtener los grupos");
      console.error(error);
    }
  };

  const fetchStudents = async () => {
    if (!selectedGroup) {
      setError("Debe seleccionar un grupo primero.");
      return;
    }

    try {
      const response = await axios.get(
        `${API_URL}/groups/students/${selectedGroup.value}`
      );
      console.log(response);
      const formattedStudents = response.data.map((est) => ({
        id: est.id, // Asegúrate de que este sea el campo correcto
        nombre: est.nombres,
        apellido: est.apellidos,
        nota: est.nota !== null ? Number(est.nota) : null,
        ultimaModificacion: est.ultima_modificacion || null,
      }));

      setStudents(formattedStudents);
      setError("");
    } catch (error) {
      setError("Error al obtener los estudiantes");
      console.error(error);
    }
  };

  const getStudentStatus = (nota) => (nota >= 6.0 ? "Aprobado" : "Reprobado");

  const getCurrentSalvadorTime = () => {
    const date = new Date();
    return new Intl.DateTimeFormat("es-SV", {
      dateStyle: "short",
      timeStyle: "short",
      timeZone: "America/El_Salvador",
    }).format(date);
  };

  const handleEditNote = (student) => {
    console.log("Estudiante seleccionado:", student); // Verifica los datos del estudiante
    setEditingStudent({
      id: student.id, // ID del estudiante
      nombre: student.nombre,
      apellido: student.apellido,
      nota: student.nota || "", // Si la nota es nula, inicializa como un string vacío
    });
    setNoteData({ note: student.nota || "" });
    setError("");
    setShowModal(true);
  };

  const handleSaveNote = async () => {
    if (noteData.note < 0 || noteData.note > 10) {
      setError("La nota debe estar entre 0 y 10.");
      return;
    }

    if (!editingStudent?.id) {
      setError("Error: No se encontró el ID del estudiante.");
      return;
    }

    try {
      const payload = {
        estudianteId: editingStudent.id, // ID del estudiante seleccionado
        grupoId: selectedGroup.value, // ID del grupo seleccionado
        nota: Number(noteData.note), // Nota ingresada por el usuario
      };

      const response = await axios.post(`${API_URL}/notas/upsert`, payload);

      if (response.status === 200) {
        const currentDateTime = getCurrentSalvadorTime();
        const updatedStudents = students.map((student) =>
          student.id === editingStudent.id
            ? {
              ...student,
              nota: Number(noteData.note),
              ultimaModificacion: currentDateTime,
            }
            : student
        );

        setStudents(updatedStudents);
        setShowModal(false);
        setEditingStudent(null);
        setNoteData({ note: "" });
        setError(""); // Limpia errores
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error("Error al guardar la nota:", error);
      setError("Error al guardar la nota.");
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setEditingStudent(null);
    setNoteData({ note: "" });
    setError("");
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <NavbarPrincipal />
      <div className="flex items-center justify-center flex-grow p-6">
        <div className="w-full max-w-6xl">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">
            Administrar Notas
          </h1>

          {/* Select para Curso */}
          <div className="mb-4">
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Seleccionar Curso:
            </label>
            <Select
              options={courses}
              onChange={(option) => {
                setSelectedCourse(option);
                fetchGroups(option.value);
              }}
              value={selectedCourse}
              placeholder="Seleccione un Curso"
              className="p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Select para Grupo */}
          <div className="mb-4">
            <label className="block mb-2 text-sm font-bold text-gray-700">
              Seleccionar Grupo:
            </label>
            <Select
              options={groups}
              onChange={(option) => setSelectedGroup(option)}
              value={selectedGroup}
              placeholder="Seleccione un Grupo"
              className="p-2 border border-gray-300 rounded"
              isDisabled={!selectedCourse}
            />
          </div>

          {/* Botón Buscar */}
          <div className="mb-6 text-center">
            <button
              onClick={fetchStudents}
              className="px-6 py-2 text-white rounded-lg bg-sky-600 hover:bg-sky-700 focus:outline-none"
            >
              Buscar Estudiantes
            </button>
          </div>

          {/* Mostrar errores si existen */}
          {error && (
            <div className="mb-4 text-center text-red-600">{error}</div>
          )}

          {/* Condicional para mostrar tabla o mensaje */}
          {searched && students.length === 0 && (
            <div className="mt-4 text-center text-gray-700">
              No se encontraron estudiantes inscritos en este grupo.
            </div>
          )}

          {students.length > 0 && (
            <div className="overflow-x-auto">
              <table className="w-full bg-white border border-gray-300 rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-3 text-left">Nombre</th>
                    <th className="p-3 text-left">Apellido</th>
                    <th className="p-3 text-left">Estado</th>
                    <th className="p-3 text-left">Nota</th>
                    <th className="p-3 text-left">Última Modificación</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student) => (
                    <tr key={student.id} className="hover:bg-gray-100">
                      <td className="p-3 border-t border-gray-300">
                        {student.nombre}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {student.apellido}
                      </td>
                      <td
                        className={`p-3 border-t border-gray-300 ${student.nota >= 6
                          ? "text-green-600"
                          : student.nota !== null
                            ? "text-red-600"
                            : "text-gray-600"
                          }`}
                      >
                        {student.nota !== null
                          ? getStudentStatus(student.nota)
                          : "-"}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        <div className="flex items-center gap-2">
                          <span>
                            {student.nota !== null ? student.nota : "-"}
                          </span>
                          <button
                            onClick={() => handleEditNote(student)}
                            className="px-2 py-1 text-white rounded bg-lightgreentxt hover:bg-darkgreentxt "
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {student.ultimaModificacion || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Modal para agregar/editar nota */}
          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
              <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
                <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">
                  {editingStudent?.nota ? "Editar Nota" : "Agregar Nota"}
                </h2>
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-bold text-gray-700">
                    Nota:
                  </label>
                  <input
                    type="number"
                    name="note"
                    value={noteData.note}
                    min="0"
                    max="10"
                    step="0.1"
                    onChange={(e) =>
                      setNoteData({ ...noteData, note: e.target.value })
                    }
                    placeholder="Escribe la nota (0-10)"
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-sky-500"
                  />
                  {error && (
                    <p className="mt-2 text-sm text-red-600">{error}</p>
                  )}
                </div>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={handleSaveNote}
                    className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
                  >
                    Guardar
                  </button>
                  <button
                    onClick={handleCancel}
                    className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Modal de confirmación */}
          {showSuccessModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
              <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
                <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">Nota Actualizada con Éxito</h2>
                <p className="mb-6 text-center text-gray-600">La nota del estudiante ha sido registrada exitosamente.</p>

                <div className="flex justify-center">
                  <button
                    onClick={() => setShowSuccessModal(false)} // Asegurarse de usar la función anónima aquí
                    className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default AssignNotes;
