import React from 'react';
import Footer from '../Footer';
import Navbar from '../NavIndex/Navbar'; 
import { FiTool } from 'react-icons/fi'; 
import { Link } from 'react-router-dom'; 

const Information = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex items-center justify-center flex-1 bg-gray-50">
                <div className="text-center">
                    <div className="inline-flex items-center justify-center w-24 h-24 mb-6 bg-yellow-100 rounded-full">
                        <FiTool className="w-12 h-12 text-yellow-500" />
                    </div>
                    <h1 className="mb-4 text-4xl font-bold text-sky-700">Página en construcción</h1>
                    <p className="mb-6 text-lg text-gray-600">
                        Estamos trabajando arduamente para traer esta funcionalidad lo más pronto posible. ¡Gracias por tu paciencia!
                    </p>
                    <Link to="/">
                        <button className="px-6 py-3 mt-4 text-lg font-semibold text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt">
                            Volver al inicio
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default Information;
