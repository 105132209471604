import { AbrirModal } from "../../components/AbrirModal";
import { CambiarRolM } from "../roles/CambiarRolM";
import { useListadoRoles } from "../roles/useListadoRoles";
import { useGestionRol } from "../roles/useGestionRol";
import { BarraBusqueda } from '../../components/BarraBusqueda';


const ListadoUsuario = () => {
    const {
        usuarios,
        openModal,
        handleCancel,
        handleClose,
        handleContinue,
        showModal,
        elementoSelecionado,
        handleUpdateRol,
        handleRoleChange,
        searchText,
        setSearchText
    } = useGestionRol();

    const {
        roles
    } = useListadoRoles();

    return (
        <>
            <div className="mt-custom d-flex">
                <div className="p-2 flex-grow-1"><h1>Usuarios</h1></div>
            </div>
            <div className="table-responsive text-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-2 mb-2">
                            <form className="d-flex">
                                <select className="form-select" onChange={handleRoleChange}>
                                    <option value="0">Todos</option>
                                    {roles.map(rol => (
                                        <option key={rol.idRol} value={rol.idRol}>{rol.rol}</option>
                                    ))}
                                </select>
                            </form>
                        </div>
                        {/* Barra de búsqueda */}

                        <div className="col-12 col-md-6 mb-2">
                            <BarraBusqueda
                                placeholder={"Escriba el nombre del usuario."}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <table id="dataTable" className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Rol</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios ? usuarios.map((usuario) => (
                            <tr key={usuario.idUsuario}>
                                <td>{usuario.idUsuario}</td>
                                <td>{usuario.nombres && usuario.apellidos ? `${usuario.nombres} ${usuario.apellidos}` : usuario.nombres || ''}</td>
                                <td>{usuario.rol}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '0' }}>
                                    <button className="btn btn-success btn-sm p-1" onClick={() => openModal(usuario)}>Cambiar rol</button>
                                </td>
                            </tr>
                        )) : null}
                    </tbody>
                </table>
            </div>
            <AbrirModal show={showModal}
                handleContinue={handleContinue}
                handleCancel={handleCancel}
                handleClose={handleClose}
                titulo={"Cambiar rol"}
                ocultar={false}>
                {elementoSelecionado && <CambiarRolM usuario={elementoSelecionado} onUpdateRol={handleUpdateRol} />}
            </AbrirModal>
        </>
    );
}

export { ListadoUsuario };