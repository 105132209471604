import { useEffect } from 'react';
import { useListadoMisCursos } from '../misCursos/useListadoMisCursos'
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { useAuth } from '../../contexts/DataContext';

const ListadoMisCursos = () => {
    const { user } = useAuth();
    const {
        getMisCursos,
        misCursos,
        pagDetalles
    } = useListadoMisCursos();

    useEffect(() => {
        getMisCursos(user[0].idUsuario);
    }, [])
    return (
        <>
            <div className="container-fluid" style={{ paddingTop: '60px' }}>
                <div className="row">
                    {misCursos.length > 0 ? (
                        misCursos.map((curso, index) => (
                            <div key={index} className="col-md-4 pt-2">
                                <div className="shadow p-3 bg-color2 rounded text-center">
                                    <div className="text-center">
                                        <span className='text-color5' style={{ fontWeight: 'bold' }}> {curso.curso}</span>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 d-flex justify-content-center">
                                            <div className="text-center border-color1" style={{ width: '100px', height: '100px', margin: 'auto' }}>
                                                {curso.imagen ? (
                                                    <img src={curso.imagen} style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }} />
                                                ) : (
                                                    <p style={{ margin: '0', lineHeight: '100px' }}>Imagen no disponible</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="shadow col-10  d-flex justify-content-center" style={{paddingTop: '6px', paddingBottom: '6px' }}>
                                                <div className="row text-color4">
                                                    <div className="text-center" >
                                                        <span style={{ fontWeight: 'bold' }}> Horario:</span> {curso.dias}
                                                    </div>
                                                    <div className="text-center">
                                                        <span style={{ fontWeight: 'bold' }}> Grupo:</span> G{curso.grupo}
                                                    </div>
                                                    <div className="text-center" >
                                                        <span style={{ fontWeight: 'bold' }}> Modalidad:</span> {curso.modalidad}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center d-flex justify-content-end" style={{ paddingTop: '10px' }}>
                                            <BtnAgregar titulo={'Ver detalles'} onclick={() => pagDetalles(curso.idCurso)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-md-4 " style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                            <div className="shadow p-3 bg-white rounded text-center" style={{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px', /*border: '2px solid black'*/ }}>
                                <div className="text-center">
                                    <p>NO HAY CURSOS</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
export { ListadoMisCursos };
