import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from '../../contexts/DataContext';
import { useListadoMisCursos } from "./useListadoMisCursos";

const ListadoDetalles = () => {
    const { user } = useAuth();
    let { idCurso } = useParams();
    const {
        getDetalleCursos,
        detallesCursos
    } = useListadoMisCursos();

    useEffect(() => {
        getDetalleCursos(user[0].idUsuario, idCurso);
    }, [])

    const gruposCurso = detallesCursos.reduce((grupos, detalle) => {
        const grupoExistente = grupos.find(grupo => grupo.idCurso === detalle.idCurso); // Verificar si ya existe un grupo para este idModulo
        if (grupoExistente) {// Si existe, agregar la actividad al grupo existente
            grupoExistente.actividades.push(detalle);
        } else {
            grupos.push({
                idCurso: detalle.idCurso,
                curso: detalle.curso,
                actividades: [detalle]
            });
        }
        return grupos;
    }, []);

    return (
        <>
            <div className="container-fluid pt-2">
                <div className="card mt-custom">
                    <div className="shadow p-2 rounded bg-color5 text-color1" style={{ textAlign: 'center' }}>
                        <h2 className="h2 mb-2 small" style={{ fontSize: '20px' }}>Cursos y notas</h2>
                    </div>
                    <div className="row justify-content-center">
                        {gruposCurso.length > 0 ? (
                            gruposCurso.map((grupo, index) => (
                                <div key={index} className="col-md-8 text-center pt-2 pb-2">
                                    <div className="shadow p-2 bg-color4 text-color1"> Curso - {grupo.curso}</div>
                                    <div className="p-2 border">
                                        <table className="table border-color3 text-color4" >
                                            <thead>
                                                <tr>
                                                    <th>Actividad</th>
                                                    <th>Porcentaje</th>
                                                    <th>Nota</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {grupo.actividades.map((actividad, idx) => (
                                                    <tr key={idx}>
                                                        <td>{actividad.actividad}</td>
                                                        <td>{actividad.porcentaje + '%'}</td>
                                                        <td>{(actividad.nota > 0) ? actividad.nota : '-'}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th>Total general</th>
                                                    <th>
                                                        {
                                                            grupo.actividades.reduce((totalPorcent, actividad) => {
                                                                return totalPorcent + actividad.porcentaje;
                                                            }, 0) // Para mostrar el total con 2 decimales
                                                        }%
                                                    </th>
                                                    <th>
                                                        {
                                                            grupo.actividades.reduce((total, actividad) => {
                                                                return total + (actividad.nota > 0 ? actividad.nota * (actividad.porcentaje / 100) : 0);
                                                            }, 0).toFixed(2) // Para mostrar el total con 2 decimales
                                                        }
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-md-8 text-center">
                                <p>No hay información disponible.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </>
    )
}

export { ListadoDetalles };
