import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarPrincipal from '../NavPrincipal/NavbarPrincipal';
import VerticalMenu from './VerticalMenu';
import {API_URL} from '../../config'

const Grades = () => {
  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(`${API_URL}/grades`);
        setGrades(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchGrades();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <NavbarPrincipal />
      <div className="flex flex-grow bg-gray-50">
        <VerticalMenu enabledRoutes={{ grades: true }} />
        <div className="flex flex-col items-center justify-center flex-1 p-6">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">Visualizador de Notas</h1>
          {loading ? (
            <p className="text-center text-blue-500">Cargando notas...</p>
          ) : (
            <div className="w-full max-w-4xl overflow-x-auto">
              <table className="w-full bg-white border border-gray-300 rounded-lg shadow-md">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-3 text-left">Curso</th>
                    <th className="p-3 text-left">Grupo</th>
                    <th className="p-3 text-left">Nota</th>
                    <th className="p-3 text-left">Estado</th>
                    <th className="p-3 text-left">Fecha de Registro</th>
                  </tr>
                </thead>
                <tbody>
                  {grades.length === 0 ? (
                    <tr>
                      <td className="p-3 text-center text-gray-600 border-t border-gray-300" colSpan="5">
                        No hay notas disponibles.
                      </td>
                    </tr>
                  ) : (
                    grades.map((grade) => (
                      <tr key={grade.idNotas} className="hover:bg-gray-100">
                        <td className="p-3 border-t border-gray-300">{grade.curso_nombre}</td>
                        <td className="p-3 border-t border-gray-300">{grade.grupo_nombre}</td>
                        <td className="p-3 border-t border-gray-300">{grade.nota}</td>
                        <td className={`p-3 border-t border-gray-300 ${grade.estado === 'Aprobado' ? 'text-green-600' : 'text-red-600'}`}>
                          {grade.estado}
                        </td>
                        <td className="p-3 border-t border-gray-300">{new Date(grade.fecha_registro).toLocaleDateString()}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Grades;
