import useInscripcion from './useInscripcion';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEditar } from '../../buttons/BtnEditar';
import { SeleccionHorario } from '../grupos/SeleccionHorario';
import { AbrirModal } from '../../components/AbrirModal';
import { Direcciones } from '../../models/Direcciones';
import { useAuth } from '../../contexts/DataContext';
import { useEffect } from 'react';
import { BtnEliminar } from '../../buttons/BtnEliminar';

const Inscripcion = () => {
    const { getUser } = useAuth();
    const {
        showModal,
        handleClose,
        handleContinue,
        handleCancel,
        datoSeleccionado,
        handleChange,
        departamentos,
        municipios,
        openModal,
        direccionParticipante,
        errorTelefonoFijo,
        direccionEncargado,
        errorTelefonoMovil,
        fuentes,
        fuentesSeleccionadas,
        handleChangeFuentes,
        validarCamposInscripcion,
        handleChangeDirecciones,
        direccionSeleccionada,
        departamentosDir,
        municipiosDir,
        validarCampos,
        idCurso
    } = useInscripcion();

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            <div className="container-fluid pt-3 mt-custom" >
                <div className="card">
                    <div className="card-header bg-color3 text-color1">
                        <h2 className="text-left mb-2 small">Ficha de registro</h2>
                    </div>
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
                                <label htmlFor="codigo" className="form-label">Código:</label>
                                <input type="text" id="codigo" value={(datoSeleccionado.codigo) ? datoSeleccionado.codigo : ''} name="codigo" onChange={handleChange} className="form-control" />
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 mb-3">
                                <label htmlFor="expediente" className="form-label">Expediente:</label>
                                <input type="text" id="expediente" value={(datoSeleccionado.expediente) ? datoSeleccionado.expediente : ''} name="expediente" onChange={handleChange} className="form-control" />
                            </div>
                        </div>
                        {/* DEFINIR LUGAR Y FECHA DE NACIMIENTO*/}
                        <div className="mb-3">
                            <strong>Lugar y fecha de nacimiento</strong>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-4 col-md-3 col-lg-3">
                                <label htmlFor="departamentoNacimiento" className="form-label">Departamento</label>
                                <select
                                    id='departamentoNacimiento'
                                    onChange={handleChange}
                                    value={(datoSeleccionado.departamentoNacimiento) ? datoSeleccionado.departamentoNacimiento : ''}
                                    className="form-select form-select-md mb-3"
                                    name="departamentoNacimiento"
                                    required
                                >
                                    {departamentos.length > 0 ? (
                                        departamentos.map(dep => (
                                            <option key={dep.idDepartamento} value={dep.idDepartamento}>
                                                {dep.departamento}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>No hay departamentos disponibles</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-sm-4 col-md-3 col-lg-3">
                                <label htmlFor="municipioNacimiento" className="form-label">Municipio</label>
                                <select
                                    id='municipioNacimiento'
                                    onChange={handleChange}
                                    value={(datoSeleccionado.municipioNacimiento) ? datoSeleccionado.municipioNacimiento : ''}
                                    className="form-select form-select-md mb-3"
                                    name="municipioNacimiento"
                                    required
                                >
                                    {municipios.length > 0 ? (
                                        municipios.map(muni => (
                                            <option key={muni.idMunicipio} value={muni.idMunicipio}>
                                                {muni.municipio}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>No hay municipios disponibles</option>
                                    )}
                                </select>
                            </div>

                            <div className="col-sm-4 col-md-3 col-lg-3 mb-3">
                                <label htmlFor="fechaNacimiento" className="form-label">Fecha de Nacimiento:</label>
                                <input type="date" id="fechaNacimiento" value={(datoSeleccionado.fechaNacimiento) ? datoSeleccionado.fechaNacimiento : ''} name="fechaNacimiento" onChange={handleChange} className="form-control" />
                            </div>
                        </div>
                        {/*DATOS PERSONALES*/}
                        <div className="mb-3">
                            <strong>Datos Personales</strong>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                                <label htmlFor="nombres" className="form-label">Nombres:</label>
                                <input type="text" id="nombres" value={(datoSeleccionado.nombres) ? datoSeleccionado.nombres : ''} name="nombres" onChange={handleChange} className="form-control" />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                                <label htmlFor="apellidos" className="form-label">Apellidos:</label>
                                <input type="text" id="apellidos" value={(datoSeleccionado.apellidos) ? datoSeleccionado.apellidos : ''} name="apellidos" onChange={handleChange} className="form-control" />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                                <label htmlFor="dui" className="form-label">DUI:</label>
                                <input type="text" id="dui" value={(datoSeleccionado.dui) ? datoSeleccionado.dui : ''} name="dui" className="form-control" onChange={handleChange} />
                                {//errors.dui && <div className="text-danger">{errors.dui}</div>
                                }
                            </div>

                            <div className="col-sm-6 col-md-5 col-lg-3 mb-3">
                                <label htmlFor="nit" className="form-label">NIT:</label>
                                <input type="text" id="nit" value={(datoSeleccionado.nit) ? datoSeleccionado.nit : ''} name="nit" className="form-control" onChange={handleChange} />
                                {//errors.nit && <div className="text-danger">{errors.nit}</div>
                                }
                            </div>
                            <div className="col-sm-4 col-md-4 col-lg-4 mb-3">
                                <label htmlFor="carnetMinoridad" className="form-label">Carnet de Minoridad:</label>
                                <input type="text" id="carnetMinoridad" value={(datoSeleccionado.carnetMinoridad) ? datoSeleccionado.carnetMinoridad : ''} name="carnetMinoridad" className="form-control" onChange={handleChange} />
                                {//errors.carnetMinoridad && <div className="text-danger">{errors.carnetMinoridad}</div>
                                }
                            </div>
                            <br></br>
                            <div className="col-9 col-sm-6 col-md-3 col-lg-6 mb-3">
                                <label htmlFor="nie" className="form-label">NIE:</label>
                                <input type="text" id="nie" value={(datoSeleccionado.nie) ? datoSeleccionado.nie : ''} name="nie" className="form-control" onChange={handleChange} />
                                {//errors.nie && <div className="text-danger">{errors.nie}</div>
                                }
                            </div>
                            <br></br>
                            <div className="col-3 col-sm-2 col-md-2 col-lg-2 mb-3">
                                <label htmlFor="edad" className="form-label">Edad:</label>
                                <input type="text" disabled id="edad" value={(datoSeleccionado.edad) ? datoSeleccionado.edad : ''} name="edad" className="form-control" />
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-6 mb-3">
                                <label htmlFor="genero" className="form-label">Género:</label>
                                <select id="genero" name="genero" onChange={handleChange} className="form-select" value={datoSeleccionado.genero}>
                                    {datoSeleccionado.genero ? (
                                        <>
                                            <option value={datoSeleccionado.genero}>
                                                {datoSeleccionado.genero === 'masculino' ? 'MASCULINO' : 'FEMENINO'}
                                            </option>
                                            <option value={datoSeleccionado.genero === 'masculino' ? 'femenino' : 'masculino'}>
                                                {datoSeleccionado.genero === 'masculino' ? 'FEMENINO' : 'MASCULINO'}
                                            </option>
                                        </>
                                    ) : (
                                        <>
                                            <option value="">Seleccione---</option>
                                            <option value="masculino">MASCULINO</option>
                                            <option value="femenino">FEMENINO</option>
                                        </>
                                    )}
                                </select>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-6 mb-3">
                                <label htmlFor="estadoFamiliar" className="form-label">Estado Civil:</label>
                                <select id="estadoFamiliar" name="estadoFamiliar" onChange={handleChange} className="form-select" value={datoSeleccionado.estadoFamiliar}>
                                    {datoSeleccionado.estadoFamiliar ? (
                                        <>
                                            <option value={datoSeleccionado.estadoFamiliar}>
                                                {datoSeleccionado.estadoFamiliar === 'soltero' ? 'SOLTERO/A' :
                                                    datoSeleccionado.estadoFamiliar === 'casado' ? 'CASADO/A' :
                                                        datoSeleccionado.estadoFamiliar === 'acompaniado' ? 'ACOMPAÑADO/A' :
                                                            'DIVORCIADO/A'}
                                            </option>
                                            {datoSeleccionado.estadoFamiliar !== 'soltero' && <option value="soltero">SOLTERO/A</option>}
                                            {datoSeleccionado.estadoFamiliar !== 'casado' && <option value="casado">CASADO/A</option>}
                                            {datoSeleccionado.estadoFamiliar !== 'acompaniado' && <option value="acompaniado">ACOMPAÑADO/A</option>}
                                            {datoSeleccionado.estadoFamiliar !== 'divorciado' && <option value="divorciado">DIVORCIADO/A</option>}
                                        </>
                                    ) : (
                                        <>
                                            <option value="">Seleccione---</option>
                                            <option value="soltero">SOLTERO/A</option>
                                            <option value="casado">CASADO/A</option>
                                            <option value="acompaniado">ACOMPAÑADO/A</option>
                                            <option value="divorciado">DIVORCIADO/A</option>
                                        </>
                                    )}
                                </select>
                            </div>
                        </div>
                        {/* APARTADO PARA DOMICILIO*/}
                        <div className="mb-3">
                            <strong>Datos de Domicilio</strong>
                        </div>
                        <div className="row mb-3">
                            <div className="container">
                                <div className="row mb-3">
                                    <div className="col-md-12 d-flex">
                                        <div className="flex-grow-1">
                                            <label htmlFor="direccionParticipante" className="form-label">Direccion Participante.</label>
                                            <textarea disabled id="direccionParticipante" value={datoSeleccionado.direccionParticipante ? datoSeleccionado.direccionParticipante : ''} name="direccionParticipante" onChange={handleChange} className="form-control"></textarea>
                                        </div>
                                        <div className="ms-2 align-self-end">
                                            {(datoSeleccionado.direccionParticipante) ?
                                                <BtnEditar databstogle="modal" databstarget="#modalDirecciones" onclick={() => openModal(1, Direcciones(datoSeleccionado.dirParticipante, datoSeleccionado.muParticipante, datoSeleccionado.comParticipante, datoSeleccionado.depParticipante))} titulo={"Cambiar Direccion"} />
                                                :
                                                <BtnAgregar databstogle="modal" databstarget="#modalDirecciones" onclick={() => openModal(1, Direcciones(datoSeleccionado.dirParticipante, datoSeleccionado.muParticipante, datoSeleccionado.comParticipante, datoSeleccionado.depParticipante))} titulo={"Agregar Direccion"} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                <label htmlFor="telefonoFijo" className="form-label">Teléfono Fijo:</label>
                                <input
                                    type="text"
                                    id="telefonoFijo"
                                    name="telefonoFijo"
                                    className={`form-control ${errorTelefonoFijo ? 'is-invalid' : ''}`}
                                    onChange={handleChange}
                                    value={(datoSeleccionado.telefonoFijo) ? datoSeleccionado.telefonoFijo : ''}

                                />
                                {errorTelefonoFijo && <div className="invalid-feedback">{errorTelefonoFijo}</div>}
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                <label htmlFor="telefono" className="form-label">Teléfono Móvil:</label>
                                <input
                                    type="text"
                                    id="telefono"
                                    name="telefono"
                                    className={`form-control ${errorTelefonoMovil ? 'is-invalid' : ''}`}
                                    onChange={handleChange}
                                    value={(datoSeleccionado.telefono) ? datoSeleccionado.telefono : ''}

                                />
                                {errorTelefonoMovil && <div className="invalid-feedback">{errorTelefonoMovil}</div>}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                <label htmlFor="correo" className="form-label">E-mail:</label>
                                <input disabled type="text" id="correo" value={(datoSeleccionado.correo) ? datoSeleccionado.correo : ''} name="correo" onChange={handleChange} className="form-control" />
                            </div>
                        </div>
                        {/* APARTADO PARA DEFINIR PROFESION U OFICIO*/}
                        <div className="mb-3">
                            <strong>Profesión u Oficio</strong>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-5 mb-3">
                                <label htmlFor="profesion" className="form-label">¿Se dedica a alguna profesión u oficio?</label>
                                <div className="col-3 col-sm-2 col-md-4 col-lg-3">
                                    <select id="profesion" name="profesion" className="form-select" onChange={handleChange} value={datoSeleccionado.profesion}>
                                        <option value="no">No</option>
                                        <option value="si">Sí</option>
                                    </select>
                                </div>
                            </div>
                            {datoSeleccionado.profesion === 'si' && (
                                <div className="col-md-7 mb-3">
                                    <label htmlFor="ocupacion" className="form-label">¿A que profesion u oficio se dedica?</label>
                                    <input type="text" id="ocupacion" value={datoSeleccionado.ocupacion || ''} onChange={handleChange} name="ocupacion" className="form-control" />
                                </div>
                            )}
                        </div>

                        {/* APARTADO PARA DEFINIR SI ES ALERGICO A ALGUN INGREDIENTE */}
                        <div className="mb-3">
                            <strong>Alergico al contacto con ingredientes</strong>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-5 mb-3">
                                <label htmlFor="alergico" className="form-label">¿Presenta algun tipo de alergias?</label>
                                <div className="col-3 col-sm-2 col-md-4 col-lg-3">
                                    <select id="alergico" name="alergico" className="form-select" onChange={handleChange} value={datoSeleccionado.alergico} >
                                        <option value="no">No</option>
                                        <option value="si">Sí</option>
                                    </select>
                                </div>
                            </div>
                            {(datoSeleccionado.alergico === 'si') && (
                                <div className="col-md-7 mb-3">
                                    <label htmlFor="padecimiento" className="form-label">¿A que ingrediente es alergico?</label>
                                    <input type="text" id="padecimiento" value={(datoSeleccionado.padecimiento) ? datoSeleccionado.padecimiento : ''} onChange={handleChange} name="padecimiento" className="form-control" />
                                </div>
                            )}
                        </div>

                        <div className="mb-3">
                            <strong>IMPORTANTE: Porfavor brinde informacion de 1 persona, en caso que necesitemos comunicarnos con usted</strong>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-8 col-md-8 col-lg-8 mb-3">
                                <label htmlFor="nombresContacto" className="form-label">Nombre Completo:</label>
                                <input type="text" id="nombresContacto" onChange={handleChange} value={datoSeleccionado.nombresContacto ? datoSeleccionado.nombresContacto : ''} name="nombresContacto" className="form-control" />
                            </div>
                            <div className="col-sm-4 col-md-4 col-lg-4 mb-3">
                                <label htmlFor="parentesco" className="form-label">Parentesco:</label>
                                <input type="text" id="parentesco" onChange={handleChange} value={datoSeleccionado.parentesco} name="parentesco" className="form-control" />
                            </div>
                            <div className="container">
                                <div className="row mb-3">
                                    <div className="col-md-12 d-flex">
                                        <div className="flex-grow-1">
                                            <label htmlFor="direccionEncargado" className="form-label">Direccion Encargado.</label>
                                            <textarea disabled id="direccionEncargado" value={datoSeleccionado.direccionEncargado ? datoSeleccionado.direccionEncargado : ''} name="direccionEncargado" onChange={handleChange} className="form-control"></textarea>
                                        </div>
                                        <div className="ms-2 align-self-end">
                                            {(datoSeleccionado.direccionParticipante) ?
                                                <BtnEditar databstogle="modal" databstarget="#modalDirecciones" onclick={() => openModal(2, Direcciones(datoSeleccionado.dirContacto, datoSeleccionado.muContacto, datoSeleccionado.comContacto, datoSeleccionado.depContacto))} titulo={"Cambiar Direccion"} />
                                                :
                                                <BtnAgregar databstogle="modal" databstarget="#modalDirecciones" onclick={() => openModal(2, Direcciones(datoSeleccionado.dirContacto, datoSeleccionado.muContacto, datoSeleccionado.comContacto, datoSeleccionado.depContacto))} titulo={"Agregar Direccion"} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                <label htmlFor="telFijoContacto" className="form-label">Tel fijo:</label>
                                <input type="text" id="telFijoContacto" onChange={handleChange} value={datoSeleccionado.telFijoContacto} name="telFijoContacto" className="form-control" />
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
                                <label htmlFor="telefonoContacto" className="form-label">Tel movil:</label>
                                <input type="text" id="telefonoContacto" onChange={handleChange} value={datoSeleccionado.telefonoContacto} name="telefonoContacto" className="form-control" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                                <label htmlFor="correoContacto" className="form-label">E-mail:</label>
                                <input type="text" id="correoContacto" onChange={handleChange} value={datoSeleccionado.correoContacto} name="correoContacto" className="form-control" />
                            </div>
                        </div>

                        <div className="mb-3">
                            <strong>¿Cómo se enteró de la capacitacion?</strong>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                {fuentes.length > 0 ? (
                                    fuentes.map((fuente) => (
                                        <div key={fuente.idFuente} className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="comoSeEntero"
                                                id={`fuente-${fuente.idFuente}`}
                                                value={fuente.fuente}
                                                checked={fuentesSeleccionadas.includes(fuente.idFuente)}
                                                onChange={handleChangeFuentes}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={`fuente-${fuente.idFuente}`}
                                            >
                                                {fuente.fuente}
                                            </label>
                                        </div>
                                    ))
                                ) : (
                                    <p>No hay fuentes disponibles.</p>
                                )}
                            </div>
                        </div>
                        <div className="mt-custom d-flex">
                            <div className='p-2 flex-grow-1'></div>
                            <div className="p-2">
                                <BtnAgregar add={'mb-3'} onclick={validarCamposInscripcion} titulo={'Continuar Inscripcion'} />
                            </div>
                        </div>
                    </div >
                    <div className="modal fade" id="modalDirecciones" tabIndex="-1" aria-labelledby="modalDireccionesLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="modalDireccionesLabel">Gestionar dirección</h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        id='btnCerrarModal'
                                    ></button>
                                </div>
                                <div className="container mt-2 mb-2">
                                    <div className="card">
                                        <div className="card-header" style={{ backgroundColor: '#5561B8', color: 'white' }}>
                                            Dirección
                                        </div>
                                        <div className="card-body">
                                            <div className="row justify-content-center mb-3">
                                                <div className="col-md-6">
                                                    <label htmlFor="idDepartamento" className="form-label">Departamento</label>
                                                    <select
                                                        id='idDepartamento'
                                                        onChange={handleChangeDirecciones}
                                                        value={direccionSeleccionada.idDepartamento || ''}
                                                        className="form-select form-select-lg mb-3"
                                                        name="idDepartamento"
                                                        required
                                                    >
                                                        <option value="">Seleccione un departamento</option>
                                                        {departamentosDir ? departamentosDir.map(dep => (
                                                            <option key={dep.idDepartamento} value={dep.idDepartamento}>
                                                                {dep.departamento}
                                                            </option>
                                                        )) : null}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="idMunicipio" className="form-label">Municipio</label>
                                                    <select
                                                        id='idMunicipio'
                                                        onChange={handleChangeDirecciones}
                                                        value={direccionSeleccionada.idMunicipio || ''}
                                                        className="form-select form-select-lg mb-3"
                                                        name="idMunicipio"
                                                        required
                                                    >
                                                        <option value="">Seleccione un municipio</option>
                                                        {municipiosDir ? municipiosDir.map(muni => (
                                                            <option key={muni.idMunicipio} value={muni.idMunicipio}>
                                                                {muni.municipio}
                                                            </option>
                                                        )) : null}
                                                    </select>
                                                </div>
                                                <div className="container">
                                                    <div className="row mb-3">
                                                        <div className="col-md-12 d-flex">
                                                            <div className="flex-grow-1">
                                                                <label htmlFor="complemento" className="form-label">Complemento: Canton, Calle, Pasaje, etc.</label>
                                                                <textarea
                                                                    id="complemento"
                                                                    value={direccionSeleccionada.complemento || ''}
                                                                    name="complemento"
                                                                    onChange={handleChangeDirecciones}
                                                                    className="form-control"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 text-md-end">
                                                            <hr />
                                                            <BtnEliminar add={'me-2'} type='button' titulo={'Cancelar'} databsdismiss={'modal'} />
                                                            <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AbrirModal show={showModal}
                handleContinue={handleContinue}
                handleCancel={handleCancel}
                handleClose={handleClose}
                titulo={"Seleccionar Horario"}
                ocultar={true}>
                <SeleccionHorario idCurso={idCurso} idParticipante={datoSeleccionado.idParticipante} />
            </AbrirModal>
        </>

    );
}
export { Inscripcion };