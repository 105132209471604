import { GoogleLogin } from 'react-google-login';


function LoginButton({onSuccess, onFailure}) {

    return (
        <>
            <GoogleLogin
                className={'col-md-8'}
                clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE} 
                buttonText={'Iniciar sesion con google'}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiesPolicy={'single_host_origin'}
                isSignedIn={
                    false}
                prompt={"select_account"}
            />
        </>
    );
}

export { LoginButton };