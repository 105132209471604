import React, { useState, useEffect } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarPrincipal from './../../NavPrincipal/NavbarPrincipal';
import VerticalMenu from './../VerticalMenu';
import Footer from '../../Footer';
import IdentificationForm from './IdentificationForm';
import GeneralInfoForm from './GeneralInfoForm';
import AcademicInfoForm from './AcademicInfoForm';
import EmergencyContactForm from './EmergencyContactForm';
import SourceInfoForm from './SourceInfoForm';
import CareerSelectionForm from './CareerSelectionForm';
import WaitingRoom from './WaitingRoom'
import { API_URL, GET_FACULTADES, GET_CARRERAS, BEARER_TOKEN } from '../../../config';

const Profile = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [estado, setEstado] = useState(null);
  const [tipoInscripcion, setTipoInscripcion] = useState(null);
  const [exonerado, setExonerado] = useState(null);

  const [isPaid, setIsPaid] = useState(false);

  // Estado de los pasos y los datos del estudiante
  const [step, setStep] = useState(1);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [studentData, setStudentData] = useState({
    primer_nombre: '', segundo_nombre: '', primer_apellido: '', segundo_apellido: '',
    nacionalidad: '', dui: '', dui_responsable: '', fecha_nacimiento: '', genero: '',
    direccion: '', departamento: '', distrito: '', municipio: '', zona: '',
    telefono_movil: '', telefono_fijo: '', lugar_trabajo: '', lugar_trabajo_direccion: '',
    telefono_trabajo: '', nombre_padre: '', direccion_padre: '', telefono_fijo_padre: '',
    telefono_movil_padre: '', nombre_madre: '', direccion_madre: '', telefono_fijo_madre: '',
    telefono_movil_madre: '', titulo_bachillerato: '', institucion_bachillerato: '',
    anio_graduacion_bachillerato: '', titulo_superior: '', institucion_superior: '',
    anio_graduacion_superior: '', contacto_emergencia: '', parentesco: '', dui_contacto: '',
    telefono_movil_contacto: '', telefono_fijo_contacto: '', lugar_trabajo_contacto: '',
    fuente_informacion: '', facultad: '', carrera_estudio: '', codigo_carrera: ''
  });
  const [enabledRoutes, setEnabledRoutes] = useState({
    inscriptionProcess: true,
    profile: true,
    schedulesAndGroups: false,
    payment: false,
    grades: false,
  });

  // Estados para opciones de facultades y carreras
  const [facultades, setFacultades] = useState([]);
  const [carreras, setCarreras] = useState([]);
  const [allCarreras, setAllCarreras] = useState([]);

  // Efecto para cargar facultades y carreras

  useEffect(() => {
    const fetchFacultades = async () => {
      try {
        const response = await axios.get(`${API_URL}/aux/facultades`);
        const facultadesData = response.data.data;

        // Filtrar para excluir la última facultad
        const filteredFacultades = facultadesData.slice(0, -1).map(facultad => ({
          value: facultad.IDFacultad,
          label: facultad.FacultadNombre
        }));

        setFacultades(filteredFacultades);
      } catch (error) {
        console.error('Error fetching facultades:', error);
      }
    };



    const fetchCarreras = async () => {
      try {
        const response = await axios.get(`${API_URL}/aux/carreras`);
        setAllCarreras(response.data.data);
      } catch (error) {
        console.error('Error fetching carreras:', error);
      }
    };

    fetchFacultades();
    fetchCarreras();
  }, []);


  useEffect(() => {
    const fetchStudentData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');

        // Obtener estado
        const statusResponse = await axios.get(`${API_URL}/student/get-status`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setEstado(statusResponse.data.estado);

        // Obtener tipo de inscripción
        const inscripcionResponse = await axios.get(`${API_URL}/student/get-inscripcion`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTipoInscripcion(inscripcionResponse.data.tipoInscripcion);

        // Obtener exonerado
        const exoneradoResponse = await axios.get(`${API_URL}/student/get-exonerate-state`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setExonerado(exoneradoResponse.data.exonerado);

      } catch (error) {
        console.error("Error obteniendo los datos del estudiante:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentData();
  }, []);

  useEffect(() => {
    const checkEstadoPago = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/student/estado-pago`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.estado_pago === 1) {

          setIsPaid(true);

          const routes = {
            inscriptionProcess: true, // Siempre habilitado
            profile: true, // Siempre habilitado
            schedulesAndGroups: true,
            payment: true,
            grades: true, // Habilitado solo si el pago está completo
          };

          setEnabledRoutes(routes);
        }
      } catch (error) {
        console.error('Error al obtener el estado de pago:', error);
      }
    };

    checkEstadoPago();
  }, []);

  // Manejo de selección de facultades y carreras
  const handleFacultadChange = (selectedFacultad) => {
    console.log("Selected Facultad:", selectedFacultad); // Ver qué datos se reciben en selectedFacultad

    setStudentData(prevData => ({
      ...prevData,
      facultad: selectedFacultad.label,
      carrera_estudio: ''
    }));

    const filteredCarreras = allCarreras
      .filter(carrera =>
        carrera.IDFacultad === selectedFacultad.value &&
        !carrera.CarreraNombre.toLowerCase().includes('especialidad') &&
        !carrera.CarreraNombre.toLowerCase().includes('profesorado')
      )
      .map(carrera => ({
        value: carrera.IDCarrera,
        label: carrera.CarreraNombre,
        codigo: carrera.Codigo,
        facultad: carrera.IDFacultad
      }));

    console.log("Filtered Carreras:", filteredCarreras); // Ver qué campos devuelve cada carrera filtrada y mapeada

    setCarreras(filteredCarreras);
  };


  const handleSelectChange = (selectedOption) => {
    setStudentData(prevData => ({ ...prevData, carrera_estudio: selectedOption.label, codigo_carrera: selectedOption.codigo }));
  };

  // Funciones para avanzar y retroceder entre pasos
  const handleNextStep = () => setStep(prevStep => prevStep + 1);
  const handlePreviousStep = () => setStep(prevStep => prevStep - 1);

  /*
  Tipo Inscripción casos (tipoInscripcion):
      1.Inscripción normal (form_completo) (maestria,educacion,ciencia_datos)

          (casos retirados y comentados)
      2.Ingreso con Curso Preu de otra Universidad (form_exonerado_completo)
      3.Ingreso de estudiantes que comenzaron una carrera en otra universidad  (form_exonerado_completo)
      
      4.Ingreso de estudiantes con carreras(ahora exonerado general)(form_exonerado_completo)
      5.Equivalencias Externas (ahora equivalencias general)(equivalencias_externas)
  
  */

  const submitStudentData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(`${API_URL}/student/complete`, studentData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {

        setShowSuccessModal(true);  //Activa el modal de éxito si el envío fue exitoso
      }
    } catch (error) {
      console.error("Error al enviar los datos del estudiante:", error);
    }
  };

  const handleCloseSuccessModal = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const res = await axios.get(`${API_URL}/student/get-inscripcion`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Convierte tipoInscripcion a minúsculas
      const tipoInscripcion = res.data.tipoInscripcion.toString().toLowerCase();

      // Manejo de estado según tipo de inscripción
      if (tipoInscripcion === '1') {
        await axios.put(`${API_URL}/student/update-status`, {
          estado: "form_completo"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });

      } else if (['2', '3', '4'].includes(tipoInscripcion)) {
        await axios.put(`${API_URL}/student/update-status`, {
          estado: "form_exonerado_completo"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });

      } else if (tipoInscripcion === '5') {
        await axios.put(`${API_URL}/student/update-status`, {
          estado: "equivalencias_externas"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }

      const carreraSeleccionada = studentData.carrera_estudio.toLowerCase();

      if (carreraSeleccionada.includes('maestria')) {
        await axios.put(`${API_URL}/student/update-status`, {
          estado: "maestria"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else if (['lenguaje', 'matematica', 'ciencias naturales', 'deportes'].some(term => carreraSeleccionada.includes(term))) {
        await axios.put(`${API_URL}/student/update-status`, {
          estado: "educacion"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else if (carreraSeleccionada.includes('ciencia de datos')) {
        await axios.put(`${API_URL}/student/update-status`, {
          estado: "ciencia_datos"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }
      const statusResponse = await axios.get(`${API_URL}/student/get-status`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const estadoActualizado = statusResponse.data.estado;

      setTimeout(() => {
        if (estadoActualizado === 'form_completo') {
          navigate('/schedules-and-groups');
        } else if (estadoActualizado === 'ciencia_datos') {
          navigate('/payment');
        } else {
          navigate('/inscription-process');
        }
      }, 100);

    } catch (error) {
      console.error("Error en handleCloseSuccessModal:", error);
    } finally {
      setLoading(false);
      setShowSuccessModal(false);
    }
  };

  useEffect(() => {
    const checkEstadoPago = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/student/estado-pago`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.estado_pago === 1) {

          const routes = {
            inscriptionProcess: true, // Siempre habilitado
            profile: true, // Siempre habilitado
            schedulesAndGroups: true,
            payment: true,
            grades: true, // Habilitado solo si el pago está completo
          };

          setEnabledRoutes(routes);
        }
      } catch (error) {
        console.error('Error al obtener el estado de pago:', error);
      }
    };

    checkEstadoPago();
  }, []);


  return (
    <div className="flex flex-col min-h-screen">
      <NavbarPrincipal />
      <div className="flex flex-grow bg-gray-100">
        <VerticalMenu enabledRoutes={enabledRoutes} />
        <div className="flex items-center justify-center flex-1 p-6 pl-20">

          {(estado === 'Pendiente') ? (
            <>
              {step === 1 && <IdentificationForm studentData={studentData} setStudentData={setStudentData} handleNextStep={handleNextStep} />}
              {step === 2 && <GeneralInfoForm studentData={studentData} setStudentData={setStudentData} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
              {step === 3 && <AcademicInfoForm studentData={studentData} setStudentData={setStudentData} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
              {step === 4 && <EmergencyContactForm studentData={studentData} setStudentData={setStudentData} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
              {step === 5 && <SourceInfoForm studentData={studentData} setStudentData={setStudentData} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}
              {step === 6 && (
                <CareerSelectionForm
                  studentData={studentData}
                  setStudentData={setStudentData}
                  facultades={facultades}
                  carreras={carreras}
                  handleFacultadChange={handleFacultadChange}
                  handleSelectChange={handleSelectChange}
                  handlePreviousStep={handlePreviousStep}
                  setShowSuccessModal={setShowSuccessModal}
                  submitStudentData={submitStudentData}
                />
              )}
            </>
          ) : (
            <div className="mt-6">
              <div className="mt-6">
                  <WaitingRoom estado={estado} tipoInscripcion={tipoInscripcion} exonerado={exonerado} />
                </div>
            </div>
          )}

        </div>
      </div>

      {/* Modal de confirmación de registro exitoso */}
      {showSuccessModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
          <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
            <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">Registro de Estudiante Exitoso</h2>
            <p className="mb-6 text-center text-gray-600">Su registro ha sido completado exitosamente.</p>
            <div className="flex justify-center">
              <button onClick={handleCloseSuccessModal} className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none">
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Profile;
