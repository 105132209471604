import { useState } from "react";
import { useFetch } from "../../tools/useFetch";
import { FuenteInformacion } from "../../models/FuenteInformacion";

const useFuenteInformacion = () => {

  //ORDENAR CONSTANTES
  const { getFetch } = useFetch();
  const [fuentesInformacion, setFuentesInformacion] = useState([]);

  //ORDENAR GETS
  const getFuentesByIdParticipante = (id) => {
    const urlParcial = `fuentes/${id}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        const datosEstandarizados = datos.map(item =>
          FuenteInformacion(
            item.idFuenteInformacion || 0,
            item.idParticipante || 0,
            item.idFuente || 0,
          )
        );
        setFuentesInformacion(datosEstandarizados);
      })
      .catch((error) => {
        console.log("Error->", error);
      });
  };

  return {
    fuentesParticipante: fuentesInformacion,
    getFuentesByIdParticipante
  }
}

export { useFuenteInformacion }