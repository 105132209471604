import React from 'react'

function BarraBusqueda({ placeholder, value, onChange }) {
    return (
        <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className="form-control mb-3"
        />
    )
}

export { BarraBusqueda }