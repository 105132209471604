import React, { useState } from 'react';

const EmergencyContactForm = ({
  studentData,
  setStudentData,
  handleNextStep,
  handlePreviousStep,
}) => {
  const [errors, setErrors] = useState({});

  // Validación de los campos, incluyendo DUI y teléfonos
  const validate = () => {
    const newErrors = {};
    if (!studentData.contacto_emergencia.trim()) {
      newErrors.contacto_emergencia = 'El contacto de emergencia es requerido';
    }
    if (!studentData.parentesco.trim()) {
      newErrors.parentesco = 'El parentesco es requerido';
    }
    if ((!/^\d{8}-\d$/.test(studentData.dui_contacto))) {
      newErrors.dui_contacto = 'El DUI debe tener el formato ########-#';
    }
    if (!/^\d{4} \d{4}$/.test(studentData.telefono_movil_contacto)) {
      newErrors.telefono_movil_contacto = 'El teléfono móvil debe tener el formato 0000 0000';
    }
    if (studentData.telefono_fijo_contacto && !/^\d{4} \d{4}$/.test(studentData.telefono_fijo_contacto)) {
      newErrors.telefono_fijo_contacto = 'El teléfono fijo debe tener el formato 0000 0000';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Maneja cambios en los campos del formulario y aplica formato
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Formato de teléfonos y DUI
    if (name === 'telefono_movil_contacto' || name === 'telefono_fijo_contacto') {
      formattedValue = value.replace(/\D/g, '');
      if (formattedValue.length > 4) {
        formattedValue = formattedValue.slice(0, 4) + ' ' + formattedValue.slice(4, 8);
      }
    } else if (name === 'dui_contacto') {
      formattedValue = value.replace(/\D/g, '');
      if (formattedValue.length > 8) {
        formattedValue = formattedValue.slice(0, 8) + '-' + formattedValue.slice(8, 9);
      }
    }

    setStudentData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  // Avanza al siguiente paso si la validación es exitosa
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-2xl p-8 space-y-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center text-sky-700">Contacto de Emergencia</h1>

      <div className="space-y-4">
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">Contacto de Emergencia<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="contacto_emergencia"
            placeholder="Juanito Alcachofa"
            onChange={handleChange}
            value={studentData.contacto_emergencia}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.contacto_emergencia && <p className="text-red-600">{errors.contacto_emergencia}</p>}
        </div>

        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">Parentesco del Contacto de Emergencia<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="parentesco"
            placeholder="Ej. Hermano"
            onChange={handleChange}
            value={studentData.parentesco}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.parentesco && <p className="text-red-600">{errors.parentesco}</p>}
        </div>

        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">DUI del Contacto<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="dui_contacto"
            placeholder="00000000-0"
            onChange={handleChange}
            value={studentData.dui_contacto}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
            maxLength={10}
          />
          {errors.dui_contacto && <p className="text-red-600">{errors.dui_contacto}</p>}
        </div>

        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">Teléfono Móvil del Contacto<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="telefono_movil_contacto"
            placeholder="#### ####"
            onChange={handleChange}
            value={studentData.telefono_movil_contacto}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.telefono_movil_contacto && <p className="text-red-600">{errors.telefono_movil_contacto}</p>}
        </div>

        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">Teléfono Fijo del Contacto</label>
          <input
            type="text"
            name="telefono_fijo_contacto"
            placeholder="#### ####"
            onChange={handleChange}
            value={studentData.telefono_fijo_contacto}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.telefono_fijo_contacto && <p className="text-red-600">{errors.telefono_fijo_contacto}</p>}
        </div>

        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">Lugar de Trabajo del Contacto</label>
          <input
            type="text"
            name="lugar_trabajo_contacto"
            placeholder="Lugar de Trabajo del Contacto"
            onChange={handleChange}
            value={studentData.lugar_trabajo_contacto}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
        </div>
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="px-4 py-2 text-lg font-semibold text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none"
        >
          Atrás
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-lg font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
        >
          Siguiente
        </button>
      </div>
    </form>
  );
};

export default EmergencyContactForm;
