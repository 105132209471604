import React, { useState } from "react";
import Navbar from '../NavIndex/Navbar'; 
import BackgroundImage from '../../assets/images/Instalaciones/EdificioC.JPG';
import LoginForm from '../Login/LoginForm';
import RegisterForm from '../Login/RegisterForm';

const Home = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('login');

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="flex flex-col h-screen">
            <Navbar />
            {/* Inicio */}
            <div 
                className="relative flex flex-col items-center justify-center flex-grow bg-center bg-cover"
                style={{ backgroundImage: `url(${BackgroundImage})` }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative max-w-5xl px-8 py-16 text-center bg-white rounded-lg shadow-2xl bg-opacity-80 backdrop-blur-md">
                    <h1 className="mb-4 text-4xl font-extrabold md:text-5xl text-darkbluetxt">
                        Bienvenido a la <span className="italic text-lightgreentxt">USO</span>
                    </h1>
                    <p className="mb-8 text-lg font-light text-gray-800 md:text-xl">
                        Donde tu futuro comienza hoy. A través de este portal, podrás realizar todo tu proceso de admisión de manera sencilla y rápida.
                    </p>
                    
                    <div className="flex flex-col justify-center space-y-4 md:space-y-0 md:flex-row md:space-x-4">
                        {/* Botón para iniciar sesión */}
                        <button 
                            onClick={() => {
                                setActiveTab('login');
                                setIsModalOpen(true);
                            }} 
                            className="px-6 py-3 text-lg font-semibold text-white transition duration-300 transform rounded-lg shadow-md bg-lightgreentxt hover:scale-105 focus:outline-none"
                        >
                            Iniciar Sesión
                        </button>

                        {/* Botón para registrarse */}
                        <button 
                            onClick={() => {
                                setActiveTab('register');
                                setIsModalOpen(true);
                            }} 
                            className="px-6 py-3 text-lg font-semibold text-white transition duration-300 transform rounded-lg shadow-md bg-bluelight hover:scale-105 focus:outline-none"
                        >
                            Regístrate
                        </button>
                    </div>
                </div>
            </div>

            {/* Modal de inicio de sesión/registro */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center p-4 overflow-y-auto bg-gray-900 bg-opacity-50">
                    <div className="relative w-full max-w-md p-8 bg-white rounded-lg shadow-lg md:max-w-lg lg:max-w-xl">
                        <button
                            className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700 focus:outline-none"
                            onClick={closeModal}
                        >
                            ✕
                        </button>
                        <div className="relative flex justify-center p-2 mb-4">
                            <div className="relative flex items-center p-1 bg-gray-200 rounded-lg" style={{ height: '54px' }}>
                                <div
                                    className={`absolute inset-y-0 left-0 w-1/2 bg-lightgreentxt rounded-lg transition-transform duration-500 ease-out transform ${
                                        activeTab === 'login' ? 'translate-x-0' : 'translate-x-full'
                                    }`}
                                ></div>
                                <button
                                    className={`relative z-10 py-2 px-8 text-lg font-semibold rounded-lg transition-transform duration-300 focus:outline-none ${
                                        activeTab === 'login' 
                                        ? 'text-white' 
                                        : 'text-gray-400 hover:text-lightgreentxt'
                                    }`}
                                    onClick={() => setActiveTab('login')}
                                    style={{ height: '100%' }}
                                >
                                    Iniciar Sesión
                                </button>
                                <button
                                    className={`relative z-10 py-2 px-8 text-lg font-semibold rounded-lg transition-transform duration-300 focus:outline-none ${
                                        activeTab === 'register' 
                                        ? 'text-white' 
                                        : 'text-gray-400 hover:text-lightgreentxt'
                                    }`}
                                    onClick={() => setActiveTab('register')}
                                    style={{ height: '100%' }}
                                >
                                    Regístrate
                                </button>
                            </div>
                        </div>
                        <div className="pt-4 overflow-y-auto max-h-[70vh]">
                            {activeTab === 'login' && <LoginForm />}
                            {activeTab === 'register' && <RegisterForm closeParentModal={closeModal} />}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
