import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/DataContext';

function PrivateRoutes({ hasRole: rol }) {
    const { user, getUser } = useAuth();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            if (!user) {
                await getUser();
            }
            setIsLoading(false);
        };

        fetchUser();
    }, []);

    if (isLoading) {
        // Mostrar indicador de carga mientras se verifica el estado del usuario
        if(location.key === 'default'){
            return <div>Loading...</div>;
        }
        
    }

    if (!user) {
        // Redirigir al login si no hay usuario autenticado
        return <Navigate to='/login' state={{ from: location }} />;
    }

    if (rol && user[0]?.rol !== rol) {
        // Redirigir si el usuario no tiene el rol necesario
        return <Navigate to='/' />;
    }
    
    // Mostrar el contenido protegido si todo está en orden
    return <Outlet />;
}

export { PrivateRoutes };
