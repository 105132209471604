import React from 'react'

const BtnAgregar = ({ add = '', databstogle, databstarget, titulo, onclick, type=''}) => {
    
    return (
        <>
            <button type={type} data-bs-toggle={databstogle} data-bs-target={databstarget} onClick={onclick}  className={'btn bg-color4 hAgregar text-color1 ' + add}>{titulo}</button>
        </>
    )
}

export { BtnAgregar }