import { TimePicker } from '@material-ui/pickers';
import React, { useState, useEffect } from 'react';

function HorariosModal({ cursoSeleccionado, horarioSeleccionado, handleChange, diasSeleccionados, handleButtonClick }) {
    const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

    const convertToTimePickerFormat = (timeString) => {
        if (timeString instanceof Date) {
            return timeString;
        }
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, seconds, 0);
        return date;
    };

    return (
        <form className="row g-3 needs-validation" noValidate>
            <div className="col-md-1 d-none">
                <label htmlFor="txtIdGrupo" className="form-label">IDGRUPO</label>
                <input
                    id='txtIdGrupo'
                    onChange={handleChange}
                    value={(horarioSeleccionado) ? horarioSeleccionado.idGrupo ? horarioSeleccionado.idGrupo : '' : ''}
                    type="number"
                    className="form-control"
                    name="idGrupo"
                    disabled
                />
            </div>
            <div className="col-md-1">
                <label htmlFor="txtIdHorario" className="form-label">ID</label>
                <input
                    id='txtIdHorario'
                    onChange={handleChange}
                    value={(horarioSeleccionado) ? horarioSeleccionado.idHorario ? horarioSeleccionado.idHorario : '' : ''}
                    type="number"
                    className="form-control"
                    name="idHorario"
                    disabled
                />
            </div>
            <div className="col-md-4">
                <label htmlFor="txtDiaInicio" className="form-label">Día Inicio</label>
                <input
                    id='txtDiaInicio'
                    onChange={handleChange}
                    value={(horarioSeleccionado) ? horarioSeleccionado.diaInicio ? horarioSeleccionado.diaInicio.split('T')[0] : '' : ''}
                    type="date"
                    className="form-control"
                    name="diaInicio"
                    required
                    min={(cursoSeleccionado) ? (cursoSeleccionado.fechaInicio) ? cursoSeleccionado.fechaInicio.split('T')[0] : "" : ""}  // Fecha mínima permitida
                    max={(cursoSeleccionado) ? (cursoSeleccionado.fechaFinalizacion) ? cursoSeleccionado.fechaFinalizacion.split('T')[0] : "" : ""}
                />
            </div>
            <div className="col-md-6">
                <label htmlFor="txtDiaFin" className="form-label">Día Fin</label>
                <input
                    id='txtDiaFin'
                    onChange={handleChange}
                    value={(horarioSeleccionado) ? (horarioSeleccionado.diaFin) ? horarioSeleccionado.diaFin.split('T')[0] : '' : ''}
                    type="date"
                    className="form-control"
                    name="diaFin"
                    required
                    min={(cursoSeleccionado) ? (cursoSeleccionado.fechaInicio) ? cursoSeleccionado.fechaInicio.split('T')[0] : "" : ""}  // Fecha mínima permitida
                    max={(cursoSeleccionado) ? (cursoSeleccionado.fechaFinalizacion) ? cursoSeleccionado.fechaFinalizacion.split('T')[0] : "" : ""}
                />
            </div>
            <div className="col-md-5">
                <label htmlFor="txtHoraInicio" className="form-label">Hora Inicio</label>
                <input
                    type='time'
                    className='form-control'
                    onChange={handleChange}
                    value={horarioSeleccionado && horarioSeleccionado.horaInicio
                        ? horarioSeleccionado.horaInicio
                        : ''
                    }
                    name='horaInicio' />
                {/*<TimePicker
                    className="form-control"
                    onChange={(e) => handleChange(e, 'horaInicio')}
                    value={
                        horarioSeleccionado && horarioSeleccionado.horaInicio
                            ? convertToTimePickerFormat(horarioSeleccionado.horaInicio)
                            : new Date()
                    }
                    format="hh:mm a"
                />*/}
            </div>
            <div className="col-md-6">
                <label htmlFor="txtHoraFin" className="form-label">Hora Fin</label>
                <input
                    type='time'
                    className='form-control'
                    onChange={handleChange}
                    value={horarioSeleccionado && horarioSeleccionado.horaFin
                        ? horarioSeleccionado.horaFin
                        : ''
                    }
                    name='horaFin' />
                {/*<TimePicker
                    className="form-control"
                    onChange={(e) => handleChange(e, 'horaFin')}
                    value={
                        horarioSeleccionado && horarioSeleccionado.horaFin
                            ? convertToTimePickerFormat(horarioSeleccionado.horaFin)
                            : new Date()
                    }
                    format="hh:mm a"
                />*/}
            </div>
            <div className="col-md-12">
                <label className="form-label">Días</label>
                <div className="d-flex flex-wrap">
                    {diasSemana.map(dia => (
                        <button
                            key={dia}
                            type="button"
                            style={{
                                backgroundColor: diasSeleccionados.includes(dia) ? '#2B7A78' : 'transparent',
                                color: diasSeleccionados.includes(dia) ? 'white' : '#2B7A78',
                                border: '2px solid #2B7A78',
                                borderRadius: '5px',
                                margin: '0.5rem',
                                padding: '0.5rem 1rem',
                                cursor: 'pointer',
                                outline: 'none'
                            }}
                            onClick={() => handleButtonClick(dia)}
                        >
                            {dia}
                        </button>
                    ))}
                </div>
            </div>
        </form>

    );
}

export { HorariosModal };
