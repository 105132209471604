import React from 'react';
const AsignarNotas = ({ selectedName, selectedNota, setSelectedNota }) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <span className="search-text">Nombre de alumno:</span>
                </div>
                <div className="col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        value={selectedName}
                        readOnly
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <span className="search-text">Nota:</span>
                </div>
                <div className="col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        value={selectedNota}
                        onChange={(e) => setSelectedNota(e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
};

export { AsignarNotas };
