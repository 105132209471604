import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarPrincipal from "../NavPrincipal/NavbarPrincipal";
import VerticalMenu from "./VerticalMenu";
import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Select from "react-select";

const CourseAndGroupSelection = () => {
  const [cursos, setCursos] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedCurso, setSelectedCurso] = useState(null);
  const [selectedGrupo, setSelectedGrupo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [id, setId] = useState(null);
  const [isInscrito, setIsInscrito] = useState(false);
  const [tipoInscripcion, setTipoInscripcion] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [enabledRoutes, setEnabledRoutes] = useState({
    inscriptionProcess: true,
    profile: false,
    schedulesAndGroups: true,
    payment: false,
    grades: false,
  });

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCursos = async () => {
      try {
        const response = await axios.get(`${API_URL}/courses/active`);
        const formattedCursos = response.data.map((curso) => ({
          value: curso.idCursos,
          label: curso.nombre,
        }));
        setCursos(formattedCursos);
      } catch (error) {
        setError("Error al obtener los cursos");
        console.error(error);
      }
    };
    fetchCursos();
  }, []);

  useEffect(() => {
    const fetchTipoInscripcion = async () => {
      try {
        const response = await axios.get(`${API_URL}/student/get-inscripcion`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTipoInscripcion(response.data.tipoInscripcion);
      } catch (error) {
        console.error("Error al obtener el tipo de inscripción:", error);
      }
    };

    fetchTipoInscripcion();
  }, [token]);

  useEffect(() => {
    const verificarEstadoEstudiante = async () => {
      try {
        const response = await axios.get(`${API_URL}/student/get-status`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (
          response.data.estado === "Inscrito" ||
          response.data.estado === "Completo"
        ) {
          setIsInscrito(true);
        }
      } catch (error) {
        console.error("Error al obtener el estado del estudiante:", error);
      }
    };

    verificarEstadoEstudiante();
  }, [token]);

  useEffect(() => {
    const fetchIdEstudiante = async () => {
      try {
        const response = await axios.get(`${API_URL}/student/find-id`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setId(response.data.id);
      } catch (error) {
        console.error("Error al obtener el ID", error);
        setError("Error al obtener el ID del estudiante");
      }
    };
    fetchIdEstudiante();
  }, [token]);

  const handleCursoChange = async (selectedOption) => {
    const cursoId = selectedOption.value;
    setSelectedCurso(selectedOption);
    setSelectedGrupo(null);
    setGrupos([]);
    setError("");
    setSuccess(false);
    setLoading(true);

    try {
      const response = await axios.get(
        `${API_URL}/groups/by-course/${cursoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedGrupos = response.data.map((grupo) => ({
        value: grupo.idGrupos,
        label: `${grupo.nombre} - ${grupo.hora_inicio} a ${grupo.hora_fin}`,
      }));
      setGrupos(formattedGrupos);
    } catch (error) {
      setError("Error al obtener los grupos");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCurso || !selectedGrupo) {
      setError("Debe seleccionar un curso y un grupo");
      return;
    }
    setShowConfirmModal(true); // Muestra el modal de confirmación
  };

  const confirmEnrollment = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);
    try {
      await axios.post(
        `${API_URL}/enrollment/create`,
        {
          estudiante_id: id,
          grupo_id: selectedGrupo.value,
          fecha_inscripcion: new Date().toISOString().split("T")[0],
          notas_id: null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      await axios.put(
        `${API_URL}/student/update-status`,
        {
          estado: "Inscrito",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSuccess(true);
      setSelectedCurso(null);
      setSelectedGrupo(null);
      setGrupos([]);

      navigate("/payment");
    } catch (error) {
      console.error("Error al inscribir al estudiante:", error);
      setError(
        "Error al inscribir al estudiante: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
    setShowConfirmModal(true);
  };

  useEffect(() => {
    const checkEstadoPago = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${API_URL}/student/estado-pago`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.estado_pago === 1) {
          const routes = {
            inscriptionProcess: true,
            profile: true,
            schedulesAndGroups: true,
            payment: true,
            grades: true,
          };
          setEnabledRoutes(routes);
        }
      } catch (error) {
        console.error("Error al obtener el estado de pago:", error);
      }
    };

    checkEstadoPago();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <NavbarPrincipal />
      <div className="flex flex-grow bg-gray-100">
        <VerticalMenu enabledRoutes={enabledRoutes} />
        <div className="flex items-center justify-center flex-1 p-6 pl-20">
          {tipoInscripcion === "1" ? (
            <div>
              {isInscrito ? (
                <div className="w-full max-w-md p-6 space-y-4 bg-white rounded-lg shadow-md">
                  <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
                    Ya estás Inscrito
                  </h2>
                  <p className="mb-4 text-center text-gray-600">
                    Tu inscripción ya ha sido completada. Ahora puedes proceder
                    con el siguiente paso.
                  </p>
                  <div className="flex justify-center">
                    <button
                      onClick={() => navigate("/payment")}
                      className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              ) : (
                <form
                  onSubmit={handleSubmit}
                  className="w-full max-w-md p-6 space-y-4 bg-white rounded-lg shadow-md"
                >
                  <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">
                    Selección de Curso y Grupo
                  </h1>
                  <div className="mb-4">
                    <label
                      htmlFor="curso"
                      className="block mb-2 text-sm font-semibold text-gray-700"
                    >
                      Selecciona un Curso
                    </label>
                    <Select
                      options={cursos}
                      onChange={handleCursoChange}
                      value={selectedCurso}
                      placeholder="Selecciona un curso"
                      isDisabled={isInscrito || loading}
                    />
                  </div>
                  {loading && (
                    <p className="text-center text-blue-500">
                      Cargando grupos...
                    </p>
                  )}
                  {grupos.length > 0 && (
                    <div className="mb-4">
                      <label
                        htmlFor="grupo"
                        className="block mb-2 text-sm font-semibold text-gray-700"
                      >
                        Selecciona un Grupo
                      </label>
                      <Select
                        options={grupos}
                        onChange={(option) => setSelectedGrupo(option)}
                        value={selectedGrupo}
                        placeholder="Selecciona un grupo"
                        isDisabled={isInscrito || loading}
                      />
                    </div>
                  )}

                  {error && (
                    <p className="p-2 text-center text-red-600 bg-red-100 rounded-md">
                      {error}
                    </p>
                  )}
                  {success && (
                    <p className="p-2 text-center text-green-600 bg-green-100 rounded-md">
                      Inscripción realizada con éxito
                    </p>
                  )}
                  <button
                    type="submit"
                    className="px-4 py-2 text-lg font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
                    disabled={loading || isInscrito}
                  >
                    Inscribir
                  </button>
                </form>
              )}
            </div>
          ) : (
            <div className="w-full max-w-md p-6 space-y-4 bg-white rounded-lg shadow-md">
              <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
                Proceso Inecesario
              </h2>
              <p className="text-center text-gray-600">
                Tu proceso no requiere la selección de curso y grupo. Si tienes
                dudas, contacta con la Unidad de Atención al Estudiante.
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
      {/* Modal de confirmación */}
      {showConfirmModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
          <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
            <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">
              Confirmación
            </h2>
            <p className="mb-6 text-center text-gray-600">
              ¿Estás seguro de que deseas inscribirte en el curso y grupo
              seleccionados?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={confirmEnrollment}
                className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
              >
                Confirmar
              </button>
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseAndGroupSelection;
