import axios from 'axios';
import {API_URL} from '../config';

const DOCENTE_URL = `${API_URL}/docentes`;

const createDocente = (docenteData) => {
  return axios.post(`${DOCENTE_URL}/create`, docenteData);
};

const getDocentes = () => {
  return axios.get(`${DOCENTE_URL}/all`);
};

const updateDocente = (id, docenteData) => {
  return axios.put(`${DOCENTE_URL}/update/${id}`, docenteData);
};

const deleteDocente = (id) => {
  return axios.delete(`${DOCENTE_URL}/delete/${id}`);
};

export default { createDocente, getDocentes, updateDocente, deleteDocente };
