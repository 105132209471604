
function TipoCurso(idTipoCurso = 0, tipo = '', imagen = '', descripcion = '') {
    return {
        idTipoCurso, 
        tipo, 
        imagen, 
        descripcion
    }
}

export { TipoCurso }