import React, { useState, useEffect } from 'react';

const KitsEntregados = ({ inscritoSelecionado, kits, setData }) => {
  // Inicializar formData solo una vez, no es necesario actualizarlo en useEffect
  const [formData, setFormData] = useState({
    idInscripcion: inscritoSelecionado.idInscripcion || "",
    idEntregaKit: inscritoSelecionado.idEntregaKit || "",
    idKit: inscritoSelecionado.idKit || "",
    talla: inscritoSelecionado.talla || "",
    entregado: inscritoSelecionado.entregado ?? 0, // Asegurarse de que sea 0 o 1
    fechaEntrega: inscritoSelecionado.fechaEntrega || "",
  });

  // Actualizar formData solo cuando inscritoSelecionado cambie
  useEffect(() => {
    setFormData({
      idInscripcion: inscritoSelecionado.idInscripcion || "",
      idEntregaKit: inscritoSelecionado.idEntregaKit || "", // Valor predeterminado si no está presente
      idKit: inscritoSelecionado.idKit || "",
      talla: inscritoSelecionado.talla || "",
      entregado: inscritoSelecionado.entregado ?? 0,
      fechaEntrega: inscritoSelecionado.fechaEntrega || "",
    });
  }, [inscritoSelecionado]);  

  // Manejar los cambios en los inputs
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;

    // Actualizar el formData localmente
    const updatedFormData = { ...formData, [name]: newValue };
    setFormData(updatedFormData);

    // Actualizar setData globalmente
    setData(updatedFormData); // Aquí estamos pasando el formData actualizado
  };

  return (
    <div>
      {/* Fila para ID de Inscripción y selección de Kit */}
      <div className="row mb-3">
        <div className="col-md-2 d-flex flex-column align-items-start"> {/* Alineación a la izquierda */}
          <label htmlFor="txtIdInscripcion" className="form-label">ID</label>
          <input
            id="txtIdInscripcion"
            value={formData.idInscripcion}
            type="text"
            className="form-control"
            name="idInscripcion"
            readOnly
          />
        </div>
        <div className="col-md-10 d-flex flex-column align-items-start"> {/* Alineación a la izquierda */}
          <label htmlFor="kitsSelect" className="form-label">Kits:</label>
          <select
            id="kitsSelect"
            value={formData.idKit}
            className="form-select"
            name="idKit"
            onChange={handleChange}
            required
          >
            <option value="">Selecciona un kit</option>
            {kits.map(kit => (
              <option key={kit.idKit} value={kit.idKit}>{kit.kit}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Fila para Talla y checkbox de Entregado */}
      <div className="row mb-3">
        <div className="col-md-6 d-flex flex-column align-items-start"> {/* Alineación a la izquierda */}
          <label htmlFor="txtTalla" className="form-label">Talla</label>
          <input
            id="txtTalla"
            value={formData.talla}
            type="text"
            className="form-control"
            name="talla"
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-3 d-flex align-items-center justify-content-start"> {/* Alineación a la izquierda */}
          <div className="form-check">
            <input
              id="chkEntregado"
              checked={formData.entregado === 1}
              type="checkbox"
              className="form-check-input"
              name="entregado"
              onChange={handleChange}
            />
            <label htmlFor="chkEntregado" className="form-check-label ms-2">Entregado</label>
          </div>
        </div>
      </div>

      {/* Fila para Fecha de Entrega */}
      <div className="row">
        <div className="col-md-12 d-flex flex-column align-items-start"> {/* Alineación a la izquierda */}
          <label htmlFor="txtFechaEntrega" className="form-label">Fecha de Entrega</label>
          <input
            id="txtFechaEntrega"
            value={formData.fechaEntrega}
            type="date"
            className="form-control"
            name="fechaEntrega"
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </div>
  );

};

export { KitsEntregados };
