import React from 'react';
import { useListadoCodigoServicio } from '../codigo_servicio/useListadoCodigoDeServicio';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

const ListaCodigoServicio = ({ idDepartamento }) => {
  const {
    codigosServicio,
    handleChange,
    titulo,
    codigoSeleccionado,
    openModal,
    deleteOnClick,
    validarCampos
  } = useListadoCodigoServicio(idDepartamento);

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1"><h1>Códigos de Servicio</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="modal" databstarget="#modalCodigosServicio" onclick={() => openModal(1)} titulo={"Agregar Código de Servicio"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Código</th>
              <th scope="col">Concepto</th>
              <th scope="col">Exigible</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {codigosServicio ? codigosServicio.map((codigo) => (
              <tr key={codigo.idCodigoServicio}>
                <th scope="row">{codigo.idCodigoServicio}</th>
                <td>{codigo.codigo}</td>
                <td>{codigo.concepto}</td>
                <td>{(codigo.exigible === 1) ? 'Si' : 'No'}</td>
                <td>
                  <BtnEditar databstogle="modal" databstarget="#modalCodigosServicio" onclick={() => openModal(2, codigo)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteOnClick(codigo.idCodigoServicio)} titulo={"Eliminar"} />
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>

      <div className="modal fade" id="modalCodigosServicio" tabIndex="-1" aria-labelledby="modalCodigosServicioLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalCodigosServicioLabel">{titulo}</h1>
              <button
                type="button"
                id='btnCerrarModal'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                  <label htmlFor="txtIdCodigoServicio" className="form-label">ID</label>
                  <input
                    id='txtIdCodigoServicio'
                    onChange={handleChange}
                    value={codigoSeleccionado.idCodigoServicio}
                    type="number"
                    className="form-control"
                    name="idCodigoServicio"
                    disabled
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="txtCodigo" className="form-label">Código</label>
                  <input
                    id='txtCodigo'
                    onChange={handleChange}
                    value={codigoSeleccionado.codigo}
                    type="text"
                    className="form-control"
                    name="codigo"
                    required
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="txtConcepto" className="form-label">Concepto</label>
                  <input
                    id='txtConcepto'
                    onChange={handleChange}
                    value={codigoSeleccionado.concepto}
                    type="text"
                    className="form-control"
                    name="concepto"
                    required
                  />
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="exigible"
                    id="chkExigible"
                    value={codigoSeleccionado.exigible}
                    checked={codigoSeleccionado.exigible}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="chkExigible"
                  >
                    Exigible
                  </label>
                </div>
                <div className="col-md-12 text-md-end">
                  <hr />
                  <BtnEliminar add={'me-2'} type='button' titulo={'Cancelar'} databsdismiss={'modal'} />
                  <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ListaCodigoServicio };
