import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { CodigoServicio } from '../../models/CodigoServicio';

const useListadoCodigoServicio = () => {
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, preguntarConfirmacion } = useAlert();

  // Constantes
  const [codigosServicio, setCodigosServicio] = useState([]);
  const [codigoSeleccionado, setCodigoSeleccionado] = useState(CodigoServicio());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();

  // useEffect
  useEffect(() => {
    getCodigosServicio();
  }, []);

  // Funciones
  const openModal = (op, codigoSeleccionado = CodigoServicio()) => {
    setCodigoSeleccionado(codigoSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Código de Servicio' : 'Editar Código de Servicio');
  };

  const validarCampos = () => {
    if (!codigoSeleccionado.codigo || codigoSeleccionado.codigo.trim() === '') {
      mensajeAdvertencia("Código vacío!");
      return;
    }
    if (!codigoSeleccionado.concepto || codigoSeleccionado.concepto.trim() === '') {
      mensajeAdvertencia("Concepto vacío!");
      return;
    }
    if (codigoSeleccionado.exigible === undefined) {
      mensajeAdvertencia("Campo exigible vacío!");
      return;
    }

    if (operacion === 1) {
      postOnClick(codigoSeleccionado);
    } else {
      const id = codigoSeleccionado.idCodigoServicio;
      putOnClick(id, codigoSeleccionado);
    }

    document.getElementById('btnCerrarModal')?.click();
  };

  // GET
  const getCodigosServicio = () => {
    const urlParcial = 'codigo_servicio/';
    getFetch(urlParcial)
      .then((data) => {
        const { datos, mensaje, estado } = data;
        if (estado) {
          // Mapea los datos para asegurarse de que todos tengan la estructura definida por CodigoServicio
          const datosEstandarizados = datos.map(item =>
            CodigoServicio(
              item.idCodigoServicio || 0,
              item.codigo || '',
              item.concepto || '',
              item.exigible || false
            )
          );
          setCodigosServicio(datosEstandarizados);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  // POST
  const postOnClick = (datos) => {
    const urlParcial = 'codigo_servicio/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getCodigosServicio();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  // PUT
  const putOnClick = (id, datos) => {
    const urlParcial = `codigo_servicio/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getCodigosServicio();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error->', error);
      });
  };

  // DELETE
  const deleteOnClick = async (id) => {
    const urlParcial = `codigo_servicio/${id}`;
    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );
    
    if (!confirmed){
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getCodigosServicio();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error->', error);
      });
  };

  // Handle Change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const valueToSet = type === 'checkbox' ? checked : value;
    setCodigoSeleccionado(codigoPrevio => ({ ...codigoPrevio, [name]: valueToSet }));
  };

  return {
    codigosServicio,
    getCodigosServicio,
    handleChange,
    titulo,
    openModal,
    codigoSeleccionado,
    deleteOnClick,
    validarCampos
  };
};

export { useListadoCodigoServicio };
