import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { Municipios } from '../../models/Municipios';

const useListadoMunicipios = (idDepartamento) => {

  //Todas las constantes
  const [municipios, setMunicipios] = useState([]);
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState(Municipios());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, mensajeInformacion, preguntarConfirmacion } = useAlert();


  //Los useEffect
  useEffect(() => {
    getMunicipios(idDepartamento);
  }, []);

  //Funciones
  const openModal = (op, municipioSeleccionado = Municipios()) => {
    setMunicipioSeleccionado(municipioSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Municipio' : 'Editar Municipio');
  };

  const validarCampos = () => {
    if (municipioSeleccionado.municipio.trim() === '') {
      mensajeAdvertencia("Debe seleccionar un municipio!");
    } else {
      municipioSeleccionado.idDepartamento = idDepartamento;
      if (operacion === 1) {
        postOnClick(municipioSeleccionado);
      } else {
        const id = municipioSeleccionado.idMunicipio;
        putOnClick(id, municipioSeleccionado);
      }
      document.getElementById('btnCerrarModal').click();
    }
  };

  //Todos los gets
  const getMunicipios = (idDepartamento) => {
    const urlParcial = `municipios/${idDepartamento}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos, mensaje, estado } = data;
        if (estado) {
          const datosEstandarizados = datos.map(item =>
            Municipios(
              item.idMunicipio,
              item.municipio,
              item.codigo
            )
          );
          setMunicipios(datosEstandarizados);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const postOnClick = (datos) => {
    const urlParcial = 'municipios/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getMunicipios(idDepartamento);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const putOnClick = (id, datos) => {
    const urlParcial = `municipios/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getMunicipios(idDepartamento);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const deleteOnClick = async (id) => {
    const urlParcial = `municipios/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );

    if (!confirmed) {
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getMunicipios(idDepartamento);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMunicipioSeleccionado(municipioPrevio => ({ ...municipioPrevio, [name]: value }));
  };

  return {
    municipios,
    getMunicipios,
    handleChange,
    titulo,
    openModal,
    municipioSeleccionado,
    deleteOnClick,
    validarCampos
  }
};

export { useListadoMunicipios };
