import React, { useState, useEffect } from 'react';
import NavbarPrincipal from './NavbarPrincipal';
import courseService from '../../services/courseService';

const AssignCourses = () => {
    const [courses, setCourses] = useState([]);
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
        fecha_inicio: '',
        fecha_fin: '',
        anio_curso: '',
    });
    const [editing, setEditing] = useState(false);
    const [editId, setEditId] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = async () => {
        const response = await courseService.getCourses();
        setCourses(response.data);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (editing) {
            await courseService.updateCourse(editId, formData);
        } else {
            await courseService.createCourse(formData);
        }
        setFormData({
            nombre: '',
            descripcion: '',
            fecha_inicio: '',
            fecha_fin: '',
            anio_curso: '',
        });
        setEditing(false);
        setEditId(null);
        fetchCourses();
    };

    const handleEdit = (course) => {
        setFormData({
            nombre: course.nombre,
            descripcion: course.descripcion,
            fecha_inicio: course.fecha_inicio,
            fecha_fin: course.fecha_fin,
            anio_curso: course.anio_curso,
        });
        setEditing(true);
        setEditId(course.idCursos);
    };
    const openModal = (id) => {
        setDeleteId(id);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setDeleteId(null);
    };

    const handleDelete = async () => {
        await courseService.deleteCourse(deleteId);
        setShowModal(false);
        fetchCourses();
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <NavbarPrincipal />
            <div className="flex items-center justify-center flex-grow p-6">
                <div className="w-full max-w-4xl">
                    <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">Administrar Notas</h1>
                </div>
            </div>
        </div>
    );
};

export default AssignCourses;
