import axios from 'axios';
import { API_URL } from '../config';

const COURSE_URL = `${API_URL}/courses`;

const createCourse = (courseData) => {
  return axios.post(`${COURSE_URL}/create`, courseData);
};

const getCourses = () => {
  return axios.get(`${COURSE_URL}/all`);
};

const updateCourse = (id, courseData) => {
  return axios.put(`${COURSE_URL}/update/${id}`, courseData);
};

const deleteCourse = (id) => {
  return axios.delete(`${COURSE_URL}/delete/${id}`);
};

const updateCourseStatus = (id, {estado_activo}) => {
  console.log('id->', id);
  console.log('estadoActivo', estado_activo);
  return axios.put(`${COURSE_URL}/status/${id}`, { estado_activo: estado_activo });
};

export default { createCourse, getCourses, updateCourse, deleteCourse, updateCourseStatus };

