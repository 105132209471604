import { useEffect, useState } from 'react';
import { useListadoGrupos } from './useListadoGrupos';
import { useListaActividades } from '../actividades/useListaActividades';
import { useDetallesPlantillas } from '../detalles_plantillas/useDetallesPlantillas';
import { useCuentasPorCobrar } from '../cuentas_por_cobrar/useCuentasPorCobrar';
import useInscripcion from '../inscripciones/useInscripcion';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../hooks/useAlert';

const useSeleccionarHorario = (idCurso, idParticipante) => {

    //Todas las constantes
    const navegar = useNavigate();
    const { grupos, getGruposActivos } = useListadoGrupos();
    const [horariosAgrupados, setHorariosAgrupados] = useState([]);
    const { mensajeExitoso, mensajeError } = useAlert();
    const { actividades, getActividadesByCourse } = useListaActividades();
    const { postOnClick } = useInscripcion();
    const { getPlantillasByCicloAndCurso, detallesPlantillas } = useDetallesPlantillas();
    const { postCuentaPorCobrar } = useCuentasPorCobrar();

    //useEffect
    useEffect(() => {
        if (idCurso) {
            getGruposActivos(idCurso);
            getActividadesByCourse(idCurso);
        } else {
            mensajeError('ID del curso no está disponible. No se puede obtener grupos activos.');
        }
    }, [idCurso]);

    useEffect(() => {
        if (grupos) {
            const agruparHorarios = grupos.reduce((acc, curr) => {
                const {
                    idGrupo, grupo, modalidad, lugar, idCurso,
                    idUsuario, anio, activo,
                    diaInicio, diaFin, horaInicio, horaFin, dias
                } = curr;

                // Encontrar el grupo existente
                let existingGroup = acc.find(g => g.idGrupo === idGrupo);

                // Si no existe, crear uno nuevo
                if (!existingGroup) {
                    existingGroup = {
                        idGrupo,
                        grupo,
                        modalidad, // Puedes agregar otros campos si lo deseas
                        lugar,
                        idCurso,
                        idUsuario,
                        anio,
                        activo,
                        horarios: []
                    };
                    acc.push(existingGroup);
                }

                // Agregar el horario al grupo
                existingGroup.horarios.push({
                    dias,
                    horaInicio,
                    horaFin,
                    diaInicio,
                    diaFin
                });

                return acc;
            }, []);

            // Establecer el estado con los horarios agrupados
            setHorariosAgrupados(agruparHorarios);

        }
    }, [grupos]);

    useEffect(() => {
        // Aquí agregaremos si hay pagos que debe realizar el participante
        getPlantillasByCicloAndCurso(idCurso, 0);
    }, [idCurso]);

    //Funciones
    const handleOnClick = (idGrupo) => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Meses en JavaScript son de 0 a 11
        const day = String(today.getDate()).padStart(2, '0');
        const fechaInscripcion = `${year}-${month}-${day}`;

        const datosInscripcion = {
            activa: 1,
            fechaInscripcion: fechaInscripcion,
            idParticipante: idParticipante,
            idGrupo: idGrupo
        }
        
        if (detallesPlantillas.length > 0) {
            const datosCuentaPorCobrar = {
                codigo: detallesPlantillas.orden,
                idParticipante: idParticipante
            }
            
            postCuentaPorCobrar(datosCuentaPorCobrar, detallesPlantillas)
                .then(() => {
                    continuar(datosInscripcion, actividades); // Llamar a la función continuar después de que la promesa se resuelva
                })
                .catch((error) => {
                    mensajeError("Error al asignar detalles de cuentas por cobrar.", error); // Manejar el error correctamente
                });
        } else {
            continuar(datosInscripcion, actividades)
        }

    }

    const continuar = (datosInscripcion, actividades) => {
        // Inscribimos el participante al grupo seleccionado
        postOnClick(datosInscripcion, actividades)
            .then(() => {
                mensajeExitoso("Inscripcion exitosa.");
                navegar("/realizarpago");
            })
            .catch((error) => {
                mensajeError("Error insertar notas en las actividades.", error); // Manejar el error correctamente
            });

    }

    return {
        handleOnClick,
        horariosAgrupados
    };
};

export { useSeleccionarHorario };