import React from 'react';
import { GrupoPrincipal } from './GrupoPrincipal';
import { BtnEditar } from '../buttons/BtnEditar';
import DarFormatoFecha from '../hooks/DarFormatoFecha';

function CursoPrincipal({ curso, inscribirse, user }) {
    return (
        <div key={curso.idCurso} className="course-card">
            {curso.imagen ? (
                <img src={curso.imagen} alt="Imagen del curso" className="course-img" />
            ) : (
                <p>Imagen no disponible</p>
            )}
            <div className="custom-div">
                <h3>{curso.curso}</h3>
            </div>
            <div class="curso-detalles">
                <div class="detalle-item">
                    <strong>Fecha de inicio:</strong>
                    <span>{DarFormatoFecha(curso.fechaInicio)}</span>
                </div>
                <div class="detalle-item">
                    <strong>Fecha de finalización:</strong>
                    <span>{DarFormatoFecha(curso.fechaFinalizacion)}</span>
                </div>
                <div class="detalle-item">
                    <strong>Inversión:</strong>
                    <span>${curso.precio}</span>
                </div>
            </div>

            <div className="col-md-12 d-flex justify-content-center">
                <div className="col-12 col-md-8 text-center">
                    {curso.grupos.map((grupo) => (
                        <GrupoPrincipal key={grupo.idGrupo} grupo={grupo} />
                    ))}
                    <BtnEditar
                        add={(user && (user[0].rol === 'admin' || user[0].rol === 'instructor')) ? 'd-none' : 'col-md-6'}
                        onclick={() => inscribirse(curso.idCurso)}
                        titulo="Inscribirme"
                    />
                </div>
            </div>

        </div>

    );
}

export { CursoPrincipal };
