import React, { useEffect } from "react";
import Navbar from "./Navbar";
import BackgroundImage from "../../assets/images/Instalaciones/EdificioC.JPG";
import { SiMicrosoft } from "react-icons/si";
import { API_URL } from "../../config";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const HomeDocente = () => {
  const { loginWithOAuth2 } = useAuth(); // Asegúrate de que este método esté definido
  const navigate = useNavigate();

  useEffect(() => {
    // Capturar el token devuelto por el backend
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      loginWithOAuth2(token); // Guarda el token en el contexto
      navigate("/principal", { replace: true }); // Redirige a la ruta correcta
      window.location.reload(); // Forzar una recarga
    }
  }, [loginWithOAuth2, navigate]);
  

  const handleMicrosoftLogin = () => {
    window.location.href = `${API_URL}/auth/microsoft-login`; // Cambia a redirección directa
  };
  
  


  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      {/* Inicio */}
      <div
        className="relative flex flex-col items-center justify-center flex-grow bg-center bg-cover"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative px-8 py-20 text-center bg-white rounded-lg shadow-2xl max-w-10xl bg-opacity-80 backdrop-blur-md">
          <h1 className="mb-4 text-4xl font-extrabold md:text-5xl text-darkbluetxt">
            Bienvenido al portal Administrativo{" "}
            <span className="italic text-lightgreentxt">USO</span>
          </h1>
          <p className="mb-8 text-lg font-light text-gray-800 md:text-xl">
            Accede a tus herramientas y recursos como docente. Inicia sesión con
            tu cuenta institucional para continuar.
          </p>

          {/* Contenedor para centrar el botón */}
          <div className="flex justify-center">
            <button
              onClick={handleMicrosoftLogin}
              className="flex items-center justify-center px-6 py-3 text-lg font-semibold text-white transition duration-300 transform rounded-lg shadow-md bg-bluelight hover:scale-105 focus:outline-none"
            >
              <SiMicrosoft className="w-6 h-6 mr-2" />
              Inicia sesión con tu cuenta Microsoft
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDocente;
