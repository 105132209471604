import React from 'react'
import { useListaHorarios } from './useListaHorarios';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { useNavigate } from 'react-router-dom';

function ListaHorarios() {

    const {
        horarios,
        titulo,
        handleChange,
        openModal,
        deleteOnClick,
        grupo,
        handleChangeChk,
        daysOfWeek,
        horarioSeleccionado,
        validarCampos
    } = useListaHorarios();
    const navegar = useNavigate();

    return (
        <>
            <div className="mt-custom d-flex flex-wrap align-items-center">
                <div className='p-2 flex-grow-1'>
                    <h1>Horarios del grupo: {grupo}</h1>
                </div>
                <div className="p-2">
                    <BtnEliminar add={"m-2"} onclick={() => navegar(-1)} titulo={"Regresar"} />
                    <BtnAgregar add={"m-2"} databstogle="modal" databstarget="#modalHorarios" onclick={() => openModal(1)} titulo={"Agregar Horario"} />
                </div>
            </div>
            <div>
                <div className="table-responsive text-center">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col-1">ID</th>
                                <th scope="col-4">Dias</th>
                                <th scope="col-1">Del</th>
                                <th scope="col-1">Al</th>
                                <th scope="col-1">De</th>
                                <th scope="col-1">A</th>
                                <th scope="col-3">Acciones</th>
                            </tr>
                        </thead>

                        <tbody>
                            {horarios ? horarios.map((horario, index) => (
                                <tr key={index}>
                                    <td>  {horario.idHorario ? horario.idHorario : ""}
                                    </td>
                                    <td>
                                        {horario.dias ? horario.dias : ""}
                                    </td>
                                    <td>
                                        {horario.dias ? horario.diaInicio : ""}
                                    </td>
                                    <td>
                                        {horario.dias ? horario.diaFin : ""}
                                    </td>
                                    <td>
                                        {horario.dias ? horario.horaInicio : ""}
                                    </td>
                                    <td>
                                        {horario.dias ? horario.horaFin : ""}
                                    </td>
                                    <td>
                                        <BtnEliminar onclick={() => deleteOnClick(horario.idHorario)} titulo={"Eliminar"} />
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal fade" id="modalHorarios" tabIndex="-1" aria-labelledby="modalHorariosLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modalHorariosLabel">{titulo}</h1>
                            <button
                                type="button"
                                id='btnCerrarModalHorarios'
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form className="row g-3 needs-validation" noValidate>
                                <div className='row'>
                                    <div className="col-6">
                                        <label htmlFor="textDiaInicio" className="form-label">Del dia: </label>
                                        <input
                                            id='textDiaInicio'
                                            onChange={handleChange}
                                            type="number"
                                            className="form-control"
                                            name="diaInicio"
                                            value={horarioSeleccionado.diaInicio ? horarioSeleccionado.diaInicio : ""}
                                            min="1" // Valor mínimo permitido
                                            max="31" // Valor máximo permitido
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="txtDiaFin" className="form-label">Al dia:</label>
                                        <input
                                            id='txtDiaFin'
                                            onChange={handleChange}
                                            type="number"
                                            className="form-control"
                                            name="diaFin"
                                            value={horarioSeleccionado.diaFin ? horarioSeleccionado.diaFin : ""}
                                            min="1" // Valor mínimo permitido
                                            max="31" // Valor máximo permitido
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="form-label col-12">Seleccione los dias de la semana: </label>
                                    {daysOfWeek.map((day, index) => (
                                        <div className="col-4" key={index}>
                                            <input
                                                id={`chk${day}`}
                                                onChange={handleChangeChk}
                                                type="checkbox"
                                                className="form-check-input"
                                                name="diasSemana"
                                                value={day}
                                            />
                                            <label htmlFor={`chk${day}`} className="form-check-label">
                                                {day}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className='row'>
                                    <div className="col-6">
                                        <label htmlFor="texHoraInicio" className="form-label">De: </label>
                                        <input
                                            id='textHoraInicio'
                                            onChange={handleChange}
                                            type="time"
                                            className="form-control"
                                            name="horaInicio"
                                            value={horarioSeleccionado.horaInicio ? horarioSeleccionado.horaInicio : ""}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="txtHoraFin" className="form-label">A:</label>
                                        <input
                                            id='txtHoraFin'
                                            onChange={handleChange}
                                            type="time"
                                            className="form-control"
                                            name="horaFin"
                                            value={horarioSeleccionado.horaFin ? horarioSeleccionado.horaFin : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-md-end">
                                        <hr />
                                        <button type="button" className="btn btn-danger me-2" data-bs-dismiss="modal">Cancelar</button>
                                        <button type="button" className="btn btn-primary" onClick={validarCampos}>Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { ListaHorarios }