import React from 'react'
import { BtnEditar } from '../../buttons/BtnEditar'
import { BtnAgregar } from '../../buttons/BtnAgregar'
import { GestionGrupos } from '../grupos/GestionGrupos'
import { Grupos } from '../../models/Grupos'

function GestionCursos({ tipoCurso, curso, expandedCursoId, toggleCurso, openModalCursos, openModalActividades, openModalPlantillas, expandedGrupoId, toggleGrupo, openModalGrupos, openModalHorarios, deleteOnClick, idCiclo, calcularHoras, abrirModalSeleccion }) {
    const handleCombinedChange = () => {
        toggleCurso(curso.idCurso)
        openModalCursos(curso, true);//Solo establece curso seleccionado
    };
    return (
        <div key={curso.idCurso}>
            <div className="d-flex">
                <div className="p-2 flex-grow-1">
                    <BtnEditar
                        titulo={expandedCursoId === curso.idCurso ? '-' : '+'}
                        databstogle="collapse"
                        databstarget={`#collapseCurso${curso.idCurso}`}
                        onclick={handleCombinedChange}
                    />
                    {curso.curso + ' - Estado: ' + curso.estado}
                </div>
                <div className="p-2">
                    <BtnAgregar titulo="Asignar actividades" onclick={() => openModalActividades(curso.idCurso)} />
                    <BtnAgregar add={(tipoCurso === '3') ? "d-none" : "ms-2"} titulo="Asignar plantillas" onclick={() => openModalPlantillas(curso.idCurso, idCiclo)} />
                    <BtnEditar add={(tipoCurso === '3') ? "d-none" : "ms-2"} titulo="Duplicar plantillas" onclick={() => abrirModalSeleccion(true, curso.idCurso, curso.precio)} />
                    <BtnEditar add="ms-2" titulo="Editar curso" onclick={() => openModalCursos(curso, false)} />
                    <BtnAgregar add="ms-2" titulo="Agregar grupo" onclick={() => openModalGrupos(new Grupos(null, null, null, null, curso.idCurso, null))} />
                </div>
            </div>
            {expandedCursoId === curso.idCurso && (
                <div className="card card-body ms-5" id={`collapseCurso${curso.idCurso}`}>
                    <h3>Grupos:</h3>
                    {(curso.grupos.length > 0) ? curso.grupos.map((grupo) => (
                        <GestionGrupos
                            openModalGrupos={openModalGrupos}
                            openModalHorarios={openModalHorarios}
                            toggleGrupo={toggleGrupo}
                            expandedGrupoId={expandedGrupoId}
                            key={grupo.idGrupo} grupo={grupo}
                            deleteOnClick={deleteOnClick}
                            calcularHoras={calcularHoras}
                            curso={curso}
                        />
                    )) : null}
                </div>
            )}
        </div>
    )
}

export { GestionCursos }