import { BtnAgregar } from '../../buttons/BtnAgregar';
import DarFormatoFecha from '../../hooks/DarFormatoFecha';
import { useSeleccionarHorario } from './useSeleccionarHorario';

const SeleccionHorario = ({ idCurso, idParticipante }) => {
    const {
        handleOnClick,
        horariosAgrupados // Aquí es donde obtienes los horarios agrupados
    } = useSeleccionarHorario(idCurso, idParticipante);

    return (
        <div>
            {horariosAgrupados && horariosAgrupados.length > 0 ? (
                horariosAgrupados.map((grupo) => (
                    <div key={grupo.grupo} className="card bg-color1 m-3">
                        <div className="card-header d-flex">
                            <div className="p-2 flex-grow-1 card-title text-color5">
                                <h4>GRUPO - {grupo.grupo}</h4>
                            </div>
                            <div className="p-2">
                                <BtnAgregar titulo={'Seleccionar y finalizar'} onclick={() => handleOnClick(grupo.idGrupo)} />
                            </div>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title text-color5">MODALIDAD - {grupo.modalidad}</h5>
                            <h5 className="card-text text-color4">
                                Lugar: {grupo.lugar}<br />
                            </h5>
                            {grupo.horarios && grupo.horarios.length > 0 && (
                                <div className="row">
                                    {grupo.horarios.map((horario, index) => (
                                        <div key={index} className="mb-2 col-md-4">
                                            <p className="card-text text-color4">
                                                Días: {horario.dias}<br />
                                                De: {horario.horaInicio} A {horario.horaFin}<br />
                                                Del: {new Date(horario.diaInicio).toLocaleDateString('es-ES', { day: '2-digit', month: 'short' }) + " "}
                                                Al: {new Date(horario.diaFin).toLocaleDateString('es-ES', { day: '2-digit', month: 'short' })}
                    
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div>No hay grupos disponibles</div>
            )}
        </div>
    );
}

export { SeleccionHorario };
