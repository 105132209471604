import { useNavigate } from "react-router-dom";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../config";

const WaitingRoom = ({ estado, tipoInscripcion, exonerado }) => {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [errors, setErrors] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [isAdult, setIsAdult] = useState(false);

  const [facultades, setFacultades] = useState([]);
  const [allCarreras, setAllCarreras] = useState([]);
  const [filteredCarreras, setFilteredCarreras] = useState([]);

  const validateDUI = (dui) => {
    const regex = /^\d{8}-\d$/; // Formato ########-#
    return regex.test(dui);
  };

  // Normaliza el texto eliminando tildes y convirtiendo a mayúsculas
  const normalizeText = (text) => {
    return text
      .toUpperCase()
      .replace(/[á]/gi, "A")
      .replace(/[é]/gi, "E")
      .replace(/[í]/gi, "I")
      .replace(/[ó]/gi, "O")
      .replace(/[ú]/gi, "U");
  };

  useEffect(() => {
    const fetchFacultadesYCarreras = async () => {
      try {
        const token = localStorage.getItem("token");

        const [facultadesRes, carrerasRes] = await Promise.all([
          axios.get(`${API_URL}/aux/facultades`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${API_URL}/aux/carreras`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setFacultades(
          facultadesRes.data.map((fac) => ({
            value: fac.id,
            label: fac.nombre,
          }))
        );

        setAllCarreras(
          carrerasRes.data.map((car) => ({
            value: car.id,
            label: car.nombre,
            facultad: car.facultadId,
          }))
        );
      } catch (error) {
        console.error("Error al cargar facultades y carreras:", error);
      }
    };

    fetchFacultadesYCarreras();
  }, []);

  useEffect(() => {
    const fetchStudentData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${API_URL}/student/get-datos-perfil`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const student = response.data.student;
        if (!student)
          throw new Error("No se encontraron datos del estudiante.");

        setStudentData(student);
      
        setEditData({
          primer_nombre: student.primer_nombre || "",
          segundo_nombre: student.segundo_nombre || "",
          primer_apellido: student.primer_apellido || "",
          segundo_apellido: student.segundo_apellido || "",
          carrera_estudio: student.carrera_estudio || "",
          facultad: student.facultad || "",
          estado_pago: student.estado_pago || 0,
          anio_ingreso: student.anio_ingreso || "",
          estado: student.estado || "",
          tipo_inscripcion: student.tipo_inscripcion || "",
          expediente: student.expediente || "",
          codigo_estudiante: student.codigo_estudiante || "",
          correo_institucional: student.correo_institucional || "",
        });
        calculateAge(student.fecha_nacimiento);
      } catch (error) {
        console.error("Error al cargar datos del estudiante:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentData();
  }, []);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    const age = today.getFullYear() - birth.getFullYear();
    setIsAdult(age >= 18);
  };

  const handleChange = (field, value) => {
    let normalizedValue = value;

    // Aplicar normalización solo a los campos específicos
    if (
      [
        "primer_nombre",
        "segundo_nombre",
        "primer_apellido",
        "segundo_apellido",
      ].includes(field)
    ) {
      normalizedValue = normalizeText(value);
    }

    setEditData((prevData) => ({
      ...prevData,
      [field]: normalizedValue,
    }));

    if (field === "fecha_nacimiento") calculateAge(value);
    if (field === "facultad") {
      const carrerasFiltradas = allCarreras.filter(
        (carrera) => carrera.facultad === value
      );
      setFilteredCarreras(carrerasFiltradas);
      setEditData((prevData) => ({ ...prevData, carrera_estudio: "" })); // Resetear carrera
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!editData.primer_nombre?.trim())
      newErrors.primer_nombre = "El primer nombre es obligatorio.";
    if (!editData.segundo_nombre?.trim())
      newErrors.segundo_nombre = "El segundo nombre es obligatorio.";
    if (!editData.primer_apellido?.trim())
      newErrors.primer_apellido = "El primer apellido es obligatorio.";
    if (!editData.segundo_apellido?.trim())
      newErrors.segundo_apellido = "El segundo apellido es obligatorio.";
    if (!editData.fecha_nacimiento)
      newErrors.fecha_nacimiento = "La fecha de nacimiento es obligatoria.";

    if (isAdult && (!editData.dui || !validateDUI(editData.dui))) {
      newErrors.dui = "El DUI debe tener el formato ########-#.";
    }
    if (
      !isAdult &&
      (!editData.dui_responsable || !validateDUI(editData.dui_responsable))
    ) {
      newErrors.dui_responsable =
        "El DUI del responsable debe tener el formato ########-#.";
    }

    if (!editData.genero) newErrors.genero = "Seleccione un género.";
    if (!editData.nacionalidad?.trim())
      newErrors.nacionalidad = "La nacionalidad es obligatoria.";
    if (!editData.facultad) newErrors.facultad = "Seleccione una facultad.";
    if (!editData.carrera_estudio)
      newErrors.carrera_estudio = "Seleccione una carrera.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDUIChange = (field, value) => {
    // Remover cualquier carácter que no sea un número
    const numericValue = value.replace(/\D/g, "");

    // Formatear el DUI como ########-#
    let formattedValue = numericValue;
    if (numericValue.length > 8) {
      formattedValue = `${numericValue.slice(0, 8)}-${numericValue.slice(
        8,
        9
      )}`;
    }

    setEditData((prevData) => ({
      ...prevData,
      [field]: formattedValue,
    }));
  };

  const handleSave = async () => {
    if (!validate()) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/student/actualizar-datos-perfil`,
        { studentData: editData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setShowNotificationModal(true);
        setShowEditModal(false);
        setStudentData(editData);
      } else {
        throw new Error("Error al guardar los cambios.");
      }
    } catch (error) {
      console.error("Error al guardar:", error);
    }
  };

  if (loading) return <p>Cargando datos...</p>;

  const renderContent = () => {
    if (loading) {
      return <p>Cargando...</p>;
    }

    if (!studentData) {
      return <p>No se pudieron cargar los datos del estudiante.</p>;
    }

    if (estado === "form_completo") {
      return (
        <div>
          <h2 className="mb-4 text-xl font-semibold leading-relaxed text-center text-sky-700">
            Inscripción Completa
          </h2>
          <p className="mb-4 text-center text-gray-600">
            ¡Felicidades! Has completado exitosamente el proceso de registro.
            Ahora puedes proceder a consultar los horarios y grupos asignados
            para tus clases.
          </p>
          <div className="flex justify-center">
            {" "}
            {/* Contenedor para centrar el botón */}
            <button
              onClick={() => navigate("/schedules-and-groups")}
              className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
            >
              Ver Horarios y Grupos
            </button>
          </div>
        </div>
      );
    } else if (estado === "form_exonerado_completo") {
      return (
        <div>
          {/*Caso descartado */}
          {tipoInscripcion === "2" && (
            <div>
              <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
                Proceso de Exoneración en Curso
              </h2>
              <p className="mb-4 text-center text-gray-600">
                Si completaste algún proceso de ingreso en otra universidad, por
                favor acércate a la Unidad de Atención al Estudiante en nuestras
                instalaciones. Recuerda presentar un comprobante que respalde
                dicho proceso de ingreso en la institución anterior para validar
                tu solicitud de exoneración.
              </p>
              <div className="flex justify-center">
                {" "}
                {/* Contenedor para centrar el botón */}
                <button
                  onClick={() => navigate("/payment")}
                  disabled={!exonerado}
                  className={`px-6 py-2 text-lg font-semibold text-white rounded-lg ${
                    exonerado
                      ? "bg-lightgreentxt hover:bg-darkgreentxt"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                >
                  Ir a Pago
                </button>
              </div>
            </div>
          )}
          {/*Caso descartado */}
          {tipoInscripcion === "3" && (
            <div>
              <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
                Proceso de Equivalencias y Exoneración
              </h2>
              <p className="mb-4 text-gray-600">
                Como estudiante que ha iniciado una carrera en otra universidad,
                puedes elegir entre iniciar el proceso de equivalencias o seguir
                con la exoneración para empezar desde el inicio de la carrera.
                Selecciona el proceso que deseas continuar y comunícate con el
                decano correspondiente.
              </p>

              {/* Ingeniería */}
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-800">
                  Ingeniería
                </h3>
                <p className="mt-2 text-gray-600">
                  ¿Qué proceso deseas seguir?
                </p>

                <h4 className="mt-4 font-semibold text-gray-700">
                  Equivalencias
                </h4>
                <p className="mb-2 text-gray-600">
                  Para comenzar el proceso de equivalencias en la Facultad de
                  Ingeniería, comunícate con:
                </p>
                <p className="text-gray-600">
                  👉🏼 <strong>Lic. Carlos Alberto Martínez Zaragoza</strong>{" "}
                  <br />
                  📞 WhatsApp:{" "}
                  <a
                    href="https://wa.me/50376030352"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sky-600 hover:underline"
                  >
                    7603-0352
                  </a>
                </p>
                <p className="mt-2 text-gray-600">
                  Al comunicarte, te sugerimos presentarte con tu nombre
                  completo, especificar el nombre de la ingeniería de tu
                  interés, mencionar el nivel de estudios que posees y que ya
                  has completado el formulario de ingreso.
                </p>

                <h4 className="mt-4 font-semibold text-gray-700">
                  Exoneración
                </h4>
                <p className="text-gray-600">
                  Si decides iniciar la carrera desde el principio mediante la
                  exoneración, por favor preséntate en la Unidad de Atención al
                  Estudiante. Lleva un documento que valide tus estudios previos
                  o el inicio de estudios en otra universidad.
                </p>
              </div>

              {/* Economía */}
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-800">
                  Economía
                </h3>
                <p className="mt-2 text-gray-600">
                  ¿Qué proceso deseas seguir?
                </p>

                <h4 className="mt-4 font-semibold text-gray-700">
                  Equivalencias
                </h4>
                <p className="mb-2 text-gray-600">
                  Para comenzar el proceso de equivalencias en la Facultad de
                  Economía y Ciencias Sociales, comunícate con:
                </p>
                <p className="text-gray-600">
                  👉🏼 <strong>Lic. Fernando Juan José Rodríguez Salazar</strong>{" "}
                  <br />
                  📞 WhatsApp:{" "}
                  <a
                    href="https://wa.me/50360715063"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sky-600 hover:underline"
                  >
                    6071-5063
                  </a>
                </p>
                <p className="mt-2 text-gray-600">
                  Al comunicarte, preséntate con tu nombre completo, especifica
                  el nombre de la ingeniería de tu interés, y menciona que ya
                  has completado el formulario de ingreso con los datos
                  necesarios.
                </p>

                <h4 className="mt-4 font-semibold text-gray-700">
                  Exoneración
                </h4>
                <p className="text-gray-600">
                  Si decides iniciar la carrera desde el principio mediante la
                  exoneración, por favor preséntate en la Unidad de Atención al
                  Estudiante con un documento que valide tus estudios previos o
                  el inicio de estudios en otra universidad.
                </p>
              </div>

              {/* Jurídica */}
              <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-800">
                  Jurídica
                </h3>
                <p className="mt-2 text-gray-600">
                  ¿Qué proceso deseas seguir?
                </p>

                <h4 className="mt-4 font-semibold text-gray-700">
                  Equivalencias
                </h4>
                <p className="mb-2 text-gray-600">
                  Para comenzar el proceso de equivalencias en la Facultad de
                  Ciencias Jurídicas, comunícate con:
                </p>
                <p className="text-gray-600">
                  👉🏼 <strong>Licda. Silvia Yolany López de Cruz</strong> <br />
                  📞 WhatsApp:{" "}
                  <a
                    href="https://wa.me/50378619502"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sky-600 hover:underline"
                  >
                    7861-9502
                  </a>
                </p>
                <p className="mt-2 text-gray-600">
                  Al comunicarte, te sugerimos presentarte con tu nombre
                  completo, especificar la ingeniería de tu interés, y mencionar
                  que ya has completado el formulario de ingreso.
                </p>

                <h4 className="mt-4 font-semibold text-gray-700">
                  Exoneración
                </h4>
                <p className="text-gray-600">
                  Si decides iniciar la carrera desde el principio mediante la
                  exoneración, por favor preséntate en la Unidad de Atención al
                  Estudiante con un documento que valide tus estudios previos o
                  el inicio de estudios en otra universidad.
                </p>
              </div>

              {/* Botón de Pago */}
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => navigate("/payment")}
                  disabled={!exonerado}
                  className={`px-6 py-2 text-lg font-semibold text-white rounded-lg ${
                    exonerado
                      ? "bg-lightgreentxt hover:bg-darkgreentxt"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                >
                  Ir a Pago
                </button>
              </div>
            </div>
          )}

          {tipoInscripcion === "4" && (
            <div>
              <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
                Proceso de Exoneración
              </h2>
              <p className="mb-4 text-gray-600">
                Si deseas continuar con el proceso de exoneración y comenzar la
                carrera desde el inicio, te invitamos a presentarte en la Unidad
                de Atención al Estudiante en las instalaciones de la
                universidad. Allí deberás presentar un documento que valide que
                ya posees estudios en otra carrera o que has iniciado estudios
                en otra universidad.
              </p>
              <p className="mb-4 text-gray-600">
                Cualquier duda o consulta también puedes escribirnos al
                siguiente número:
              </p>
              <p className="text-gray-600">
                👉🏼 <strong>Unidad de Atención al Estudiante</strong> <br />
                📞 WhatsApp:{" "}
                <a
                  href="https://wa.me/50378615668"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sky-600 hover:underline"
                >
                  7861-5668
                </a>
              </p>
              {/* Botón de Pago */}
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => navigate("/payment")}
                  disabled={!exonerado}
                  className={`px-6 py-2 text-lg font-semibold text-white rounded-lg ${
                    exonerado
                      ? "bg-lightgreentxt hover:bg-darkgreentxt"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                >
                  Ir a Pago
                </button>
              </div>
            </div>
          )}
        </div>
      );
    } else if (estado === "equivalencias_externas" && tipoInscripcion === "5") {
      return (
        <div className="p-6">
          <h2 className="mb-8 text-2xl font-bold text-center text-sky-700">
            Proceso de Equivalencias Externas
          </h2>

          <div className="mb-6">
            <p className="leading-relaxed text-gray-600">
              Si quieres comenzar el proceso de equivalencias debes de
              comunicarte con el Decano de la Facultad a la que pertenecerás:
            </p>
            <ul className="mt-4 space-y-4 text-gray-600">
              <li>
                <strong>Facultad de Economía y Ciencias Sociales:</strong>
                <div className="ml-4">
                  👉🏼 Lic. Fernando Juan José Rodríguez Salazar
                  <br />
                  📞 WhatsApp:{" "}
                  <a
                    href="https://wa.me/50360715063"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sky-600 hover:underline"
                  >
                    6071-5063
                  </a>
                </div>
              </li>
              <li>
                <strong>Facultad de Ingeniería y Ciencias Naturales:</strong>
                <div className="ml-4">
                  👉🏼 Lic. Carlos Alberto Martínez Zaragoza
                  <br />
                  📞 WhatsApp:{" "}
                  <a
                    href="https://wa.me/50376030352"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sky-600 hover:underline"
                  >
                    7603-0352
                  </a>
                </div>
              </li>
              <li>
                <strong>Facultad de Ciencias Jurídicas:</strong>
                <div className="ml-4">
                  👉🏼 Licda. Silvia Yolany López de Cruz
                  <br />
                  📞 WhatsApp:{" "}
                  <a
                    href="https://wa.me/50378619502"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sky-600 hover:underline"
                  >
                    7861-9502
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-6">
            <p className="leading-relaxed text-gray-600">
              Al comunicarte, te recomendamos presentarte con tu nombre
              completo, especificar el nombre de la carrera de tu interés,
              mencionar el nivel de estudio que posees y que ya has completado
              el formulario de ingreso con los datos requeridos.
            </p>
          </div>

          <div className="flex justify-center mt-6">
            <button
              onClick={() => navigate("/payment")}
              disabled={!exonerado}
              className={`px-6 py-2 text-lg font-semibold text-white rounded-lg ${
                exonerado
                  ? "bg-lightgreentxt hover:bg-darkgreentxt"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
            >
              Ir a Pago
            </button>
          </div>
        </div>
      );
    } else if (estado === "maestria") {
      return (
        <div className="p-6">
          <h2 className="mb-8 text-2xl font-bold text-center text-sky-700">
            Inscripción en Maestrías
          </h2>

          {/* Maestría en Neuropsicología del Aprendizaje */}
          <div className="mb-10">
            <h3 className="mb-2 text-lg font-semibold text-gray-800">
              Maestría en Neuropsicología del Aprendizaje
            </h3>
            <p className="mb-2 leading-relaxed text-gray-600">
              Para iniciar el proceso de inscripción, comunícate con la
              directora de la Escuela de Educación:
            </p>
            <p className="mb-2 font-medium text-gray-800">
              👉🏼 Licda. Ana María Zelidon de Lemus
            </p>
            <p className="mb-4 text-gray-800">
              📞 WhatsApp:{" "}
              <a
                href="https://wa.me/50378619131"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sky-600 hover:underline"
              >
                7861-9131
              </a>
            </p>

            <p className="leading-relaxed text-gray-600">
              Al comunicarte, preséntate con tu nombre completo, especifica el
              nombre de la maestría y menciona que has completado el formulario
              de ingreso.
            </p>
          </div>

          {/* Maestrías en Economía */}
          <div className="mb-6">
            <h3 className="mb-2 text-lg font-semibold text-gray-800">
              Maestría en Administración Financiera y Gerencia de Proyectos
            </h3>
            <p className="mb-2 leading-relaxed text-gray-600">
              Para iniciar el proceso de inscripción en estas maestrías,
              comunícate con el Decano de la Facultad de Economía y Ciencias
              Sociales:
            </p>
            <p className="mb-2 font-medium text-gray-800">
              👉🏼 Lic. Fernando Juan José Rodríguez Salazar
            </p>
            <p className="mb-4 text-gray-800">
              📞 WhatsApp:{" "}
              <a
                href="https://wa.me/50360715063"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sky-600 hover:underline"
              >
                6071-5063
              </a>
            </p>
            <p className="leading-relaxed text-gray-600">
              Al comunicarte, preséntate con tu nombre completo, especifica el
              nombre de la maestría de tu interés y menciona que has completado
              el formulario de ingreso.
            </p>
          </div>
        </div>
      );
    } else if (estado === "educacion") {
      return (
        <div className="p-6">
          <h2 className="mb-8 text-2xl font-bold text-center text-sky-700">
            Proceso de Inscripción en Carreras de Educación
          </h2>

          <div className="mb-10">
            <p className="mb-2 leading-relaxed text-gray-600">
              Para comenzar el proceso de inscripción en alguna de las carreras
              de educación debes de contactar con la directora de la Escuela de
              Educación:
            </p>
            <p className="mb-2 font-medium text-gray-800">
              👉🏼 Licda. Ana María Zelidon de Lemus
            </p>
            <p className="mb-4 text-gray-800">
              📞 WhatsApp:{" "}
              <a
                href="https://wa.me/50378619131"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sky-600 hover:underline"
              >
                7861-9131
              </a>
            </p>
            <p className="leading-relaxed text-gray-600">
              Al comunicarte, te recomendamos presentarte con tu nombre
              completo, especificar el nombre de la Licenciatura en educación de
              tu interés y mencionar que ya has completado el formulario de
              ingreso con los datos requeridos.
            </p>
          </div>
        </div>
      );
    } else if (estado === "Inscrito" || estado === "Completo") {
      return (
        <div>
          <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
            Registro Completo
          </h2>
          <p className="mb-4 text-center text-gray-600">
            Tu inscripción se ha completado exitosamente.
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => navigate("/schedules-and-groups")}
              className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
            >
              Siguiente
            </button>
          </div>
        </div>
      );
    } else if (estado === "ciencia_datos") {
      return (
        <div>
          <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
            Registro Completo
          </h2>
          <p className="mb-4 text-center text-gray-600">
            ¡Felicidades! Tu registro se ha completado exitosamente.
          </p>
          <p className="mb-4 text-center text-gray-600">
            Para el Técnico en Ciencia de Datos, es requisito completar el Curso
            de Inducción, el cual incluye dos reuniones virtuales obligatorias.
          </p>
          <p className="mb-4 text-center text-gray-600">
            Te recomendamos estar atento tanto a tu correo personal como a tu
            correo institucional, donde recibirás la información sobre las
            fechas y el acceso a estas reuniones una vez finalices el pago.
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => navigate("/payment")}
              className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
            >
              Siguiente
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">
          Proceso de Inscripción en Curso
        </h2>
        <p className="mb-4 text-center text-gray-600">
          Continúa con el proceso en tu perfil.
        </p>
        <button
          onClick={() => navigate("/inscription-process")}
          className="px-6 py-2 text-lg font-semibold text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
        >
          Ir al Perfil
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-full px-4 py-8 bg-gray-100">
      <div className="relative flex flex-col items-center w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <div className="absolute flex flex-col items-center p-3 rounded-lg top-6 bg-gray-50">
          <button
            className="flex items-center justify-center w-12 h-12 p-2 text-white rounded-full shadow-md bg-gradient-to-r from-lightgreentxt to-bluelight hover:scale-105 focus:outline-none"
            onClick={() => setShowProfileModal(true)}
            aria-label="Abrir vista del perfil"
            title="Ver Perfil"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 12c2.7 0 4.9-2.2 4.9-4.9S14.7 2.2 12 2.2 7.1 4.4 7.1 7.1 9.3 12 12 12zm0 2.2c-3.3 0-9.9 1.7-9.9 5v1.5h19.8v-1.5c0-3.3-6.6-5-9.9-5z" />
            </svg>
          </button>
          <span
            className="mt-0 text-sm font-semibold text-gray-400 cursor-pointer hover:text-gray-500"
            onClick={() => setShowProfileModal(true)}
          >
            Ver Perfil
          </span>
        </div>

        <div className="pt-16 mt-8">{renderContent()}</div>
      </div>

      {/* Modal para mostrar perfil */}
      {showProfileModal && studentData && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <div className="w-full max-w-lg p-8 mx-auto transition-all transform bg-white shadow-lg rounded-xl">
            <button
              className="absolute text-gray-500 top-4 right-4 hover:text-gray-700 focus:outline-none"
              onClick={() => setShowProfileModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="flex flex-col items-center justify-center mb-6">
              <div className="flex items-center justify-center w-20 h-20 p-4 rounded-full shadow-md bg-gradient-to-r from-lightgreentxt to-bluelight">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-16 h-16 text-white"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 12c2.761 0 5-2.239 5-5s-2.239-5-5-5-5 2.239-5 5 2.239 5 5 5zm0 2c-4.418 0-8 3.582-8 8h16c0-4.418-3.582-8-8-8z" />
                </svg>
              </div>

              <h2 className="mt-4 text-2xl font-bold text-center text-darkbluetxt">
                {`${studentData.primer_nombre} ${
                  studentData.segundo_nombre || ""
                } ${studentData.primer_apellido} ${
                  studentData.segundo_apellido
                }`}
              </h2>

              <p className="mt-2 text-center text-gray-600">
                {studentData.carrera_estudio || "Carrera no asignada"} -{" "}
                {studentData.facultad || "Facultad no asignada"}
              </p>
            </div>
            <div className="grid grid-cols-1 gap-4 text-gray-800 md:grid-cols-2">
              <div>
                <h3 className="text-lg font-semibold text-darkbluetxt">
                  Año de Ingreso:
                </h3>
                <p>{studentData.anio_ingreso}</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-darkbluetxt">
                  Código del Estudiante:
                </h3>
                <p>{studentData.codigo_estudiante || "No asignado"}</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-darkbluetxt">
                  Correo Institucional:
                </h3>
                <p>{studentData.correo_institucional || "No asignado"}</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-darkbluetxt">
                  Expediente:
                </h3>
                <p>{studentData.expediente || "No asignado"}</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-darkbluetxt">
                  Estado de Pago:
                </h3>
                <p>{studentData.estado_pago === 1 ? "Pagado" : "Pendiente"}</p>
              </div>
            </div>
            {/* Botones */}
            <div className="flex justify-end mt-6 space-x-4">
              {studentData.estado_pago === 1 ? (
                <div></div>
              ) : (
                <button
                  className="px-6 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                  onClick={() => {
                    setEditData(studentData); // Carga los datos actuales en el modal de edición
                    setShowProfileModal(false);
                    setShowEditModal(true); // Abre el modal de edición
                  }}
                >
                  Editar
                </button>
              )}
              <button
                className="px-6 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                onClick={() => setShowProfileModal(false)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal para editar perfil */}
      {showEditModal && editData && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <div className="max-w-3xl p-6 bg-white rounded-lg shadow-lg">
            <h2 className="mb-6 text-2xl font-bold text-center text-darkbluetxt">
              Editar Perfil
            </h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Primer Nombre
                </label>
                <input
                  type="text"
                  value={editData.primer_nombre || ""}
                  onChange={(e) =>
                    handleChange("primer_nombre", e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Juanito"
                />
                {errors.primer_nombre && (
                  <p className="text-red-500">{errors.primer_nombre}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Segundo Nombre
                </label>
                <input
                  type="text"
                  value={editData.segundo_nombre || ""}
                  onChange={(e) =>
                    handleChange("segundo_nombre", e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Alcachofa"
                />
                {errors.segundo_nombre && (
                  <p className="text-red-500">{errors.segundo_nombre}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Primer Apellido
                </label>
                <input
                  type="text"
                  value={editData.primer_apellido || ""}
                  onChange={(e) =>
                    handleChange("primer_apellido", e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Torres"
                />
                {errors.primer_apellido && (
                  <p className="text-red-500">{errors.primer_apellido}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Segundo Apellido
                </label>
                <input
                  type="text"
                  value={editData.segundo_apellido || ""}
                  onChange={(e) =>
                    handleChange("segundo_apellido", e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Flores"
                />
                {errors.segundo_apellido && (
                  <p className="text-red-500">{errors.segundo_apellido}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Fecha de Nacimiento
                </label>
                <input
                  type="date"
                  value={editData.fecha_nacimiento || ""}
                  onChange={(e) =>
                    handleChange("fecha_nacimiento", e.target.value)
                  }
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                />
                {errors.fecha_nacimiento && (
                  <p className="text-red-500">{errors.fecha_nacimiento}</p>
                )}
              </div>
              {isAdult ? (
                <div>
                  <label className="block mb-1 text-sm font-semibold text-gray-600">
                    DUI
                  </label>
                  <input
                    type="text"
                    value={editData.dui || ""}
                    onChange={(e) => handleDUIChange("dui", e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    placeholder="########-#"
                    maxLength="10" // Limitar el número máximo de caracteres
                  />
                  {errors.dui && <p className="text-red-500">{errors.dui}</p>}
                </div>
              ) : (
                <div>
                  <label className="block mb-1 text-sm font-semibold text-gray-600">
                    DUI del Responsable
                  </label>
                  <input
                    type="text"
                    value={editData.dui_responsable || ""}
                    onChange={(e) =>
                      handleDUIChange("dui_responsable", e.target.value)
                    }
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    placeholder="########-#"
                    maxLength="10" // Limitar el número máximo de caracteres
                  />
                  {errors.dui_responsable && (
                    <p className="text-red-500">{errors.dui_responsable}</p>
                  )}
                </div>
              )}
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Nacionalidad
                </label>
                <input
                  type="text"
                  value={editData.nacionalidad || ""}
                  onChange={(e) => handleChange("nacionalidad", e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="salvadoreño"
                />
                {errors.nacionalidad && (
                  <p className="text-red-500">{errors.nacionalidad}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Género
                </label>
                <Select
                  options={[
                    { value: "masculino", label: "Masculino" },
                    { value: "femenino", label: "Femenino" },
                  ]}
                  value={{ value: editData.genero, label: editData.genero }}
                  onChange={(selectedOption) =>
                    handleChange("genero", selectedOption.value)
                  }
                  placeholder="Selecciona tu género"
                />
                {errors.genero && (
                  <p className="text-red-500">{errors.genero}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Facultad
                </label>
                <Select
                  options={facultades}
                  value={
                    facultades?.find(
                      (fac) => fac.value === editData.facultad
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    handleChange("facultad", selectedOption.value)
                  }
                  placeholder="Selecciona una facultad"
                />
                {errors.facultad && (
                  <p className="text-red-500">{errors.facultad}</p>
                )}
              </div>
              <div>
                <label className="block mb-1 text-sm font-semibold text-gray-600">
                  Carrera
                </label>
                <Select
                  options={filteredCarreras}
                  value={
                    filteredCarreras?.find(
                      (car) => car.value === editData.carrera_estudio
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    handleChange("carrera_estudio", selectedOption.value)
                  }
                  placeholder="Selecciona una carrera"
                />
                {errors.carrera_estudio && (
                  <p className="text-red-500">{errors.carrera_estudio}</p>
                )}
              </div>
            </div>
            <div className="flex justify-end mt-6 space-x-4">
              <button
                onClick={() => setShowConfirmationModal(true)}
                className="px-6 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
              >
                Guardar
              </button>
              <button
                onClick={() => {
                  setShowEditModal(false);
                  setShowProfileModal(true);
                }}
                className="px-6 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmationModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
          <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
            <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">
              Confirmación
            </h2>
            <p className="mb-6 text-center text-gray-600">
              ¿Está seguro de que desea proceder con el pago?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => {
                  setShowConfirmationModal(false);
                  handleSave();
                  setShowProfileModal(true);
                }}
                className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
              >
                Confirmar
              </button>
              <button
                onClick={() => {
                  setShowConfirmationModal(false);
                  setShowProfileModal(true);
                }}
                className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {showNotificationModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h3 className="mb-4 text-xl font-semibold text-center text-green-600">
              ¡Éxito!
            </h3>
            <p className="text-center text-gray-600">
              Los cambios se han guardado correctamente.
            </p>
            <div className="flex justify-center mt-6">
              <button
                onClick={() => setShowNotificationModal(false)}
                className="px-6 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WaitingRoom;
