import React from 'react'
import { BtnAgregar } from '../buttons/BtnAgregar'
import { HorarioPrincipal } from './HorarioPrincipal'

function GrupoPrincipal({ grupo }) {
    return (

        <div key={grupo.idGrupo} className="mb-3">
            <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <h3 style={{ color: "black" }}> <strong> Grupo {grupo.grupo}</strong>:</h3>
            {grupo.horarios.map((horario) => (
                    <HorarioPrincipal
                        key={horario.idHorario}
                        horario={horario}
                    />
                ))}
                <br />
            </div>
        </div>

    )
}

export { GrupoPrincipal }