import React, { useState } from 'react';
import authService from '../../services/authService';
import { FiUser, FiMail, FiLock } from 'react-icons/fi';

const RegisterForm = ({ closeParentModal }) => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden');
      return;
    }

    try {
      await authService.register(name, lastname, email, password);
      setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
      setErrorMessage('Error en el registro. Inténtalo de nuevo.');
    }
  };

  const closeModalAndExit = () => {
    setShowSuccessModal(false);
    closeParentModal();
  };

  return (
    <div className="max-w-md px-6 pt-0 pb-2 mx-auto bg-white">
      <form onSubmit={handleRegister}>
        <div className="mb-3">
          <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="name">
            Nombre
          </label>
          <div className="relative">
            <FiUser className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 top-1/2 left-3" />
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nombre"
              className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="lastname">
            Apellido
          </label>
          <div className="relative">
            <FiUser className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 top-1/2 left-3" />
            <input
              type="text"
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              placeholder="Apellido"
              className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="email">
            Correo electrónico
          </label>
          <div className="relative">
            <FiMail className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 top-1/2 left-3" />
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Correo electrónico"
              className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="password">
            Contraseña
          </label>
          <div className="relative">
            <FiLock className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 top-1/2 left-3" />
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-semibold text-darkbluetxt" htmlFor="confirmPassword">
            Confirmar Contraseña
          </label>
          <div className="relative">
            <FiLock className="absolute w-5 h-5 text-gray-400 transform -translate-y-1/2 top-1/2 left-3" />
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirmar Contraseña"
              className="w-full px-10 py-3 text-gray-700 transition duration-300 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-lightgreentxt focus:shadow-md"
            />
          </div>
        </div>
        {errorMessage && <p className="p-2 mb-4 text-center text-red-600 bg-red-100 rounded-md">{errorMessage}</p>}
        <button
          type="submit"
          className="w-full px-4 py-3 text-lg font-semibold text-white transition duration-300 transform rounded-lg shadow-md bg-bluelight hover:bg-lightgreentxt hover:scale-105 focus:outline-none"
        >
          Registrarse
        </button>
      </form>
      {/* Modal de confirmación de registro exitoso */}
      {showSuccessModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
          <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
            <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">Registro Exitoso</h2>
            <p className="mb-6 text-center text-gray-600">
              Tu cuenta ha sido registrada correctamente.
              Activa tu cuenta desde el enlace enviado a tu correo electrónico: <strong> {email}</strong> <br/>
              <p className="text-red-600 uppercase" >
                ¡No olvides revisar tu carpeta de spam!
              </p>
            </p>
            <div className="flex justify-center">
              <button
                onClick={closeModalAndExit}
                className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:scale-105 focus:outline-none"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default RegisterForm;
