import { useEffect, useRef, useState } from 'react'
import { useFetch } from '../../tools/useFetch';
import { useAuth } from '../../contexts/DataContext';
import { useAlert } from '../../hooks/useAlert';
import { Notas } from '../../models/Notas';
import { Usuarios } from '../../models/Usuarios';
import { Participantes } from '../../models/Participantes';

const useListadoNotas = () => {

    //Todas las constantes
    const { user } = useAuth();
    const { getFetch, putFetch, postFetch } = useFetch();
    const [notas, setNotas] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [actividades, setActividades] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedName, setSelectedName] = useState('');
    const [selectedNota, setSelectedNota] = useState('');
    const [selectedCursoId, setSelectedCursoId] = useState('');
    const [selectedGrupoId, setSelectedGrupoId] = useState('');
    const [selectedActividadId, setSelectedActividadId] = useState('');
    const [listaNotas, setListaNotas] = useState([]);
    const tableRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const { mensajeError, mensajeExitoso, mensajeAdvertencia, mensajeInformacion } = useAlert();

    //Los useEffect
    useEffect(() => {
        if (selectedCursoId) {
            getGruposId(selectedCursoId, user[0].idUsuario);
        }
    }, [selectedCursoId]);

    useEffect(() => {
        if (selectedGrupoId) {
            getActividadesId(selectedCursoId);
        }
    }, [selectedGrupoId]);

    useEffect(() => {
        if (selectedActividadId) {
            getNotas(selectedActividadId, selectedGrupoId);
        }

    }, [selectedActividadId]);

    //Funciones
    const searchTable = () => {
        var input = document.getElementById("searchInput").value.toLowerCase();

        var table = document.getElementById("dataTable"); // Obtiene la tabla por su ID
        var rows = table.getElementsByTagName("tr"); // Obtiene todas las filas de la tabla

        var columnIndex = 1; // Por ejemplo, buscar en la primera columna (índice 0)

        for (var i = 0; i < rows.length; i++) {
            var cell = rows[i].getElementsByTagName("td")[columnIndex];
            if (cell) {
                var cellText = cell.textContent || cell.innerText;
                if (cellText.toLowerCase().startsWith(input)) {
                    rows[i].style.display = "";
                } else {
                    rows[i].style.display = "none";
                }
            }
        }
    }

    const seleccionar = (id, nombre, apellidos, nota) => {
        setSelectedId(id);
        setSelectedName(nombre + " " + (apellidos ? apellidos : ""));
        setSelectedNota(nota);
        setShowModal(true);
    };

    const AsignarNota = () => {
        if (!isNaN(selectedNota) && selectedNota <= 10) {
            putOnClick(selectedId, selectedNota);
            mensajeExitoso("Nota actualizada correctamente!");
            Limpiar();
        }
    };

    const InsertarLista = () => {
        if (listaNotas.length > 0) {
            listaNotas.forEach((item) => {
                const idNota = item.idNota;
                const nota = item.nota;
                putOnClick(idNota, nota);
            });
            mensajeExitoso("Notas actualizadas correctamente!");
        }
    }

    const Limpiar = () => {
        setSelectedId("");
        setSelectedNota("");
        setSelectedName("");
        setShowModal(false);
    };

    const LimpiarGets = (valor) => {
        if (valor === "cursos") {
            getGruposId(0, 0, 0, 0);
            getActividadesId(0);
            getNotas(0, 0);
        }
        if (valor === "grupos") {
            getActividadesId(0);
            getNotas(0, 0);
        }
        if (valor === "actividades") {
            getNotas(0, 0);
        }
    }

    const Agregar = (idNota, nuevaNota) => {
        const nuevaNotaNumber = parseInt(nuevaNota);

        if (!isNaN(nuevaNotaNumber) && Number.isInteger(nuevaNotaNumber) && nuevaNotaNumber >= 0 && nuevaNotaNumber <= 10) {
            let trimmedNota = nuevaNota.trim();// Trim para eliminar espacios en blanco si es necesario

            if (trimmedNota === '') {
                const nuevaListaNotas = listaNotas.filter(item => item.idNota !== idNota);
                setListaNotas(nuevaListaNotas);
            } else {
                // Verificar si el idNota ya existe en la lista
                const indiceElemento = listaNotas.findIndex(item => item.idNota === idNota);
                if (indiceElemento !== -1) {
                    // Si el elemento ya existe, actualizarlo en la lista
                    const nuevaListaNotas = listaNotas.map(item =>
                        item.idNota === idNota ? { ...item, nota: nuevaNota } : item
                    );
                    setListaNotas(nuevaListaNotas);
                } else {
                    const nuevoElemento = { idNota, nota: nuevaNota };
                    setListaNotas([...listaNotas, nuevoElemento]);
                }
            }
        }
    };

    const limpiarInputs = () => {
        // Obtener todas las filas de la tabla usando la referencia
        const rows = tableRef.current.querySelectorAll('tbody tr');

        // Recorrer cada fila
        rows.forEach(row => {
            // Obtener el input dentro de la fila
            const input = row.querySelector('input[type="number"]');
            if (input) {
                input.value = '';
                setListaNotas([]);
            }
        });
    };

    const handleClose = () => setShowModal(false);

    const handleContinue = () => {
        const numberRegex = /^(?:\d{1,2}(?:\.\d{1})?)?$/;
        const notaString = String(selectedNota); // Convertir a cadena si no lo es

        if (typeof notaString === 'string' && notaString.match(numberRegex)) {
            const notaNumerica = parseFloat(notaString);

            if (!isNaN(notaNumerica) && notaNumerica >= 0 && notaNumerica <= 10) {
                AsignarNota();
            } else {
                mensajeAdvertencia("La nota debe ser un número válido");
            }
        } else {
            mensajeAdvertencia("La nota debe ser un número válido");
        }
    };

    const handleCancel = () => {
        Limpiar();
    };

    //Todos los gets
    const getNotas = (idActividad, idGrupo) => {
        const urlParcial = `notas/nota/${idActividad}/${idGrupo}`
        getFetch(urlParcial)
            .then((data) => {
                const { mensaje, estado, datos } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item => {
                        const notas = Notas(item.idNota, item.nota);
                        const usuarios = Usuarios(0, '', item.nombres, item.apellidos, 0, item.sexo);
                        const participantes = Participantes(0, item.expediente);
                        return {
                            ...notas,
                            ...usuarios,
                            expediente: participantes.expediente
                        };
                    });
                    
                    setNotas(datosEstandarizados);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    const getCursosPorId = (idU) => {
        const urlParcial = `notas/cursosId/${idU}`
        getFetch(urlParcial)
            .then((data) => {
                const { datos, mensaje, estado } = data;
                if (estado) {
                    setCursos(datos);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    const getActividadesId = (id) => {
        const urlParcial = `notas/actividadesId/${id}`
        getFetch(urlParcial)
            .then((data) => {
                const { datos, mensaje, estado } = data;
                if (estado) {
                    setActividades(datos);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    const getGruposId = (idCurso, idUsuario) => {//cc
        const urlParcial = `notas/gruposId/${idCurso}/${idUsuario}`;
        getFetch(urlParcial)
            .then((data) => {
                const { datos, mensaje, estado } = data;
                if (estado) {
                    setGrupos(datos);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    //Todos los post
    const postOnClick = (datos) => {
        const urlParcial = 'notas/';
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    //mensajeExitoso(mensaje);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    //Todos los put
    const putOnClick = (id, nota) => {
        const urlParcial = `notas/notas/${id}/${nota}`;
        putFetch(urlParcial)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    getNotas(selectedActividadId, selectedGrupoId);
                    setListaNotas([]);
                    limpiarInputs();
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    //Todos los handle

    const selectOptionCurso = (event) => {
        const id = event.target.value;
        setSelectedCursoId('');
        setSelectedCursoId(id);
        LimpiarGets("cursos");
    };

    useEffect(() => {
        if (selectedCursoId) {
            getGruposId(selectedCursoId, user[0].idUsuario);
        }
    }, [selectedCursoId]);

    const selectOptionGrupo = (event) => {
        const id = event.target.value;
        setSelectedGrupoId('');
        setSelectedGrupoId(id);
        LimpiarGets("grupos");
    };

    const selectOptionAcividad = (event) => {
        const id = event.target.value;
        //console.log("SELECCIONE UNA ACTIVIDAD->", id);
        setSelectedActividadId('');
        setSelectedActividadId(id);
        LimpiarGets("actividades");
    };

    const fontSizeCard = {
        fontSize: '0.8em'
    };
    const cardHeaderStyle = {
        backgroundColor: '#5561B8',
        color: 'white',
        padding: '3px 10px',
        borderBottom: '1px solid rgba(0,0,0,.125)',
    };
    const cardBosyStyle = {
        borderRadius: '0',
        borderTop: 'none',
        backgroundColor: '#f9f9f9',
        outline: 'none',
        boxShadow: 'none',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none'
    };

    const styleNotas = {
        backgroundColor: '#f0f0f0',
        width: '40%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
        height: '100%',
        margin: '5px'
    };

    const styleNumber = {
        width: '40%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
        height: '100%'
    };

    return {
        postOnClickNotas: postOnClick,
        limpiarInputs,
        tableRef,
        getNotas,
        notas,
        searchTable,
        cursos,
        getCursosPorId,
        actividades,
        getActividadesId,
        selectOptionCurso,
        seleccionar,
        AsignarNota,
        selectedId,
        selectedName,
        selectedNota,
        setSelectedNota,
        Limpiar,
        selectOptionAcividad,
        grupos,
        getGruposId,
        selectOptionGrupo,
        Agregar,
        InsertarLista,
        fontSizeCard,
        cardBosyStyle,
        cardHeaderStyle,
        styleNotas,
        styleNumber,
        user,
        handleCancel,
        handleClose,
        handleContinue,
        showModal
    };
};
export { useListadoNotas }