import { useAlert } from "../../hooks/useAlert";
import { useFetch } from "../../tools/useFetch";

function useCursos() {
    const { getFetch, postFetch, putFetch } = useFetch();
    const { mensajeError, mensajeExitoso, mensajeInformacion } = useAlert();

    const obtenerCursosDeDiplomadoPorCiclo = async (idCiclo, idDiplomado) => {
        const urlParcial = `cursos/cursosporciclo/${idDiplomado}/${idCiclo}`;
        return getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;
                return datos;
            })
            .catch((error) => {
                console.log("Error->", error);
                throw error;
            });
    };

    return {
        obtenerCursosDeDiplomadoPorCiclo
    }
}

export { useCursos }