import { DetallesCuentasPorCobrar } from '../../models/DetallesCuentasPorCobrar';
import { useFetch } from '../../tools/useFetch';
import { useDetallesCuentasPorCobrar } from '../detalles_cuentas_por_cobrar/useDetallesCuentasPorCobrar';

function useCuentasPorCobrar() {

    const { postFetch, putFetch } = useFetch();
    const { postDetalleCuentaPorCobrar } = useDetallesCuentasPorCobrar();

    const postOnClick = (datos, detalles) => {
        const urlParcial = 'cuentas_por_cobrar/';
        return postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { insertId } = respuesta.datos;
                const datosEstandarizados = detalles.map(item =>
                    DetallesCuentasPorCobrar
                        (
                            0,
                            'pendiente',
                            item.concepto,
                            item.monto,
                            item.vencimiento ? formatFecha(item.vencimiento) : null,
                            null,
                            item.generarRecargo,
                            item.valorRecargo,
                            item.idCodigoServicio,
                            insertId,
                            item.idCurso,
                            item.orden
                        )
                );
                
                postDetalleCuentaPorCobrar(datosEstandarizados)
            })
            .catch((error) => {
                console.log('Error->', error);
            });
    };

    // Función para formatear la fecha
    const formatFecha = (fecha) => {
        return fecha.split('T')[0];
    };

    const putOnClick = (id, datos) => {
        const urlParcial = `cuentas_por_cobrar/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
            })
            .catch((error) => {
                console.log('Error->', error);
            });
    };

    return {
        postCuentaPorCobrar: postOnClick,
        putCuentaPorCobrar: putOnClick
    }
}

export { useCuentasPorCobrar }