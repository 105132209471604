import { useEffect, useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAuth } from '../../contexts/DataContext';
import { useAlert } from '../../hooks/useAlert';
import { useLocation } from 'react-router-dom';
import { DetallesCuentasPorCobrar } from '../../models/DetallesCuentasPorCobrar';
import { Cursos } from '../../models/Cursos';

function useDetallesCuentasPorCobrar() {

    const { mensajeError, mensajeExitoso, mensajeAdvertencia } = useAlert();
    // Función para crear un objeto de datos para los cursos

    //todas las constantes
    const { user } = useAuth();
    const { postFetch, putFetch, getFetch } = useFetch();
    const [cuentasPendientes, setCuentasPendientes] = useState([]);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(Cursos());
    const [cursos, setCursos] = useState([]);
    const [error, setError] = useState(null);
    const [cuentasSeleccionadas, setCuentasSeleccionadas] = useState([]);
    const sumaMontosSeleccionados = cuentasSeleccionadas.reduce((total, cuenta) => total + parseFloat(cuenta.monto), 0);
    const location = useLocation();
    const { idParticipante, idUsuario } = location.state || {};

    //los useEffect
    useEffect(() => {
        if (idUsuario) {
            getCursos(idUsuario);
        } else if (user[0].idUsuario) {
            getCursos(user[0].idUsuario);
        }
    }, [idUsuario, user]);

    useEffect(() => {
        if (cursoSeleccionado.idCurso && !idParticipante) {
            getCuentasPendientesPorCurso(cursoSeleccionado.idCurso, user[0].idParticipante);
        } else if (cursoSeleccionado.idCurso && idParticipante) {
            getCuentasPendientesPorCurso(cursoSeleccionado.idCurso, idParticipante);
        }
    }, [cursoSeleccionado.idCurso, user, idParticipante]);

    //todos los gets
    const getCuentasPendientesPorCurso = (idCurso, idParticipante) => {
        const urlParcial = `detalles_cuentas_por_cobrar/${idParticipante}/${idCurso}`
        getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;

                const datosEstandarizados = datos.map(item =>
                    DetallesCuentasPorCobrar
                        (
                            item.idDetalleCuentaPorCobrar,
                            item.estado,
                            item.concepto,
                            item.monto,
                            item.fechaVencimiento,
                            item.fechaPago,
                            item.recargo,
                            item.valorRecargo,
                            item.idCodigoServicio,
                            item.idCuentaPorCobrar,
                            item.idCurso,
                            item.orden,
                            item.idParticipante
                        )
                );
                setCuentasPendientes(datosEstandarizados);
            })
            .catch((error) => {
                console.log("Error->", error);
            })
    }

    const getCursos = (idUsuario) => {
        const urlParcial = `misCursos/cursosActivos/${idUsuario}`
        getFetch(urlParcial)
            .then((data) => {
                const { datos, estado, mensaje } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item =>
                        Cursos(
                            item.idCurso || 0,
                            item.curso || ''
                        )
                    );
                    setCursos(datosEstandarizados);
                    if (datosEstandarizados.length > 0) {
                        setCursoSeleccionado(datosEstandarizados[0]);
                    }
                } else {
                    mensajeError(mensaje);
                }

            })
            .catch((error) => {
                console.log("Error->", error);
            })
    }

    //todos los put
    const putOnClick = (id, datos) => {
        const urlParcial = `detalles_cuentas_por_cobrar/quitarrecargo/${id}`;

        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    getCuentasPendientesPorCurso(cursoSeleccionado.idCurso);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError('Error inesperado, contacte al programador. ' + error);
            });
    };

    //todos los post
    const postOnClick = (datos) => {
        const urlParcial = 'detalles_cuentas_por_cobrar/';
        return postFetch(urlParcial, datos)
            .then((respuesta) => {
            })
            .catch((error) => {
                console.log('Error->', error);
            });
    };

    const formatFecha = (fechaStr) => {
        const date = new Date(fechaStr);
        return date.toLocaleDateString(); // Ajusta el formato según tus necesidades
    };

    //todos los handleChange
    const handleCursoChange = (e) => {
        const { name, value } = e.target;
        setCursoSeleccionado(cursoPrevio => ({ ...cursoPrevio, [name]: value }));
    };

    const handleCheckboxChange = (id, monto) => {
        setCuentasSeleccionadas((prevState) => {
            if (prevState.some(cuenta => cuenta.id === id)) {
                return prevState.filter(cuenta => cuenta.id !== id);
            } else {
                return [...prevState, { id, monto }];
            }
        });
    };

    return {
        postDetalleCuentaPorCobrar: postOnClick,
        cuentasSeleccionadas,
        error,
        cursos,
        cuentasPendientes,
        cursoSeleccionado,
        handleCursoChange,
        sumaMontosSeleccionados,
        formatFecha,
        //handleFinalizarSeleccion,
        handleCheckboxChange,
        getCuentasPendientesPorCurso,
        cursoSeleccionado,
        idParticipante
    }
}

export { useDetallesCuentasPorCobrar }