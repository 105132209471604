import React, { useState } from 'react';

const SourceInfoForm = ({ studentData, setStudentData, handlePreviousStep, handleNextStep }) => {
  const [selectedMethod, setSelectedMethod] = useState(studentData.fuente_informacion || '');
  const [otherMethod, setOtherMethod] = useState(studentData.fuente_informacion || ''); // Usa fuente_informacion
  const [errors, setErrors] = useState({});

  const knowledgeOptions = [
    'Redes Sociales',
    'Recomendación de un amigo',
    'Publicidad',
    'Página web',
    'Correo Electrónico',
    'Evento de la universidad',
    'Otro'
  ];

  const handleOptionChange = (method) => {
    setSelectedMethod(method);
    const finalValue = method !== 'Otro' ? method : otherMethod;
    setStudentData((prevData) => ({
      ...prevData,
      fuente_informacion: finalValue // Guarda en fuente_informacion
    }));
    if (method !== 'Otro') {
      setOtherMethod(''); // Limpia el campo de "Otro" si no es la opción seleccionada
    }
  };

  const handleOtherMethodChange = (e) => {
    const value = e.target.value;
    setOtherMethod(value);
    setStudentData((prevData) => ({
      ...prevData,
      fuente_informacion: value // Actualiza fuente_informacion con el valor de "Otro"
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!selectedMethod) {
      newErrors.knowledge_method = 'Por favor selecciona una opción.';
    } else if (selectedMethod === 'Otro' && !otherMethod.trim()) {
      newErrors.other_method = 'Por favor especifica el método.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-2xl p-8 space-y-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center text-sky-700">¿Cómo te enteraste del curso?</h1>
      <p className="text-center text-gray-600">Selecciona una opción que describa cómo obtuviste la información del curso.<span className="text-red-500">*</span></p>

      <div className="space-y-4">
        {knowledgeOptions.map((method) => (
          <div key={method} className="flex items-center">
            <input
              type="radio"
              name="knowledge_method"
              value={method}
              checked={selectedMethod === method}
              onChange={() => handleOptionChange(method)}
              className="w-4 h-4 border-gray-300 rounded text-sky-700 focus:ring-sky-500"
            />
            <label className="ml-2 text-sm font-semibold text-gray-600">{method}</label>
          </div>
        ))}

        {selectedMethod === 'Otro' && (
          <div>
            <label className="block mb-1 text-sm font-semibold text-gray-600">Especifica otro método:</label>
            <input
              type="text"
              name="other_method"
              value={otherMethod}
              onChange={handleOtherMethodChange}
              placeholder="Especifica otro método"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
            />
            {errors.other_method && <p className="text-red-600">{errors.other_method}</p>}
          </div>
        )}

        {errors.knowledge_method && <p className="text-red-600">{errors.knowledge_method}</p>}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="px-4 py-2 text-lg font-semibold text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none"
        >
          Atrás
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-lg font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
        >
          Siguiente
        </button>
      </div>
    </form>
  );
};

export default SourceInfoForm;
