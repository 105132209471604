import React from 'react';

function AsignarPlantillas({ plantillas, plantillasSeleccionadas, handleChangePlantillas }) {
    return (
        <div className="container mb-3">
            <div className="row mt-2">
                {plantillas.length > 0 ? (
                    plantillas.map((plantilla) => {
                        // Encontrar si la plantilla está seleccionada
                        const plantillaSeleccionada = plantillasSeleccionadas.find(
                            (p) => p.idPlantilla === plantilla.idPlantilla
                        );

                        return (
                            <div key={plantilla.idPlantilla} className="row mb-3">
                                <div className="card col-12">
                                    <div className="card-body d-flex justify-content-between align-items-center">
                                        <div>
                                            <label className="form-check-label" htmlFor={`plantilla-${plantilla.idPlantilla}`}>
                                                {plantilla.concepto} : ${plantilla.monto}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="plantilla"
                                                id={`plantilla-${plantilla.idPlantilla}`}
                                                value={plantilla.idPlantilla}
                                                checked={!!plantillaSeleccionada} // Check si está seleccionada
                                                onChange={(e) => handleChangePlantillas(plantilla, e)}
                                            />
                                        </div>
                                    </div>

                                    {/* Mostrar detalles si la plantilla está seleccionada */}
                                    {plantillaSeleccionada && (
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                            <div>
                                                <label htmlFor={`txtGenerar-${plantilla.idPlantilla}`} className="form-label">Generar recargo</label>
                                                <input
                                                    id={`txtGenerar-${plantilla.idPlantilla}`}
                                                    type="checkbox"
                                                    className="form-check-input ms-2"
                                                    name="generar"
                                                    checked={plantillaSeleccionada.generarRecargo || false}
                                                    onChange={(e) => handleChangePlantillas(plantilla, e)}
                                                />
                                            </div>
                                            {(plantillaSeleccionada.generarRecargo) ?
                                                <>
                                                    <div>
                                                        <label htmlFor={`txtValorRecargo-${plantilla.idPlantilla}`} className="form-label">Valor recargo $</label>
                                                        <input
                                                            id={`txtValorRecargo-${plantilla.idPlantilla}`}
                                                            value={plantillaSeleccionada.valorRecargo || ''}
                                                            type="number"
                                                            className="form-control"
                                                            name="valorRecargo"
                                                            onChange={(e) => handleChangePlantillas(plantilla, e)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor={`txtVencimiento-${plantilla.idPlantilla}`} className="form-label">Fecha vencimiento</label>
                                                        <input
                                                            id={`txtVencimiento-${plantilla.idPlantilla}`}
                                                            value={plantillaSeleccionada.vencimiento ? plantillaSeleccionada.vencimiento.split('T')[0] : ''}
                                                            type="date"
                                                            className="form-control"
                                                            name="vencimiento"
                                                            onChange={(e) => handleChangePlantillas(plantilla, e)}
                                                        />
                                                    </div>
                                                </> : null}
                                            <div>
                                                <label htmlFor={`txtExigible-${plantilla.idPlantilla}`} className="form-label">Exigible</label>
                                                <input
                                                    id={`txtExigible-${plantilla.idPlantilla}`}
                                                    type="checkbox"
                                                    className="form-check-input ms-2"
                                                    name="exigible"
                                                    checked={plantillaSeleccionada.exigible || false}
                                                    onChange={(e) => handleChangePlantillas(plantilla, e)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p>No hay plantillas disponibles.</p>
                )}
            </div>
        </div>
    );
}

export { AsignarPlantillas };
