import { useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../hooks/useAlert';
import { Cursos } from '../../models/Cursos';
import { Grupos } from '../../models/Grupos';
import { Horarios } from '../../models/Horarios';
import { Actividades } from '../../models/Actividades';
import { Notas } from '../../models/Notas';

const useListadoMisCursos = () => {

    //Todas las constantes
    const { getFetch } = useFetch();
    const [misCursos, setMisCursos] = useState([]);
    const [detallesCursos, setDetallesCursos] = useState([]);
    const navegar = useNavigate();
    const { mensajeError, mensajeExitoso, mensajeAdvertencia, mensajeInformacion } = useAlert();

    //Todas las funciones
    const pagDetalles = (id) => {
        navegar(`/Listado/${id}`);//Enviar parametros por url
    }

    //Todos los gets
    const getMisCursos = (idUsuario) => {
        const urlParcial = `misCursos/cursosActivos/${idUsuario}`
        getFetch(urlParcial)
            .then((data) => {
                const { mensaje, estado, datos } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item => {
                        const cursos = Cursos(item.idCurso, item.curso, item.imagen);
                        const grupos = Grupos('', item.grupo, item.modalidad, item.lugar, item.idCurso);
                        const horarios = Horarios('', '', '', '', '', item.dias);
                        return {
                            ...cursos,
                            ...grupos,
                            ...horarios
                        };
                    });

                    setMisCursos(datosEstandarizados);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    }

    const getDetalleCursos = (idUsuario, idCurso) => {
        const urlParcial = `misCursos/NotaModulo/${idUsuario}/${idCurso}`
        getFetch(urlParcial)
            .then((data) => {
                const { mensaje, estado, datos } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item => {
                        const cursos = Cursos(item.idCurso, item.curso);
                        const actividad = Actividades(item.idActividad, item.idCurso, item.actividad, item.porcentaje);
                        const nota = Notas(item.idNota, item.nota);
                        return {
                            ...cursos,
                            ...actividad,
                            ...nota
                        };
                    });

                    setDetallesCursos(datosEstandarizados);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            })
    }

    return {
        getMisCursos,
        misCursos,
        pagDetalles,
        getDetalleCursos,
        detallesCursos
    }
}
export { useListadoMisCursos }