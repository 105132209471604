
function Participantes(idParticipante = 0, expediente = '', codigo = '', carnetMinoridad = '', nie = '', fechaNacimiento = null, telefonoFijo = '', ocupacion = '', padecimiento = '', estadoFamiliar = '', idUsuario = 0, lugarNacimiento = 0, idDireccion = 0, idResponsable = 0) {
    return {
        idParticipante, 
        expediente, 
        codigo, 
        carnetMinoridad, 
        nie, 
        fechaNacimiento, 
        telefonoFijo, 
        ocupacion, 
        padecimiento, 
        estadoFamiliar, 
        idUsuario, 
        lugarNacimiento, 
        idDireccion, 
        idResponsable
    }
}

export { Participantes }