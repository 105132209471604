import React, { useState, useEffect } from "react";
import NavbarPrincipal from "./NavbarPrincipal";
import courseService from "../../services/courseService";

const AssignCourses = () => {
  const [courses, setCourses] = useState([]);
  const [formData, setFormData] = useState({
    nombre: "",
    descripcion: "",
    fecha_inicio: "",
    fecha_fin: "",
    anio_curso: "",
  });
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [activo, setActivo] = useState(false);

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editing) {
      await courseService.updateCourse(editId, formData);
    } else {
      await courseService.createCourse(formData);
    }
    setFormData({
      nombre: "",
      descripcion: "",
      fecha_inicio: "",
      fecha_fin: "",
      anio_curso: "",
    });
    setEditing(false);
    setEditId(null);
    fetchCourses();
  };

  const handleEdit = (course) => {
    setFormData({
      nombre: course.nombre,
      descripcion: course.descripcion,
      fecha_inicio: new Date(course.fecha_inicio).toISOString().split("T")[0], // Formato YYYY-MM-DD
      fecha_fin: new Date(course.fecha_fin).toISOString().split("T")[0], // Formato YYYY-MM-DD
      anio_curso: course.anio_curso,
    });
    setEditing(true);
    setEditId(course.idCursos);
  };

  const openModal = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setDeleteId(null);
  };

  const handleDelete = async () => {
    await courseService.deleteCourse(deleteId);
    setShowModal(false);
    fetchCourses();
  };

  const handleOnChangeEstadoCourse = async (course) => {
    console.log("course", course);
    const { idCursos, estado_activo } = course;
    const nuevoEstado = estado_activo === 1 ? false : true;
    console.log("nuevoEstado", nuevoEstado);
    try {
      const algo = await courseService.updateCourseStatus(idCursos, {
        estado_activo: nuevoEstado,
      });
      console.log("algo", algo);
      fetchCourses();
    } catch (error) {
      console.error("Error al actualizar el estado del curso:", error);
    }
  };

  const handleVisibilityToggle = async (id, estadoActual) => {
    console.log("id", id);
    console.log("estadoActual", estadoActual);

    try {
      //const estadoActivo = !estadoActual; // Cambia el estado actual al opuesto
      setActivo(!estadoActual);
      const algo = await courseService.updateCourseStatus(id, {
        estado_activo: true,
      });
      console.log("algo", algo);
      fetchCourses(); // Refresca los datos después de actualizar
    } catch (error) {
      console.error("Error al actualizar el estado del curso:", error);
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await courseService.getCourses();
      console.log(response);
      setCourses(response.data);
    } catch (error) {
      console.error("Error al obtener los cursos:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <NavbarPrincipal />
      <div className="flex items-center justify-center flex-grow p-6">
        <div className="w-full max-w-4xl">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">
            Administrar Cursos
          </h1>

          <form
            onSubmit={handleSubmit}
            className="p-6 mb-8 bg-white rounded-lg shadow-lg"
          >
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                placeholder="Nombre"
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                placeholder="Descripción"
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="date"
                name="fecha_inicio"
                value={formData.fecha_inicio}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="date"
                name="fecha_fin"
                value={formData.fecha_fin}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="number"
                name="anio_curso"
                value={formData.anio_curso}
                onChange={handleChange}
                placeholder="Año del curso"
                required
                className="p-2 border border-gray-300 rounded"
              />
            </div>
            <button
              type="submit"
              className="w-full p-2 mt-4 text-white rounded bg-sky-600 hover:bg-sky-700"
            >
              {editing ? "Actualizar Curso" : "Crear Curso"}
            </button>
          </form>

          <h2 className="mb-4 text-2xl font-semibold text-center text-sky-700">
            Lista de Cursos
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full bg-white border border-gray-300 rounded-lg shadow-md">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-3 text-left">Nombre</th>
                  <th className="p-3 text-left">Descripción</th>
                  <th className="p-3 text-left">Fecha Inicio</th>
                  <th className="p-3 text-left">Fecha Fin</th>
                  <th className="p-3 text-left">Año del Curso</th>
                  <th className="p-3 text-left">Estado</th>{" "}
                  {/* Nueva columna */}
                  <th className="p-3 text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {courses.length === 0 ? (
                  <tr>
                    <td
                      className="p-3 text-center text-gray-600 border-t border-gray-300"
                      colSpan="7" // Actualizado para incluir la nueva columna
                    >
                      No hay cursos registrados.
                    </td>
                  </tr>
                ) : (
                  courses.map((course) => (
                    <tr key={course.idCursos} className="hover:bg-gray-100">
                      <td className="p-3 border-t border-gray-300">
                        {course.nombre}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {course.descripcion}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {new Date(course.fecha_inicio).toLocaleDateString()}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {new Date(course.fecha_fin).toLocaleDateString()}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {course.anio_curso}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {/* Estado del curso */}
                        <label className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            checked={course.estado_activo === 1} // Verificar booleano
                            onChange={() => handleOnChangeEstadoCourse(course)}
                          />
                          <span>
                            {course.estado_activo ? "Activo" : "Inactivo"}
                          </span>
                        </label>
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {/* Acciones */}
                        <button
                          onClick={() => handleEdit(course)}
                          className="p-1 mr-2 text-white bg-yellow-500 rounded hover:bg-yellow-600"
                        >
                          Editar
                        </button>
                        <button
                          onClick={() => openModal(course.idCursos)}
                          className="p-1 text-white bg-red-500 rounded hover:bg-red-600"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {/* Modal de Confirmación */}
          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
              <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
                <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">
                  Confirmación
                </h2>
                <p className="mb-6 text-center text-gray-600">
                  ¿Estás seguro de que deseas eliminar este curso?
                </p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={handleDelete}
                    className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
                  >
                    Confirmar
                  </button>
                  <button
                    onClick={closeModal}
                    className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignCourses;
