import React from 'react'
import { BtnAgregar } from '../../buttons/BtnAgregar'
import { GestionCursos } from '../cursos/GestionCursos'
import { BtnEditar } from '../../buttons/BtnEditar'
import { Cursos } from '../../models/Cursos'


function GestionDiplomados({ diplomado, expandedDiplomadoId, toggleDiplomado, openModalCursos, openModalDiplomados, openModalActividades, openModalPlantillas, expandedCursoId, toggleCurso, expandedGrupoId, toggleGrupo, openModalGrupos, openModalHorarios, deleteOnClick, idCiclo, tipoFiltro, calcularHoras, abrirModalSeleccion }) {
    return (
        <div key={diplomado.idDiplomado}>
            <div className="d-flex">
                <div className="p-2 flex-grow-1">
                    <BtnEditar
                        titulo={expandedDiplomadoId === diplomado.idDiplomado ? '-' : '+'}
                        databstogle="collapse"
                        databstarget={`#collapseDiplomado${diplomado.idDiplomado}`}
                        onclick={() => toggleDiplomado(diplomado.idDiplomado)}
                    />
                    {diplomado.nombreDiplomado}
                </div>
                <div className="p-2">
                    <BtnEditar titulo="Editar Diplomado" onclick={() => openModalDiplomados(diplomado)} />
                    <BtnAgregar add="ms-2" titulo="Agregar Curso" onclick={() => openModalCursos(Cursos(null, null, null, null, null, null, null, null, null, diplomado.idDiplomado, null), false)} />
                </div>
            </div>
            {expandedDiplomadoId === diplomado.idDiplomado && (
                <div className="card card-body ms-5" id={`collapseDiplomado${diplomado.idDiplomado}`}>
                    <h3>Cursos:</h3>
                    {(diplomado.cursos.length > 0) ? diplomado.cursos.map((curso) => (
                        <GestionCursos
                            key={curso.idCurso} curso={curso}
                            openModalCursos={openModalCursos}
                            openModalGrupos={openModalGrupos}
                            openModalHorarios={openModalHorarios}
                            expandedCursoId={expandedCursoId}
                            toggleCurso={toggleCurso}
                            toggleGrupo={toggleGrupo}
                            expandedGrupoId={expandedGrupoId}
                            deleteOnClick={deleteOnClick}
                            openModalActividades={openModalActividades}
                            openModalPlantillas={openModalPlantillas}
                            idCiclo={idCiclo}
                            tipoCurso={tipoFiltro}
                            calcularHoras={calcularHoras}
                            abrirModalSeleccion={abrirModalSeleccion}
                        />
                    )) : null}
                </div>
            )}
        </div>
    )
}

export { GestionDiplomados }