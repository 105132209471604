import axios from 'axios';
import {API_URL} from '../config';

const GROUP_URL = `${API_URL}/groups`;

const createGroup = (groupData) => {
  return axios.post(`${GROUP_URL}/create`, groupData);
};

const getAllGrupos = async () => {
  return axios.get(`${GROUP_URL}/all`);
};

const updateGroup = (id, groupData) => {
  return axios.put(`${GROUP_URL}/update/${id}`, groupData);
};

const deleteGroup = (id) => {
  return axios.delete(`${GROUP_URL}/delete/${id}`);
};

const getGroupsByCourse = async (courseId) => {
  return axios.get(`${API_URL}/groups/by-course/${courseId}`);
};
export default { createGroup, getAllGrupos, updateGroup, deleteGroup, getGroupsByCourse };
