import { useEffect, useState } from "react";
import { useAgrupar } from "../../hooks/useAgrupar";
import { useFetch } from "../../tools/useFetch";
import { useAlert } from "../../hooks/useAlert";
import { useListadoCiclos } from "../ciclos/useListadoCiclos";

function useAdministracionCursoDiplomado() {
    const { ciclos, getCiclos } = useListadoCiclos();
    const { agruparDatos } = useAgrupar();
    const [cursos, setCursos] = useState([]);
    const [diplomados, setDiplomados] = useState([]);
    const { getFetch } = useFetch();
    const { mensajeError } = useAlert();
    // Estado para controlar el tipo seleccionado
    const [tipoFiltro, setTipoFiltro] = useState('1'); // '1' para "Diplomados"
    const [cicloSelect, setCicloSelect] = useState(0);
    const [anioSelect, setAnioSelect] = useState('');

    useEffect(() => {
        if (ciclos && ciclos.length > 0) {
            setCicloSelect((cicloSelect) ? cicloSelect : ciclos[0].idCiclo);
            setAnioSelect((anioSelect) ? anioSelect : ciclos[0].anio);
        }
    }, [ciclos]);

    useEffect(() => {
        if (cicloSelect != 0) {
            obtenerCursos();
        }
    }, [cicloSelect]);

    //Traer informacion de los cursos en inscripcion
    const obtenerCursos = () => {
        const urlParcial = `diplomados/${cicloSelect}`;
        getFetch(urlParcial)
            .then((data) => {
                const { datos, mensaje, estado } = data;
                if (estado) {
                    // Si no hay diplomados, podemos ignorar esta parte
                    const { cursos, diplomados } = agruparDatos(datos);

                    setCursos(cursos); // Actualiza el estado con los cursos procesados
                    setDiplomados(diplomados);
                } else {
                    mensajeError(mensaje); // Manejo de error si la API falla
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    //Parte para filtrar

    // Manejador del cambio en el select
    const handleChangeTipo = (e) => {
        setTipoFiltro(e.target.value);
    };

    // Manejador del cambio en el select
    const handleChangeCiclo = (event) => {
        const selectedId = event.target.value; // Obtienes el idCiclo seleccionado
        const selectedCiclo = ciclos.find(ciclo => ciclo.idCiclo === parseInt(selectedId)); // Busca el objeto
        setCicloSelect(selectedCiclo.idCiclo);
        setAnioSelect(selectedCiclo.anio);
    };

    // Filtrar los cursos según el tipo seleccionado
    const cursosFiltrados = Object.values(cursos).filter((curso) => {
        if (tipoFiltro === '0') return true; // Todos
        if (tipoFiltro === '2' || tipoFiltro === '3') return curso.idTipoCurso === parseInt(tipoFiltro);
        return false; // Si se selecciona "1" (Diplomados), no mostramos cursos
    });

    return {
        ciclos,
        cicloSelect,
        anioSelect,
        handleChangeCiclo,
        cursos,
        obtenerCursos,
        datos: diplomados,
        handleChangeTipo,
        tipoFiltro,
        cursosFiltrados,
        setTipoFiltro,
        getCiclos
    }
}

export { useAdministracionCursoDiplomado }