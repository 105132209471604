import { useState } from "react";
import { useFetch } from "../../tools/useFetch";
import { useAlert } from "../../hooks/useAlert";
import { Cursos } from "../../models/Cursos";

function useSeleccionCurso({ obtenerCursos, setShowModalCursosSeleccion }) {

    const { getFetch, postFetch } = useFetch();
    const [cursos, setCursos] = useState([]);
    const { mensajeError, mensajeExitoso } = useAlert();
    const [esParaDetalles, setEsParaDetalles] = useState(false);
    const [idCurso, setIdCurso] = useState(0);

    const obtenerCursosFinalizados = (tipoFiltro) => {
        if (tipoFiltro === '1') {
            return;
        }
        const urlParcial = `cursos/finalizados/${tipoFiltro}`
        getFetch(urlParcial)
            .then((data) => {
                const { datos, estado, mensaje } = data;
                if(estado){
                    const datosEstandarizados = datos.map(item => 
                        Cursos(item.idCurso, item.curso)
                    );
                    setCursos(datosEstandarizados);
                }else{
                    mensajeError(mensaje);
                }
                
            })
            .catch((error) => {
                console.log("Error->", error);
            })
    }

    const obtenerCursosConDetallesPlantillas = () => {
        const urlParcial = `/cursos/duplicardetalles`
        getFetch(urlParcial)
            .then((data) => {
                const { datos, estado, mensaje } = data;
                if(estado){
                    const datosEstandarizados = datos.map(item => 
                        Cursos(item.idCurso, item.curso)
                    );
                    setCursos(datosEstandarizados);
                }else{
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                console.log("Error->", error);
            })
    }

    const insertarReplicaCurso = (datosRep) => {
        const urlParcial = `cursos/duplicar`;
        postFetch(urlParcial, datosRep)
            .then((respuesta) => {
                const { estado, mensaje, datos } = respuesta;
                if (estado) {
                    const datosAct = {
                        idInsertado: datos.insertId,
                        idCurso: datosRep.idCurso
                    }
                    insertarReplicaActividad(datosAct);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };

    const insertarReplicaDetallesPlantillas = (datos) => {
        const urlParcial = `detalles_plantillas/duplicar`;
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { estado, mensaje } = respuesta;
                if (estado) {
                    mensajeExitoso("Pagos duplicados exitosamente.");
                    setShowModalCursosSeleccion(false);
                    obtenerCursos();
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };

    const insertarReplicaActividad = (datosRep) => {
        const urlParcial = `actividades/duplicar`;
        postFetch(urlParcial, datosRep)
            .then((respuesta) => {
                const { estado, mensaje } = respuesta;
                if (estado) {
                    mensajeExitoso("Curso duplicado exitosamente.");
                    setShowModalCursosSeleccion(false);
                    obtenerCursos();
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador. " + error);
            });
    };

    const handleOnClick = (idCiclo, idCursoDuplicar) => {
        if(esParaDetalles){
            setShowModalCursosSeleccion(false);
            const datos = { idCursoDuplicar: idCursoDuplicar, idCiclo: idCiclo, idCurso: idCurso }
            insertarReplicaDetallesPlantillas(datos);            
            return;
        }
        const datos = {
            idCiclo: idCiclo,
            idCurso: idCursoDuplicar,
            idDiplomado: null
        }
        insertarReplicaCurso(datos);
    };

    return {
        obtenerCursosConDetallesPlantillas,
        obtenerCursosFinalizados,
        handleOnClick,
        cursos,
        esParaDetalles,
        setEsParaDetalles,
        setIdCurso
    }
}

export { useSeleccionCurso }