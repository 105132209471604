import { createContext, useContext, useState, useEffect } from 'react';
import { useFetch } from '../tools/useFetch';
import { jwtDecode } from 'jwt-decode'; // Importar la librería para decodificar JWT

const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { getFetch } = useFetch();

  const getUser = () => {
    return new Promise((resolve, reject) => {
      const urlParcial = 'cookies/obtenercookie';
      getFetch(urlParcial)
        .then((data) => {
          const { datos, mensaje, estado } = data;
          if (estado) {
            if (datos) {
              const decoded = jwtDecode(datos);
              setUser(decoded.info);
              resolve(decoded.info);
            }
          } else {
            console.log(mensaje);
            resolve(null); // Sigue resolviendo para finalizar la promesa
          }
        })
        .catch((error) => {
          console.log('Error inesperado, contacte al programador. ' + error);
          reject(error); // Rechaza la promesa en caso de error
        });
    });
  };

  const deleteUser = () => {
    const urlParcial = 'cookies/removercookie';
    getFetch(urlParcial)
      .then((data) => {
        const { mensaje, estado } = data;
        if (estado) {
          setUser(null); // Establecer el estado del usuario a null al eliminar la cookie
        } else {
          console.log(mensaje);
        }
      })
      .catch((error) => {
        console.log('Error inesperado, contacte al programador. ' + error);
      });
  };

  return (
    <DataContext.Provider value={{ user, setUser, deleteUser, getUser }}>
      {children}
    </DataContext.Provider>
  );
};

export const useAuth = () => useContext(DataContext);

