import React, { useEffect } from 'react'
import { useListadoDepartamentos } from './useListadoDepartamentos';
import { ListaMunicipios } from '../municipios/ListadoMunicipios';
import { useLocation } from 'react-router-dom';
import { BtnEditar } from '../../buttons/BtnEditar';
import { BtnEliminar } from '../../buttons/BtnEliminar';

function GestionDepartamentos() {

    const location = useLocation();
    const { departamento, operacion } = location.state || {};

    const {
        departamentoSeleccionado,
        setOperacion,
        setDepartamentoSeleccionado,
        iradepartamentos,
        handleChange,
        validarCampos
    } = useListadoDepartamentos();

    useEffect(() => {
        (operacion) ? setOperacion(operacion) : setOperacion(1);
        (departamento) ? setDepartamentoSeleccionado(departamento) : setDepartamentoSeleccionado(null)
    }, [operacion, departamento]);

    return (

        <div className='container mt-custom'>
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-4">
                    <label htmlFor="txtIdDepartamento" className="form-label">ID</label>
                    <input
                        id='txtIdDepartamento'
                        onChange={handleChange}
                        value={departamentoSeleccionado.idDepartamento}
                        type="number"
                        className="form-control"
                        name="idDepartamento"
                        disabled
                    />
                </div>
                <div className="col-md-5">
                    <label htmlFor="txtDepartamento" className="form-label">Departamento</label>
                    <input
                        id='txtDepartamento'
                        onChange={handleChange}
                        value={departamentoSeleccionado.departamento}
                        type="text"
                        className="form-control"
                        name="departamento"
                        required
                    />
                </div>
                <div className="col-md-5">
                    <label htmlFor="txtCodigo" className="form-label">Código</label>
                    <input
                        id='txtCodigo'
                        onChange={handleChange}
                        value={departamentoSeleccionado.codigo}
                        type="text"
                        className="form-control"
                        name="codigo"
                        required
                    />
                </div>

            </form>
            <div className='row container mt-3'>

                {(departamentoSeleccionado && departamentoSeleccionado.idDepartamento) ?
                    <>
                        <div className="col-md-12">
                            <div className="p-3 border">
                                <ListaMunicipios idDepartamento={departamentoSeleccionado.idDepartamento} />
                            </div>
                        </div>
                    </> : null

                }

                <div className="col-12">
                    <hr />
                    <BtnEliminar add={'me-2'} type='button' onclick={iradepartamentos} titulo={'Cancelar'} />
                    <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                    
                </div>
            </div>

        </div>
    )
}

export default GestionDepartamentos