import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarPrincipal from '../NavPrincipal/NavbarPrincipal';
import VerticalMenu from './VerticalMenu';
import { API_URL } from '../../config';
import Footer from '../Footer';

const Payment = () => {
  const [paymentData, setPaymentData] = useState({
    nombre: '', tarjeta: '', venceyear: '', vencemonth: '', cvv: ''
  });

  const [error, setError] = useState(false); // nuevo estado para manejar el error
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const navigate = useNavigate();
  const [isPaid, setIsPaid] = useState(false);
  const [enabledRoutes, setEnabledRoutes] = useState({
    inscriptionProcess: true,
    profile: false,
    schedulesAndGroups: false,
    payment: true,
    grades: false,
  });

  const token = localStorage.getItem('token');

  const [estado, setEstado] = useState(null);

  // Generar opciones para el selector de años desde el año actual hasta 10 años adelante
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 10 }, (_, index) => currentYear + index);

  // Obtener el costo del curso al cargar el componente
  useEffect(() => {
    const fetchCostoCurso = async () => {
      try {
        const tipoIncripcion = await axios.get(`${API_URL}/student/get-inscripcion`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (tipoIncripcion.data.tipoInscripcion === '5') {
          const pagoEquivalencias = await axios.get(`${API_URL}/config/pago-equivalencias`);
          //console.log('pagoEquivalencias', pagoEquivalencias.data);
          setPaymentData((prevState) => ({
            ...prevState,
            monto: pagoEquivalencias.data.costo_curso,
            ciclo: pagoEquivalencias.data.ciclo_actual,
            codigoservicio: pagoEquivalencias.data.codigo_servicio,
            anioactual: pagoEquivalencias.data.anio_curso
          }
          ));
        }
        else {
          //console.log('No estoy aquí!!!!!')
          const pagoNormal = await axios.get(`${API_URL}/config/pago`);
          setPaymentData((prevState) => ({
            ...prevState,
            monto: pagoNormal.data.costo_curso, // Usamos costo_curso de la respuesta del controlador
            ciclo: pagoNormal.data.ciclo_actual, // Usamos anio_curso en lugar de ciclo_actual
            codigoservicio: pagoNormal.data.codigo_servicio,
            anioactual: pagoNormal.data.anio_curso
          }
          ));
          //console.log('pagoNormal.data:', pagoNormal.data); // Verificar el contenido de response.data
        }
        //console.log('paymentData = ', paymentData)
      } catch (error) {
        console.error('Error al obtener el costo del curso:', error);
      }
    };
    fetchCostoCurso();
  }, []);

  useEffect(() => {
    const fetchEstudianteId = async () => {
      try {
        const res = await axios.get(`${API_URL}/student/find-id`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPaymentData((prevState) => ({
          ...prevState,
          personaid: res.data.id
        }
        ));
        //console.log('personaid', res.data);
      } catch (error) {
        console.error('Error al obtener el id de la persona', error);
      }
    };
    fetchEstudianteId();
  }, []);

  // Comprobar el estado de pago al cargar el componente
  useEffect(() => {
    const checkEstadoPago = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/student/estado-pago`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.estado_pago === 1) {
          setIsPaid(true);
          // Si el pago está completo, deshabilita el formulario
          const routes = {
            inscriptionProcess: true, // Siempre habilitado
            profile: true, // Siempre habilitado
            schedulesAndGroups: true,
            payment: true,
            grades: true, // Habilitado solo si el pago está completo
          };

          setEnabledRoutes(routes);
        }
      } catch (error) {
        console.error('Error al obtener el estado de pago:', error);
      }
    };

    checkEstadoPago();
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!paymentData.nombre.trim()) newErrors.nombre = 'El nombre en la tarjeta es requerido';
    if (!/^\d{4} \d{4} \d{4} \d{4}(\s?\d{0,3})?$/.test(paymentData.tarjeta)) newErrors.tarjeta = 'Número de tarjeta inválido';
    if (!paymentData.vencemonth) newErrors.vencemonth = 'Mes de expiración inválido';
    if (!paymentData.venceyear) newErrors.venceyear = 'Año de expiración inválido';
    if (!/^\d{3,4}$/.test(paymentData.cvv)) newErrors.cvv = 'CVV inválido';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;
    if (name === "tarjeta") {
      // Formateo automático para el número de tarjeta en bloques de 4
      formattedValue = value.replace(/\D/g, "").replace(/(.{4})/g, "$1 ").trim();
    } else if (name === "cvv") {
      // Solo permite dígitos en el CVV
      formattedValue = value.replace(/\D/g, "");
    }

    setPaymentData((prevData) => ({
      ...prevData,
      [name]: formattedValue
    }));
  };

  const confirmAndSubmit = async () => {
    setShowConfirmModal(false);
    setLoading(true);
    setError(false);
    if (!validate()) {
      setLoading(false); // Rehabilita el botón si la validación falla
      return;
    }
    //console.log('paymentData:', paymentData); // Verificar el contenido de paymentData

    setErrors({});
    setSuccess(false);

    const token = localStorage.getItem('token');
    try {
      const dataToSend = {
        ...paymentData,
        fecha: new Date().toISOString().split('T')[0],
        esrecargo: "NO",
      };
      //console.log('dataToSend:', dataToSend);

      // Realiza la solicitud de pago al servidor
      const response = await axios.post(`${API_URL}/aux/process-payment`, dataToSend);
      //console.log('response.data:', response.data);

      /*
      const response = {
        ok:true,
        mensaje:'Pago realizado',
        dato:null
      }
        */

      // Destructuramos la respuesta para extraer "ok", "mensaje", y "data"
      const { ok, mensaje, data } = response.data;
      console.log('response: ', response);
      if (ok === true) {
        setError(false);
        setSuccess(true); // Mantenemos el éxito para deshabilitar el botón
        setLoading(false);

        // Desencadenar evento de éxito
        await axios.put(`${API_URL}/student/set-pago`, { estado_pago: true }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await axios.get(`${API_URL}/student/get-data-sinergia`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const statusResponse = await axios.get(`${API_URL}/student/get-status`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const estadoActualizado = statusResponse.data.estado;

        // Agregar retraso antes de redirigir
        setTimeout(() => {

          if (estadoActualizado === 'ciencia_datos' || estadoActualizado === 'form_exonerado_completo') {
            navigate('/profile');
          } else {
            navigate('/grades');
          }
        }, 3000);
      } else {
        setLoading(false);
        setError(true);
        setErrors({ submit: 'Error en el pago: ' + (mensaje || 'Error desconocido') });
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setErrors({ submit: 'Error al procesar el pago: ' + (error.data?.message || error.message) });
    }
  };
  const handleRedirect = async () => {
    const token = localStorage.getItem('token');
    try {
      // Obtén el estado actualizado del estudiante desde el backend
      const response = await axios.get(`${API_URL}/student/get-status`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const estado = response.data.estado;

      // Redirige a la ruta correcta según el estado
      if (estado === 'ciencia_datos' || estado === 'form_exonerado_completo') {
        navigate('/profile');
      } else {
        navigate('/grades');
      }
    } catch (error) {
      console.error("Error obteniendo el estado del estudiante:", error);
    }
  };


  return (
    <div className="flex flex-col min-h-screen">
      <NavbarPrincipal />
      <div className="flex flex-grow bg-gray-100">
        <VerticalMenu enabledRoutes={enabledRoutes} />
        <div className="flex items-center justify-center flex-1 p-6 pl-20">
          {isPaid ? ( // Condicional para mostrar el mensaje de pago completado o el formulario
            <div className="w-full max-w-md p-6 space-y-4 bg-white rounded-lg shadow-md">
              <h2 className="mb-4 text-xl font-semibold text-center text-sky-700">Pago Completado</h2>
              <p className="mb-4 text-center text-gray-600">
                Ya has completado el pago. Puedes continuar con el proceso.
              </p>
              <div className="flex justify-center"> {/* Contenedor para centrar el botón */}
                <button
                  onClick={handleRedirect}
                  className="px-6 py-2 text-lg font-semibold text-center text-white rounded-lg bg-lightgreentxt hover:bg-darkgreentxt"
                >
                  Siguiente
                </button>
              </div>
            </div>

          ) : (
            <form onSubmit={(e) => { e.preventDefault(); setShowConfirmModal(true); }}
              className="w-full max-w-md p-6 space-y-4 bg-white rounded-lg shadow-md">
              <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">Pago con Tarjeta</h1>
              <div className="mb-4">
                <input
                  type="text"
                  name="nombre"
                  placeholder="Nombre en la Tarjeta"
                  value={paymentData.nombre}
                  onChange={handleChange}
                  required
                  disabled={loading || isPaid}
                  className={`w-full p-2 border ${errors.nombre ? 'border-red-500' : 'border-gray-300'} rounded`}
                />
                {errors.nombre && <p className="mt-1 text-red-600">{errors.nombre}</p>}
              </div>

              <div className="mb-4">
                <input
                  type="text"
                  name="tarjeta"
                  placeholder="Número de Tarjeta"
                  value={paymentData.tarjeta}
                  onChange={handleChange}
                  required
                  disabled={loading || isPaid}
                  maxLength="19"
                  className={`w-full p-2 border ${errors.tarjeta ? 'border-red-500' : 'border-gray-300'} rounded`}
                />
                {errors.tarjeta && <p className="mt-1 text-red-600">{errors.tarjeta}</p>}
              </div>

              <div className="flex mb-4 space-x-4">
                <div>
                  <select
                    name="vencemonth"
                    value={paymentData.vencemonth}
                    onChange={handleChange}
                    required
                    disabled={loading || isPaid}
                    className={`w-full p-2 border ${errors.vencemonth ? 'border-red-500' : 'border-gray-300'} rounded`}
                  >
                    <option value="">Mes (MM)</option>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                      <option key={month} value={month < 10 ? `0${month}` : month}>
                        {month < 10 ? `0${month}` : month}
                      </option>
                    ))}
                  </select>
                  {errors.vencemonth && <p className="mt-1 text-red-600">{errors.vencemonth}</p>}
                </div>
                <div>
                  <select
                    name="venceyear"
                    value={paymentData.venceyear}
                    onChange={handleChange}
                    required
                    disabled={loading || isPaid}
                    className={`w-full p-2 border ${errors.venceyear ? 'border-red-500' : 'border-gray-300'} rounded`}
                  >
                    <option value="">Año (AAAA)</option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors.venceyear && <p className="mt-1 text-red-600">{errors.venceyear}</p>}
                </div>
                <div>
                  <input
                    type="text"
                    name="cvv"
                    placeholder="CVV"
                    value={paymentData.cvv}
                    onChange={handleChange}
                    required
                    disabled={loading || isPaid}
                    maxLength="4"
                    className={`w-full p-2 border ${errors.cvv ? 'border-red-500' : 'border-gray-300'} rounded`}
                  />
                  {errors.cvv && <p className="mt-1 text-red-600">{errors.cvv}</p>}
                </div>
              </div>

              {errors.submit && <p className="p-2 text-center text-red-600 bg-red-100 rounded-md">{errors.submit}</p>}
              {loading && <p className="text-center text-blue-500">Procesando pago...</p>}
              {success && <p className="p-2 text-center text-green-600 bg-green-100 rounded-md">Pago realizado con éxito, revise su correo electronico</p>}

              <button
                type="submit"
                className={`px-4 py-2 text-lg font-semibold rounded-lg focus:outline-none flex items-center justify-center ${loading || success || error ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-bluelight hover:bg-sky-700 text-white"
                  }`}
                disabled={loading || success || error} // se desactiva en caso de loading, success o error
              >
                {loading ? "Procesando..." : success ? "Pago completado" : error ? "Error en el pago" : "Pagar"}
              </button>
            </form>
          )}
        </div>
      </div>

      {showConfirmModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
          <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
            <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">Confirmación</h2>
            <p className="mb-6 text-center text-gray-600">¿Está seguro de que desea proceder con el pago?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={confirmAndSubmit}
                className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
              >
                Confirmar
              </button>
              <button
                onClick={() => {
                  setShowConfirmModal(false);
                  setLoading(false);
                }}
                className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Payment;
