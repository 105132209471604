import React, { useState, useEffect } from 'react';
import NavbarPrincipal from '../NavPrincipal/NavbarPrincipal';
import docenteService from '../../services/docenteService';

const ManageTeachers = () => {
  const [teachers, setTeachers] = useState([]);
  const [formData, setFormData] = useState({
    nombres: '',
    apellidos: '',
    materia: ''
  });
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  //modales
  const [showModal, setShowModal] = useState(false); // Estado para el modal
  const [deleteId, setDeleteId] = useState(null); // Estado para almacenar el ID de eliminación


  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    const response = await docenteService.getDocentes();
    setTeachers(response.data);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editing) {
      await docenteService.updateDocente(editId, formData);
    } else {
      await docenteService.createDocente(formData);
    }
    setFormData({
      nombres: '',
      apellidos: '',
      materia: ''
    });
    setEditing(false);
    setEditId(null);
    fetchTeachers();
  };

  const handleEdit = (teacher) => {
    setFormData({
      nombres: teacher.nombres,
      apellidos: teacher.apellidos,
      materia: teacher.materia
    });
    setEditing(true);
    setEditId(teacher.idDocentes);
  };

  const handleDelete = async () => {
    await docenteService.deleteDocente(deleteId);
    setShowModal(false);
    setDeleteId(null);
    fetchTeachers();
  };

  const openModal = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setDeleteId(null);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <NavbarPrincipal />
      <div className="flex items-center justify-center flex-grow p-6">
        <div className="w-full max-w-4xl">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">Administrar Docentes</h1>

          <form onSubmit={handleSubmit} className="p-6 mb-8 bg-white rounded-lg shadow-lg">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <input
                type="text"
                name="nombres"
                value={formData.nombres}
                onChange={handleChange}
                placeholder="Nombres"
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="apellidos"
                value={formData.apellidos}
                onChange={handleChange}
                placeholder="Apellidos"
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="materia"
                value={formData.materia}
                onChange={handleChange}
                placeholder="Materia"
                required
                className="p-2 border border-gray-300 rounded"
              />
            </div>
            <button
              type="submit"
              className="w-full p-2 mt-4 text-white rounded bg-sky-600 hover:bg-sky-700"
            >
              {editing ? 'Actualizar Docente' : 'Crear Docente'}
            </button>
          </form>

          <h2 className="mb-4 text-2xl font-semibold text-center text-sky-700">Lista de Docentes</h2>
          <div className="overflow-x-auto">
            <table className="w-full bg-white border border-gray-300 rounded-lg shadow-md">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-3 text-left">Nombres</th>
                  <th className="p-3 text-left">Apellidos</th>
                  <th className="p-3 text-left">Materia</th>
                  <th className="p-3 text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {teachers.length === 0 ? (
                  <tr>
                    <td className="p-3 text-center text-gray-600 border-t border-gray-300" colSpan="4">
                      No hay docentes registrados.
                    </td>
                  </tr>
                ) : (
                  teachers.map((teacher) => (
                    <tr key={teacher.idDocentes} className="hover:bg-gray-100">
                      <td className="p-3 border-t border-gray-300">{teacher.nombres}</td>
                      <td className="p-3 border-t border-gray-300">{teacher.apellidos}</td>
                      <td className="p-3 border-t border-gray-300">{teacher.materia}</td>
                      <td className="p-3 border-t border-gray-300">
                        <button
                          onClick={() => handleEdit(teacher)}
                          className="p-1 mr-2 text-white bg-yellow-500 rounded hover:bg-yellow-600"
                        >
                          Editar
                        </button>
                        <button
                          onClick={() => openModal(teacher.idDocentes)}
                          className="p-1 text-white bg-red-500 rounded hover:bg-red-600"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Modal de Confirmación */}

          { showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
              <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
                <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">Confirmación</h2>
                <p className="mb-6 text-center text-gray-600">¿Estás seguro de que deseas eliminar este docente?</p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={handleDelete}
                    className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
                  >
                    Confirmar
                  </button>
                  <button
                    onClick={closeModal}
                    className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default ManageTeachers;
