import { useEffect, useState } from 'react';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BarraBusqueda } from '../../components/BarraBusqueda';

const SeleccionDiplomado = ({ tipoFiltro, showModalDiplomadosSeleccion, cicloSelect, anioSelect, obtenerDiplomadosFinalizados, diplomados, handleOnClick, getCiclos }) => {
    const [searchText, setSearchText] = useState(''); // Estado para el texto de búsqueda
    const [filteredDiplomados, setFilteredDiplomados] = useState([]); // Estado para diplomados filtrados

    useEffect(() => {
        obtenerDiplomadosFinalizados(tipoFiltro);
    }, [showModalDiplomadosSeleccion]);

    useEffect(() => {
        // Filtra los diplomados en base al texto de búsqueda
        const filtered = diplomados.filter(diplomado =>
            diplomado.diplomado.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredDiplomados(filtered);
    }, [searchText, diplomados]);

    return (
        <div>
            {/* Barra de búsqueda */}
            <BarraBusqueda
                placeholder={"Escriba el nombre del diplomado."}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />

            {/* Mostrar diplomados filtrados */}
            {filteredDiplomados && filteredDiplomados.length > 0 ? (
                filteredDiplomados.map((diplomado) => (
                    <div key={diplomado.diplomado} className="card bg-color1 m-3">
                        <div className="card-header d-flex">
                            <div className="p-2 flex-grow-1 card-title text-color5">
                                <h4>{diplomado.diplomado}</h4>
                            </div>
                            <div className="p-2">
                                <BtnAgregar titulo={'Seleccionar y finalizar'} onclick={() => handleOnClick(anioSelect, cicloSelect, diplomado.idDiplomado, getCiclos)} />
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div>No hay diplomados disponibles</div>
            )}
        </div>
    );
};

export { SeleccionDiplomado };
