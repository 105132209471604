import React, { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from "../../hooks/useAlert"
import { Kits } from '../../models/Kits';

const useListadoKits = () => {

  const {
    mensajeAdvertencia,
    mensajeError,
    mensajeExitoso
  } = useAlert();

  //ORDENAR CONSTANTES
  const [kits, setKits] = useState([]);
  const [kitSeleccionado, setKitSeleccionado] = useState(Kits());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
  const { preguntarConfirmacion } = useAlert();

  //ORDENAR USEEFFECT
  useEffect(() => {
    getKits();
  }, []);

  //ORDENAR FUNCIONES
  const openModal = (op, kitSeleccionado = Kits()) => {
    setKitSeleccionado(kitSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Kit' : 'Editar Kit');
  };

  const deleteData = (id) => {
    deleteOnClick(id);
  };

  const validarCampos = () => {
    if (kitSeleccionado.kit.trim() === '') {
      mensajeAdvertencia("Kit no puede quedar vacio");
    } else {
      if (operacion === 1) {
        postOnClick(kitSeleccionado);
      } else {
        const id = kitSeleccionado.idKit;
        putOnClick(id, kitSeleccionado);
      }
      document.getElementById('btnCerrarModal').click();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKitSeleccionado((kitPrevio) => ({ ...kitPrevio, [name]: value }));
  };

  //ORDENAR GETS
  const getKits = () => {
    const urlParcial = "kits/";
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        const datosEstandarizados = datos.map(item =>
          Kits(
            item.idKit || 0,
            item.kit || ''
          )
        )
        setKits(datosEstandarizados);
      })
      .catch((error) => {
        console.log("Error->", error);
      });
  };

  //ORDENAR POST
  const postOnClick = (datos) => {
    const urlParcial = 'kits/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos insertados");
          getKits();
        } else {
          mensajeError("Error al insertar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  //ORDENAR PUT
  const putOnClick = (id, datos) => {
    const urlParcial = `kits/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos actualizados");
          getKits();
        } else {
          mensajeError("Error al actuaizar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  //ORDENAR DELETE
  const deleteOnClick = async (id) => {
    const urlParcial = `kits/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );

    if (!confirmed) {
      return;
    }
    
    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Registro eliminado");
          getKits();
        } else {
          mensajeError("Error al eliminar registro: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  return {
    getKits,
    kits,
    handleChange,
    titulo,
    openModal,
    kitSeleccionado,
    deleteData,
    validarCampos
  };
};

export { useListadoKits };
