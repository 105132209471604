import { useEffect, useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { useNavigate } from 'react-router-dom';

const useListadoGrupos = (idCurso, idCiclo) => {

  const {
    mensajeAdvertencia,
    mensajeError,
    mensajeExitoso,
    preguntarConfirmacion
  } = useAlert();

  const crearDatos = (grupo = null) => {
    return {
      idGrupo: grupo ? grupo.idGrupo : '',
      modalidad: grupo ? grupo.modalidad : '',
      lugar: grupo ? grupo.lugar : '',
      idUsuario: grupo ? grupo.idUsuario : '',
      grupo: grupo ? grupo.grupo : ''
    };
  };

  //ORDENAR CONSTANTES
  const navegar = useNavigate();
  const [grupos, setGrupos] = useState([]);
  const [instructores, setInstructores] = useState([]);
  const [grupoSeleccionado, setGrupoSeleccionado] = useState(crearDatos());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();

  //ORDENAR USEEFFECT
  

  //ORDENAR FUNCIONES
  const openModal = (op, grupoSeleccionado = crearDatos()) => {
    setGrupoSeleccionado(grupoSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Grupo' : 'Editar Grupo');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGrupoSeleccionado((grupoPrevio) => ({ ...grupoPrevio, [name]: value }));
  };

  const deleteData = (id) => {
    deleteOnClick(id);
  };

  const validarCampos = () => {
    if (grupoSeleccionado.grupo === '' || grupoSeleccionado.modalidad === ''
      || grupoSeleccionado.lugar === '' 
      || grupoSeleccionado.idUsuario === '') {
      mensajeAdvertencia("Por favor complete todos los campos");
    } else {
      const datos = {
        modalidad: grupoSeleccionado.modalidad,
        lugar: grupoSeleccionado.lugar,
        idUsuario: grupoSeleccionado.idUsuario,
        idCurso: idCurso,
        idCiclo: idCiclo,
        grupo: grupoSeleccionado.grupo
      };

      if (operacion === 1) {
        postOnClick(datos);
      } else {
        const id = grupoSeleccionado.idGrupo;
        putOnClick(id, datos);
      }
      document.getElementById('btnCerrarModal').click();
    }
  };

  //ORDENAR GETS
  const getGrupos = (idCu, idCi) => {
    const urlParcial = `grupos/${idCu}/${idCi}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        setGrupos(datos);
      })
      .catch((error) => {
        console.log("Error->", error);
      });
  };

  const getGruposActivos = (idCu) => {
    const urlParcial = `grupos/${idCu}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        setGrupos(datos);
      })
      .catch((error) => {
        console.log("Error->", error);
      });
  };

  const getInstructores = () => {
    const urlParcial = `usuarios/instructores/3`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        setInstructores(datos);
      })
      .catch((error) => {
        console.log("Error->", error);
      });
  };

  //ORDENAR POST
  const postOnClick = (datos) => {
    const urlParcial = 'grupos/';
    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos insertados");
          getGrupos(idCurso, idCiclo);
        } else {
          mensajeError("Error al insertar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  //ORDENAR PUT
  const putOnClick = (id, datos) => {
    const urlParcial = `grupos/${id}`;
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Datos actualizados");
          getGrupos(idCurso, idCiclo);
        } else {
          mensajeError("Error al actuaizar datos: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  //ORDENAR DELETE
  const deleteOnClick = async (id) => {
    const urlParcial = `grupos/${id}`;

    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
  );

  if (!confirmed) {
      return;
  } 
    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso("Registro eliminado");
          getGrupos(idCurso, idCiclo);
        } else {
          mensajeError("Error al eliminar registro: " + mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador: " + error);
      });
  };

  const handleOnClick = (idGrupo, grupo) => {
    navegar("/horariosgestion", { state: { idGrupo: idGrupo, grupo: grupo } });
  }
  return {
    handleOnClick,
    getGruposActivos,
    instructores,
    grupos,
    titulo,
    handleChange,
    crearDatos,
    grupoSeleccionado,
    openModal,
    deleteData,
    validarCampos,
    getGrupos,
    getInstructores
  };
};

export { useListadoGrupos };