import React, { useState } from 'react';
const ListadoPagos = ({
    styleText,
    Monto,
    numeroTarjeta,
    codigo,
    fechaVencimiento,
    nombreTitular,
    estiloInputTarjeta,
    estiloInputFecha,
    estiloInputMonto,
    estiloInputCodigo,
    estiloInputTitular,
    esValidoTarjeta,
    esValidoFecha,
    esValidoMonto,
    setNumeroTarjeta,
    setFechaVencimiento,
    setMonto,
    setCodigo,
    setNombreTitular
}) => {

    const meses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const anios = [];

    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= currentYear + 10; i++) {
        anios.push(i);
    }

    const [mes, setMes] = useState('');
    const [anio, setAnio] = useState('');

    // Actualizamos la fecha cuando se selecciona mes o año
    const handleMonthChange = (e) => {
        const selectedMes = e.target.value;
        setMes(selectedMes);
        setFechaVencimiento(selectedMes && anio ? `${anio}-${selectedMes.padStart(2, '0')}` : '');
    };

    const handleYearChange = (e) => {
        const selectedAnio = e.target.value;
        setAnio(selectedAnio);
        setFechaVencimiento(mes && selectedAnio ? `${selectedAnio}-${mes.padStart(2, '0')}` : '');
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="row">
                    <div className="col-md-12 mb-3" >
                        <i className="bi bi-credit-card"></i><span style={{ margin: '4px' }}>Tarjeta de credito o debito</span>
                    </div>
                </div>

                <div className="row mb-auto">
                    <div className="col-md-3 text-center d-flex align-items-center" >
                        <p style={styleText}>Monto</p>
                    </div>
                    <div className="col-md-9 text-center d-flex align-items-center">
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="bi bi-cash-coin"></i>
                            </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Monto a pagar"
                                value={Monto}
                                onChange={(e) => setMonto(e.target.value)}
                                style={estiloInputMonto}
                                required
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                {/*Mostrar menesaje*/}
                <div className="row mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-8 text-center d-flex align-items-center">
                        {!esValidoMonto && <p style={{ color: 'red' }}>Monto no valido</p>}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-3 text-center d-flex align-items-center">
                        <p style={styleText}>Nombre del titular</p>
                    </div>
                    <div className="col-md-9 text-center d-flex align-items-center">
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="bi bi-type"></i>
                            </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nombre del titular"
                                value={nombreTitular}
                                onChange={(e) => setNombreTitular(e.target.value)}
                                style={estiloInputTitular}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-auto">
                    <div className="col-md-3 text-center d-flex align-items-center" >
                        <p style={styleText}>Numero de tarjeta</p>
                    </div>
                    <div className="col-md-9 text-center d-flex align-items-center">
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="bi bi-keyboard"></i>
                            </span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Número de tarjeta"
                                value={numeroTarjeta}
                                onChange={(e) => setNumeroTarjeta(e.target.value)}
                                style={estiloInputTarjeta}
                                required
                            />
                        </div>
                    </div>
                </div>

                {/*Mostrar menesaje*/}
                <div className="row mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-9 text-center d-flex align-items-center">
                        {!esValidoTarjeta && <p style={{ color: 'red' }}>Número de tarjeta inválido</p>}
                    </div>
                </div>

                <div className="row mb-auto">
                    <div className="col-md-3 text-center">
                        <p style={styleText}>Fecha Vencimiento</p>
                    </div>
                    <div className="col-md-9 text-center">
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="bi bi-calendar-date"></i>
                            </span>
                            {/* Selector Mes */}
                            <select
                                className="form-select"
                                value={mes}
                                onChange={handleMonthChange}
                                style={estiloInputFecha}
                            >
                                <option value="">Mes</option>
                                {meses.map((mes, index) => (
                                    <option key={index} value={(index + 1).toString()}>
                                        {mes}
                                    </option>
                                ))}
                            </select>

                            {/* Selector Año */}
                            <select
                                className="form-select"
                                value={anio}
                                onChange={handleYearChange}
                                style={estiloInputFecha}
                            >
                                <option value="">Año</option>
                                {anios.map((anio) => (
                                    <option key={anio} value={anio}>
                                        {anio}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                {/*Mostrar menesaje*/}
                <div className="row mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-9 text-center d-flex align-items-center">
                        {!esValidoFecha && <p style={{ color: 'red' }}>Tarjeta fuera de vigencia</p>}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-3 text-center d-flex align-items-center">
                        <p style={styleText}>CVV</p>
                    </div>
                    <div className="col-md-9 text-center d-flex align-items-center"  >
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="bi bi-shield-lock-fill"></i>
                            </span>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Codigo"
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                                style={estiloInputCodigo}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ListadoPagos };
