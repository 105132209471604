
import { useEffect, useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { useNavigate } from 'react-router-dom';
import { Participantes } from '../../models/Participantes';
import { Usuarios } from '../../models/Usuarios';
import { useListaListaTiposDeCurso } from '../tipocurso/useListaTiposDeCurso';
import { useListadoCiclos } from '../ciclos/useListadoCiclos';
import { Cursos } from '../../models/Cursos';
import { TipoCurso } from '../../models/TipoCurso';

const useListadoParticipantes = () => {

  //Todas las constantes
  const [participantes, setParticipantes] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [cursoSeleccionado, setCursoSeleccionado] = useState("0");
  const [cicloSeleccionado, setCicloSeleccionado] = useState("0");
  const [tipoSeleccionado, setTipoSeleccionado] = useState("0");
  const [showSolventes, setShowSolventes] = useState(false); // Estado para el checkbox
  const { getFetch } = useFetch();
  const { mensajeError } = useAlert();
  const navegar = useNavigate();

  const {
    getTiposCurso,
    tiposCurso
  } = useListaListaTiposDeCurso();

  const {
    ciclos
  } = useListadoCiclos();

  //Los useEffect
  useEffect(() => {
      getCursos(cicloSeleccionado, tipoSeleccionado);
  
  }, [tipoSeleccionado, cicloSeleccionado]);

  useEffect(() => {
    getTiposCurso(); // Trae los tipos de curso
  }, []); // Solo se ejecuta una vez al montar el componente

  useEffect(() => {
      getParticipantes(cursoSeleccionado, cicloSeleccionado);
  }, [cursoSeleccionado]);

  //Funciones
  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    if (name === "tipo") {
      setTipoSeleccionado(value);
      if (cursoSeleccionado !== "0") setCursoSeleccionado("0"); // Actualizar solo si es necesario
    } else if (name === "ciclo") {
      setCicloSeleccionado(value);
    } else if (name === "curso") {
      setCursoSeleccionado(value);
    }
  };

  const handleSolventesCheckbox = (e) => {
    setShowSolventes(e.target.checked); // Activamos/desactivamos el filtro según el checkbox
  };
  
  //Todos los gets
  const getParticipantes = (idCurso, idCiclo) => {
    const urlParcial = `participantes/${idCurso}/${idCiclo}`;
    getFetch(urlParcial)
      .then((data) => {
        const { mensaje, estado, datos } = data;
        if (estado) {
          setParticipantes(datos);
          console.log(datos);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });
  };
  const getCursos = (idCiclo, idTipoCurso) => {
    let urlParcial = `cursos/${idCiclo}/${idTipoCurso}`;

    getFetch(urlParcial)
      .then((data) => {
        const { mensaje, estado, datos } = data;
        if (estado) {
          const datosEstandarizados = datos.map(item => {
            const cursos = Cursos(item.idCurso, item.curso, '', '', '', item.estado);
            const tipo = TipoCurso(item.idTipoCurso, item.tipo);
            return {
              ...cursos,
              ...tipo
            };
          });

          setCursos(datosEstandarizados);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError("Error inesperado, contacte al programador. " + error);
      });

  };
  const vercursos = (idParticipante, idUsuario) => {
    navegar("/detalles_cuentas_por_cobrar", { state: { idParticipante: idParticipante, idUsuario: idUsuario } });
    console.log(idParticipante, idUsuario);
  };

  return {
    participantes,
    getParticipantes,
    vercursos,
    tiposCurso,
    ciclos,
    cursos,
    handleSelectChange,
    handleSolventesCheckbox,
    showSolventes
  }
};

export { useListadoParticipantes };
