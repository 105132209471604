import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { useState } from 'react';

const useListadoCuentasPorCobrar = ({ cursoSeleccionado, idParticipante, getCuentasPendientesPorCurso }) => {

  // Estados
  const { putFetch, deleteFetch } = useFetch();
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, preguntarConfirmacion } = useAlert();
  const [detalle, setDetalle] = useState({});
  const [fechaPago, setFechaPago] = useState('');

  // Validar campos del formulario
  const validarCampos = () => {
    if(fechaPago === ''){
      mensajeAdvertencia("Debe seleccionar una fecha de pago!");
      return;
    }
    
    const datos = {
      estado: 'pagado',
      fechaPago: fechaPago
    };

    const id = detalle.idDetalleCuentaPorCobrar;
    putOnClick(id, datos);
    handleClose();
  };

  // Actualizar un detalle existente
  const putOnClick = (id, datos) => {
    const urlParcial = `detalles_cuentas_por_cobrar/${id}`; // Verifica el endpoint real
    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getCuentasPendientesPorCurso(cursoSeleccionado.idCurso, idParticipante);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  // Eliminar un detalle
  const deleteOnClick = async (id) => {
    const urlParcial = `detalles_cuentas_por_cobrar/${id}`; // Verifica el endpoint real
    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );
    
    if (!confirmed){
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          mensajeExitoso(mensaje);
          getCuentasPendientesPorCurso(cursoSeleccionado.idCurso, idParticipante);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error->', error);
      });
  };

  const [showModal, setShowModal] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };

  const openModal = (detalle) => {
    setDetalle(detalle)
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleContinue = () => {
    validarCampos();
  };

  return {
    deleteOnClick,
    showModal,
    handleClose,
    handleCancel,
    handleContinue,
    openModal,
    setFechaPago
  };
};

export { useListadoCuentasPorCobrar };
