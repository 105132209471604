import { useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { Actividades } from '../../models/Actividades';
const useListaActividades = () => {

    const { mensajeError, mensajeAdvertencia, preguntarConfirmacion } = useAlert();
    const [idCurso, setIdCurso] = useState(0);
    const [actividades, setActividades] = useState([]);
    const [actividadSeleccionada, setActividadSeleccionada] = useState(Actividades());
    const [operacion, setOperacion] = useState(1);
    const [titulo, setTitulo] = useState('');
    const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
    const [showModalActividades, setShowModalActividad] = useState(false);

    const openModal = (op, actividadSeleccionada = Actividades()) => {
        setActividadSeleccionada(actividadSeleccionada);
        setOperacion(op);
        setTitulo(op === 1 ? 'Registrar Actividad' : 'Editar Actividad');
    };

    const openModalActividades = (idCurso) => {
        setIdCurso(idCurso);
        getActividades(idCurso);
        setShowModalActividad(true);
    };

    const getActividades = (idCurso) => {
        const urlParcial = `actividades/${idCurso}`;
        getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;
                estandarizar(datos);
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    const getActividadesByCourse = (idCurso) => {
        const urlParcial = `actividades/curso/${idCurso}`;
        getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;
                estandarizar(datos);
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    const estandarizar = (datos) => {
        const datosEstandarizados = datos.map(item =>
            Actividades(
                item.idActividad || 0,
                item.idCurso || 0,
                item.actividad || '',
                item.porcentaje || 0
            )
        );
        setActividades(datosEstandarizados);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setActividadSeleccionada((actividadPrevio) => ({ ...actividadPrevio, [name]: value }));
    };

    const validarCampos = () => {
        if (actividadSeleccionada.actividad.trim() === '') {
            mensajeAdvertencia('Actividad vacio!');
        } else if (actividadSeleccionada.porcentaje === '') {
            mensajeAdvertencia('Porcentaje vacio!');
        } else {
            let totalPorcentaje = 0;
            actividades.forEach(actividad => {
                if (actividadSeleccionada.idActividad) {
                    if (parseInt(actividadSeleccionada.idActividad, 10) === parseInt(actividad.idActividad, 10)) {
                        totalPorcentaje -= actividad.porcentaje;
                        totalPorcentaje += parseInt(actividadSeleccionada.porcentaje, 10);
                    }
                }
                totalPorcentaje += actividad.porcentaje;
            });

            if (!actividadSeleccionada.idActividad) {
                totalPorcentaje += parseInt(actividadSeleccionada.porcentaje, 10);
            }

            if (totalPorcentaje > 100) {
                mensajeAdvertencia("La suma de los porcentajes supera el 100%");
                return;
            }

            actividadSeleccionada.idCurso = idCurso;//Le asignamos el id del curso
            if (operacion === 1) {
                postOnClick(actividadSeleccionada);
            } else {
                const id = actividadSeleccionada.idActividad;
                putOnClick(id, actividadSeleccionada);
            }
            document.getElementById('btnCerrarModalActividades').click();
        }
    };

    const postOnClick = (datos) => {
        const urlParcial = 'actividades/';
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                getActividades(idCurso);
            })
            .catch((error) => {
                console.log('Error->', error);
            });
    };

    const putOnClick = (id, datos) => {
        const urlParcial = `actividades/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                getActividades(idCurso);
            })
            .catch((error) => {
                console.log('Error->', error);
            });
    };

    const deleteOnClick = async (id) => {
        const urlParcial = `actividades/${id}`;
        const confirmed = await preguntarConfirmacion(
            "¿Estás seguro que desea eliminar?",
            "Esta acción no se puede deshacer."
        );

        if (!confirmed) {
            return;
        } 

        deleteFetch(urlParcial)
            .then((respuesta) => {
                const { estado, mensaje } = respuesta;
                if (!estado) {
                    mensajeError("No se puede eliminar!" + mensaje);
                }
                getActividades(idCurso);
            })
            .catch((error) => {
                console.log('Error->', error);
            });
    };
    return {
        getActividades,
        actividades,
        titulo,
        handleChangeActividad: handleChange,
        titulo,
        openModalActividad: openModal,
        openModalActividades,
        actividadSeleccionada,
        deleteOnClickActividad: deleteOnClick,
        validarCampos,
        setShowModalActividad,
        showModalActividades,
        getActividadesByCourse,
    };
};
export { useListaActividades };