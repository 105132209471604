import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {API_URL} from '../../config'

const ActivateAccount = () => {
  const { token } = useParams(); // Obtiene el token de la URL
  const [message, setMessage] = useState('');
  const [isActivated, setIsActivated] = useState(false);  // Estado para determinar si la cuenta fue activada
  const navigate = useNavigate();

  useEffect(() => {
    const activateAccount = async () => {
      try {
        const response = await axios.get(`${API_URL}/auth/activate/${token}`);
        setMessage('Tu cuenta ha sido activada correctamente.');
        setIsActivated(true);  // Marca la cuenta como activada
      } catch (error) {
        setMessage('Error al activar la cuenta. El token puede ser inválido o haber expirado.');
      }
    };

    activateAccount();
  }, [token]);

  // Función para redirigir al usuario a la página principal
  const handleGoToHome = () => {
    navigate('/');  // Redirige al index o página principal
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md p-6 mx-auto bg-white rounded-lg shadow-md">
        {isActivated ? (
          <>
            <h1 className="mb-4 text-3xl font-bold text-center text-darkbluetxt">¡Cuenta Activada!</h1>
            <p className="mb-6 text-center text-gray-700">{message}</p>
            <button
              onClick={handleGoToHome}
              className="w-full px-4 py-3 text-lg font-semibold text-white transition duration-300 transform rounded-lg shadow-md bg-bluelight hover:bg-lightgreentxt hover:scale-105 focus:outline-none"
            >
              Ir al inicio
            </button>
          </>
        ) : (
          <>
            <h1 className="mb-4 text-3xl font-bold text-center text-darkbluetxt">Activación de Cuenta</h1>
            <p className="text-center text-gray-700">{message}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ActivateAccount;
