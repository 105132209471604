import React, { useState } from 'react';
import Select from 'react-select';

const CareerSelectionForm = ({ 
  studentData, 
  setStudentData, 
  facultades, 
  carreras, 
  handleFacultadChange, 
  handleSelectChange, 
  handlePreviousStep, 
  setShowSuccessModal,  
  submitStudentData }) => {
  const [selectedFacultad, setSelectedFacultad] = useState(null);
  const [selectedCarrera, setSelectedCarrera] = useState(null);
  const [errors, setErrors] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const validate = () => {
    const newErrors = {};
    if (!selectedFacultad) newErrors.facultad = 'Seleccione una facultad';
    if (!selectedCarrera) newErrors.carrera = 'Seleccione una carrera';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleShowConfirmModal = (e) => {
    e.preventDefault();
    if (validate()) setShowConfirmModal(true);
  };

  const confirmAndSubmit = () => {
    setShowConfirmModal(false);
    submitStudentData(); // Llama la función de envío al backend
  };

  return (
    <form onSubmit={handleShowConfirmModal} className="w-full max-w-2xl p-8 space-y-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center text-sky-700">Selecciona Facultad y Carrera</h1>

      {/* Facultad */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Facultad<span className="text-red-500">*</span></label>
        <Select
          options={facultades}
          onChange={(facultad) => { setSelectedFacultad(facultad); handleFacultadChange(facultad); }}
          value={selectedFacultad}
          placeholder="Seleccione una Facultad"
          className="w-full"
        />
        {errors.facultad && <p className="text-red-600">{errors.facultad}</p>}
      </div>

      {/* Carrera */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Carrera<span className="text-red-500">*</span></label>
        <Select
          options={carreras}
          onChange={(carrera) => { setSelectedCarrera(carrera); handleSelectChange(carrera); }}
          value={selectedCarrera}
          placeholder="Seleccione una Carrera"
          className="w-full"
        />
        {errors.carrera && <p className="text-red-600">{errors.carrera}</p>}
      </div>

      {/* Botones de navegación */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="px-4 py-2 text-lg font-semibold text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none"
        >
          Atrás
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-lg font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
        >
          Enviar
        </button>
      </div>

      {/* Modal de Confirmación */}
      {showConfirmModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
          <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-xl">
            <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">Confirmación</h2>
            <p className="mb-6 text-center text-gray-600">
              ¿Está seguro de que desea enviar su Formulario? Una vez enviado, no podrá hacer cambios.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={confirmAndSubmit}
                className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
              >
                Confirmar
              </button>
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

    </form>
  );
};

export default CareerSelectionForm;
