import { useListadoKits } from '../kits/useListadoKits';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

const ListaKits = () => {

  const {
    kits,
    handleChange,
    titulo,
    kitSeleccionado,
    openModal,
    deleteData,
    validarCampos
  } = useListadoKits();

  return (
    <>
      <div className="mt-custom d-flex">
        <div className='p-2 flex-grow-1'><h1>Kits</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="modal" databstarget="#modalKits" onclick={() => openModal(1)} titulo={"Agregar Kit"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Kits</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {kits ? kits.map((kit) => (
              <tr key={kit.idKit}>
                <th scope="row">{kit.idKit}</th>
                <td>{kit.kit}</td>
                <td>
                  
                  <BtnEditar databstogle="modal" databstarget="#modalKits" onclick={() => openModal(2, kit)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteData(kit.idKit)} titulo={"Eliminar"} />

                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>

      <div className="modal fade" id="modalKits" tabIndex="-1" aria-labelledby="modalKitsLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalKitsLabel">{titulo}</h1>
              <button
                type="button"
                id='btnCerrarModal'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                  <label htmlFor="txtIdKit" className="form-label">ID Kit</label>
                  <input
                    id='txtIdKit'
                    onChange={handleChange}
                    value={kitSeleccionado.idKit}
                    type="number"
                    className="form-control"
                    name="idKit"
                    disabled
                  />
                </div>
                <div className="col-md-9">
                  <label htmlFor="txtKit" className="form-label">Kit </label>
                  <input
                    id='txtKit'
                    onChange={handleChange}
                    value={kitSeleccionado.kit}
                    type="text"
                    className="form-control"
                    name="kit"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-12 text-md-end">
                    <hr />
                    <BtnEliminar add={'me-2'}  type='button' titulo={'Cancelar'} databsdismiss={'modal'} />
                    <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { ListaKits };
