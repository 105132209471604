import { BtnAgregar } from "../../buttons/BtnAgregar";
import { AbrirModal } from "../../components/AbrirModal";
import { useListadoCiclos } from "../ciclos/useListadoCiclos";
import { useGestionInscritos } from "./useGestionInscritos";
import { KitsEntregados } from "./kitsEntregados";  // Asegúrate de que la ruta sea correcta

const GestionInscritos = () => {
    const {
        filtrarInscritos,
        handleSelectChange,
        cicloSeleccionado,
        cursoSeleccionado,
        handleCheckboxChange,
        handleSearchChange,
        searchQuery,
        buscarPorExpediente,
        cursos,
        showModal,
        handleContinue,
        handleCancel,
        handleClose,
        openModal,
        inscritoSelecionado,
        kits,
        setData,
        tiposCurso,
        tipoSeleccionado
    } = useGestionInscritos();
    const {
        ciclos
    } = useListadoCiclos();
    return (
        <>
            <div className="d-flex mt-custom">
                <div className="p-2 flex-grow-1">
                    <h1>Administracion de inscripciones</h1>
                </div>
            </div>

            <div className="table-responsive text-center">
                <div className="container-fluid">
                    {/* Fila de filtros */}
                    <div className="row mb-4">
                        {/* Primer select */}

                        <div className="col-12 col-md-3 mb-3 mb-md-0">
                            <select className="form-select"
                                name="tipo"
                                onChange={handleSelectChange}
                                value={tipoSeleccionado}>
                                <option value="0">Tipo curso</option>
                                {tiposCurso.map((tipo) => (
                                    <option key={tipo.idTipoCurso} value={tipo.idTipoCurso}>
                                        {tipo.tipo}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-12 col-md-3 mb-3 mb-md-0">
                            <select className="form-select"
                                name="ciclo"
                                onChange={handleSelectChange}
                                value={cicloSeleccionado}>
                                <option value="0">Seleccione un ciclo</option>
                                {ciclos.map(ciclo => (
                                    <option key={ciclo.idCiclo} value={ciclo.idCiclo}>
                                        {`${ciclo.ciclo} - ${ciclo.anio}`}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Filtro por selección 2 */}
                        <div className="col-12 col-md-3 mb-3 mb-md-0">
                            <select className="form-select"
                                name="curso"
                                onChange={handleSelectChange}
                                value={cursoSeleccionado}
                            >
                                <option value="0">Seleccione un curso</option>
                                {cursos.map(curso => (
                                    <option key={curso.idCurso} value={curso.idCurso}>
                                        {curso.curso}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Fila para el checkbox y barra de búsqueda */}
                    <div className="row mb-3 d-flex flex-column align-items-start">
                        {/* Checkbox */}
                        <div className="col-12 d-flex align-items-center">
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="expedienteCheckbox"
                                    checked={buscarPorExpediente}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label ms-2" htmlFor="expedienteCheckbox">
                                    Cambiar filtro
                                </label>
                            </div>
                        </div>

                        {/* Barra de búsqueda */}
                        <div className="col-6 mt-2">
                            <input
                                className="form-control"
                                id="searchInput2"
                                placeholder={buscarPorExpediente ? "Buscar por expediente" : "Buscar por nombre"}
                                aria-label={buscarPorExpediente ? "Buscar por expediente" : "Buscar por nombre"}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                </div>

                {/* Tabla de datos */}
                <div className="table-responsive">
                    <table id="dataTable" className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center" style={{ width: '10%' }}>Expediente</th>
                                <th scope="col" className="text-start" style={{ width: '20%' }}>Nombre</th>
                                <th scope="col" className="text-start" style={{ width: '15%' }}>Correo</th>
                                <th scope="col" className="text-center" style={{ width: '10%' }}>Fecha inscripcion</th>
                                <th scope="col" className="text-center" style={{ width: '10%' }}>Kit entregado</th>
                                <th scope="col" className="text-center" style={{ width: '20%' }}>Inscripcion</th>
                                <th scope="col" className="text-center" style={{ width: '30%' }}>Entrega kit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtrarInscritos().map((inscrito) => (
                                <tr key={inscrito.idInscripcion}>
                                    <td className="text-center">{inscrito.expediente}</td>
                                    <td className="text-start">{inscrito.nombres}</td>
                                    <td className="text-start">{inscrito.correo}</td>
                                    <td className="text-center">
                                        {new Date(inscrito.fechaInscripcion).toLocaleDateString('es-ES')}
                                    </td>
                                    <td className="text-center">
                                        {inscrito.entregado !== null && inscrito.entregado !== 0 ? "SI" : "No"}
                                    </td>

                                    <td className="text-center">
                                        {inscrito.activa === 1 ? "ACTIVA" : "No"}
                                    </td>
                                    <td className="text-center">
                                        <BtnAgregar titulo={"Entrega kits"} onclick={() => openModal(inscrito)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <AbrirModal show={showModal}
                    handleContinue={handleContinue}
                    handleCancel={handleCancel}
                    handleClose={handleClose}
                    titulo={"Asignar kits"}
                    ocultar={false}
                >
                    <KitsEntregados inscritoSelecionado={inscritoSelecionado} kits={kits} setData={setData} />
                </AbrirModal>
            </div >
        </>
    );
};

export { GestionInscritos };
