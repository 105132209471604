import React, { useEffect } from 'react';
import { useListaListaTiposDeCurso } from './useListaTiposDeCurso';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

const ListaTiposDeCurso = () => {
  const {
    getTiposCurso,
    tiposCurso,
    handleChange,
    titulo,
    tiposCursoSeleccionado,
    openModal,
    deleteOnClick,
    validarCampos,
    handleChangeFile,
    imagen,
    fileInputRef
  } = useListaListaTiposDeCurso();

  useEffect(() => {
    getTiposCurso();
  }, [getTiposCurso]);

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1"><h1>Tipos de curso</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="modal" databstarget="#modalTipoCurso" onclick={() => openModal(1)} titulo={"Agregar TipoCurso"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Descripción</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {tiposCurso ? tiposCurso.map((tipocurso) => (
              <tr key={tipocurso.idTipoCurso}>
                <td scope="row">{tipocurso.tipo}</td>
                <td scope='row'>{tipocurso.descripcion}</td>
                <td>
                  <BtnEditar databstogle="modal" databstarget="#modalTipoCurso" onclick={() => openModal(2, tipocurso)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteOnClick(tipocurso.idTipoCurso)} titulo={"Eliminar"} />
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>

      <div className="modal fade" id="modalTipoCurso" tabIndex="-1" aria-labelledby="modalTiposCursoLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalTiposCursoLabel">{titulo}</h1>
              <button
                type="button"
                id='btnCerrarModal'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                  <label htmlFor="txtIdTipoCurso" className="form-label">IDTipoCurso</label>
                  <input
                    id='txtIdTipoCurso'
                    onChange={handleChange}
                    value={tiposCursoSeleccionado.idTipoCurso}
                    type="number"
                    className="form-control"
                    name="idTipoCurso"
                    disabled
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="txtTipo" className="form-label">Tipo</label>
                  <input
                    id="txtTipo"
                    onChange={handleChange}
                    value={tiposCursoSeleccionado.tipo}
                    type="text"
                    className="form-control"
                    name="tipo"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="txtDescripcion" className="form-label">Descripción</label>
                  <input
                    id="txtDescripcion"
                    onChange={handleChange}
                    value={tiposCursoSeleccionado.descripcion}
                    type="text"
                    className="form-control"
                    name="descripcion"
                    required
                  />
                </div>

                <div className="col-md-12">
                  <label htmlFor="txtImagen" className="form-label">Seleccionar imagen</label>
                  <input
                    id='txtImagen'
                    onChange={handleChangeFile}
                    type="file"
                    className="form-control"
                    name="imagen"
                    ref={fileInputRef}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  {imagen ? (
                    <img src={imagen} alt='Imagen seleccionada' height='150px' width='150px' />
                  ) : tiposCursoSeleccionado.imagen ? (
                    <img src={tiposCursoSeleccionado.imagen} alt='Imagen cargada' height='150px' width='150px' />
                  ) : (
                    <p>No se ha seleccionado ninguna imagen</p>
                  )}
                </div>
                <div className="col-md-12 text-md-end">
                  <hr />
                  <BtnEliminar add={'me-2'} type='button' titulo={'Cancelar'} databsdismiss={'modal'} />
                  <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ListaTiposDeCurso };
