import { useListadoFuentes } from '../fuentes/useListadoFuentes';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

const ListaFuentes = () => {

  const {
    fuentes,
    handleChange,
    titulo,
    fuenteSeleccionada,
    openModal,
    deleteData,
    validarCampos
  } = useListadoFuentes();

  return (
    <>
      <div className="mt-custom d-flex">
        <div className='p-2 flex-grow-1'><h1>Fuentes</h1></div>
        <div className="p-2">
          <BtnAgregar databstogle="modal" databstarget="#modalFuentes" onclick={() => openModal(1)} titulo={"Agregar Fuente"} />
        </div>
      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Fuentes</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {fuentes ? fuentes.map((fuente) => (
              <tr key={fuente.idFuente}>
                <th scope="row">{fuente.idFuente}</th>
                <td>{fuente.fuente}</td>
                <td>

                  <BtnEditar databstogle="modal" databstarget="#modalFuentes" onclick={() => openModal(2, fuente)} titulo={'Editar'} />
                  <BtnEliminar disabled={false} onclick={() => deleteData(fuente.idFuente)} titulo={"Eliminar"} />

                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>

      <div className="modal fade" id="modalFuentes" tabIndex="-1" aria-labelledby="modalFuentesLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="modalFuentesLabel">{titulo}</h1>
              <button
                type="button"
                id='btnCerrarModal'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                  <label htmlFor="txtIdFuente" className="form-label">ID</label>
                  <input
                    id='txtIdFuente'
                    onChange={handleChange}
                    value={fuenteSeleccionada.idFuente}
                    type="number"
                    className="form-control"
                    name="idFuente"
                    disabled
                  />
                </div>
                <div className="col-md-9">
                  <label htmlFor="txtFuente" className="form-label">Fuente</label>
                  <input
                    id='txtFuente'
                    onChange={handleChange}
                    value={fuenteSeleccionada.fuente}
                    type="text"
                    className="form-control"
                    name="fuente"
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-12 text-md-end">
                    <hr />
                    <BtnEliminar add={'me-2'} type='button' titulo={'Cancelar'} databsdismiss={'modal'} />
                    <BtnEditar type='button' onclick={validarCampos} titulo={'Guardar cambios'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ListaFuentes }
