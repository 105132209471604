import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Login } from '../pages/Login'
import { NotFound } from '../pages/NotFound'
import { Layout } from '../components/Layout'
import { PublicRoutes } from './PublicRoutes'
import { PrivateRoutes } from './PrivateRoutes'
import { Home } from '../pages/Home'
import { ListadoNotas } from '../pages/Nota/ListadoNotas'
import { ListaDepartamentos } from '../pages/departamentos/ListadoDepartamentos'
import { ListaRoles } from '../pages/roles/ListaRoles'
import { ListaFuentes } from '../pages/fuentes/ListaFuentes'
import { ListaKits } from '../pages/kits/ListaKits'
import { GestionCursos } from '../pages/cursos/GestionCursos'
import { ListaCiclos } from '../pages/ciclos/ListadoCiclos'
import { Inscripcion } from '../pages/inscripciones/Inscripcion'
import { ListaEntregaKit } from '../pages/entrega_kit/ListadoEntregaDeKit'
import GestionDepartamentos from '../pages/departamentos/GestionDepartamentos'
import { ListadoMisCursos } from '../pages/misCursos/ListadoMisCursos'
import { ListadoDetalles } from '../pages/misCursos/ListadoDetalles'
import { ListadoPagos } from '../pages/pagos/listadoPagos'
import { ListaActividades } from '../pages/actividades/ListaActividades'
import { ListaPlantillas } from '../pages/plantillas/listadoPlantillas'
import { ListaCodigoServicio } from '../pages/codigo_servicio/ListadoCodigoDeServicio'
import { DetallesCuentasPorCobrar } from '../pages/detalles_cuentas_por_cobrar/DetallesCuentasPorCobrar'
import { ListaTiposDeCurso } from '../pages/tipocurso/ListaTiposDeCurso'
import { ListadoUsuario } from '../pages/usuarios/ListadoUsuario'
import { ListaHorarios } from '../pages/horarios/ListaHorarios'
import { ListaParticipantes } from '../pages/participantes/ListaParticipantes'
import { ListaDetallesCuentasPorCobrar } from '../pages/detalles_cuentas_por_cobrar/ListadoCuentasPorCobrar'
import { AdministracionCursoDiplomado } from '../pages/administrar_curso_diplomado/AdministracionCursoDiplomado'
import { GestionInscritos } from '../pages/inscritos/GestionInscritos'

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route exact path='/' Component={Home} />
                    <Route element={<PublicRoutes />}>
                        <Route exact path='/login' Component={Login} />
                    </Route>
                    <Route element={<PrivateRoutes hasRole="instructor" />}>
                        <Route exact path='/asignarnotas' Component={ListadoNotas} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/departamentos' Component={ListaDepartamentos} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/roles' Component={ListaRoles} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/asignarRol' Component={ListadoUsuario} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/fuentes' Component={ListaFuentes} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/kits' Component={ListaKits} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/cursos' Component={AdministracionCursoDiplomado} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="participante" />}>
                        <Route exact path='/cursos/:idCurso' Component={Inscripcion} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/ciclos' Component={ListaCiclos} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/entrega_kit' Component={ListaEntregaKit} />

                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/cursosgestion' Component={GestionCursos} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/modulosgestion' Component={ListaActividades} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/departamentosgestion' Component={GestionDepartamentos} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="participante" />}>
                        <Route exact path='/misCursos' Component={ListadoMisCursos} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="participante" />}>
                        <Route exact path='/Listado/:idCurso' Component={ListadoDetalles} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="participante" />}>
                        <Route exact path='/Pago' Component={ListadoPagos} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/plantillas' Component={ListaPlantillas} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/codigo_servicio' Component={ListaCodigoServicio} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="participante" />}>
                        <Route exact path='/realizarpago' Component={DetallesCuentasPorCobrar} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/tiposcurso' Component={ListaTiposDeCurso} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/horariosgestion' Component={ListaHorarios} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/participantes' Component={ListaParticipantes} />
                    </Route>


                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/inscritos' Component={GestionInscritos} />
                    </Route>

                    <Route element={<PrivateRoutes hasRole="admin" />}>
                        <Route exact path='/detalles_cuentas_por_cobrar' Component={ListaDetallesCuentasPorCobrar} />
                    </Route>


                    <Route path='*' Component={NotFound} />
                </Routes>
            </Layout>
        </BrowserRouter>
    )
}

export { AppRouter }