import { Cursos } from "../models/Cursos";
import { Diplomados } from "../models/Diplomados";

function useAgrupar() {
    const agruparDatos = (datos) => {
        const agrupados = {
            cursos: {},
            diplomados: {}
        };

        datos.forEach(({
            idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, 
            precio, duracion, idTipoCurso, idCiclo, idGrupo, grupo, modalidad, 
            lugar, idUsuario, idHorario, diaInicio, diaFin, horaInicio, horaFin, dias,
            idDiplomado, nombreDiplomado, fechaInicioD, fechaFinD, duracionD, completo
        }) => {
            // Validar si es un curso independiente
            if (!idDiplomado) {
                if (idCurso && !agrupados.cursos[idCurso]) {
                    agrupados.cursos[idCurso] = Cursos(
                        idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, 
                        precio, duracion, idTipoCurso, null, idCiclo
                    );
                }

                // Agregar grupo y horario al curso
                if (idGrupo && idCurso) {
                    const grupoObj = agrupados.cursos[idCurso].agregarGrupo(
                        idGrupo, grupo, modalidad, lugar, idCurso, idUsuario
                    );
                    if (idHorario) {
                        grupoObj.agregarHorario(
                            idHorario, diaInicio, diaFin, horaInicio, horaFin, dias, idGrupo
                        );
                    }
                }
            } else if (idDiplomado) {
                // Si es un diplomado, agrégalo solo si no existe ya
                if (!agrupados.diplomados[idDiplomado]) {
                    agrupados.diplomados[idDiplomado] = Diplomados(
                        idDiplomado, nombreDiplomado, fechaInicioD, fechaFinD, duracionD, completo
                    );
                }

                // Agregar curso al diplomado solo si tiene idCurso válido
                if (idCurso) {
                    const cursoObj = agrupados.diplomados[idDiplomado].agregarCurso(
                        idCurso, curso, imagen, fechaInicio, fechaFinalizacion, estado, 
                        precio, duracion, idTipoCurso, idDiplomado, idCiclo
                    );

                    // Agregar grupo y horario si existen
                    if (idGrupo) {
                        const grupoObj = cursoObj.agregarGrupo(
                            idGrupo, grupo, modalidad, lugar, idCurso, idUsuario
                        );
                        if (idHorario) {
                            grupoObj.agregarHorario(
                                idHorario, diaInicio, diaFin, horaInicio, horaFin, dias, idGrupo
                            );
                        }
                    }
                }
            }
        });

        return agrupados;
    };

    return { agruparDatos };
}

export { useAgrupar };
