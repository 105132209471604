import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config';

const GeneralInfoForm = ({ studentData, setStudentData, handleNextStep, handlePreviousStep }) => {
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Validación y formateo de número de teléfono
  const validatePhoneNumber = (phone) => {
    const regex = /^\d{4} \d{4}$/;
    return regex.test(phone);
  };

  const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, '');
    if (digits.length > 4) {
      return digits.slice(0, 4) + ' ' + digits.slice(4, 8);
    }
    return digits;
  };

  // Manejo de cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    
    if (name === 'telefono_movil' || name === 'telefono_fijo' || name === 'telefono_trabajo' || name === 'telefono_movil_padre' || name === 'telefono_movil_madre') {
      formattedValue = formatPhoneNumber(value);
    }

    setStudentData(prevData => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  // Manejo de cambios en el campo de búsqueda
  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Llamada a la API cuando se han ingresado al menos tres caracteres
    if (value.length > 2) {
      try {
        const response = await axios.get(`${API_URL}/location/location-search`, {
          params: { term: value },
        });
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching location suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const [departamento, distrito, municipio] = suggestion.location.split(', ');
    setStudentData({
      ...studentData,
      departamento,
      distrito,
      municipio,
    });
    setSearchTerm(suggestion.location);
    setSuggestions([]);
  };

  // Validación del formulario
  const validate = () => {
    const newErrors = {};
    if (!studentData.direccion.trim()) newErrors.direccion = 'La dirección es requerida';
    if (!studentData.zona) newErrors.zona = 'La zona es requerida';
    if (!studentData.telefono_movil.trim()) {
      newErrors.telefono_movil = 'El teléfono móvil es requerido';
    } else if (!validatePhoneNumber(studentData.telefono_movil)) {
      newErrors.telefono_movil = 'El teléfono móvil debe tener el formato #### ####';
    }
    if (studentData.telefono_fijo && !validatePhoneNumber(studentData.telefono_fijo)) {
      newErrors.telefono_fijo = 'El teléfono fijo debe tener el formato #### ####';
    }
    if (studentData.telefono_trabajo && !validatePhoneNumber(studentData.telefono_trabajo)) {
      newErrors.telefono_trabajo = 'El teléfono de trabajo debe tener el formato #### ####';
    }
    if (!studentData.departamento || !studentData.distrito || !studentData.municipio) {
      newErrors.location = 'El Departamento, Municipio y Distrito son obligatorios';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-2xl p-8 space-y-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center text-sky-700">Información General</h1>

      {/* Dirección */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Dirección <span className="text-red-500">*</span></label>
        <input
          type="text"
          name="direccion"
          value={studentData.direccion}
          onChange={handleChange}
          placeholder="Dirección"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
        {errors.direccion && <p className="text-red-600">{errors.direccion}</p>}
      </div>

      {/* Departamento, Municipio, Distrito */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Departamento, Municipio, Distrito <span className="text-red-500">*</span></label>
        <input
          type="text"
          name="location"
          value={searchTerm}
          onChange={handleSearchChange}
          required
          placeholder="Buscar ubicación"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
        {suggestions.length > 0 && (
          <ul className="absolute z-10 bg-white border border-gray-300 rounded-md">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="p-2 cursor-pointer hover:bg-gray-200"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.location}
              </li>
            ))}
          </ul>
        )}
        {errors.location && <p className="text-red-600">{errors.location}</p>}
      </div>

      {/* Zona */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Zona <span className="text-red-500">*</span></label>
        <select
          name="zona"
          value={studentData.zona}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        >
          <option value="">Seleccione Zona</option>
          <option value="Rural">Rural</option>
          <option value="Urbano">Urbano</option>
        </select>
        {errors.zona && <p className="text-red-600">{errors.zona}</p>}
      </div>

      {/* Teléfonos */}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        {/* Teléfono Móvil */}
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">Teléfono Móvil <span className="text-red-500">*</span></label>
          <input
            type="text"
            name="telefono_movil"
            value={studentData.telefono_movil}
            onChange={handleChange}
            placeholder="#### ####"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.telefono_movil && <p className="text-red-600">{errors.telefono_movil}</p>}
        </div>

        {/* Teléfono Fijo */}
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">Teléfono Fijo</label>
          <input
            type="text"
            name="telefono_fijo"
            value={studentData.telefono_fijo}
            onChange={handleChange}
            placeholder="#### ####"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.telefono_fijo && <p className="text-red-600">{errors.telefono_fijo}</p>}
        </div>
      </div>

      {/* Lugar de Trabajo y Teléfono de Trabajo */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Lugar de Trabajo</label>
        <input
          type="text"
          name="lugar_trabajo"
          value={studentData.lugar_trabajo}
          onChange={handleChange}
          placeholder="Lugar de Trabajo"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
      </div>

      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Teléfono de Trabajo</label>
        <input
          type="text"
          name="telefono_trabajo"
          value={studentData.telefono_trabajo}
          onChange={handleChange}
          placeholder="#### ####"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
        {errors.telefono_trabajo && <p className="text-red-600">{errors.telefono_trabajo}</p>}
      </div>


      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Nombre del Padre</label>
        <input
          type="text"
          name="nombre_padre"
          value={studentData.nombre_padre || ''}
          onChange={handleChange}
          placeholder="Nombre completo del padre"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
      </div>


      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Dirección del Padre</label>
        <input
          type="text"
          name="direccion_padre"
          value={studentData.direccion_padre || ''}
          onChange={handleChange}
          placeholder="Dirección del padre"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
      </div>


      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Teléfono Móvil del Padre</label>
        <input
          type="text"
          name="telefono_movil_padre"
          value={studentData.telefono_movil_padre || ''}
          onChange={handleChange}
          placeholder="#### ####"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
      </div>

      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Nombre de la Madre</label>
        <input
          type="text"
          name="nombre_madre"
          value={studentData.nombre_madre || ''}
          onChange={handleChange}
          placeholder="Nombre completo de la madre"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
      </div>

      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Dirección de la Madre</label>
        <input
          type="text"
          name="direccion_madre"
          value={studentData.direccion_madre || ''}
          onChange={handleChange}
          placeholder="Dirección de la madre"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
      </div>


      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">Teléfono Móvil de la Madre</label>
        <input
          type="text"
          name="telefono_movil_madre"
          value={studentData.telefono_movil_madre || ''}
          onChange={handleChange}
          placeholder="#### ####"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
      </div>


      {/* Botones de Navegación */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handlePreviousStep}
          className="px-4 py-2 text-lg font-semibold text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none"
        >
          Atrás
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-lg font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
        >
          Siguiente
        </button>
      </div>
    </form>
  );
};

export default GeneralInfoForm;
