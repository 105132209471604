import React, { useState } from "react";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
} from "docx";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faFileWord,
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import NavbarPrincipal from "./NavbarPrincipal";

const Reports = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2025 + 2 },
    (_, i) => 2025 + i
  );

  // Estados
  const [year, setYear] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportName, setReportName] = useState("");
  const [confirmedState, setConfirmedState] = useState({
    year: "",
    reportType: "",
    reportName: "",
  });
  const [loading, setLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [previewData, setPreviewData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  // Modo de pruebas
  const testingMode = "complete";

  const reportOptions = {
    "Por estudiante": [
      "Municipios provenientes",
      "Departamentos provenientes",
      "Distritos provenientes",
      "Edades",
      "Género",
      "Casco(rural o urbano)",
      "Institución de procedencia",
      "Año de graduación",
    ],
    "Por inscripción": [
      "Estudiantes Inscritos por carrera en cada curso",
      "Estudiantes Inscritos por curso",
      "Estudiantes Inscritos en horarios por carrera",
    ],
    "Por registro de acciones": [
      "Estudiantes que desertaron del curso preu",
      "Estudiantes que realizaron cambio de horario o de curso",
      "Estudiantes que reprobaron el curso",
    ],
  };

  // Datos simulados
  const localData = Array.from({ length: 50 }, (_, i) => ({
    Nombre: `Estudiante ${i + 1}`,
    Apellido: `Apellido ${i + 1}`,
    Curso: ["Matemáticas", "Biología", "Química", "Física"][i % 4],
    Grupo: ["A", "B", "C", "D"][i % 4],
    Teléfono: `12345${i.toString().padStart(4, "0")}`,
    "Correo personal": `estudiante${i + 1}@correo.com`,
    "Correo institucional": `estudiante${i + 1}@institucional.com`,
  }));

  const fetchData = async () => {
    if (testingMode === "complete") return localData;
    if (testingMode === "empty") return [];
    if (testingMode === "error") throw new Error("Simulación de error.");
    return [];
  };

  const handleGenerateReport = async () => {
    if (!year || !reportType || !reportName) {
      setErrorMessage(
        "Por favor, selecciona todas las opciones antes de generar el reporte."
      );
      setShowButtons(false);
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setShowButtons(false);

    try {
      const data = await fetchData();
      if (data.length === 0) {
        setErrorMessage(
          "No se encontraron datos para el informe seleccionado."
        );
      } else {
        setConfirmedState({ year, reportType, reportName });
        setShowButtons(true);
        setPreviewData(data);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentDate = () => new Date().toISOString().split("T")[0];

  //generación de archivos a descargar

  const generateFile = async (type) => {
    const data = await fetchData();
    const fileName = `${confirmedState.reportName}(${getCurrentDate()})`;

    if (type === "excel") {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Reporte");
      worksheet.columns = Object.keys(data[0]).map((header) => ({
        header,
        key: header,
        width: 20,
      }));
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell, colNumber) => {
        if (cell.value) {
          cell.font = { bold: true, color: { argb: "FFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "1F4E78" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        }
      });
      data.forEach((row, rowIndex) => {
        const rowValues = Object.values(row);
        const newRow = worksheet.addRow(rowValues);
        newRow.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: rowIndex % 2 === 0 ? "DDEBF7" : "FFFFFF" }, // Alternar colores
          };
          cell.border = {
            top: { style: "thin", color: { argb: "A6A6A6" } },
            left: { style: "thin", color: { argb: "A6A6A6" } },
            bottom: { style: "thin", color: { argb: "A6A6A6" } },
            right: { style: "thin", color: { argb: "A6A6A6" } },
          };
          cell.alignment = { vertical: "middle", horizontal: "left" };
        });
      });
      worksheet.columns.forEach((column) => {
        let maxLength = 10;
        column.eachCell({ includeEmpty: true }, (cell) => {
          if (cell.value) {
            const cellLength = cell.value.toString().length;
            maxLength = Math.max(maxLength, cellLength);
          }
        });
        column.width = maxLength + 2;
      });
      worksheet.views = [
        {
          state: "frozen",
          ySplit: 1,
        },
      ];
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `${fileName}.xlsx`);
    } else if (type === "pdf") {
      const doc = new jsPDF();
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text(`Reporte de ${confirmedState.reportName}`, 10, 10);
      doc.setFontSize(12);
      doc.text(`Fecha: ${getCurrentDate()}`, 10, 20);
      const headers = Object.keys(data[0]);
      const body = data.map((row, rowIndex) =>
        headers.map((key) => ({
          content: row[key],
          styles: {
            fillColor: rowIndex % 2 === 0 ? [221, 235, 247] : [255, 255, 255],
            textColor: [0, 0, 0],
            halign: "left",
          },
        }))
      );
      doc.autoTable({
        startY: 40,
        head: [
          headers.map((header) => ({
            content: header,
            styles: { fillColor: [35, 81, 126], textColor: [255, 255, 255] },
          })),
        ],
        body,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          lineColor: [166, 166, 166],
          lineWidth: 0.1,
        },
      });
      doc.save(`${fileName}.pdf`);
    } else if (type === "word") {
      const headers = Object.keys(data[0]).map(
        (header) =>
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: header,
                    bold: true,
                    color: "FFFFFF",
                    size: 18,
                  }),
                ],
                alignment: "center",
              }),
            ],
            shading: { fill: "1F4E78" },
            margins: { top: 50, bottom: 50, left: 50, right: 50 },
            verticalAlign: "center",
            borders: {
              top: { size: 2, color: "FFFFFF" },
              bottom: { size: 2, color: "FFFFFF" },
              left: { size: 2, color: "FFFFFF" },
              right: { size: 2, color: "FFFFFF" },
            },
          })
      );

      const rows = data.map(
        (row, rowIndex) =>
          new TableRow({
            children: Object.values(row).map(
              (cell) =>
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: String(cell),
                          size: 16,
                        }),
                      ],
                      alignment: "left",
                    }),
                  ],
                  shading: {
                    fill: rowIndex % 2 === 0 ? "DDEBF7" : "FFFFFF",
                  },
                  margins: { top: 50, bottom: 50, left: 50, right: 50 },
                  verticalAlign: "center",
                  preferredWidth: { size: 0, type: "auto" },
                  borders: {
                    top: { size: 1, color: "A6A6A6" },
                    bottom: { size: 1, color: "A6A6A6" },
                    left: { size: 1, color: "A6A6A6" },
                    right: { size: 1, color: "A6A6A6" },
                  },
                })
            ),
          })
      );
      const table = new Table({
        rows: [new TableRow({ children: headers }), ...rows],
        width: { size: 100, type: "pct" },
      });
      const doc = new Document({
        sections: [
          {
            properties: {
              page: {
                size: {
                  orientation: "portrait",
                },
              },
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Reporte de ${confirmedState.reportName}`,
                    bold: true,
                    size: 28,
                  }),
                ],
                alignment: "left",
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Fecha: ${getCurrentDate()}`,
                    bold: true,
                    size: 26,
                  }),
                ],
                alignment: "left",
              }),
              new Paragraph({
                text: " ", // Espacio en blanco
              }),
              new Paragraph({
                text: " ", // Espacio en blanco
              }),
              table,
            ],
          },
        ],
      });

      const blob = await Packer.toBlob(doc);
      saveAs(blob, `${fileName}.docx`);
    }
  };

  //orden para tabla
  const sortedData = [...previewData].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const aValue = String(a[sortConfig.key]).toLowerCase();
    const bValue = String(b[sortConfig.key]).toLowerCase();

    return sortConfig.direction === "ascending"
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <NavbarPrincipal />
      <div className="flex items-center justify-center flex-grow p-6">
        <div className="w-full max-w-4xl">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">
            Generador de Reportes
          </h1>

          <form className="p-6 mb-8 bg-white rounded-lg shadow-lg">
            {/* Selección de Año */}
            <div>
              <label
                className="block mb-2 text-sm font-semibold text-gray-600"
                htmlFor="year"
              >
                Selecciona el Año
              </label>
              <select
                id="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-lightgreentxt"
              >
                <option value="">-- Selecciona un Año --</option>
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>

            {/* Selección de Tipo de Reporte */}
            <div>
              <label
                className="block mb-2 text-sm font-semibold text-gray-600"
                htmlFor="reportType"
              >
                Selecciona el Tipo de Reporte
              </label>
              <select
                id="reportType"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-lightgreentxt"
              >
                <option value="">-- Selecciona una Clasificación --</option>
                {Object.keys(reportOptions).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* Selección del Nombre del Reporte */}
            {reportType && (
              <div>
                <label
                  className="block mb-2 text-sm font-semibold text-gray-600"
                  htmlFor="reportName"
                >
                  Selecciona el Nombre del Reporte
                </label>
                <select
                  id="reportName"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-lightgreentxt"
                >
                  <option value="">-- Selecciona un Informe --</option>
                  {reportOptions[reportType].map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Botón Generar */}
            <div className="text-center">
              <button
                type="button"
                onClick={handleGenerateReport}
                className={`w-full px-4 py-2 mt-6 text-lg font-semibold text-white rounded-lg transition-all ${
                  year && reportType && reportName
                    ? "bg-bluelight hover:bg-lightgreentxt"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
                disabled={!year || !reportType || !reportName}
              >
                Generar Informe
              </button>
            </div>
          </form>

          <div className="pt-4 p-2 mb-8  ">
            {/* Mensajes */}
            {loading && (
              <p className="mt-6 text-center text-blue-500 animate-pulse">
                Generando reporte...
              </p>
            )}
            {errorMessage && (
              <p className="p-2 text-center text-red-500">{errorMessage}</p>
            )}

            {/* Botones para Descargar */}
            {showButtons && (
              <div className="mt-8 text-center">
                <h2 className="mb-4 text-xl font-bold text-darkbluetxt">
                  Reporte:{" "}
                  <span className="text-lightgreentxt">
                    {confirmedState.reportName}
                  </span>
                </h2>
                <p className="mb-6 text-sm text-gray-500">
                  Fecha de generación: {getCurrentDate()}
                </p>
                <div className="flex gap-4">
                  <button
                    onClick={() => generateFile("excel")}
                    className="flex items-center justify-center flex-1 px-4 py-2 text-sm font-semibold text-white transition-all bg-green-500 rounded-lg hover:bg-green-600"
                  >
                    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />{" "}
                    Excel
                  </button>
                  <button
                    onClick={() => generateFile("pdf")}
                    className="flex items-center justify-center flex-1 px-4 py-2 text-sm font-semibold text-white transition-all bg-red-500 rounded-lg hover:bg-red-600"
                  >
                    <FontAwesomeIcon icon={faFilePdf} className="mr-2" /> PDF
                  </button>
                  <button
                    onClick={() => generateFile("word")}
                    className="flex items-center justify-center flex-1 px-4 py-2 text-sm font-semibold text-white transition-all bg-blue-500 rounded-lg hover:bg-blue-600"
                  >
                    <FontAwesomeIcon icon={faFileWord} className="mr-2" /> Word
                  </button>
                </div>
              </div>
            )}

            <br />
            {/* Vista previa del informe */}
            {previewData.length > 0 && (
              <div className="mt-8">
                <div className="overflow-x-auto max-h-[40vh]">
                  <table className="w-full text-left border-collapse">
                    <thead className="text-white bg-darkbluetxt">
                      <tr>
                        {Object.keys(previewData[0]).map((header) => (
                          <th
                            key={header}
                            onClick={() => requestSort(header)}
                            className="sticky top-0 z-10 px-4 py-2 text-xs font-bold uppercase border-b border-gray-300 cursor-pointer bg-darkbluetxt"
                          >
                            <div className="flex items-center justify-between">
                              {header}
                              {sortConfig.key === header ? (
                                sortConfig.direction === "ascending" ? (
                                  <FontAwesomeIcon icon={faSortUp} />
                                ) : (
                                  <FontAwesomeIcon icon={faSortDown} />
                                )
                              ) : (
                                <FontAwesomeIcon icon={faSort} />
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {sortedData.map((row, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } hover:bg-gray-200 hover:text-gray-700`}
                        >
                          {Object.values(row).map((cell, idx) => (
                            <td
                              key={idx}
                              className="px-4 py-2 text-xs border-b border-gray-200"
                            >
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
