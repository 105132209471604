import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRouteButton = ({ children }) => {
    const { isAuthenticated, accessGranted } = useAuth();
    const location = useLocation();

    if (!isAuthenticated || !accessGranted) {
        return <Navigate to="/principal" replace state={{ from: location }} />;
    }

    return children;
};

export default ProtectedRouteButton;
