import React, { useState, useEffect } from "react";

const IdentificationForm = ({
  studentData,
  setStudentData,
  handleNextStep,
}) => {
  const [errors, setErrors] = useState({});
  const [isAdult, setIsAdult] = useState(false);

  // Calcula si el usuario es adulto en base a la fecha de nacimiento
  useEffect(() => {
    if (studentData.fecha_nacimiento) {
      const age = calculateAge(studentData.fecha_nacimiento);
      setIsAdult(age >= 18);
    }
  }, [studentData.fecha_nacimiento]);

  // Normaliza el texto eliminando tildes y convirtiendo a mayúsculas
  const normalizeText = (text) => {
    return text
      .toUpperCase()
      .replace(/[á]/gi, "A")
      .replace(/[é]/gi, "E")
      .replace(/[í]/gi, "I")
      .replace(/[ó]/gi, "O")
      .replace(/[ú]/gi, "U");
  };

  // Manejo de cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Formateo del DUI
    if (name === "dui" || name === "dui_responsable") {
      formattedValue = formatDUI(value);
    }

    // Normalizar los campos específicos
    if (
      [
        "primer_nombre",
        "segundo_nombre",
        "primer_apellido",
        "segundo_apellido",
      ].includes(name)
    ) {
      formattedValue = normalizeText(value);
    }

    setStudentData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  // Valida el formato de DUI salvadoreño
  const validateDUI = (dui) => {
    const regex = /^\d{8}-\d$/;
    return regex.test(dui);
  };

  // Formatea el DUI para el formato ########-#
  const formatDUI = (value) => {
    value = value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8) + "-" + value.slice(8, 9);
    }
    return value;
  };

  // Calcula la edad del usuario
  const calculateAge = (birthDateString) => {
    const today = new Date();
    const birthDate = new Date(birthDateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  // Validación del formulario
  const validate = () => {
    const newErrors = {};
    if (!studentData.primer_nombre.trim())
      newErrors.primer_nombre = "El primer nombre es requerido";

    if (!studentData.segundo_nombre.trim())
      newErrors.segundo_nombre = "El segundo nombre es requerido";

    if (!studentData.primer_apellido.trim())
      newErrors.primer_apellido = "El primer apellido es requerido";

    if (!studentData.segundo_apellido.trim())
      newErrors.segundo_apellido = "El segundo apellido es requerido";

    if (!studentData.nacionalidad.trim())
      newErrors.nacionalidad = "La nacionalidad es requerida";

    if (!studentData.fecha_nacimiento) {
      newErrors.fecha_nacimiento = "La fecha de nacimiento es requerida";
    } else {
      const age = calculateAge(studentData.fecha_nacimiento);
      setIsAdult(age >= 18);
    }

    if (isAdult && !validateDUI(studentData.dui)) {
      newErrors.dui = "El DUI es requerido y debe tener el formato ########-#";
    } else if (!isAdult && !validateDUI(studentData.dui_responsable)) {
      newErrors.dui_responsable =
        "El DUI del responsable debe tener el formato ########-#";
    }

    if (!studentData.genero) {
      newErrors.genero = "El género es requerido";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Función para manejar el envío del formulario y pasar al siguiente paso
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleNextStep();
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-2xl p-8 space-y-6 bg-white rounded-lg shadow-lg"
    >
      <h1 className="text-3xl font-bold text-center text-sky-700">
        Identificación
      </h1>

      {/* Primer Nombre */}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">
            Primer Nombre <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="primer_nombre"
            value={studentData.primer_nombre}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.primer_nombre && (
            <p className="text-red-600">{errors.primer_nombre}</p>
          )}
        </div>

        {/* Segundo Nombre */}
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">
            Segundo Nombre<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="segundo_nombre"
            value={studentData.segundo_nombre}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.segundo_nombre && (
            <p className="text-red-600">{errors.segundo_nombre}</p>
          )}
        </div>
      </div>

      {/* Apellidos */}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">
            Primer Apellido <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="primer_apellido"
            value={studentData.primer_apellido}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.primer_apellido && (
            <p className="text-red-600">{errors.primer_apellido}</p>
          )}
        </div>

        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">
            Segundo Apellido<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="segundo_apellido"
            value={studentData.segundo_apellido}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.segundo_apellido && (
            <p className="text-red-600">{errors.segundo_apellido}</p>
          )}
        </div>
      </div>

      {/* Fecha de Nacimiento */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">
          Fecha de Nacimiento <span className="text-red-500">*</span>
        </label>
        <input
          type="date"
          name="fecha_nacimiento"
          value={studentData.fecha_nacimiento}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
        {errors.fecha_nacimiento && (
          <p className="text-red-600">{errors.fecha_nacimiento}</p>
        )}
      </div>
      {/* Nacionalidad */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">
          Nacionalidad <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          placeholder="Salvadoreña"
          name="nacionalidad"
          value={studentData.nacionalidad}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        />
        {errors.nacionalidad && (
          <p className="text-red-600">{errors.nacionalidad}</p>
        )}
      </div>

      {/* DUI o DUI Responsable */}
      {isAdult ? (
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">
            DUI <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="dui"
            placeholder="00000000-0"
            value={studentData.dui}
            onChange={handleChange}
            maxLength={10}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.dui && <p className="text-red-600">{errors.dui}</p>}
        </div>
      ) : (
        <div>
          <label className="block mb-1 text-sm font-semibold text-gray-600">
            DUI del Responsable <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="dui_responsable"
            placeholder="00000000-0"
            value={studentData.dui_responsable}
            onChange={handleChange}
            maxLength={10}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
          />
          {errors.dui_responsable && (
            <p className="text-red-600">{errors.dui_responsable}</p>
          )}
        </div>
      )}

      {/* Género */}
      <div>
        <label className="block mb-1 text-sm font-semibold text-gray-600">
          Género <span className="text-red-500">*</span>
        </label>
        <select
          name="genero"
          value={studentData.genero}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-sky-500"
        >
          <option value="">Seleccione Género</option>
          <option value="Masculino">Masculino</option>
          <option value="Femenino">Femenino</option>
        </select>
        {errors.genero && <p className="text-red-600">{errors.genero}</p>}
      </div>
      {/* Botón de Enviar */}
      <button
        type="submit"
        className="px-4 py-2 text-lg font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
      >
        Siguiente
      </button>

      {/* Error de envío general */}
      {errors.submit && (
        <p className="p-2 mt-4 text-center text-red-600 bg-red-100 rounded-md">
          {errors.submit}
        </p>
      )}
    </form>
  );
};

export default IdentificationForm;
