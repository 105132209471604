import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu, FiX, FiChevronDown } from 'react-icons/fi';
import imgLogo from '../../assets/images/usoLogo.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setOpenDropdown(null);
    };

    const toggleDropdown = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdownName);
        }
    };

    const isActive = (path) => location.pathname === path;

    return (
        <nav className="relative z-50 w-full bg-gradient-to-r from-sky-500 via-sky-700 to-blue-900">
            <div className="hidden py-4 pr-10 space-x-4 text-white justify-items-start lg:flex ps-8">
                <div>
                    <Link to="https://www.usonsonate.edu.sv/" className="object-left">
                        <img src={imgLogo} alt="Logo" className="h-14" />
                    </Link>
                </div>
                <div className="flex items-center space-x-8 text-base text-nowrap ps-4">
                    <Link
                        to="/"
                        className={`border-b-2 transition-colors duration-300 ${isActive('/') ? 'text-green-500 border-b-green-500' : 'border-transparent hover:text-green-500 hover:border-b-green-500'
                            }`}
                    >
                        Inicio
                    </Link>
                    <a
                        href="https://www.usonsonate.edu.sv/institucion"
                        className="transition-colors duration-300 border-b-2 border-transparent hover:text-green-500 hover:border-b-green-500"
                    >
                        Institución
                    </a>
                    <div className="relative group">
                        <button
                            className="flex items-center text-white transition-colors duration-300 border-b-2 border-transparent hover:text-green-500"
                            onClick={() => toggleDropdown('facultades')}
                        >
                            Facultades <FiChevronDown className="ml-1" />
                        </button>
                        {openDropdown === 'facultades' && (
                            <div className="absolute left-0 z-40 w-64 pt-4 bg-white rounded-md shadow-lg text-wrap">
                                <a href="https://www.usonsonate.edu.sv/facultades/facultad-de-ciencias-juridicas-dr-mauro-alfredo-bernal-silva" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Facultad de Ciencias Jurídicas</a>
                                <a href="https://www.usonsonate.edu.sv/facultades/facultad-de-ciencias-naturales-e-ingenieria" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Facultad de Ciencias Naturales e Ingeniería</a>
                                <a href="https://www.usonsonate.edu.sv/facultades/facultad-de-ciencias-sociales-y-economicas" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Facultad de Ciencias Sociales y Económicas</a>
                                <a href="https://www.usonsonate.edu.sv/facultades/escuela-de-educacion" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Escuela de Educación</a>
                                <a href="https://www.usonsonate.edu.sv/facultades/maestrias" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Maestrías</a>
                            </div>
                        )}
                    </div>
                    <div className="relative group">
                        <button
                            className="flex items-center text-white transition-colors duration-300 border-b-2 border-transparent hover:text-green-500"
                            onClick={() => toggleDropdown('academica')}
                        >
                            Académica <FiChevronDown className="ml-1" />
                        </button>
                        {openDropdown === 'academica' && (
                            <div className="absolute left-0 z-40 w-64 pt-4 bg-white rounded-md shadow-lg text-wrap">
                                <a href="https://www.usonsonate.edu.sv/academica/calendario-de-actividades" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Calendario Académico</a>
                                <a href="https://www.usonsonate.edu.sv/academica/instructivo-de-matricula" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Instructivo de Matrícula</a>
                                <a href="https://www.usonsonate.edu.sv/academica/reglamento" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Reglamentos</a>
                            </div>
                        )}
                    </div>
                    <a
                        href="https://www.usonsonate.edu.sv/servicios"
                        className="transition-colors duration-300 border-b-2 border-transparent hover:text-green-500 hover:border-b-green-500"
                    >
                        Servicios
                    </a>
                    <a
                        href="https://www.usonsonate.edu.sv/educacion-continua"
                        className="transition-colors duration-300 border-b-2 border-transparent hover:text-green-500 hover:border-b-green-500"
                    >
                        Educación Continua
                    </a>
                    <a
                        href="https://www.usonsonate.edu.sv/vida-universitaria"
                        className="transition-colors duration-300 border-b-2 border-transparent hover:text-green-500 hover:border-b-green-500"
                    >
                        Vida Universitaria
                    </a>
                </div>
            </div>

            <div className="flex items-center justify-between px-4 py-5 text-white lg:hidden">
                <Link to="https://www.usonsonate.edu.sv/">
                    <img src={imgLogo} alt="Logo" className="h-14" />
                </Link>
                <button onClick={toggleMenu} className="focus:outline-none">
                    {isMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                </button>
            </div>

            {isMenuOpen && (
                <div className="absolute left-0 top-auto z-50 w-full text-gray-600 bg-white shadow-lg rounded-b-md lg:hidden">
                    <ul className="px-6 py-4 space-y-2">
                        <li>
                            <Link
                                to="/"
                                className={`block py-2 border-b border-gray-200 hover:text-green-500 ${isActive('/') ? 'text-green-500' : ''
                                    }`}
                            >
                                Inicio
                            </Link>
                        </li>
                        <li>
                            <a
                                href="https://www.usonsonate.edu.sv/institucion"
                                className="block py-2 border-b border-gray-200 hover:text-green-500"
                            >
                                Institución
                            </a>
                        </li>
                        <li>
                            <button
                                onClick={() => toggleDropdown('facultades')}
                                className="flex items-center justify-between w-full py-2 border-b border-gray-200 hover:text-green-500"
                            >
                                Facultades
                                <span>{openDropdown === 'facultades' ? '-' : '+'}</span>
                            </button>
                            {openDropdown === 'facultades' && (
                                <ul className="pl-4">
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/facultades/facultad-de-ciencias-juridicas-dr-mauro-alfredo-bernal-silva"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Facultad de Ciencias Jurídicas
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/facultades/facultad-de-ciencias-naturales-e-ingenieria"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Facultad de Ciencias Naturales e Ingeniería
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/facultades/facultad-de-ciencias-sociales-y-economicas"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Facultad de Ciencias Sociales y Económicas
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/facultades/escuela-de-educacion"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Escuela de Educación
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/facultades/maestrias"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Maestrías
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li>
                            <button
                                onClick={() => toggleDropdown('academica')}
                                className="flex items-center justify-between w-full py-2 border-b border-gray-200 hover:text-green-500"
                            >
                                Académica
                                <span>{openDropdown === 'academica' ? '-' : '+'}</span>
                            </button>
                            {openDropdown === 'academica' && (
                                <ul className="pl-4">
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/academica/calendario-de-actividades"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Calendario Académico
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/academica/instructivo-de-matricula"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Instructivo de Matrícula
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.usonsonate.edu.sv/academica/reglamento"
                                            className="block py-2 hover:text-green-500"
                                        >
                                            Reglamentos
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li>
                            <a
                                href="https://www.usonsonate.edu.sv/servicios"
                                className="block py-2 border-b border-gray-200 hover:text-green-500"
                            >
                                Servicios
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.usonsonate.edu.sv/educacion-continua"
                                className="block py-2 border-b border-gray-200 hover:text-green-500"
                            >
                                Educación Continua
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.usonsonate.edu.sv/vida-universitaria"
                                className="block py-2 border-b border-gray-200 hover:text-green-500"
                            >
                                Vida Universitaria
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
