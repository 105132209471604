import { useState } from "react";
import { useFetch } from "../tools/useFetch";
import { useAgrupar } from "../hooks/useAgrupar";
import { useAlert } from "../hooks/useAlert";

function useHome() {
    const [cursos, setCursos] = useState([]);
    const [diplomados, setDiplomados] = useState([]);
    const { getFetch, postFetch } = useFetch();
    const { agruparDatos } = useAgrupar();

    const { mensajeError } = useAlert();

    //Traer informacion de los cursos en inscripcion
    const obtenerCursos = (idUsuario) => {
        const urlParcial = `cursos/info/${idUsuario}`;
        getFetch(urlParcial)
            .then((data) => {
                const { datos, mensaje, estado } = data;
                if (estado) {
                    // Si no hay diplomados, podemos ignorar esta parte
                    const { cursos, diplomados } = agruparDatos(datos);
                    setCursos(cursos); // Actualiza el estado con los cursos procesados
                    setDiplomados(diplomados);
                } else {
                    mensajeError(mensaje); // Manejo de error si la API falla
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    //Parte para filtrar
    // Estado para controlar el tipo seleccionado
    const [tipoFiltro, setTipoFiltro] = useState('0'); // '0' para "Todos"

    // Manejador del cambio en el select
    const handleChange = (e) => {
        setTipoFiltro(e.target.value);
    };

    // Filtrar los cursos según el tipo seleccionado
    const cursosFiltrados = Object.values(cursos).filter((curso) => {
        if (tipoFiltro === '0') return true; // Todos
        if (tipoFiltro === '2' || tipoFiltro === '3') return curso.idTipoCurso === parseInt(tipoFiltro);
        return false; // Si se selecciona "1" (Diplomados), no mostramos cursos
    });

    return {
        handleChange,
        cursos,
        obtenerCursos,
        diplomados,
        cursosFiltrados,
        tipoFiltro
    }
}

export { useHome }