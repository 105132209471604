import React, { useState, useEffect } from "react";
import Select from "react-select";
import NavbarPrincipal from "./NavbarPrincipal";
import grupoService from "../../services/grupoService";
import docenteService from "../../services/docenteService";
import courseService from "../../services/courseService";

const AssignGroup = () => {
  const [grupos, setGrupos] = useState([]); // Asegurar que inicia como array
  const [docentes, setDocentes] = useState([]); // Asegurar que inicia como array
  const [cursos, setCursos] = useState([]); // Asegurar que inicia como array
  const [newGrupo, setNewGrupo] = useState({
    nombre: "",
    descripcion: "",
    hora_inicio: "",
    hora_fin: "",
    docentes: [], // Ahora múltiples docentes
    curso_id: null,
  });
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const [showModal, setShowModal] = useState(false); // Estado para el modal
  const [deleteId, setDeleteId] = useState(null); // Estado para almacenar el ID de eliminación

  useEffect(() => {
    fetchGrupos();
    fetchDocentes();
    fetchCursos();
  }, []);

  const fetchGrupos = async () => {
    try {
      const response = await grupoService.getAllGrupos();
      const gruposConDocentes = (response.data || []).map((grupo) => ({
        ...grupo,
        docentes: grupo.docentes ? JSON.parse(grupo.docentes) : [],
      }));
      setGrupos(gruposConDocentes);
    } catch (error) {
      console.error("Error fetching grupos:", error);
      setGrupos([]);
    }
  };

  const fetchDocentes = async () => {
    try {
      const response = await docenteService.getDocentes();
      setDocentes(
        (response.data || []).map((docente) => ({
          value: docente.idDocentes,
          label: `${docente.nombres} ${docente.apellidos}`,
        }))
      );
    } catch (error) {
      console.error("Error fetching docentes:", error);
      setDocentes([]);
    }
  };

  const fetchCursos = async () => {
    try {
      const response = await courseService.getCourses();
      setCursos(
        (response.data || []).map((curso) => ({
          value: curso.idCursos,
          label: curso.nombre,
        }))
      );
    } catch (error) {
      console.error("Error fetching cursos:", error);
      setCursos([]);
    }
  };

  const handleChange = (e) => {
    setNewGrupo({ ...newGrupo, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOptions, { name }) => {
    if (name === "docentes") {
      setNewGrupo({
        ...newGrupo,
        docentes: selectedOptions.map((option) => option.value),
      });
    } else {
      setNewGrupo({ ...newGrupo, [name]: selectedOptions.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editing) {
        await grupoService.updateGroup(editId, newGrupo);
      } else {
        await grupoService.createGroup(newGrupo);
      }
      setNewGrupo({
        nombre: "",
        descripcion: "",
        hora_inicio: "",
        hora_fin: "",
        docentes: [],
        curso_id: null,
      });
      setEditing(false);
      setEditId(null);
      fetchGrupos();
    } catch (error) {
      console.error("Error submitting grupo:", error);
    }
  };

  const handleEdit = (grupo) => {
    setNewGrupo({
      nombre: grupo.nombre,
      descripcion: grupo.descripcion,
      hora_inicio: grupo.hora_inicio,
      hora_fin: grupo.hora_fin,
      docentes: grupo.docentes || [],
      curso_id: grupo.curso_id,
    });
    setEditing(true);
    setEditId(grupo.idGrupos);
  };

  const handleDelete = async () => {
    try {
      await grupoService.deleteGroup(deleteId);
      setShowModal(false);
      setDeleteId(null);
      fetchGrupos();
    } catch (error) {
      console.error("Error deleting grupo:", error);
    }
  };

  const openModal = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setDeleteId(null);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <NavbarPrincipal />
      <div className="flex items-center justify-center flex-grow p-6">
        <div className="w-full max-w-4xl">
          <h1 className="mb-6 text-3xl font-bold text-center text-sky-700">
            Gestión de Grupos
          </h1>

          <form
            onSubmit={handleSubmit}
            className="p-6 mb-8 bg-white rounded-lg shadow-lg"
          >
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <input
                type="text"
                name="nombre"
                value={newGrupo.nombre}
                onChange={handleChange}
                placeholder="Nombre"
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="descripcion"
                value={newGrupo.descripcion}
                onChange={handleChange}
                placeholder="Descripción"
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="time"
                name="hora_inicio"
                value={newGrupo.hora_inicio}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="time"
                name="hora_fin"
                value={newGrupo.hora_fin}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded"
              />
              <Select
                isMulti
                name="docentes"
                options={docentes}
                onChange={handleSelectChange}
                placeholder="Seleccione los Docentes"
                value={docentes.filter((docente) =>
                  newGrupo.docentes.includes(docente.value)
                )}
                className="p-2 border border-gray-300 rounded"
              />
              <Select
                name="curso_id"
                options={cursos}
                onChange={(option) =>
                  handleSelectChange(option, { name: "curso_id" })
                }
                placeholder="Seleccione un Curso"
                value={
                  cursos.find((option) => option.value === newGrupo.curso_id) ||
                  null
                }
                className="p-2 border border-gray-300 rounded"
              />
            </div>
            <button
              type="submit"
              className="w-full p-2 mt-4 text-white rounded bg-sky-600 hover:bg-sky-700"
            >
              {editing ? "Actualizar Grupo" : "Crear Grupo"}
            </button>
          </form>

          <h2 className="mb-4 text-2xl font-semibold text-center text-sky-700">
            Listado de Grupos
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full bg-white border border-gray-300 rounded-lg shadow-md">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-3 text-left">Nombre</th>
                  <th className="p-3 text-left">Descripción</th>
                  <th className="p-3 text-left">Hora Inicio</th>
                  <th className="p-3 text-left">Hora Fin</th>
                  <th className="p-3 text-left">Docentes</th>
                  <th className="p-3 text-left">Curso</th>
                  <th className="p-3 text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {grupos.length === 0 ? (
                  <tr>
                    <td
                      className="p-3 text-center text-gray-600 border-t border-gray-300"
                      colSpan="7"
                    >
                      No hay grupos registrados.
                    </td>
                  </tr>
                ) : (
                  grupos.map((grupo) => (
                    <tr key={grupo.idGrupos} className="hover:bg-gray-100">
                      <td className="p-3 border-t border-gray-300">
                        {grupo.nombre}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {grupo.descripcion}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {grupo.hora_inicio}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {grupo.hora_fin}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {(grupo.docentes || [])
                          .map(
                            (docente) =>
                              `${docente.nombres} ${docente.apellidos}`
                          )
                          .join(", ")}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        {cursos.find((c) => c.value === grupo.curso_id)
                          ?.label || "N/A"}
                      </td>
                      <td className="p-3 border-t border-gray-300">
                        <button
                          onClick={() => handleEdit(grupo)}
                          className="p-1 mr-2 text-white bg-yellow-500 rounded hover:bg-yellow-600"
                        >
                          Editar
                        </button>
                        <button
                          onClick={() => openModal(grupo.idGrupos)}
                          className="p-1 text-white bg-red-500 rounded hover:bg-red-600"
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Modal de Confirmación */}
          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-70">
              <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-xl">
                <h2 className="mb-4 text-2xl font-bold text-center text-lightgreentxt">
                  Confirmación
                </h2>
                <p className="mb-6 text-center text-gray-600">
                  ¿Estás seguro de que deseas eliminar este grupo?
                </p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={handleDelete}
                    className="px-6 py-3 font-semibold text-white rounded-lg bg-bluelight hover:bg-sky-700 focus:outline-none"
                  >
                    Confirmar
                  </button>
                  <button
                    onClick={closeModal}
                    className="px-6 py-3 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignGroup;