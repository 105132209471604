import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AccountActivated = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirige a la página principal después de 3 segundos
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);

    // Limpia el temporizador si el componente se desmonta antes de los 3 segundos
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="mb-4 text-3xl font-bold text-green-600">¡Cuenta activada con éxito!</h1>
      <p className="text-lg text-gray-700">Serás redirigido a la página principal en unos segundos.</p>
      <p className="text-lg text-gray-700">Si no eres redirigido automáticamente, haz clic <a href="/" className="text-blue-500 hover:underline">aquí</a>.</p>
    </div>
  );
};

export default AccountActivated;
