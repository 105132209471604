import { useEffect, useState } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useAlert } from '../../hooks/useAlert';
import { Usuarios } from '../../models/Usuarios';
import { Roles } from '../../models/Roles';

function useGestionRol() {

    // Todas las constantes
    const { getFetch, putFetch } = useFetch();
    const { mensajeError, mensajeExitoso, mensajeAdvertencia } = useAlert();
    const [usuarios, setUsuarios] = useState([]);  // Lista completa de usuarios
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]); // Lista de usuarios filtrados
    const [showModal, setShowModal] = useState(false);
    const [elementoSelecionado, setElementoSeleccionado] = useState(null);
    const [rol, setRol] = useState('0'); // Valor de rol, por defecto es '0' (todos)
    const [searchText, setSearchText] = useState(''); // Estado para el texto de búsqueda

    // Cargar los usuarios cuando el componente se monta
    useEffect(() => {
        getUsuarios(); // Inicialmente obtenemos los usuarios
    }, []);

    // Filtrar usuarios cuando cambian el texto de búsqueda o el rol
    useEffect(() => {
        filtroUsuarios(); // Aplica los filtros al cargar los usuarios
    }, [usuarios, searchText, rol]); // Asegura que se apliquen filtros cada vez que cambian los usuarios, el rol o el texto de búsqueda

    // Filtra los usuarios por nombre y rol
    const filtroUsuarios = () => {
        let usuariosFiltrados = [...usuarios]; // Crear una copia de la lista de usuarios

        // Filtrar por rol
        if (rol !== '0') {
            usuariosFiltrados = usuariosFiltrados.filter(usuario => usuario.idRol === parseInt(rol));
        }

        // Filtrar por nombre (basado en nombres y apellidos)
        if (searchText) {
            usuariosFiltrados = usuariosFiltrados.filter(usuario => {
                const nombreCompleto = `${usuario.nombres} ${usuario.apellidos}`.toLowerCase();
                return nombreCompleto.includes(searchText.toLowerCase());
            });
        }

        setUsuariosFiltrados(usuariosFiltrados); // Actualiza la lista filtrada
    };

    // Obtener todos los usuarios (sin filtros)
    const getUsuarios = () => {
        const urlParcial = 'usuarios/';
        getFetch(urlParcial)
            .then((data) => {
                const { mensaje, estado, datos } = data;
                if (estado) {
                    const datosEstandarizados = datos.map(item => {
                        const usuarios = Usuarios(item.idUsuario, '', item.nombres, item.apellidos, '', '', item.dui);
                        const rol = Roles(item.idrol, item.rol);
                        return {
                            ...usuarios,
                            ...rol
                        };
                    });

                    setUsuarios(datosEstandarizados);
                    filtroUsuarios();
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError('Error inesperado, contacte al programador. ' + error);
            });
    };

    // Abre el modal
    const openModal = (usuario) => {
        setElementoSeleccionado(usuario);
        setShowModal(true);
    };

    // Cierra el modal
    const handleClose = () => setShowModal(false);

    // Continúa con el cambio de rol
    const handleContinue = () => {
        if (!Validar()) {
            mensajeAdvertencia("Seleccione un rol");
        } else {
            const datos = { idRol: elementoSelecionado.idrol };
            const id = elementoSelecionado.idUsuario;
            putOnClick(id, datos); // Cambiar rol
        }
    };

    // Valida si el usuario seleccionado tiene un rol
    const Validar = () => elementoSelecionado && elementoSelecionado.idrol != null && elementoSelecionado.idrol !== "";

    // Maneja el cambio de rol
    const handleRoleChange = (event) => {
        const selectedValue = event.target.value;
        setRol(selectedValue); // Actualiza el rol
        filtroUsuarios(); // Aplica los filtros después de cambiar el rol
    };

    // Maneja el cambio del texto de búsqueda
    const handleSearchChange = (event) => {
        setSearchText(event.target.value); // Actualiza el texto de búsqueda
    };

    // Cancela el modal
    const handleCancel = () => setShowModal(false);

    // Actualiza el rol del usuario
    const handleUpdateRol = (nuevoRol) => {
        setElementoSeleccionado(prev => ({
            ...prev,
            idrol: nuevoRol
        }));
    };

    // Realiza la actualización del rol del usuario
    const putOnClick = (id, datos) => {
        const urlParcial = `usuarios/rol/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso(mensaje);
                    // Después de actualizar el rol, recarga los usuarios
                    getUsuarios();  // Trae nuevamente todos los usuarios
                    setShowModal(false);
                } else {
                    mensajeError(mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador. " + error);
            });
    };

    return {
        usuarios: usuariosFiltrados, // Mostrar solo los usuarios filtrados
        openModal,
        handleCancel,
        handleClose,
        handleContinue,
        showModal,
        elementoSelecionado,
        handleUpdateRol,
        handleRoleChange,
        handleSearchChange,
        searchText,
        setSearchText
    }
}

export { useGestionRol };
