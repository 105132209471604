import { useState } from "react";
import { useFetch } from "../../tools/useFetch";
import { Horarios } from "../../models/Horarios";
import { useAlert } from "../../hooks/useAlert";
import { format } from "date-fns";

function useGestionHorarios({ obtenerCursos }) {
    const [horarioSeleccionado, setHorarioSeleccionado] = useState(null);
    const [showModalHorarios, setShowModalHorarios] = useState(false);
    const [diasSeleccionados, setDiasSeleccionados] = useState([]);
    const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();
    const { mensajeError, mensajeExitoso, mensajeInformacion, preguntarConfirmacion } = useAlert();

    const openModalHorarios = (horario = null) => {
        setHorarioSeleccionado(horario);
        setShowModalHorarios(true);
        const diasString = horario && horario.dias ? horario.dias : '';
        setDiasSeleccionados(diasString ? diasString.split(',').map(dia => dia.trim()) : []);
    };


    const handleChangeHorario = (e) => {

        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setHorarioSeleccionado(horarioPrevio => ({
            ...horarioPrevio,
            [name]: newValue,
        }));

    };

    const handleButtonClick = (dia) => {
        if (!dia) return;
        const nuevosDias = diasSeleccionados.includes(dia)
            ? diasSeleccionados.filter(d => d !== dia) // Si ya está, lo eliminamos
            : [...diasSeleccionados, dia]; // Si no, lo agregamos al final

        setDiasSeleccionados(nuevosDias);
        setHorarioSeleccionado(prev => ({
            ...prev,
            dias: nuevosDias.join(', ') // Convertimos el array de días a string para el objeto horario
        }));
    };

    const handleContinueHorario = () => {
        const horario = validarCamposGrupo();
        if (!horario) return;

        if (horario.idHorario) {
            putOnClick(horario.idHorario, horario);
        } else {
            postOnClick(horario);
        }
        setShowModalHorarios(false);
        //calcularTotalHorasPorDia(horario.horaInicio, horario.horaFin, horario.idGrupo);
    };


    const calcularTotalHorasPorDia = (horaInicio, horaFin, idGrupo) => {
        // Extraer solo la parte de la hora (HH:MM)
        const inicioHora = horaInicio.split(':').slice(0, 2).join(':'); // "07:00"
        const finHora = horaFin.split(':').slice(0, 2).join(':'); // "09:00"

        const inicio = new Date(`1970-01-01T${inicioHora}:00`);
        const fin = new Date(`1970-01-01T${finHora}:00`);

        // Calcular horas diarias
        const horasDiarias = (fin - inicio) / (1000 * 60 * 60); // Convertir de milisegundos a horas

        getDiasTotales(idGrupo, diasSeleccionados, horasDiarias);
    };


    const validarCamposGrupo = () => {

        const horario = new Horarios(
            horarioSeleccionado ? horarioSeleccionado.idHorario : null,
            horarioSeleccionado ? horarioSeleccionado.diaInicio.split('T')[0] : '',
            horarioSeleccionado ? horarioSeleccionado.diaFin.split('T')[0] : '',
            horarioSeleccionado ? (horarioSeleccionado.horaInicio instanceof Date) ? format((new Date(horarioSeleccionado ? horarioSeleccionado.horaInicio : '')), "HH:mm:00") : horarioSeleccionado.horaInicio : '',
            horarioSeleccionado ? (horarioSeleccionado.horaFin instanceof Date) ? format((new Date(horarioSeleccionado ? horarioSeleccionado.horaFin : '')), "HH:mm:00") : horarioSeleccionado.horaFin : '',
            horarioSeleccionado ? horarioSeleccionado.dias : '',
            horarioSeleccionado ? horarioSeleccionado.idGrupo : ''
        );

        if (!horario.diaInicio || !horario.diaFin || !horario.horaInicio || !horario.horaFin || !horario.dias || !horario.idGrupo) {
            mensajeInformacion("Por favor llenar los campos necesarios");
            return false;
        }
        return horario;
    };

    const postOnClick = (datos) => {
        const urlParcial = 'horarios/';
        postFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Datos insertados");
                    obtenerCursos();
                } else {
                    mensajeError("Error al insertar datos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    };

    const putOnClick = (id, datos) => {
        const urlParcial = `horarios/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Datos actualizados");
                    obtenerCursos();
                } else {
                    mensajeError("Error al actualizar datos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    };

    const deleteOnClick = async (id) => {
        const urlParcial = `horarios/${id}`;
        const confirmed = await preguntarConfirmacion(
            "¿Estás seguro que desea eliminar?",
            "Esta acción no se puede deshacer."
        );
      
        if (!confirmed) {
            return;
        } 
        deleteFetch(urlParcial)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Registro eliminado");
                    obtenerCursos();
                } else {
                    mensajeError("Error al eliminar registro: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    }

    const getDiasTotales = (idGrupo, dias, horasdiarias) => {
        const diasString = dias.join(',');
        const urlParcial = `horarios/calcularDias/${idGrupo}?dias=${diasString}`;
        getFetch(urlParcial)
            .then((data) => {
                const { datos } = data;

                if (datos) {
                    const cantidadDias = datos.cantidad_dias || 0; // Usar 0 si no existe
                    const idCurso = datos.idCurso || null; // Usar null si no existe
                    const idDiplomado = datos.idDiplomado || null; // Usar null si no existe

                    const totalHoras = horasdiarias * cantidadDias;
                    putDuracionCurso(idDiplomado, idCurso, { duracion: totalHoras }); // Enviar duración directamente
                }
            })
            .catch((error) => {
                console.log("Error al traer info de horarios->", error);
            });
    };

    const putDuracionCurso = (idDiplomado, id, datos) => {
        const urlParcial = `cursos/actualizarDuracion/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    putDuracionDiplomado(idDiplomado);
                } else {
                    mensajeError("Error al actualizar datos en cursos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado en cursos, contacte al programador: " + error);
            });
    };

    const putDuracionDiplomado = (id, datos) => {
        const urlParcial = `diplomados/actualizarDuracionDiplomado/${id}`;
        putFetch(urlParcial, datos)
            .then((respuesta) => {
                const { mensaje, estado } = respuesta;
                if (estado) {
                    mensajeExitoso("Datos actualizados");
                    obtenerCursos();
                } else {
                    mensajeError("Error al actualizar datos: " + mensaje);
                }
            })
            .catch((error) => {
                mensajeError("Error inesperado, contacte al programador: " + error);
            });
    };

    return {
        openModalHorarios,
        handleChangeHorario,
        handleContinueHorario,
        handleButtonClick,
        horarioSeleccionado,
        showModalHorarios,
        setShowModalHorarios,
        diasSeleccionados,
        deleteOnClick
    };
}

export { useGestionHorarios };
