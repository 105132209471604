function Inscripciones(idInscripcion = 0, activa = 0, fechaInscripcion = '', idParticipante = 0, idGrupo = 0) {

    return {
        idInscripcion,
        activa,
        fechaInscripcion,
        idParticipante,
        idGrupo
    };
}

export { Inscripciones }