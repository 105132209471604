import React from 'react';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { BtnEditar } from '../../buttons/BtnEditar';

function GestionHorarios({ horario, openModalHorarios, deleteOnClick }) {
    return (
        <div className="row mb-3" key={horario.idHorario}>
            {/* Columna para el texto */}
            <div className="col-8">
                <p>
                    Del: {new Date(horario.diaInicio).toLocaleDateString('es-ES', { day: '2-digit', month: 'short' }) + " "}
                    Al: {new Date(horario.diaFin).toLocaleDateString('es-ES', { day: '2-digit', month: 'short' })} - 
                    Días: {horario.dias} - De: {horario.horaInicio} A: {horario.horaFin}
                </p>
            </div>


            <div className="col-4 text-end">
                <BtnEditar titulo="Editar Horario" onclick={() => openModalHorarios(horario)} className="me-0" />
                <BtnEliminar titulo={'Eliminar Horario'} onclick={() => deleteOnClick(horario.idHorario)} className="ms-1" />
            </div>
        </div>
    );
}

export { GestionHorarios };
