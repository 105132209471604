import { useEffect, useState } from 'react';
import { BtnAgregar } from '../../buttons/BtnAgregar';
import { BarraBusqueda } from '../../components/BarraBusqueda';

const SeleccionCurso = ({ tipoFiltro, showModalCursosSeleccion, cicloSelect, obtenerCursosFinalizados, obtenerCursosConDetallesPlantillas, cursos, handleOnClick, esParaDetalles }) => {
    const [searchText, setSearchText] = useState(''); // Estado para el texto de búsqueda
    const [filteredCursos, setFilteredCursos] = useState([]); // Estado para cursos filtrados

    useEffect(() => {
        (esParaDetalles) ? obtenerCursosConDetallesPlantillas() : obtenerCursosFinalizados(tipoFiltro);
    }, [showModalCursosSeleccion]);

    useEffect(() => {
        // Filtra los cursos en base al texto de búsqueda
        const filtered = cursos.filter(curso =>
            curso.curso.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredCursos(filtered);
    }, [searchText, cursos]);

    return (
        <div>
            {/* Barra de búsqueda */}
            <BarraBusqueda
                placeholder={"Escriba el nombre del curso."}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />

            {/* Mostrar cursos filtrados */}
            {filteredCursos && filteredCursos.length > 0 ? (
                filteredCursos.map((curso) => (
                    <div key={curso.curso} className="card bg-color1 m-3">
                        <div className="card-header d-flex">
                            <div className="p-2 flex-grow-1 card-title text-color5">
                                <h4>{curso.curso}</h4>
                            </div>
                            <div className="p-2">
                                <BtnAgregar titulo={'Seleccionar y finalizar'} onclick={() => handleOnClick(cicloSelect, curso.idCurso)} />
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div>No hay cursos disponibles</div>
            )}
        </div>
    );
};

export { SeleccionCurso };
