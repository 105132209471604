import React, { useEffect, useState } from 'react';
import { useListadoCuentasPorCobrar } from '../detalles_cuentas_por_cobrar/useListadoCuentasPorCobrar';
import { BtnEditar } from '../../buttons/BtnEditar';
import { BtnEliminar } from '../../buttons/BtnEliminar';
import { useDetallesCuentasPorCobrar } from './useDetallesCuentasPorCobrar';
import { AbrirModal } from '../../components/AbrirModal';
import SeleccionFecha from './SeleccionFecha';

const ListaDetallesCuentasPorCobrar = () => {

  const {
    cursos,
    cuentasPendientes,
    cursoSeleccionado,
    handleCursoChange,
    getCuentasPendientesPorCurso,
    idParticipante
    //handleFinalizarSeleccion,
  } = useDetallesCuentasPorCobrar();

  const {
    deleteOnClick,
    showModal,
    handleClose,
    handleCancel,
    handleContinue,
    openModal,
    setFechaPago
  } = useListadoCuentasPorCobrar({
    cursoSeleccionado,
    idParticipante,
    getCuentasPendientesPorCurso    // Pasamos la función
  });

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1">
          <h1>Detalles de pago del curso</h1>
        </div>
      </div>

      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Estado</th>
              <th scope="col">Concepto</th>
              <th scope="col">Fecha de Vencimiento</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cuentasPendientes.length > 0 ? (
              cuentasPendientes.map((detalle) => (
                <tr key={detalle.idDetalleCuentaPorCobrar}>
                  <td>{(detalle.estado) ? detalle.estado : ''}</td>
                  <td>{(detalle.concepto) ? detalle.concepto : ''}</td>
                  <td>{(detalle.fechaVencimiento) ? detalle.fechaVencimiento.split('T')[0] : ''}</td>
                  <td>
                    {/*BtnPagar*/}
                    <BtnEditar
                      onclick={() => openModal(detalle)}
                      //onclick={() => validarCampos(detalle)}
                      titulo="Pagar"
                    />
                    <BtnEliminar
                      disabled={false}
                      onclick={() => deleteOnClick(detalle.idDetalleCuentaPorCobrar)}
                      titulo="Eliminar"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No hay detalles disponibles</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AbrirModal show={showModal}
        handleContinue={handleContinue}
        handleCancel={handleCancel}
        handleClose={handleClose}
        titulo={"Cambiar rol"}
        ocultar={false}>
          <SeleccionFecha establecerFecha={setFechaPago}/>
      </AbrirModal>
    </>
  );
};

export { ListaDetallesCuentasPorCobrar };
