import { useState, useEffect } from 'react';
import { useFetch } from '../../tools/useFetch';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../hooks/useAlert';
import { Departamentos } from '../../models/Departamentos';

const useListadoDepartamentos = () => {
  const { mensajeError, mensajeExitoso, mensajeAdvertencia, preguntarConfirmacion } = useAlert();
  const navegar = useNavigate();

  // Todas las constantes
  const [departamentos, setDepartamentos] = useState([]);
  const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState(Departamentos());
  const [operacion, setOperacion] = useState(1);
  const [titulo, setTitulo] = useState('');
  const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();

  // Función para abrir el modal
  const openModal = (op, departamentoSeleccionado = Departamentos()) => {
    setDepartamentoSeleccionado(departamentoSeleccionado);
    setOperacion(op);
    setTitulo(op === 1 ? 'Registrar Departamento' : 'Editar Departamento');
    navegar("/departamentosgestion", { state: { departamento: departamentoSeleccionado, operacion: op } });
  };

  // Validación de campos
  const validarCampos = () => {
    if (departamentoSeleccionado.departamento.trim() === '') {
      mensajeAdvertencia('¡Departamento vacío!');
      return false;
    }

    if (operacion === 1) {
      // Insertar
      postOnClick(departamentoSeleccionado);
    } else {
      // Actualizar
      const id = departamentoSeleccionado.idDepartamento;
      putOnClick(id, departamentoSeleccionado);
    }

    iradepartamentos();

  };

  // Obtener departamentos
  const getDepartamentos = (nombre = '') => {
    const urlParcial = `departamentos/name/${encodeURIComponent(nombre)}`;
    getFetch(urlParcial)
      .then((data) => {
        const { datos } = data;
        const datosEstandarizados = datos.map(item =>
          Departamentos(
            item.idDepartamento,
            item.departamento,
            item.codigo
          )
        )
        setDepartamentos(datosEstandarizados);
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  // Crear nuevo departamento
  const postOnClick = (datos) => {
    const urlParcial = 'departamentos/';

    postFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getDepartamentos();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  // Actualizar departamento
  const putOnClick = (id, datos) => {
    const urlParcial = `departamentos/${id}`;

    putFetch(urlParcial, datos)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getDepartamentos();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error inesperado, contacte al programador. ' + error);
      });
  };

  // Eliminar departamento
  const deleteOnClick = async (id) => {
    const urlParcial = `departamentos/${id}`;
    const confirmed = await preguntarConfirmacion(
      "¿Estás seguro que desea eliminar?",
      "Esta acción no se puede deshacer."
    );
    
    if (!confirmed){
      return;
    }

    deleteFetch(urlParcial)
      .then((respuesta) => {
        const { mensaje, estado } = respuesta;
        if (estado) {
          getDepartamentos();
          mensajeExitoso(mensaje);
        } else {
          mensajeError(mensaje);
        }
      })
      .catch((error) => {
        mensajeError('Error->', error);
      });
  };

  // Redirigir a la lista de departamentos
  const iradepartamentos = () => {
    navegar("/departamentos");
  };

  // Manejar cambios en el formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepartamentoSeleccionado(departamentoPrevio => ({ ...departamentoPrevio, [name]: value }));
  };

  // Manejar cambio en el campo de búsqueda
  const handleForSearchChange = (e) => {
    const { value } = e.target
    e.preventDefault();  // Previene la recarga de la página
    getDepartamentos(value);
  };

  return {
    getDepartamentos,
    handleForSearchChange,
    setOperacion,
    departamentos,
    handleChange,
    iradepartamentos,
    titulo,
    openModal,
    departamentoSeleccionado,
    setDepartamentoSeleccionado,
    deleteOnClick,
    validarCampos
  };
};

export { useListadoDepartamentos };
