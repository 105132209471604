
function DetallesPlantillas(idDetallePlantilla = 0, vencimiento  = '', orden = '', generarRecargo = false, valorRecargo = 0.0, exigible = false, idPlantilla = 0, idCodigoServicio = 0, idCiclo = 0, idCurso = 0) {
    return {
        idDetallePlantilla,
        vencimiento,
        orden, 
        generarRecargo, 
        valorRecargo, 
        exigible, 
        idPlantilla, 
        idCodigoServicio, 
        idCiclo, 
        idCurso
    }
}

export { DetallesPlantillas }