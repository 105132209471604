import React, { useEffect, useState } from 'react'

function CursosModal({ cursoSeleccionado, handleChange, imagen, handleChangeFile }) {

    return (
        <form className="row g-3 needs-validation" noValidate>
            <div className="col-md-2 d-none">
                <label htmlFor="txtIdDiplomado" className="form-label">ID Diplomado</label>
                <input
                    id='txtIdDiplomado'
                    onChange={handleChange}
                    value={(cursoSeleccionado) ? (cursoSeleccionado.idDiplomado) ? cursoSeleccionado.idDiplomado : '' : ''}
                    type="number"
                    className="form-control"
                    name="idDiplomado"
                />
            </div>
            <div className="col-md-2">
                <label htmlFor="txtIdCurso" className="form-label">IDCurso</label>
                <input
                    id='txtIdCurso'
                    onChange={handleChange}
                    value={(cursoSeleccionado) ? (cursoSeleccionado.idCurso) ? cursoSeleccionado.idCurso : '' : ''}
                    type="number"
                    className="form-control"
                    name="idCurso"
                    disabled
                />
            </div>
            <div className="col-md-8">
                <label htmlFor="txtCurso" className="form-label">Curso</label>
                <input
                    id='txtCurso'
                    onChange={handleChange}
                    value={(cursoSeleccionado) ? (cursoSeleccionado.curso) ? cursoSeleccionado.curso : '' : ''}
                    type="text"
                    className="form-control"
                    name="curso"
                    required
                />
            </div>
            <div className="col-md-2">
                <label htmlFor="txtDuracion" className="form-label">Duracion</label>
                <input
                    id='txtDuracion'
                    onChange={handleChange}
                    value={(cursoSeleccionado) ? (cursoSeleccionado.duracion) ? cursoSeleccionado.duracion : '' : ''}
                    type="number"
                    className="form-control"
                    name="duracion"
                />
            </div>
            <div className="col-md-4">
                <label htmlFor="txtPrecio" className="form-label">Precio</label>
                <input
                    id='txtPrecio'
                    onChange={handleChange}
                    value={(cursoSeleccionado) ? (cursoSeleccionado.precio) ? cursoSeleccionado.precio : '0.00' : '0.00'}
                    type="number"
                    className="form-control"
                    name="precio"
                    disabled
                />
            </div>
            <div className="col-md-4">
                <label htmlFor="txtImagen" className="form-label">CursoSeleccionadoar imagen</label>
                <input
                    id='txtImagen'
                    onChange={handleChangeFile}
                    type="file"
                    className="form-control"
                    name="imagen"
                    required
                />
            </div>
            <div className="col-md-4">
                {//console.log(cursoSeleccionado.imagen)
                }
                {imagen ? (
                    <img src={imagen} alt='Imagen' height='70px' width='140px' />
                ) : cursoSeleccionado && cursoSeleccionado.imagen ? (
                    <img src={cursoSeleccionado.imagen} alt='Imagen' height='70px' width='140px' />
                ) : (
                    <p>No se ha cursoSeleccionadoado ninguna imagen</p>
                )}
            </div>
            <div className="col-md-6">
                <label htmlFor="txtFechaInicio" className="form-label">Fecha Inicio</label>
                <input
                    id='txtFechaInicio'
                    onChange={handleChange}
                    value={(cursoSeleccionado) ? (cursoSeleccionado.fechaInicio) ? cursoSeleccionado.fechaInicio.split('T')[0] : '' : ''}
                    type="date"
                    className="form-control"
                    name="fechaInicio"
                    required
                />
            </div>
            <div className="col-md-6">
                <label htmlFor="txtFechaFin" className="form-label">Fecha Finalizacion</label>
                <input
                    id='txtFechaFin'
                    onChange={handleChange}
                    value={(cursoSeleccionado) ? (cursoSeleccionado.fechaFinalizacion) ? cursoSeleccionado.fechaFinalizacion.split('T')[0] : '' : ''}
                    type="date"
                    className="form-control"
                    name="fechaFinalizacion"
                    required
                />
            </div>
            <div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="estado"
                        id="inlineRadio1"
                        value="EDICION"
                        checked={ (cursoSeleccionado) ? (cursoSeleccionado.estado === 'EDICION') : false}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">EDICION</label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="estado"
                        id="inlineRadio2"
                        value="INSCRIPCION"
                        checked={ (cursoSeleccionado) ? (cursoSeleccionado.estado === 'INSCRIPCION') : false }
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">INSCRIPCION</label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="estado"
                        id="inlineRadio3"
                        value="PROCESO"
                        checked={ (cursoSeleccionado) ? (cursoSeleccionado.estado === 'PROCESO' ) : false }
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio3">PROCESO</label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="estado"
                        id="inlineRadio4"
                        value="FINALIZADO"
                        checked={ (cursoSeleccionado) ? (cursoSeleccionado.estado === 'FINALIZADO') : false }
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio4">FINALIZADO</label>
                </div>
            </div>

        </form>
    )
}

export { CursosModal }