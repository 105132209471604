import React, { useEffect, useState } from 'react';
import { useListadoParticipantes } from '../participantes/useListadoParticipantes';
import { BtnEditar } from '../../buttons/BtnEditar';
import { BarraBusqueda } from '../../components/BarraBusqueda';

const ListaParticipantes = () => {

  const [searchText, setSearchText] = useState(''); // Estado para el texto de búsqueda
  const [filteredParticipantes, setFilteredParticipantes] = useState([]); // Estado para cursos filtrados

  const {
    participantes,
    vercursos,
    getParticipantes,
    tiposCurso,
    ciclos,
    cursos,
    handleSelectChange,
    handleSolventesCheckbox,
    showSolventes
  } = useListadoParticipantes();

  useEffect(() => {
    getParticipantes();
  }, []);

  useEffect(() => {
    const filtered = participantes.filter((participante) => {
      // Filtrar por texto de búsqueda
      const matchesSearch = participante.nombres.toLowerCase().includes(searchText.toLowerCase());

      // Filtrar por estado solvente o insolvente
      const isSolvente = showSolventes
        ? participante.estadoCuenta === 'Pagado Completo'  // Mostrar los que ya pagaron
        : participante.estadoCuenta === 'Pendiente';     // Mostrar los que están en pendiente

      return matchesSearch && isSolvente;
    });

    setFilteredParticipantes(filtered);
  }, [searchText, participantes, showSolventes]);  // Asegúrate de incluir 'showSolventes' en las dependencias

  return (
    <>
      <div className="mt-custom d-flex">
        <div className="p-2 flex-grow-1">
          <h1>Participantes</h1>
          <div className="container-fluid">
            {/* Fila de filtros */}
            <div className="row mb-4">
              {/* Primer select */}

              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <select className="form-select"
                  onChange={handleSelectChange}
                  name="tipo">
                  <option value="0">Tipo curso</option>
                  {tiposCurso.map((tipo) => (
                    <option key={tipo.idTipoCurso} value={tipo.idTipoCurso}>
                      {tipo.tipo}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <select className="form-select"
                  onChange={handleSelectChange}
                  name="ciclo">
                  <option value="0">Seleccione un ciclo</option>
                  {ciclos.map(ciclo => (
                    <option key={ciclo.idCiclo} value={ciclo.idCiclo}>
                      {`${ciclo.ciclo} - ${ciclo.anio}`}
                    </option>
                  ))}
                </select>
              </div>

              {/* Filtro por selección 2 */}
              <div className="col-12 col-md-3 mb-3 mb-md-0">
                <select className="form-select"
                  onChange={handleSelectChange}
                  name="curso"
                >
                  <option value="0">Seleccione un curso</option>
                  {cursos.map(curso => (
                    <option key={curso.idCurso} value={curso.idCurso}>
                      {curso.curso}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Fila para el checkbox y barra de búsqueda */}
            <div className="row mb-3 d-flex flex-column align-items-start">
              {/* Checkbox */}
              <div className="col-12 d-flex align-items-center">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="expedienteCheckbox"
                    onChange={handleSolventesCheckbox} // Manejar el cambio en el checkbox
                  />
                  <label className="form-check-label ms-2" htmlFor="expedienteCheckbox">
                    Solventes
                  </label>
                </div>
              </div>

              {/* Barra de búsqueda */}
              <div className="col-6 mt-2">
                <BarraBusqueda
                  placeholder={"Escriba el nombre del participante."}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="table-responsive text-center">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Expediente</th>
              <th scope="col">Nombres</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Monto pendiente</th>
              <th scope="col">Total pagado</th>
              <th scope="col">Total a pagar</th>
              <th scope="col">estadoCuenta</th>
              <th scope="col">Acciones</th>

            </tr>
          </thead>
          <tbody>
            {filteredParticipantes ? filteredParticipantes.map((participante) => (
              <tr key={participante.idParticipante}>
                <td>{participante.expediente}</td>
                <td>{participante.nombres}</td>
                <td>{participante.apellidos}</td>
                <td>{participante.loQueFaltaPagar}</td>
                <td>{participante.totalPagado}</td>
                <td>{participante.totalAPagar}</td>
                <td>{participante.estadoCuenta}</td>
                <td>
                  <BtnEditar onclick={() => vercursos(participante.idParticipante, participante.idUsuario)} titulo={'Informacion pagos'} />
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ListaParticipantes };